import {useQuery} from '@apollo/client'
import React, {useEffect, useState} from 'react'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import {
  TPropertiesListResponse,
  TPropertiesListVariables,
} from '../../data/graphql/queries/properties/types'
import {GET_PROPERTIES} from '../../data/graphql/queries/properties'
import useToast from '../useToast'
import useUserAccess from '../useUserAccess'

const usePropertiesFilter = (initialValue?: number[]) => {
  const {showToast} = useToast()
  const userAccess = useUserAccess()
  const [properties, setProperties] = useState<CheckboxItem[]>([])

  const propertiesResponse = useQuery<TPropertiesListResponse, TPropertiesListVariables>(
    GET_PROPERTIES,
    {
      variables: {
        filter: {
          propertyId: {
            in: userAccess.properties,
          },
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Floor Plans Data',
          type: 'error',
        })
      },
    },
  )
  const propertiesList = propertiesResponse.data?.transactionalDb.allProperties.nodes

  useEffect(() => {
    if (propertiesList) {
      const newBuidlings: CheckboxItem[] = []

      propertiesList.forEach(property => {
        newBuidlings.push({
          id: +property.propertyId,
          name: property.propertyName,
          checked: !!initialValue?.includes(+property.propertyId),
        })
      })

      setProperties(newBuidlings)
    }
  }, [propertiesList])

  return [properties, setProperties] as const
}

export default usePropertiesFilter
