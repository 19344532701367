// Breadcrumbs.tsx

import React, {FC} from 'react'
import './Breadcrumbs.style.scss'
import Crumb, {CrumbProps} from './Crumb'
import BackButton from '../BackButton'

export interface BreadcrumbsProps {
  id?: string
  crumbs?: CrumbProps[]
  showBack?: boolean
  goBackUrl?: string
}

const defaultProps: BreadcrumbsProps = {
  crumbs: [{name: 'Home', url: '/'}],
  showBack: true,
}

const Breadcrumbs: FC<BreadcrumbsProps> = (props: BreadcrumbsProps) => {
  props = {...defaultProps, ...props}

  return (
    <div className={'breadcrumbs-container'} data-testid={'Breadcrumbs'}>
      {props.showBack && <BackButton to={props.goBackUrl} />}

      <ol
        id={props.id}
        className={'breadcrumbs'}
        itemScope
        itemType='https://schema.org/BreadcrumbList'
      >
        {props.crumbs?.map((crumb, index, array) => {
          return (
            <Crumb
              url={crumb.url}
              name={crumb.name}
              className={crumb.className}
              key={index}
              index={index + 1}
              isLast={array.length - 1 === index}
            />
          )
        })}
      </ol>
    </div>
  )
}

export default Breadcrumbs
