// components/Menu/Menu.tsx

import React, {FC} from 'react'
import './Menu.style.scss'

export interface MenuProps {
  id?: string
  children?: React.ReactNode
}

const defaultProps: MenuProps = {}

const Menu: FC<MenuProps> = (props: MenuProps) => {
  props = {...defaultProps, ...props}

  return (
    <ul id={props.id} className={'menu'} data-testid={'Menu'}>
      {props.children}
    </ul>
  )
}

export default Menu
