import {FC} from 'react'

const Sliders: FC = () => (
  <g>
    <path d='M4 21V14' />
    <path d='M4 10V3' />
    <path d='M12 21V12' />
    <path d='M12 8V3' />
    <path d='M20 21V16' />
    <path d='M20 12V3' />
    <path d='M1 14H7' />
    <path d='M9 8H15' />
    <path d='M17 16H23' />
  </g>
)

export default Sliders
