import Activity from './Icons/Activity'
import AlertCircle from './Icons/AlertCircle'
import AlertTriangle from './Icons/AlertTriangle'
import ArrowDown from './Icons/ArrowDown'
import ArrowUp from './Icons/ArrowUp'
import AtSign from './Icons/AtSign'
import Bell from './Icons/Bell'
import BellOff from './Icons/BellOff'
import Bookmark from './Icons/Bookmark'
import Calendar from './Icons/Calendar'
import Camera from './Icons/Camera'
import Check from './Icons/Check'
import CheckCircle from './Icons/CheckCircle'
import ChevronDown from './Icons/ChevronDown'
import ChevronLeft from './Icons/ChevronLeft'
import ChevronRight from './Icons/ChevronRight'
import ChevronUp from './Icons/ChevronUp'
import ChevronsLeft from './Icons/ChevronsLeft'
import ChevronsRight from './Icons/ChevronsRight'
import Circle from './Icons/Circle'
import Clipboard from './Icons/Clipboard'
import Close from './Icons/Close'
import Copy from './Icons/Copy'
import CreditCard from './Icons/CreditCard'
import DollarSign from './Icons/DollarSign'
import Droplet from './Icons/Droplet'
import Edit from './Icons/Edit'
import Edit2 from './Icons/Edit2'
import Eye from './Icons/Eye'
import EyeOff from './Icons/EyeOff'
import File from './Icons/File'
import FileText from './Icons/FileText'
import Filter from './Icons/Filter'
import Flag from './Icons/Flag'
import Grid from './Icons/Grid'
import Hash from './Icons/Hash'
import Home from './Icons/Home'
import Image from './Icons/Image'
import Info from './Icons/Info'
import Layout from './Icons/Layout'
import Link from './Icons/Link'
import List from './Icons/List'
import Loader from './Icons/Loader'
import Lock from './Icons/Lock'
import Logout from './Icons/Logout'
import Mail from './Icons/Mail'
import MapPin from './Icons/MapPin'
import Menu from './Icons/Menu'
import MoreVertical from './Icons/MoreVertical'
import Package from './Icons/Package'
import Paperclip from './Icons/Paperclip'
import Phone from './Icons/Phone'
import Plus from './Icons/Plus'
import Search from './Icons/Search'
import Send from './Icons/Send'
import Settings from './Icons/Settings'
import Sliders from './Icons/Sliders'
import Thermometr from './Icons/Thermometr'
import Tag from './Icons/Tag'
import Tool from './Icons/Tool'
import Unlock from './Icons/Unlock'
import Upload from './Icons/Upload'
import User from './Icons/User'
import UserPlus from './Icons/UserPlus'
import Users from './Icons/Users'
import XCircle from './Icons/XCircle'
import Zap from './Icons/Zap'
import Loader2 from './Icons/Loader2'
import BatteryEmpty from './Icons/BatteryEmpty'
import BatteryLow from './Icons/BatteryLow'
import BatteryPartial from './Icons/BatteryPartial'
import BatteryFull from './Icons/BatteryFull'
import WiFi from './Icons/WiFi'
import WiFiOff from './Icons/WiFiOff'
import Refresh from './Icons/Refresh'

export const Icons = {
  'activity': <Activity />,
  'alert-circle': <AlertCircle />,
  'alert-triangle': <AlertTriangle />,
  'arrow-down': <ArrowDown />,
  'arrow-up': <ArrowUp />,
  'at-sign': <AtSign />,
  'bell': <Bell />,
  'bell-off': <BellOff />,
  'bookmark': <Bookmark />,
  'calendar': <Calendar />,
  'camera': <Camera />,
  'check': <Check />,
  'check-circle': <CheckCircle />,
  'chevron-down': <ChevronDown />,
  'chevron-left': <ChevronLeft />,
  'chevron-right': <ChevronRight />,
  'chevron-up': <ChevronUp />,
  'chevrons-left': <ChevronsLeft />,
  'chevrons-right': <ChevronsRight />,
  'circle': <Circle />,
  'clipboard': <Clipboard />,
  'close': <Close />,
  'copy': <Copy />,
  'credit-card': <CreditCard />,
  'dollar-sign': <DollarSign />,
  'droplet': <Droplet />,
  'edit': <Edit />,
  'edit-2': <Edit2 />,
  'eye': <Eye />,
  'eye-off': <EyeOff />,
  'file': <File />,
  'file-text': <FileText />,
  'filter': <Filter />,
  'flag': <Flag />,
  'grid': <Grid />,
  'hash': <Hash />,
  'home': <Home />,
  'image': <Image />,
  'info': <Info />,
  'layout': <Layout />,
  'link': <Link />,
  'list': <List />,
  'loader': <Loader />,
  'loader2': <Loader2 />,
  'lock': <Lock />,
  'logout': <Logout />,
  'mail': <Mail />,
  'map-pin': <MapPin />,
  'menu': <Menu />,
  'more-vertical': <MoreVertical />,
  'package': <Package />,
  'paperclip': <Paperclip />,
  'phone': <Phone />,
  'plus': <Plus />,
  'search': <Search />,
  'send': <Send />,
  'settings': <Settings />,
  'sliders': <Sliders />,
  'tag': <Tag />,
  'thermometr': <Thermometr />,
  'tool': <Tool />,
  'unlock': <Unlock />,
  'upload': <Upload />,
  'user': <User />,
  'user-plus': <UserPlus />,
  'users': <Users />,
  'x-circle': <XCircle />,
  'zap': <Zap />,
  'battery-empty': <BatteryEmpty />,
  'battery-low': <BatteryLow />,
  'battery-partial': <BatteryPartial />,
  'battery-full': <BatteryFull />,
  'wifi': <WiFi />,
  'wifi-off': <WiFiOff />,
  'refresh': <Refresh />,
}

export type IconOptions = keyof typeof Icons
