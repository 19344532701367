import {FC} from 'react'

const Eye: FC = () => (
  <g>
    <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' />
    <circle cx='12' cy='12' r='3' />
  </g>
)

export default Eye
