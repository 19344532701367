// components/Carousel/Carousel.tsx

import React, {FC, JSXElementConstructor, ReactElement, useEffect, useState} from 'react'
import {useSwipeable} from 'react-swipeable'
import './Carousel.style.scss'

export interface CarouselProps {
  id?: string
  children: Array<ReactElement<any, string | JSXElementConstructor<any>>>
}

interface CarouselItemProps {
  children: React.ReactNode
  width?: number
}

export const CarouselItem: FC<CarouselItemProps> = ({children, width}) => {
  return (
    <div style={{width: width}} className='carousel-item'>
      {children}
    </div>
  )
}

const Carousel: FC<CarouselProps> = (props: CarouselProps) => {
  props = {...props}
  const [activeIndex, setActiveIndex] = useState(0)
  const [paused, setPaused] = useState(false)

  const updateIndex = newIndex => {
    if (newIndex < 0) {
      newIndex = React.Children.count(props.children) - 1
    } else if (newIndex >= React.Children.count(props.children)) {
      newIndex = 0
    }

    setActiveIndex(newIndex)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1)
      }
    }, 3000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  })

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  })

  return (
    <div
      {...handlers}
      className='carousel'
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div className='inner' style={{transform: `translateX(-${activeIndex * 100}%)`}}>
        {React.Children.map(props.children, (child, index) => {
          return React.cloneElement(child, {width: '100%'})
        })}
      </div>
      <div className='indicators'>
        {React.Children.map(props.children, (child, index) => {
          return (
            <div
              className={`${index === activeIndex ? 'active' : ''}`}
              onClick={() => {
                updateIndex(index)
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Carousel
