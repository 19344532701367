import {useEffect, useMemo, useState} from 'react'

const useIsInViewport = (ref: any) => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      }),
    [],
  )

  useEffect(() => {
    if (ref) {
      observer.observe(ref)
    }

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

export default useIsInViewport
