import './Search.style.scss'
import {FC} from 'react'
import Row from '../../../components/Grid/Row'

type TSearchProps = {
  children: React.ReactNode[]
}

const Search: FC<TSearchProps> = ({children}) => {
  return (
    <Row>
      <form className={'filter-form'} data-testid='search-form'>
        {children}
      </form>
    </Row>
  )
}

export default Search
