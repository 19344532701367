// layouts/People/People.tsx

import React, {useEffect, useState} from 'react'
import './Reports.style.scss'
import {Outlet} from 'react-router-dom'
import Row from '../../components/Grid/Row'
import Column from '../../components/Grid/Column'
import Section from '../../components/Grid/Section'
import Tabbar from '../../components/Tabbar'
import ExportTable from '../../components/ExportTable/ExportTable'
import {capitalize} from '../../functions'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

const ReportsLayout = () => {
  // TABS
  const [selectedTab, setSelectedTab] = useState<string>('people')

  useEffect(() => {
    const pathname = window.location.pathname

    setSelectedTab(pathname.split('/')[2])
  }, [])

  const tabs = [{label: 'People', key: 'people', to: '/reports/people/residents'}]

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'reports-layout'} data-testid={'PeopleLayout'}>
        <Section spacing={'md'}>
          <div className='d-flex space-between align-center'>
            <h3 className={'color-black overview-title'}>Reports</h3>
            <ExportTable fileName={capitalize(selectedTab)} />
          </div>
        </Section>

        <Section>
          <Row>
            <Column>
              <div className='d-flex align-end tabbar-place'>
                <Tabbar tabs={tabs} selected={selectedTab} onTabChange={setSelectedTab} />
              </div>

              <Outlet />
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default ReportsLayout
