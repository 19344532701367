import {shuffle} from 'd3'
import {validatePasword} from './validator.functions'

/** converts an array to a string formatted as CSS */
export function toCSSString(params) {
  return params && params.length > 0
    ? Object.keys(params)
        .map(key => key + ':' + params[key])
        .join(';')
    : null
}

/** Converts any string to kebab-case */
export function toKebabCase(str: string) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // get all lowercase letters that are near to uppercase ones
    .replace(/[\s_]+/g, '-') // replace all spaces and low dash
    .toLowerCase() // convert to lower case
}

/** normalizes values for more accurate comparisons */
export function normalizeValue(value: string | number): string {
  return value?.toString().toLowerCase().replace(/\s/g, '')
}

/** Converts an object of type QueryOptions into a query string */
export function toQueryString(params): string | null {
  return params
    ? '?' +
        Object.keys(params)
          .map(key => key + '=' + params[key])
          .join('&')
    : null
}

/** converts a string to a url friendly slug */
export function slugify(str: string | number) {
  return str
    .toString()
    .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '') // removes special characters
    .replace(/([a-z])([A-Z])/g, '$1-$2') // get all lowercase letters that are near to uppercase ones
    .replace(/[\s_]+/g, '-') // replace all spaces and low dash
    .toLowerCase() // convert to lower case
}

/** normalizes string for comparison */
export function normalize(str: string | number): string {
  return str.toString().trim().toLowerCase().normalize()
}

/** formats a phone number */
export function formatPhoneNumber(str?: string | null) {
  if (!str) {
    return ''
  }

  const phoneNumber = clearPhoneNumberFormatting(str)
  let formattedNumber = phoneNumber

  if (!phoneNumber.startsWith('+')) {
    formattedNumber = '+1' + phoneNumber
  }

  const countryCode = formattedNumber.substring(0, 2)
  const area = formattedNumber.substring(2, 5)
  const front = formattedNumber.substring(5, 8)
  const end = formattedNumber.substring(8, 12)

  if (front) {
    formattedNumber = countryCode + ' (' + area + ') ' + front
  }

  if (end) {
    formattedNumber += '-' + end
  }

  return formattedNumber
}

export function clearPhoneNumberFormatting(str: string) {
  if (!str) {
    return ''
  }

  return str.replace(/[^0-9+]/g, '')
}

/** Returns a random string */
export function randomString(len = 16): string {
  return Math.random().toString(36).substr(2, len).toString()
}

/** Returns the extension on the end of a file name */
// export function getFileExtension(filename: string): string | null {
//   return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename).toString().toLowerCase() : null;
// }

/** formats the input into a comma seperated string */
export function numberWithCommas(x: string | number): string {
  return x
    .toString()
    .trim()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/** Check to see if input is a number */
export function isNumber(x): boolean {
  return typeof x === 'number'
}

/** Checks to see if input is a string */
export function isString(x): boolean {
  return typeof x === 'string'
}

/** removes all spaces from string */
export function removeWhitespace(str: string): string {
  return str.replace(/ /g, '')
}

/** make all words with first character in uppercase regex */
export function capitalize(str?: string | null): string {
  if (!str) {
    return ''
  }

  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  )
}

export function limitString(str = '', length = 100) {
  if (!str) {
    return ''
  } else if (str?.length > length) {
    return str.substring(0, length) + '...'
  }

  return str
}

export const generatePassword = (length = 10) => {
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz'.split('')
  const numberChars = '0123456789'.split('')
  const symbolChars = '!@#$^()_!@#$^()_'.split('')

  const allowedChars = shuffle([
    ...uppercaseChars,
    ...lowercaseChars,
    ...numberChars,
    ...symbolChars,
  ])

  const getPassword = () => {
    const result: string[] = []

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allowedChars.length)
      result.push(allowedChars[randomIndex])
    }

    return result.join('')
  }

  let password = getPassword()

  while (!validatePasword(password, length)) {
    password = getPassword()
  }

  return password
}
