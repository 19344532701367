// components/ButtonRow/ButtonRow.tsx

import React, {FC} from 'react'
import './ButtonRow.style.scss'

export interface ButtonRowProps {
  id?: string
  align?: 'left' | 'center' | 'right'
  children?: React.ReactNode
}

const defaultProps: ButtonRowProps = {
  align: 'left',
}

const ButtonRow: FC<ButtonRowProps> = (props: ButtonRowProps) => {
  props = {...defaultProps, ...props}

  return (
    <div id={props.id} className={'button-row'} data-testid={'ButtonRow'}>
      <div className={`btn-row align-${props.align}`}>{props.children}</div>
    </div>
  )
}

export default ButtonRow
