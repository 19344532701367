// components/Menu/MenuItem.tsx

import React, {FC} from 'react'
import './Menu.style.scss'
import Icon from '../Icon'
import {IconOptions} from '../Icon/Icons.d'

export interface MenuItemProps {
  icon?: IconOptions
  theme?: 'default' | 'danger'
  children?: React.ReactNode
  onClick?
}

const defaultProps: MenuItemProps = {
  icon: 'circle',
  theme: 'default',
}

const MenuItem: FC<MenuItemProps> = (props: MenuItemProps) => {
  props = {...defaultProps, ...props}

  return (
    <ul
      className={`menu-item ${props.theme}`}
      data-testid={'MenuItem'}
      onClick={props.onClick}
    >
      <Icon icon={props.icon || 'circle'} size={'sm'} />
      <div className={'menu-item-label'}>{props.children}</div>
    </ul>
  )
}

export default MenuItem
