import {gql} from '@apollo/client'

export const GET_ALL_DEVICES = gql`
  query AllDeviceInventoryViews(
    $first: Int, 
    $offset: Int, 
    $condition: DeviceInventoryViewCondition, 
    $filter: DeviceInventoryViewFilter,
    $orderBy: [DeviceInventoryViewsOrderBy!]
  ) {
    transactionalDb {
      allDeviceInventoryViews(
        first: $first, 
        offset: 
        $offset, 
        condition: $condition, 
        filter: $filter,
        orderBy: $orderBy
      ) {
        totalCount
        nodes {
          id: installedDeviceId
          lockId
          deviceTypeId
          deviceLocation
          deviceType
          deviceStatusCurrent
          buildingNumber
          unitNumber
          deviceBatteryLevel
          deviceStatusUpdated
          deviceBatteryLevelUpdated
          installedDeviceId
          masterPinStatus
        }
      }
    }
  }
`

export const GET_LOCK_IDS_BY_INSTALLED_DEVICE_IDS = gql`
  query GetLockIdsByDeviceIds($filter: DeviceInventoryViewFilter) {
    transactionalDb {
      allDeviceInventoryViews(filter: $filter) {
        nodes {
          lockId
        }
      }
    }
  }
`

export const GET_ACCESS_POINTS_DEVICES = gql`
  query AllDeviceInventoryViews(
    $condition: DeviceInventoryViewCondition,
    $filter: DeviceInventoryViewFilter
  ) {
    transactionalDb {
      allDeviceInventoryViews(condition: $condition, filter: $filter) {
        nodes {
          unitId
          unitNumber
          deviceTypeId
          deviceLocationTypeId

          installedDeviceId
          lockId
        }
      }
    }
  }
`

export const GET_ACTIVE_WORK_ASSIGMENTS = gql`
  query AllActiveAssignments(
    $condition: ActiveDeviceWorkAssignmentViewCondition,
    $filter: ActiveDeviceWorkAssignmentViewFilter
  ) {
    transactionalDb {
      assignments: allActiveDeviceWorkAssignmentViews(
        condition: $condition, 
        filter: $filter
      ) {
        nodes {
          id: deviceWorkAssignmentId
          unitId
          unitNumber
          propertyId
          buildingId
          buildingName
          deviceTypeId
          deviceClassTypeId
          deviceLocationTypeId
        }
      }
    }
  }
`

export const GET_ALL_ASSIGNED_DEVICES = gql`
  query AllAssignedDevices(
    $assignmentsCondition: DeviceWorkAssignmentCondition, 
    $assignmentsFilter: DeviceWorkAssignmentFilter, 
    $devicesFilter: InstalledDeviceFilter
  ) {
    transactionalDb {
      assignments: allDeviceWorkAssignments(filter: $assignmentsFilter, condition: $assignmentsCondition) {
        totalCount
        nodes {
          unitId
          deviceTypeId
          assignedVendorId
          installedDeviceId
          deviceLocationTypeId
          deviceWorkAssignmentId
          deviceTypeByDeviceTypeId {
            deviceClassTypeId
          }
        }
      }
      devices: allInstalledDevices(filter: $devicesFilter) {
        nodes {
          unitId,
          installedDeviceId,
          deviceLocationTypeId,
          deviceType: deviceTypeByDeviceTypeId {
            id: deviceTypeId
            deviceClassTypeId
          }
          assignments: deviceWorkAssignmentsByInstalledDeviceId(condition: $assignmentsCondition, first: 1) {
            nodes {
              id: deviceWorkAssignmentId
              assignedVendorId
            }
          }
        }
      }
    }
  }
`

export const GET_DEVICES_STATUS = gql`
  query AllDeviceInventoryViews(
    $lockLowBatteryCondition: DeviceInventoryViewCondition, 
    $lockLowBatteryFilter: DeviceInventoryViewFilter, 
    $lockOfflineCondition: DeviceInventoryViewCondition,
    $lockOfflineFilter: DeviceInventoryViewFilter,
    $thermostatOfflineCondition:DeviceInventoryViewCondition
    $thermostatOfflineFilter:DeviceInventoryViewFilter
  ) {
  transactionalDb {
    locksLowBattery:allDeviceInventoryViews(
      condition: $lockLowBatteryCondition, 
      filter: $lockLowBatteryFilter
    ) {
      totalCount
    }
    
    locksOffline:allDeviceInventoryViews(
      condition: $lockOfflineCondition,
      filter: $lockOfflineFilter,
    ) {
      totalCount
    }

    theromstatsOffline:allDeviceInventoryViews(
      condition: $thermostatOfflineCondition,
      filter: $thermostatOfflineFilter,
    ) {
      totalCount
    }
  }
}
`

export const GET_THERMOSTATS = gql`
  query GetThermostats(
    $first: Int, 
    $offset: Int, 
    $condition: ThermostatStatusViewCondition, 
    $filter: ThermostatStatusViewFilter, 
    $orderBy: [ThermostatStatusViewsOrderBy!]
  ) {
    transactionalDb {
      allThermostatStatusViews(first: $first, offset: $offset, condition: $condition, filter: $filter, orderBy: $orderBy) {
        totalCount
        nodes {
          propertyName
          buildingName
          unitNumber
          deviceType
          location
          mode
          heatCelsiusSetting
          coolCelsiusSetting
          ambientHumidityPercent
          ambientTemperatureCelsius
          lastUpdatedDt
        }
      }
    }
  }
`

export const GET_LOCK_MASTER_PIN = gql`
  query GetLockMasterPin($input: DecryptMasterPinFromDatabaseInput!) {
    lock {
      decryptMasterPinFromDatabase(input: $input) {
        ... on DecryptMasterPinFromDatabaseResult {
          pin
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`

export const REQUEST_MASTER_PIN_RESET = gql`
  mutation RequestMasterPinReset($input: RequestMasterPinResetInput!) {
    lock {
      requestMasterPinReset(input: $input) {
        ... on RequestSubmitted {
          submittedTransactionId
        }
        ... on RequestAlreadyPending {
          pendingTransactionId
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`
