import './Staff.style.scss'

import {useCallback, useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import DataGrid from '../../../components/DataGrid'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import Paginator from '../../../components/Paginator'
import Panel from '../../../components/Panel'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import {ResidentPreview} from '../Residents/Residents'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import useStaffData from '../../../hooks/data/useStaffData'
import StaffFilters, {TStaffFilterFields} from './StaffFilters'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import {capitalize, toCommonDateFormat} from '../../../functions'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'
import DeactivatePersonCell from '../../../components/DeactivatePersonCell'

const PAGE_SIZE = 10

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'nameWithAvatar:asc', value: 'EMPLOYEE_NAME_ASC', label: 'Name Acs'},
  {sortKey: 'nameWithAvatar:desc', value: 'EMPLOYEE_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'role:asc', value: 'DEPARTMENT_DESCRIPTION_ASC', label: 'Role Asc'},
  {sortKey: 'role:desc', value: 'DEPARTMENT_DESCRIPTION_DESC', label: 'Role Desc'},
  {sortKey: 'property:asc', value: 'PROPERTY_NAME_ASC', label: 'Property Acs'},
  {sortKey: 'property:desc', value: 'PROPERTY_NAME_DESC', label: 'Property Desc'},
  {
    sortKey: 'propertyGroup:asc',
    value: 'CATEGORY_DESCRIPTION_ASC',
    label: 'Property Group Acs',
  },
  {
    sortKey: 'propertyGroup:desc',
    value: 'CATEGORY_DESCRIPTION_DESC',
    label: 'Property Group Desc',
  },
  {sortKey: 'hireDate:asc', value: 'HIRE_DATE_ASC', label: 'Hire Date Asc'},
  {sortKey: 'hireDate:desc', value: 'HIRE_DATE_DESC', label: 'Hire Date Desc'},
]

const Staff = () => {
  const navigate = useNavigate()
  const [staff, setStaff] = useState<any[]>([])

  const {setQuery} = useContext(ExportTableContext)

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    setQueryOptions,
    onChangeNumberOfItems,
  } = useQueryOptions<TStaffFilterFields>({
    limit: PAGE_SIZE,
    page: 1,
    orderBy: ['HIRE_DATE_DESC'],
    filters: {},
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    staff: staffList,
    response: staffResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = useStaffData(debouncedSearchTerm, queryOptions)

  useEffect(() => {
    if (staffList) {
      const initialRequestData = staffList?.map(staff => {
        const name = capitalize(staff.employeeName || '')

        return {
          nameWithAvatar: <CellWithAvatar name={name} />,
          role: staff.departmentDescription,
          property: staff.propertyName || '—',
          propertyGroup: staff.categoryDescription || '—',
          hireDate: toCommonDateFormat(staff.hireDate) || '—',
          terminationDate: staff.terminationDate ? (
            <DeactivatePersonCell
              personProfileId={Number(staff.personProfileId)}
              onRevoke={staffResponse.refetch}
            >
              {toCommonDateFormat(staff.terminationDate)}
            </DeactivatePersonCell>
          ) : (
            '—'
          ),
          id: staff.personEmployeeId,
        }
      })

      setStaff(initialRequestData)
    } else {
      setStaff([])
    }
  }, [staffList, staffResponse.variables?.offset])

  const onChangeSortOrder = useCallback(
    (value: string) => {
      tableSort.setSortValue(value)
      upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
    },
    [upsertQueryOptions],
  )

  const onChangeSearchQuery = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions(prev => ({
        ...prev,
        searchTerm,
        page: 1,
      }))
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [setQueryOptions],
  )

  const onPressPerson = useCallback(
    (person?: ResidentPreview) => {
      if (person?.id) {
        navigate(`/people/staff/${person.id}`)
      }
    },
    [navigate],
  )

  const onPressRow = useCallback(
    (index: number) => {
      onPressPerson(staff[index])
    },
    [staff, onPressPerson],
  )

  const onSubmitFilter = useCallback(
    (filters: TStaffFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        page: 1,
        filters,
      }))
    },
    [setQueryOptions],
  )

  const dataForTableQuery = useCallback(async () => {
    try {
      const query = await queryForDownloadTable()

      const staff = query.data?.transactionalDb.allPortalEmployeeViews.nodes

      const tableData = staff.map(staff => {
        const name = capitalize(staff.employeeName || '-')
        return Object.values({
          name: name,
          role: staff.departmentDescription || '—',
          property: staff.propertyName || '—',
          propertyGroup: staff.categoryDescription || '—',
          hireDate: toCommonDateFormat(staff.hireDate) || '—',
          terminationDate: toCommonDateFormat(staff.hireDate) || '—',
        })
      })

      tableData.unshift(['Name', 'Role', 'Property', 'Property Goup', 'Hire Date'])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const emptyStaffTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Staff table is empty...',
  })

  const itemCount =
    staffResponse.data?.transactionalDb.allPortalEmployeeViews.totalCount || 0

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Staff'} data-testid={'StaffView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search staff'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onChangeSearchQuery}
                  />
                  <SearchFilters
                    filter={StaffFilters}
                    initialValue={queryOptions.filters}
                    onSubmit={onSubmitFilter}
                  />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>

                <Panel theme={'white'}>
                  <DataGrid
                    selectableRows
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    onRowSelect={onPressRow}
                    loading={staffResponse.loading}
                    emptyTableComponent={emptyStaffTable}
                    columns={[
                      {name: 'Name', key: 'nameWithAvatar', sortable: true},
                      {name: 'Role', key: 'role', sortable: true},
                      {name: 'Property', key: 'property', sortable: true},
                      {name: 'Property Group', key: 'propertyGroup', sortable: true},
                      {name: 'Hire Date', key: 'hireDate', sortable: true},
                      {name: 'Termination Date', key: 'terminationDate', sortable: true},
                    ]}
                    rows={staff}
                  />
                </Panel>
                <TableFooter itemCount={itemCount} loading={staffResponse.loading}>
                  <Paginator
                    itemCount={itemCount}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />

                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Staff
