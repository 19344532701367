import './PendingVnedorInvitesPanel.style.scss'

import {FC, useEffect} from 'react'
import {TGetVendorPendingInvitesResponse} from '../../../data/graphql/queries/people/types'
import {useFormik} from 'formik'
import Modal from '../../../components/Modal'
import InputText from '../../../components/InputText/InputText'
import Button from '../../../components/Button'
import Row from '../../../components/Grid/Row'
import Column from '../../../components/Grid/Column'
import * as Yup from 'yup'
import {isValidPhoneNumber} from '../../../functions'
import useResendVendorInvite from './useResendVendorInvite'
import useToast from '../../../hooks/useToast'

type TAppInvite =
  TGetVendorPendingInvitesResponse['transactionalDb']['invites']['nodes'][number]

type EditInviteModalProps = {
  isOpen: boolean
  invite: TAppInvite | null
  onClose: () => void
}

type TField = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label('First Name'),
  lastName: Yup.string().required().label('Last Name'),
  phoneNumber: Yup.string()
    .required()
    .label('Phone Number')
    .test('isValidNumber', 'Phone number is not valid', isValidPhoneNumber),
  email: Yup.string().label('Email').email('Invalid email format').required(),
})

const EditInviteModal: FC<EditInviteModalProps> = ({
  isOpen,
  invite,
  onClose,
  ...props
}) => {
  const {showErrorToast} = useToast()
  const vendorInvite = useResendVendorInvite()

  const form = useFormik<TField>({
    validationSchema,
    initialValues: {
      firstName: invite?.firstName || '',
      lastName: invite?.lastName || '',
      email: invite?.email || '',
      phoneNumber: invite?.mobilePhone || '',
    },
    onSubmit(values) {
      if (!invite) {
        return showErrorToast('Request failed', 'Invalid invitation data')
      }

      vendorInvite
        .resend({
          ...invite,
          ...values,
        })
        .then(onClose)
    },
  })

  useEffect(() => {
    form.resetForm()

    if (invite) {
      form.setValues({
        firstName: invite.firstName,
        lastName: invite.lastName,
        email: invite.email,
        phoneNumber: invite.mobilePhone,
      })
    }
  }, [invite])

  return (
    <Modal
      width={'unset'}
      className='EditInviteModal'
      isOpen={isOpen}
      closeModal={onClose}
    >
      <h4>Edit invited vendor info</h4>

      <Row>
        <Column md={'6'}>
          <InputText
            name='firstName'
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            invalid={!!form.errors.firstName}
            invalidMessage={form.errors.firstName}
            value={form.values.firstName}
            placeholder='First Name'
            label='First Name'
          />
        </Column>
        <Column md={'6'}>
          <InputText
            name='lastName'
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            invalid={!!form.errors.lastName}
            invalidMessage={form.errors.lastName}
            value={form.values.lastName}
            placeholder='Last Name'
            label='Last Name'
          />
        </Column>
      </Row>

      <Row>
        <Column md={'6'}>
          <InputText
            name='email'
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            invalid={!!form.errors.email}
            invalidMessage={form.errors.email}
            value={form.values.email}
            placeholder='Email'
            label='Email'
          />
        </Column>
        <Column md={'6'}>
          <InputText
            name='phoneNumber'
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            invalid={!!form.errors.phoneNumber}
            invalidMessage={form.errors.phoneNumber}
            value={form.values.phoneNumber}
            placeholder='Phone number'
            label='Phone number'
          />
        </Column>
      </Row>

      <div className='footer'>
        <Button disabled={vendorInvite.loading} onClick={form.submitForm}>
          {vendorInvite.loading ? 'Loading...' : 'Save & Resend'}
        </Button>
        <Button theme='outline' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default EditInviteModal
