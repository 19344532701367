import Filters from '../../../components/Filters'
import AccordionGroup from '../../../components/Accordion/AccordionGroup'
import useDateRangeFilter from '../../../hooks/filters/useDateRangeFilter'
import DateRangeFilterNode from '../../../forms/Filters/FilterNodes/DateRangeFilterNode'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'
import StructuresFilterNode from '../../../forms/Filters/FilterNodes/StructuresFilterNode/StructuresFilterNode'
import useStructuresFilter, {
  TFilterItem,
} from '../../../hooks/filters/useStructuresFilter'
import useLockAccessScheduleFilter from '../../../hooks/filters/useLockAccessScheduleFilter'
import CheckboxListFilterNode from '../../../forms/Filters/FilterNodes/CheckboxListFilterNode'
import IsActiveStatusNode, {
  TIsPersonActive,
} from '../../../forms/Filters/FilterNodes/IsActiveStatusNode'
import {useState} from 'react'

export type TGuestsFilterFields = {
  accessType?: string[]
  unitFilters?: TFilterItem[]
  activeEnd?: string
  activeStart?: string
  deactiveEnd?: string
  deactiveStart?: string
  isPersonAccessActive?: TIsPersonActive
}

const GuestsFilters = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TGuestsFilterFields>) => {
  const isReports = window.location.pathname.includes('reports')

  const {structures, setStructures, getSelectedIds, clearSelections} =
    useStructuresFilter(initialValue?.unitFilters)
  const {accessesTypes, setAccessesTypes, getSelectedTypes} = useLockAccessScheduleFilter(
    initialValue?.accessType,
  )
  const [activeRange, setActiveRange] = useDateRangeFilter({
    to: initialValue?.activeEnd,
    from: initialValue?.activeStart,
  })
  const [deactiveRange, setDeactiveRange] = useDateRangeFilter({
    to: initialValue?.deactiveEnd,
    from: initialValue?.deactiveStart,
  })

  const [isPersonAccessActive, setPersonAccess] = useState<TIsPersonActive>(
    initialValue?.isPersonAccessActive || 'all',
  )

  const onChangePersonAccessFilter = (value: string) => {
    setPersonAccess(value as TIsPersonActive)
  }

  const onApplyFilter = () => {
    const filter = getSelectedIds()
    const accessTypes = getSelectedTypes()
    onSubmit?.({
      unitFilters: filter,
      activeEnd: activeRange.to || undefined,
      activeStart: activeRange.from || undefined,
      deactiveEnd: deactiveRange.to || undefined,
      deactiveStart: deactiveRange.from || undefined,
      accessType: accessTypes.length ? accessTypes : undefined,
      isPersonAccessActive,
    })
  }

  const onClearFilter = () => {
    clearSelections()
    setAccessesTypes(values =>
      values.map(item => ({
        ...item,
        checked: false,
      })),
    )

    setPersonAccess('all')
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <StructuresFilterNode value={structures} onChange={setStructures} />
        <CheckboxListFilterNode
          title='Access'
          value={accessesTypes}
          onChange={setAccessesTypes}
        />
        <DateRangeFilterNode
          value={activeRange}
          title={'Active dates'}
          onChange={setActiveRange}
        />
        <DateRangeFilterNode
          value={deactiveRange}
          title={'Deactive dates'}
          onChange={setDeactiveRange}
        />
        {isReports && (
          <IsActiveStatusNode
            value={isPersonAccessActive}
            onChange={onChangePersonAccessFilter}
            title={'Status'}
          />
        )}
      </AccordionGroup>
    </Filters>
  )
}

export default GuestsFilters
