import {getAuth} from 'firebase/auth'

import {GraphQLWsLink} from '@apollo/client/link/subscriptions'
import {createClient, CloseCode} from 'graphql-ws'

let shouldRefreshToken = false
let tokenExpiryTimeout: ReturnType<typeof setTimeout> | null = null // used in wsLink

export const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URI || '',
    connectionParams: async () => {
      const auth = getAuth()
      let token = await auth?.currentUser?.getIdToken(true)

      if (shouldRefreshToken) {
        token = await auth?.currentUser?.getIdToken(true)
        shouldRefreshToken = false
      }

      return {'apthub-auth-token': token}
    },
    on: {
      connected: async (socket: any) => {
        const auth = getAuth()
        const token = await auth?.currentUser?.getIdTokenResult()

        const tokenExpiresIn = token
          ? new Date(token.expirationTime).getTime() - Date.now() - 1000 * 60
          : 0

        tokenExpiryTimeout = setTimeout(() => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.close(CloseCode.Forbidden, 'Forbidden')
          }
        }, tokenExpiresIn)
      },
      closed: (event: any) => {
        if (event.code === CloseCode.Forbidden) {
          shouldRefreshToken = true
        }
      },
    },
  }),
)
