import {useState} from 'react'

const useDateRangeFilter = (initialValue?: {to?: string; from?: string}) => {
  const [date, setDate] = useState({
    to: initialValue?.to || '',
    from: initialValue?.from || '',
  })

  return [date, setDate] as const
}

export default useDateRangeFilter
