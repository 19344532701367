import React, {FC} from 'react'
import './UnitDetails.style.scss'
import Icon from '../../components/Icon'

type DeviceCountTileProps = {
  count: number
  label: string
  className?: string
}

const DeviceCountTile: FC<DeviceCountTileProps> = ({
  count = 0,
  label,
  className = '',
}) => {
  const getLabel = () => {
    if (count === 1) {
      return label
    }

    return label + 's'
  }

  return (
    <div className={className}>
      <div className='d-flex align-center'>
        <Icon icon='lock' theme='info' size='md' />
        <div className='d-flex flex-column'>
          <h5>{count}</h5>
          <span className='body-small-light-12 gray'>{getLabel()}</span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(DeviceCountTile)
