import {TAccessTypeCode} from '../data/graphql/queries/common/types'

export function handlePersonAccessCodeGuests(accessCode: TAccessTypeCode) {
  switch (accessCode) {
    case TAccessTypeCode.P:
      return 'Pin Code'
    case TAccessTypeCode.NC:
      return 'Unkown'
    default:
      return 'App'
  }
}
