// components/InputSelect/InputSelect.tsx

import React, {FC} from 'react'
import './InputSelect.style.scss'
import InputWrapper from '../InputWrapper'
import {randomString} from '../../functions'
import classNames from 'classnames'

export interface InputSelectProps {
  id?: string
  label?: string
  defaultOptionLabel?: string
  name?: string
  srOnlyLabel?: boolean
  moreInfo?: string
  footnote?: string
  theme?: 'default' | 'white'
  required?: boolean
  invalid?: boolean
  invalidMessage?: string
  prefixIcon?: string
  placeholder?: string
  autocomplete?: boolean
  readonly?: boolean
  disabled?: boolean
  maxLength?: number
  value?: number | string
  allowNull?: boolean
  options: {
    label: string
    value: string
  }[]
  size?: 'default' | 'md' | 'xs' | 'lg'
  onValueChange: (value: string) => void
  testId?: string
}

const defaultProps: InputSelectProps = {
  id: randomString(),
  allowNull: true,
  theme: 'default',
  options: [],
  size: 'default',
  onValueChange: v => v,
  testId: 'select',
  defaultOptionLabel: 'Choose one',
}

const InputSelect: FC<InputSelectProps> = (props: InputSelectProps) => {
  props = {...defaultProps, ...props}

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    props.onValueChange(value)
  }

  return (
    <InputWrapper
      id={props.id}
      label={props.label}
      srOnlyLabel={props.srOnlyLabel}
      required={props.required}
      moreInfo={props.moreInfo}
      footnote={props.footnote}
      invalid={props.invalid}
      invalidMessage={props.invalidMessage}
    >
      <div
        className={`input-select ${props.invalid ? 'is-invalid' : ''} ${props.theme}`}
        data-testid={'InputSelect'}
      >
        <select
          id={props.id}
          name={props.name}
          className={classNames({
            'is-invalid': !!props.invalid,
            [`size-${props.size}`]: true,
          })}
          disabled={props.disabled}
          value={props.value}
          onChange={handleChange}
          data-testid={props.testId}
        >
          {props.allowNull && (
            <option value={''} disabled={props.required}>
              {props.defaultOptionLabel}
            </option>
          )}

          {props.options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </select>
      </div>
    </InputWrapper>
  )
}

export default InputSelect
