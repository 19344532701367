import './Section.scss'

export type SectionProps = {
  id?: string
  spacing?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'section-md'
  justify?: 'right' | 'center' | 'left' | 'fluid'
  theme?: 'default' | 'white'
  className?: string
  children?: React.ReactNode
}

const defaultProps: SectionProps = {
  spacing: 'sm',
  justify: 'fluid',
  className: '',
}

const Section: React.FC<SectionProps> = (props: SectionProps) => {
  props = {...defaultProps, ...props}

  return (
    <section
      id={props.id}
      className={`section spacing-${props.spacing} justify-${props.justify} ${props.theme} ${props.className}`}
    >
      <div className={`container ${props.justify}`}>{props.children}</div>
    </section>
  )
}

export default Section
