import {FC} from 'react'

const BellOff: FC = () => (
  <g>
    <path d='M13.73 21a2 2 0 0 1-3.46 0' />
    <path d='M18.63 13A17.89 17.89 0 0 1 18 8' />
    <path d='M6.26 6.26A5.86 5.86 0 0 0 6 8c0 7-3 9-3 9h14' />
    <path d='M18 8a6 6 0 0 0-9.33-5' />
    <line x1='1' y1='1' x2='23' y2='23' />
  </g>
)

export default BellOff
