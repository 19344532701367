import './Overview.style.scss'

import {useState} from 'react'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import {useQueryOptions} from '../../../hooks/useQueryOptions'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import {TServiceTicketFilterFields} from './ServiceTickets/ServiceTicketsFilter'
import Tabbar from '../../../components/Tabbar/Tabbar'
import useServiceTickets from '../../../hooks/data/useServiceTickets'
import ServiceTickets from './ServiceTickets'
import {ServiceTicketStatuses} from '../../../data/graphql/queries/properties/types'
import Badge from '../../../components/Badge/Badge'
import Notes from './Notes'
import {useParams} from 'react-router-dom'

const newTicketIds = [
  ServiceTicketStatuses.UNKNOWN,
  ServiceTicketStatuses.IN_PROGRESS,
  ServiceTicketStatuses.ON_HOLD,
]

const completedTicketsIds = [
  ServiceTicketStatuses.CANCELLED,
  ServiceTicketStatuses.COMPLETED,
]

const Overview = () => {
  const params = useParams<{propertyId: string}>()
  const propertyId = params.propertyId ? +params.propertyId : -1
  const [activeTab, setActiveTab] = useState('open')

  const {queryOptions, debouncedSearchTerm} = useQueryOptions<TServiceTicketFilterFields>(
    {},
    false,
  )

  const newTicketsQuery = useServiceTickets(debouncedSearchTerm, queryOptions, {
    filter: {
      serviceTicketStatusTypeId: {
        in: newTicketIds,
      },
    },
    condition: {
      propertyId,
    },
  })

  const completedTicketsQuery = useServiceTickets(debouncedSearchTerm, queryOptions, {
    filter: {
      serviceTicketStatusTypeId: {
        in: completedTicketsIds,
      },
    },
    condition: {
      propertyId,
    },
  })

  const newTicketsNumber = newTicketsQuery.tickets?.totalCount || 0
  const completedTicketsNumber = completedTicketsQuery.tickets?.totalCount || 0

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className='PropertyOverview'>
        <Notes />

        <Section spacing='none'>
          <div className={'section-title-wrapper'}>
            <h6 className='h6 section-title'>Maintenance Requests</h6>
            <Badge size={'sm'} theme={'info'}>
              {newTicketsNumber + completedTicketsNumber}
            </Badge>
          </div>

          <Row>
            <Column xs={'12'}>
              <Tabbar
                tabs={[
                  {
                    label: 'Open',
                    key: 'open',
                    badge: newTicketsQuery.tickets?.totalCount,
                    theme: 'danger',
                  },
                  {
                    label: 'Closed',
                    key: 'closed',
                    badge: completedTicketsQuery.tickets?.totalCount,
                    theme: 'danger',
                  },
                ]}
                selected={activeTab}
                onTabChange={setActiveTab}
              />
            </Column>
          </Row>
        </Section>

        {activeTab === 'open' && <ServiceTickets ticketIds={newTicketIds} />}

        {activeTab === 'closed' && <ServiceTickets ticketIds={completedTicketsIds} />}
      </div>
    </ErrorBoundary>
  )
}

export default Overview
