import {useParams} from 'react-router-dom'
import Filters from '../../../../components/Filters'
import AccordionGroup from '../../../../components/Accordion/AccordionGroup'
import {TFilterComponentProps} from '../../../../layouts/People/Search/SearchFilters'
import StructuresFilterNode from '../../../../forms/Filters/FilterNodes/StructuresFilterNode'
import useStructuresFilter, {
  TFilterItem,
} from '../../../../hooks/filters/useStructuresFilter'
import useServiceTicketCategoryFilter from '../../../../hooks/filters/useServiceTicketCategoryFilter'
import CheckboxListFilterNode from '../../../../forms/Filters/FilterNodes/CheckboxListFilterNode'
import useServiceTicketIssueLocationFilter from '../../../../hooks/filters/useServiceTicketIssueLocationFilter'
import useDateRangeFilter from '../../../../hooks/filters/useDateRangeFilter'
import DateRangeFilterNode from '../../../../forms/Filters/FilterNodes/DateRangeFilterNode'

export type TServiceTicketFilterFields = {
  unitFilters?: TFilterItem[]
  category?: number[]
  issueLocation?: number[]
  updatedFrom?: string
  updatedTo?: string
}

const ServiceTicketsFilters = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TServiceTicketFilterFields>) => {
  const params = useParams<{propertyId: string}>()
  const propertyId = params.propertyId ? [+params.propertyId] : []

  const [categories, setCategories] = useServiceTicketCategoryFilter(
    initialValue?.category,
  )
  const [locations, setLocations] = useServiceTicketIssueLocationFilter(
    initialValue?.issueLocation,
  )
  const [updatedAt, setUpdatedAt] = useDateRangeFilter({
    from: initialValue?.updatedFrom,
    to: initialValue?.updatedTo,
  })
  const {structures, setStructures, getSelectedIds, clearSelections} =
    useStructuresFilter(initialValue?.unitFilters, {propertyId})

  const onApplyFilter = () => {
    const unitFilters = getSelectedIds()
    const categoryIds = categories.filter(({checked}) => checked).map(({id}) => +id)
    const issueLocationIds = locations.filter(({checked}) => checked).map(({id}) => +id)

    onSubmit?.({
      unitFilters,
      category: categoryIds,
      issueLocation: issueLocationIds,
      updatedFrom: updatedAt.from,
      updatedTo: updatedAt.to,
    })
  }

  const onClearFilter = () => {
    clearSelections()
    setLocations(locations.map(item => ({...item, checked: false})))
    setCategories(categories.map(item => ({...item, checked: false})))
    setUpdatedAt({
      to: '',
      from: '',
    })
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <StructuresFilterNode value={structures} onChange={setStructures} />

        <CheckboxListFilterNode
          title='Category'
          value={categories}
          onChange={setCategories}
        />
        <CheckboxListFilterNode
          title={'Location'}
          value={locations}
          onChange={setLocations}
        />
        <DateRangeFilterNode
          title='Updated date'
          value={updatedAt}
          onChange={setUpdatedAt}
        />
      </AccordionGroup>
    </Filters>
  )
}

export default ServiceTicketsFilters
