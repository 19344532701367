import {useEffect, useState} from 'react'
import {capitalize} from '../../functions'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'

const types = ['offline', 'online', 'unknown']

const useNetworkStatusFilter = (initialValue?: string[]) => {
  const [value, setValue] = useState<CheckboxItem[]>([])

  useEffect(() => {
    setValue(
      types.map(value => ({
        id: value,
        name: capitalize(value),
        checked: !!initialValue?.includes(value),
      })),
    )
  }, [])

  const getSelected = () => {
    return value.filter(({checked}) => checked).map(({id}) => id.toString())
  }

  const clearSelections = () => {
    setValue(item => ({
      ...item,
      checked: false,
    }))
  }

  return {value, setValue, getSelected, clearSelections}
}

export default useNetworkStatusFilter
