import {useQuery} from '@apollo/client'
import {GET_DEVICE_MANUFACTURERS} from '../../data/graphql/queries/properties'
import {
  TGetDeviceManufacturersRespone,
  TGetDeviceManufacturersVariables,
} from '../../data/graphql/queries/properties/types'
import {useMemo} from 'react'

const useDeviceManufacturers = () => {
  const query = useQuery<
    TGetDeviceManufacturersRespone,
    TGetDeviceManufacturersVariables
  >(GET_DEVICE_MANUFACTURERS, {
    variables: {
      condition: {
        isActive: true,
        isDeleted: false,
      },
    },
  })

  const data = useMemo(() => {
    return query.data?.transactionalDb.manufacturers.nodes.filter(({id}) => +id > 0)
  }, [query.data])

  return {
    loading: query.loading,
    data,
    query,
  }
}

export default useDeviceManufacturers
