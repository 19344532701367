import {FC} from 'react'

const Grid: FC = () => (
  <g>
    <rect x='3' y='3' width='7' height='7' />
    <rect x='14' y='3' width='7' height='7' />
    <rect x='14' y='14' width='7' height='7' />
    <rect x='3' y='14' width='7' height='7' />
  </g>
)

export default Grid
