import React from 'react'
import Accordion from '../../../components/Accordion'
import AccordionBody from '../../../components/Accordion/AccordionBody'
import AccordionHead from '../../../components/Accordion/AccordionHead'
import InputRadioGroup from '../../../components/InputRadioGroup'

export type TIsPersonActive = 'all' | 'active' | 'inactive'

export type TIsActiveAccessFilterProps = {
  title?: string
  value: TIsPersonActive
  onChange: (value: string) => void
}

const IsActiveStatusNode = ({title, value, onChange}: TIsActiveAccessFilterProps) => {
  return (
    <Accordion transparent>
      <AccordionHead>
        <div className='d-flex space-between align-center'>
          <div className='desktop-label-semibold-14'>{title}</div>
        </div>
      </AccordionHead>

      <AccordionBody>
        <InputRadioGroup
          inline
          groupName='RadioAccessResidentGroup'
          id={`access-group-filter`}
          value={value}
          onRadioChange={onChange}
          options={[
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Active',
              value: 'active',
            },
            {
              label: 'Inactive',
              value: 'inactive',
            },
          ]}
        />
      </AccordionBody>
    </Accordion>
  )
}

export default IsActiveStatusNode
