import React, {useState} from 'react'
import Filters from '../../../components/Filters'
import AccordionGroup from '../../../components/Accordion/AccordionGroup'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'
import IsActiveStatusNode, {
  TIsPersonActive,
} from '../../../forms/Filters/FilterNodes/IsActiveStatusNode'
import useDateRangeFilter from '../../../hooks/filters/useDateRangeFilter'
import DateRangeFilterNode from '../../../forms/Filters/FilterNodes/DateRangeFilterNode'

export type TVendorsFilterFields = {
  isActive?: TIsPersonActive
  activeDateFrom?: string
  activeDateTo?: string
  deactiveDateFrom?: string
  deactiveDateTo?: string
}

const VendorFilters = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TVendorsFilterFields>) => {
  const onApplyFilter = () => {
    onSubmit?.({
      activeDateFrom: activeDateRange.from || undefined,
      activeDateTo: activeDateRange.to || undefined,
      deactiveDateFrom: deactiveDateRange.from || undefined,
      deactiveDateTo: deactiveDateRange.to || undefined,
      isActive: isPersonAccessActive,
    })
  }

  const [activeDateRange, setActiveDateRange] = useDateRangeFilter({
    to: initialValue?.activeDateTo,
    from: initialValue?.activeDateFrom,
  })

  const [deactiveDateRange, setDeactiveDateRange] = useDateRangeFilter({
    to: initialValue?.deactiveDateTo,
    from: initialValue?.deactiveDateFrom,
  })

  const onClearFilter = () => {
    setActiveDateRange({
      from: '',
      to: '',
    })
    setDeactiveDateRange({
      from: '',
      to: '',
    })
    setPersonAccess('all')
  }

  const [isPersonAccessActive, setPersonAccess] = useState<TIsPersonActive>(
    initialValue?.isActive === undefined ? 'all' : initialValue?.isActive,
  )

  const onChangePersonStatusFilter = (value: string) => {
    setPersonAccess(value as TIsPersonActive)
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <DateRangeFilterNode
          value={activeDateRange}
          title={'Active date'}
          onChange={setActiveDateRange}
        />
        <DateRangeFilterNode
          value={deactiveDateRange}
          title={'Deactive date'}
          onChange={setDeactiveDateRange}
        />
        <IsActiveStatusNode
          value={isPersonAccessActive}
          onChange={onChangePersonStatusFilter}
          title={'Status'}
        />
      </AccordionGroup>
    </Filters>
  )
}

export default VendorFilters
