import {gql} from '@apollo/client'

export const INVITE_VENDOR = gql`
  mutation SendVendorInvitation($input: SendVendorInvitationInput!) {
    utility {
      sendVendorInvitation(input: $input) {
        ... on CommonError {
          code
          message
          status
          source
        }
        ... on SendInvitationSuccess {
          success
        }
      }
    }
  }
`

export const UPDATE_NOTE = gql`
  mutation UpdatePersonAccessByPersonAccessId($input: UpdatePersonAccessByPersonAccessIdInput!) {
    transactionalDb {
      updatePersonAccessByPersonAccessId(input: $input) {
        personAccess {
          notes
        }
      }
    }
  }
`

export const ASSIGN_INSTALLATION_TASKS = gql`
  mutation AssignInstallationTasks($input: AssignInstallationTasksInput!) {
    utility {
      assignInstallationTasks(input: $input) {
        ... on AssignInstallationTasksResult {
          assigned {
            deviceWorkAssignmentId
            deviceType
            personIdVendor
            deviceLocation
            houseName
          }
          failed {
            deviceInventoryViewId
            reason
          }
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`

export const CANCEL_INSTALLATION_TASKS = gql`
  mutation CancelTask($input: CancelTasksInput!) {
    utility {
      cancelTasks(input: $input) {
        ... on CancelTasksResult {
          canceled {
            deviceWorkAssignmentId
          }
          failed {
            deviceWorkAssignmentId
            reason
          }
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`

export const CREATE_PIN_ONLY_USER = gql`
  mutation CreatePinOnlyUser($input: CreatePinOnlyUserInput!) {
    lock {
      createPinOnlyUser(input: $input) {
        ... on PinOnlyUser {
          firstName
          lastName
          personId
          personProfileId
          yale {
            userId
          }
        }
        ... on CommonError {
          code
          message
          source
          status
        }
      }
    }
  }
`

export const STORE_BUILDING_ACCOUNT_SECRET = gql`
  mutation StoreBuildingPersonSecret($input: StoreBuildingPersonSecretInput!) {
    utility {
      storeBuildingPersonSecret(input: $input) {
        ... on StorePersonSecretSuccess {
          personSecretId
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`

export const DEACTIVATE_PERSON = gql`
  mutation DeactivatePerson($input: DeactivateUserInput!) {
  utility {
    deactivateUser(input: $input){
      ... on DeactivatedUser {
        personProfileId
        deactivateLockUser {
          yale {
            ... on RevokeAccessToLocksResult {
              revoked {
                installedDeviceId
              }
              failed {
                installedDeviceId
                reason
              }
            }
            ... on CommonError {
              code
              message
              status
              source
            }
          }
          brivo {
            ... on CommonError {
              code
              message
              status
              source
            }
            ... on DeactivateBrivoLockUserResult {
              deactivated {
                propertyId
              }
              failed {
                propertyId
                reason
              }
            }
          }
        }
        deactivateThermostatUser {
          nest {
            ... on RevokeThermostatAccessResult {
              revoked {
                installedDeviceId
              }
              failed {
                installedDeviceId
                reason
              }
            }
            ... on CommonError {
              code
              message
              status
              source
            }
          }
          honeywell {
            ... on RevokeThermostatAccessResult {
              revoked {
                installedDeviceId
              }
              failed {
                installedDeviceId
                reason
              }
            }
            ... on CommonError {
              code
              message
              status
              source
            }
          }
        }
      }
      ... on CommonError {
        code
        message
        status
        source
      }
    }
  }
}
`
