// layouts/People/People.tsx

import {useEffect, useState} from 'react'
import './ReportsPeople.style.scss'
import {Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import InputRadioGroup from '../../components/InputRadioGroup'
import People from '../../views/Reports/PeopleReports/People'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

const ReportsPeople = () => {
  // TABS
  const location = useLocation()
  const navigate = useNavigate()
  const selectedGroup = location.pathname.split('/')[3] || 'residents'

  const handleSelect = value => {
    navigate(`/reports/people/${value}`)
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'reports-people'} data-testid={'PeopleLayout'}>
        <InputRadioGroup
          inline
          groupName='RadioStoryGroup'
          id={`radio-group-id`}
          value={selectedGroup}
          onRadioChange={handleSelect}
          options={[
            {
              label: 'Residents',
              value: 'residents',
            },
            {
              label: 'Staff',
              value: 'staff',
            },
            // {
            //   label: 'Admins',
            //   value: 'admins',
            // },
            {
              label: 'Guests',
              value: 'guests',
            },
            {
              label: 'Vendors',
              value: 'vendors',
            },
          ]}
        />
        <Outlet />
      </div>
    </ErrorBoundary>
  )
}

export default ReportsPeople
