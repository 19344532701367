import {useEffect, useMemo, useState} from 'react'
import usePersonHouseAccounts from '../../hooks/data/usePersonHouseAccounts'
import useDeviceManufacturers from './useDeviceManufacturers'
import usePropertiesWithBuildings from './usePropertiesWithBuildings'
import useBuildingAccountSecret from './useBuildingAccountSecret'

const useBuildingSecretForm = () => {
  const personHouses = usePersonHouseAccounts()
  const allProperties = usePropertiesWithBuildings()
  const deviceManufacturers = useDeviceManufacturers()

  const [propertyId, setPropertyId] = useState('')
  const [buildingId, setBuildingId] = useState('')
  const [manufacturerCode, setManufacturerCode] = useState('')

  const buildingPerson = personHouses.byBuildingId[buildingId]

  const properties = useMemo(() => {
    if (!allProperties.data) {
      return []
    }

    return allProperties.data.map(property => ({
      value: property.id,
      label: property.name,
    }))
  }, [allProperties.data])

  const buildings = useMemo(() => {
    const property = allProperties.data?.find(({id}) => id === propertyId)

    if (!property) {
      return []
    }

    return property.buildings.nodes.map(building => ({
      value: building.id,
      label: `${building.name} (${building.number})`,
    }))
  }, [propertyId, allProperties.data])

  const manufacturers = useMemo(() => {
    if (!deviceManufacturers.data) {
      return []
    }

    return deviceManufacturers.data.map(({code, description}) => ({
      value: code,
      label: description,
    }))
  }, [deviceManufacturers.data])

  useEffect(() => {
    if (properties.length) {
      setPropertyId(properties[0].value)
    }
  }, [properties])

  useEffect(() => {
    if (propertyId) {
      setBuildingId(buildings[0]?.value || '')
    }
  }, [propertyId, buildings])

  useEffect(() => {
    const id = manufacturers[0]?.value || ''

    setManufacturerCode(buildingId ? id : '')
  }, [buildingId, buildings, manufacturers])

  return {
    loading: personHouses.loading || allProperties.loading || deviceManufacturers.loading,
    buildingPerson,

    propertyId,
    buildingId,
    manufacturerCode,

    setPropertyId,
    setBuildingId,
    setManufacturerCode,

    buildings,
    properties,
    manufacturers,
  }
}

export default useBuildingSecretForm
