// layouts/Devices/Devices.tsx

import React, {useEffect, useState} from 'react'
import './Devices.style.scss'
import {Outlet, useLocation} from 'react-router-dom'
import Row from '../../components/Grid/Row'
import Column from '../../components/Grid/Column'
import Section from '../../components/Grid/Section'
import ExportTable from '../../components/ExportTable/ExportTable'
import Tabbar from '../../components/Tabbar'
import Tile from './Tile'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'

const DevicesLayout = () => {
  // TABS
  const [selectedTab, setSelectedTab] = useState<string>('')
  const location = useLocation()

  useEffect(() => {
    const pathname = location.pathname
    setSelectedTab(pathname.split('/')[pathname.split('/').length - 1])
  }, [location])

  const tabs = [
    {label: 'Locks', key: 'locks', to: '/devices/locks'},
    {label: 'Thermostats', key: 'thermostats', to: '/devices/thermostats'},
  ]

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'devices-layout'} data-testid={'DevicesLayout'}>
        <Section spacing={'md'}>
          <div className='d-flex space-between align-center'>
            <h3 className={'color-black overview-title'}>Devices</h3>

            <ExportTable fileName={selectedTab} />
          </div>
          <Tile />
        </Section>

        <Section>
          <Row>
            <Column>
              <div className='d-flex align-end tabbar-place'>
                <Tabbar tabs={tabs} selected={selectedTab} onTabChange={setSelectedTab} />
              </div>
              <Outlet />
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default DevicesLayout
