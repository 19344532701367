import {useCallback, useEffect, useState} from 'react'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import {GET_ALL_TYPES} from '../../data/graphql/queries/common'
import {useQuery} from '@apollo/client'
import {TAllTypesResponse} from '../../data/graphql/queries/common/types'

const useLeaseTypeFilter = (initialValue?: number[]) => {
  const allTypes = useQuery<TAllTypesResponse>(GET_ALL_TYPES)
  const leaseTypes = allTypes.data?.transactionalDb?.allLeaseTypes.nodes

  const [types, setTypes] = useState<CheckboxItem[]>([])

  useEffect(() => {
    const value = leaseTypes || []

    setTypes(
      value.map(item => ({
        id: item.id,
        name: item.description,
        checked: !!initialValue?.includes(+item.id),
      })),
    )
  }, [leaseTypes])

  const getSelectedIds = useCallback(() => {
    return types.filter(({checked}) => checked).map(({id}) => +id)
  }, [types])

  const clear = useCallback(() => {
    setTypes(
      types.map(item => ({
        ...item,
        checked: false,
      })),
    )
  }, [types])

  return {types, setTypes, clear, getSelectedIds}
}

export default useLeaseTypeFilter
