import {useQuery} from '@apollo/client'
import {
  TGetAssignedDevicesResponse,
  TGetAssignedDevicesVariables,
} from '../../data/graphql/queries/devices/types'
import {GET_ALL_ASSIGNED_DEVICES} from '../../data/graphql/queries/devices'
import {DeviceTypes} from '../../data/graphql/queries/common/types'
import {useCallback, useEffect, useState} from 'react'
import {getInventoryId} from '../../functions/lock.functions'
import useInstallerContext from '../../layouts/VendorUserLayout/InstallerContext/useIntallerContext'

// TODO: filter installed device by propertyId
// TODO: remove vendorId from props and take it from context

type TDeviceData = {
  unitId: string
  installedDeviceId: string | null
  assignedVendorId: string | null
  deviceLocationTypeId: string
  deviceTypeId: string
  deviceClassTypeId: string
  deviceWorkAssignmentId: string | null
}

type TAssignment =
  TGetAssignedDevicesResponse['transactionalDb']['assignments']['nodes'][number]

type TInstalledDevice =
  TGetAssignedDevicesResponse['transactionalDb']['devices']['nodes'][number]

const useUnavailableDevices = (vendorId?: string | number) => {
  const [devices, setDevices] = useState<Record<string, TDeviceData>>({})
  const {replacements} = useInstallerContext()
  const response = useQuery<TGetAssignedDevicesResponse, TGetAssignedDevicesVariables>(
    GET_ALL_ASSIGNED_DEVICES,
    {
      variables: {
        assignmentsCondition: {
          isActive: true,
          isDeleted: false,
          isComplete: false,
          deviceWorkAssignmentType: 'I',
        },
        assignmentsFilter: {
          deviceTypeId: {
            in: [
              DeviceTypes.YALE_622,
              DeviceTypes.NEST_E,
              DeviceTypes.BRIVO,
              DeviceTypes.YALE_ASSURE_2,
              DeviceTypes.HONEYWELL_T6,
            ],
          },
        },
        devicesFilter: {
          isDeleted: {
            equalTo: false,
          },
          deviceTypeId: {
            in: [
              DeviceTypes.YALE_622,
              DeviceTypes.NEST_E,
              DeviceTypes.BRIVO,
              DeviceTypes.YALE_ASSURE_2,
              DeviceTypes.HONEYWELL_T6,
            ],
          },
        },
      },
      fetchPolicy: 'network-only',
    },
  )

  const getDevicesFromAssignments = useCallback(
    (assignments?: TAssignment[]) => {
      const devices: Record<string, TDeviceData> = {}
      const deviceIdentifiers: string[] = []

      assignments?.forEach(device => {
        const classTypeId = device.deviceTypeByDeviceTypeId.deviceClassTypeId
        const locationTypeId = device.deviceLocationTypeId
        const deviceInventoryId = getInventoryId(
          device.unitId,
          device.deviceTypeId,
          locationTypeId,
        )

        if (Number(device.assignedVendorId) === Number(vendorId)) {
          deviceIdentifiers.push(`${device.unitId}:${classTypeId}:${locationTypeId}`)
          return
        }

        if (replacements.includes(deviceInventoryId)) {
          return
        }

        devices[deviceInventoryId] = {
          unitId: device.unitId,
          deviceTypeId: device.deviceTypeId,
          deviceClassTypeId: classTypeId,
          installedDeviceId: device.installedDeviceId,
          deviceLocationTypeId: locationTypeId,
          assignedVendorId: device.assignedVendorId,
          deviceWorkAssignmentId: device.deviceWorkAssignmentId,
        }
      })

      return {
        devices,
        deviceIdentifiers,
      }
    },
    [replacements, vendorId],
  )

  const getDevicesFromInstalledDevices = useCallback(
    (installedDevices?: TInstalledDevice[], assignedDeviceIdentifiers?: string[]) => {
      const devices: Record<string, TDeviceData> = {}

      installedDevices?.forEach(device => {
        const classTypeId = device.deviceType.deviceClassTypeId
        const locationTypeId = device.deviceLocationTypeId
        const deviceInventoryId = getInventoryId(
          device.unitId,
          device.deviceType.id,
          locationTypeId,
        )

        if (
          assignedDeviceIdentifiers?.includes(
            `${device.unitId}:${classTypeId}:${locationTypeId}`,
          )
        ) {
          return
        }

        if (replacements.includes(deviceInventoryId)) {
          return
        }

        devices[deviceInventoryId] = {
          unitId: device.unitId,
          deviceTypeId: device.deviceType.id,
          deviceClassTypeId: classTypeId,
          installedDeviceId: device.installedDeviceId,
          deviceLocationTypeId: locationTypeId,
          assignedVendorId: device.assignments.nodes[0]?.assignedVendorId || null,
          deviceWorkAssignmentId: device.assignments.nodes[0]?.id || null,
        }
      })

      return devices
    },
    [replacements],
  )

  useEffect(() => {
    const {devices: assignedDevices, deviceIdentifiers: devicesAssignedToVendor} =
      getDevicesFromAssignments(response?.data?.transactionalDb?.assignments?.nodes)

    // do not add to unavailable devices if there is assignment
    // for this user for same class type and location type
    const installedDevices = getDevicesFromInstalledDevices(
      response?.data?.transactionalDb?.devices?.nodes,
      devicesAssignedToVendor,
    )

    setDevices({
      ...assignedDevices,
      ...installedDevices,
    })
  }, [
    getDevicesFromAssignments,
    getDevicesFromInstalledDevices,
    replacements,
    response?.data,
  ])

  return {
    loading: response.loading,
    devicesByInventoryId: devices,
    response,
  }
}

export default useUnavailableDevices
