import './Login.style.scss'
import {Outlet} from 'react-router-dom'
import Carousel from '../../components/Carousel'
import {CarouselItem} from '../../components/Carousel/Carousel'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'

type Props = {
  children?: React.ReactNode
}

const LoginLayout = ({children}: Props) => {
  const carouselData = [
    {
      img: 'images/carousel_illustration.png',
      object: 'APARTMENT HUB',
      title: 'A smarter way to manage it all ',
      description: '',
    },
    {
      img: '/images/carousel_illustration.png',
      object: 'APARTMENT HUB',
      title: 'A smarter way to manage it all ',
      description: '',
    },
    {
      img: '/images/carousel_illustration.png',
      object: 'APARTMENT HUB',
      title: 'A smarter way to manage it all ',
      description: '',
    },
    {
      img: '/images/carousel_illustration.png',
      object: 'APARTMENT HUB',
      title: 'A smarter way to manage it all ',
      description: '',
    },
  ]

  const carouselItemMock = (item, index) => {
    return (
      <CarouselItem key={index}>
        <img className='illustration' src={item.img} alt='illustration' />
        <div className='page-carousel-wrapper'>
          <div className='page-carousel-wrapper-object'>{item.object}</div>
          <div className='page-carousel-wrapper-title'>{item.title}</div>
          <div className='page-carousel-wrapper-description'>{item.description}</div>
        </div>
      </CarouselItem>
    )
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className='login-layout'>
        <div
          className={'login-col left'}
          style={{
            backgroundImage: `url(/images/login_background.png)`,
          }}
        >
          <Carousel>{carouselData.map(carouselItemMock)}</Carousel>
        </div>
        <div className={'login-col right'}>
          <Outlet />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default LoginLayout
