import {FC, HTMLProps} from 'react'
import './PropertyCard.style.scss'

import Badge from '../../../components/Badge'
import Hr from '../../../components/Hr'
import ProfilePicture from '../../../components/ProfilePicture'

export type TPropertyCardData = {
  name: string
  location: string
  propertyImage: string
  propertyManager: {
    img: string
    position: string
    name: string
  }
  residentsCount: string
  guestsCount: string
  referralsPercentage: string
  rentDue: string
  occupation: string
}

export type TProperyCardProps = Omit<HTMLProps<HTMLDivElement>, 'data'> & {
  data: TPropertyCardData
}

const PropertyCard: FC<TProperyCardProps> = ({data, ...props}) => {
  const labelWithInfo = (label: string, text: string) => (
    <div className='d-flex flex-column'>
      <span className='label-small-regular-12 black'>{label}</span>
      <p className='body-small-light-12'>{text}</p>
    </div>
  )

  return (
    <div id='PropertyCard' {...props}>
      <div className='d-flex fl1 card-container'>
        <img className='property-image' src={data.propertyImage} alt='propimage' />
        <div className='right d-flex flex-column fl1'>
          <div className='header-container d-flex space-between'>
            <div className='header-property-card'>
              <h5>{data.name}</h5>
              <span className='body-small-light-12'>{data.location}</span>
            </div>
            <Badge role='status' shape='square' size='sm' theme='danger'>
              {data.occupation}
            </Badge>
          </div>

          <div className='details d-flex flex-column'>
            <div className='manager d-flex align-center'>
              <ProfilePicture size={'thumb'} image={data.propertyManager.img} />
              <span className='label-small-regular-12 black'>
                {data.propertyManager.name}
              </span>
              <p className='body-small-light-12'>{data.propertyManager.position}</p>
            </div>
            <Hr />
            <div className='info d-flex space-between'>
              {labelWithInfo('Residents', data.residentsCount)}
              {labelWithInfo('Guests', data.guestsCount)}
              {labelWithInfo('Referrals', data.referralsPercentage)}
              {labelWithInfo('Rent Due', data.rentDue)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyCard
