import {gql} from '@apollo/client'

export const SEND_APP_INVITE = gql`
  mutation SendAppInvitation($input: SendAppInvitationInput!) {
    utility {
      sendAppInvitation(input: $input) {
        ... on SendInvitationSuccess {
          success
        }
        ... on CommonError {
          status
          source
          message
          code
        }
      }
    }
  }
`
