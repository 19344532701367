import './ProfileHeader.style.scss'

import {FC, ReactNode} from 'react'
import Card from '../../components/Card/Card'
import Section, {SectionProps} from '../../components/Grid/Section'
import ProfileHeaderPersonCard from './ProfileHeaderPersonCard'
import ProfileHeaderInfoCard from './ProfileHeaderInfoCard'
import CardBody from '../Card/CardBody'
import ProfileHeaderButtonCard from './ProfileHeaderButtonCard'
import classNames from 'classnames'
import ProfileHeaderBadgeCard from './ProfileHeaderBadgeCard'

type ProfileHeaderProps = SectionProps & {
  children: ReactNode | ReactNode[]
  className?: string
  wrapperId?: string
}

type ProfileHeaderComponent = FC<ProfileHeaderProps> & {
  InfoCard: typeof ProfileHeaderInfoCard
  ButtonCard: typeof ProfileHeaderButtonCard
  PersonCard: typeof ProfileHeaderPersonCard
  BadgeCard: typeof ProfileHeaderBadgeCard
}

const ProfileHeader: ProfileHeaderComponent = ({
  children,
  className = '',
  wrapperId = 'contact-card',
  ...props
}: ProfileHeaderProps) => (
  <Section className={classNames('profile-header', className)} {...props}>
    <Card padding='none' theme='dark'>
      <CardBody padding='none'>
        <div className='d-flex space-between align-center wrap' id={wrapperId}>
          {children}
        </div>
      </CardBody>
    </Card>
  </Section>
)

ProfileHeader.InfoCard = ProfileHeaderInfoCard
ProfileHeader.PersonCard = ProfileHeaderPersonCard
ProfileHeader.ButtonCard = ProfileHeaderButtonCard
ProfileHeader.BadgeCard = ProfileHeaderBadgeCard

export default ProfileHeader
