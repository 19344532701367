import {useMutation} from '@apollo/client'
import {
  TRevokeAccessToLocksResponse,
  TRevokeAccessToLocksVariables,
} from '../../data/graphql/mutations/lock/types'
import {REVOKE_ACCESSES_TO_LOCKS} from '../../data/graphql/mutations/lock'
import {useCallback} from 'react'
import {DeviceMaker} from '../../data/graphql/types'

const useRevokeAccessToSelectedLocks = () => {
  const [revokeLockAccesses, query] = useMutation<
    TRevokeAccessToLocksResponse,
    TRevokeAccessToLocksVariables
  >(REVOKE_ACCESSES_TO_LOCKS)

  const revoke = useCallback(
    async (personProfileId: number, installedDeviceIds: number[]) => {
      const response = await revokeLockAccesses({
        variables: {
          input: {
            deviceMaker: DeviceMaker.YALE,
            personProfileId,
            installedDeviceIds,
          },
        },
      })

      return response.data?.lock?.revokeAccessToSelectedLocks
    },
    [revokeLockAccesses],
  )

  return {
    loading: query.loading,
    revoke,
    query,
  }
}

export default useRevokeAccessToSelectedLocks
