import './InputCheckbox.scss'
import {randomString} from '../../functions'
import classNames from 'classnames'
import React from 'react'

export interface InputCheckboxProps {
  id?: string
  label?: string
  value?: string
  invalid?: boolean
  disabled?: boolean
  isChecked?: boolean
  renderLabel?: (id?: string) => React.ReactNode
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const defaultProps: InputCheckboxProps = {
  id: randomString(),
  disabled: false,
  isChecked: false,
}

const InputCheckbox: React.FC<InputCheckboxProps> = (props: InputCheckboxProps) => {
  props = {...defaultProps, ...props}

  return (
    <div className='checkbox'>
      <label
        className={classNames({
          'checked': !!props.isChecked,
          'disabled': !!props.disabled,
          'is-invalid': !!props.invalid,
        })}
      >
        <input
          type={'checkbox'}
          className={`${props.invalid ? 'is-invalid' : ''}`}
          name={props.id}
          value={props.value}
          disabled={!!props.disabled}
          checked={!!props.isChecked}
          onChange={props.handleChange}
        />
        {props.renderLabel ? props.renderLabel(props.id) : props.label}
      </label>
    </div>
  )
}

export default InputCheckbox
