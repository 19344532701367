export const getInFilter = <T>(arr?: T[]) => {
  if (!arr || !arr?.length) {
    return undefined
  }

  return {
    in: arr,
  }
}

export const getGreaterThanOrEqualFilter = <T>(value?: T) => {
  if (value === null || value === undefined) {
    return undefined
  }

  return {
    greaterThanOrEqualTo: value,
  }
}

export const getLessThanOrEqualFilter = <T>(value?: T) => {
  if (value === null || value === undefined) {
    return undefined
  }

  return {
    lessThanOrEqualTo: value,
  }
}

export const prepareOrder = (orderList?: string[]) => {
  let result: string[] = []
  if (orderList?.length) {
    result = orderList.filter(order => !!order)
  }

  return result
}

export const getUnitAndBuildingNumbers = (query = '') => {
  const regex = /^\d{3,5}$/
  const doesMatch = regex.test(query)
  const result: {unitNumber?: number; buildingNumber?: number} = {}

  if (doesMatch) {
    result.unitNumber = +query.slice(query.length - 3)
    result.buildingNumber = +query.slice(0, query.length - 3)
  }

  return result
}

export const isUnitNumber = (query = '') => {
  const regex = /^\d{2,8}$/
  const doesMatch = regex.test(query)

  return doesMatch
}

export const doesFilterContainAny = (filter: any) => {
  if (!filter) {
    return false
  }
  const values = Object.entries(filter)

  if (!values.length) {
    return false
  }

  return values.some(e => {
    const value = e[1]
    const key = e[0]
    if (Array.isArray(value)) {
      return !!value.length
    }
    switch (typeof value) {
      case 'boolean': {
        return value
      }
      case 'object': {
        return Object.values(value || {}).some(v => !!v)
      }
      case 'string': {
        return (key === 'isActive' || key === 'isPersonAccessActive') &&
          value.toLowerCase() === 'all'
          ? false
          : !!value.length
      }
      case 'number': {
        return !!value
      }
    }
  })
}
