import './BatteryCell.style.scss'

import Icon from '../../../components/Icon'
import React, {FC} from 'react'
import {getBatteryStatus} from '../../../functions/lock.functions'

type TBatteryCellProps = {
  level: number
}

const BatteryLevel: FC<TBatteryCellProps> = ({level}) => {
  const status = getBatteryStatus(level)

  return (
    <div className='batteryCell'>
      {!!level && (
        <Icon
          icon={`battery-${status}`}
          svgProps={{
            strokeWidth: 0,
          }}
          className={'battery-icon'}
        />
      )}

      <span>{level ? Math.round(level * 100) + '%' : '—'}</span>
    </div>
  )
}

export default React.memo(BatteryLevel)
