import Router from './router/router'
import './styles/index.scss'
import {ApolloProvider} from '@apollo/client'
import {client} from './data/graphql'
import {useFirebaseApp, AuthProvider} from 'reactfire'
import {getAuth} from 'firebase/auth'
import ToastProvider from './contexts/ToastProvider'
import {loadErrorMessages, loadDevMessages} from '@apollo/client/dev'

const App = () => {
  const app = useFirebaseApp()
  const auth = getAuth(app)

  if (process.env.NODE_ENV !== 'production') {
    loadDevMessages()
    loadErrorMessages()
  }
  return (
    <div className='app-main'>
      <ToastProvider>
        <AuthProvider sdk={auth}>
          <ApolloProvider client={client}>
            <Router />
          </ApolloProvider>
        </AuthProvider>
      </ToastProvider>
    </div>
  )
}

export default App
