import {ApolloLink} from '@apollo/client'
import {getGraphqlError, isFailureCode} from '../../../functions/api.functions'

export const errorFromBodyLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response: any) => {
    if (!response.errors) {
      let path: string[] = []
      response.errors = []

      const error = Object.keys(response.data).reduce<any>((result, key) => {
        if (!result) {
          path = [key]
          result = getGraphqlError(response.data[key])
        }

        return result
      }, null)

      if (error && isFailureCode(error?.code)) {
        response.errors.push({
          path,
          message: error.message,
          extensions: {
            body: {
              code: error.code,
              message: error.message,
              response: {
                body: {
                  error: {
                    code: error.code,
                    message: error.message,
                    status: error.status,
                  },
                },
                status: error.code,
                statusText: error.status,
              },
            },
          },
        })
      }
    }

    return response
  })
})
