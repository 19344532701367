// components/DataGrid/DataGridRow.tsx

import React, {FC} from 'react'
import './DataGrid.style.scss'
import classNames from 'classnames'

export interface DataGridRowProps {
  id?: number
  selectable?: boolean
  onRowSelect?: (index: number) => void
  children?: React.ReactNode
  className?: string
}

const defaultProps: DataGridRowProps = {
  selectable: false,
  onRowSelect: () => null,
  id: -1,
}

const DataGridRow: FC<DataGridRowProps> = (props: DataGridRowProps) => {
  props = {...defaultProps, ...props}

  return (
    <tr
      className={classNames(`data-grid-row`, props.className, {
        selectable: props.selectable,
      })}
      data-testid={`DataGridRow-${props.id}`}
      onClick={() => props.onRowSelect?.(props.id ?? -1)}
    >
      {props.children}
    </tr>
  )
}

export default DataGridRow
