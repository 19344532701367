import {FC} from 'react'
import Card from '../../../components/Card'
import CardBody from '../../../components/Card/CardBody'
import Icon from '../../../components/Icon'
import {formatMoney} from '../../../functions'
import {CardProps} from '../../../components/Card/Card'
import Spinner from '../../../components/Spinner'

type RentOverdueProps = CardProps & {
  loading?: boolean
  value: string | number
}

const RentOverdueCard: FC<RentOverdueProps> = ({value, loading, ...props}) => {
  return (
    <Card padding='none' {...props}>
      <CardBody>
        <div className={'d-flex align-center'}>
          <Icon icon={'alert-triangle'} theme={'danger'} size={'lg'} />

          <h4 className={'flex-grow-1 no-margin color-black'}>
            {loading ? <Spinner /> : formatMoney(value)}
          </h4>

          <p className={'font-size-md no-margin'}>Overdue Rent</p>
        </div>
      </CardBody>
    </Card>
  )
}

export default RentOverdueCard
