import './CommonAreaDetails.style.scss'
import React, {useCallback, useEffect, useState} from 'react'

import Column from '../../components/Grid/Column'
import Panel from '../../components/Panel'
import Row from '../../components/Grid/Row'
import Section from '../../components/Grid/Section'
import DataGrid from '../../components/DataGrid'
import Paginator from '../../components/Paginator'
import PeopleFilters, {TPeopleFilterParams} from '../../layouts/People/Filters/Filters'
import Search from '../../layouts/People/Search'
import SearchFilterInput from '../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../layouts/People/Search/SearchSortBy'

import {SortOptionsItem, useQueryOptions} from '../../hooks/useQueryOptions'

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'
import {useParams} from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import {useQuery} from '@apollo/client'
import {GET_COMMON_AREA_DETAILS} from '../../data/graphql/queries/properties'
import {
  TCommonAreaResponse,
  TCommonAreaVariables,
} from '../../data/graphql/queries/properties/types'
import Spinner from '../../components/Spinner/Spinner'
import Icon from '../../components/Icon/Icon'
import InputToggle from '../../components/InputToggle/InputToggle'
import Badge from '../../components/Badge/Badge'
import {capitalize, toPrettyDate} from '../../functions'
import TableFooter from '../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../components/TabelCountItems/TableNumberOfItems'
import useToast from '../../hooks/useToast'
import {TActivityFilterFields} from '../GuestsOverview/ActivityFilters/ActivityFilters'
import {getLogEventInfo} from '../../functions/logs'
import useLockActivityLogs from '../../hooks/useLockActivityLogs'
import {
  IAllLockActivityLogsResponse,
  IAllLockActivityLogsViewsResponse,
} from '../../data/graphql/queries/activityLogs/types'

interface EventData {
  fullname: string
  lockAction: string
  category: string
  propertyName: string
  unit: string
  date: string
}

interface EventRowView {
  fullname: JSX.Element | string
  lockAction: JSX.Element
  category: string
  date: string
}

interface Log {
  name: JSX.Element | string
  action: JSX.Element
  date: string | null
  category: string
  property: string
  unit: string
}

const sortOptions: SortOptionsItem[] = [
  {value: '', label: 'All '},
  {value: 'FIRST_NAME_ASC', label: 'First Name Asc'},
  {value: 'FIRST_NAME_DESC', label: 'First Name Desc'},
  {value: 'LAST_NAME_ASC', label: 'Last Name Asc'},
  {value: 'LAST_NAME_DESC', label: 'Last Name Desc'},
  {value: 'EVENT_TYPE_ASC', label: 'Event Type Asc'},
  {value: 'EVENT_TYPE_DESC', label: 'Event Type Desc'},
  {value: 'TIME_STAMP_ASC', label: 'Date Asc'},
  {value: 'TIME_STAMP_DESC', label: 'Date Desc'},
]

interface ICommonAreaDetails {
  unitName?: string
  propertyName?: string
}

const data = [
  {
    fullname: 'John Smith',
    lockAction: 'Unlock',
    category: 'Electronics',
    propertyName: 'Smartphone',
    unit: 'pcs',
    date: '2023-06-01',
  },
]

const CommonAreaDetails = () => {
  const {unitId, propertyId} = useParams()
  const [events, setEvents] = useState<EventRowView[]>([])
  const {showToast} = useToast()

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    setQueryOptions,
    onChangeNumberOfItems,
  } = useQueryOptions<TActivityFilterFields>({
    page: 1,
    orderBy: ['TIME_STAMP_DESC'],
    searchTerm: '',
  })

  const {data} = useLockActivityLogs(debouncedSearchTerm, queryOptions)

  const [commonAreaDetails, setCommonAreaDetails] = useState({
    unitName: '—',
    propertyName: '—',
  })
  const breadcrumbsProps = [
    {name: 'Properties', url: '/properties'},
    {name: commonAreaDetails.propertyName, url: `/properties/${propertyId}/other_access`},
    {
      name: commonAreaDetails.unitName,
      url: `/properties/${propertyId}/other_access/${unitId}`,
    },
  ]

  const propertyResponse = useQuery<TCommonAreaResponse, TCommonAreaVariables>(
    GET_COMMON_AREA_DETAILS,
    {
      variables: {
        propertyId,
        unitId,
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Common Area Data',
          type: 'error',
        })
      },
    },
  )

  const onChangeSearchQuery = useCallback((searchTerm: string) => {
    setQueryOptions(prev => ({
      ...prev,
      searchTerm,
      page: 1,
    }))
  }, [])

  useEffect(() => {
    const data = propertyResponse.data?.transactionalDb
    if (!data) return
    setCommonAreaDetails({
      unitName: data.unitByUnitId.unitNumber,
      propertyName: data.propertyByPropertyId.propertyName,
    })
  }, [propertyResponse])

  const prepareData = useCallback(
    (
      data: IAllLockActivityLogsViewsResponse['transactionalDb']['allLockActivityViews']['nodes'],
    ) => {
      const events = data.map(item => {
        const name = `${item.firstName} ${item.lastName}`
        const eventInfo = getLogEventInfo(item)

        return {
          fullname: (
            <div className='label-small-regular-12'>{name.trim() ? name : 'System'}</div>
          ),
          lockAction: (
            <Badge theme={eventInfo.type} size={'sm'}>
              {eventInfo.label}
            </Badge>
          ),
          date: item.timeStamp
            ? toPrettyDate(parseInt(item.timeStamp)) || '—'
            : 'Invalid Date',
          category: capitalize(item.eventType || ''),
        }
      })

      setEvents(events)
    },
    [],
  )

  useEffect(() => {
    prepareData(data?.transactionalDb?.allLockActivityViews?.nodes || [])
  }, [data])

  const onSubmitFilter = useCallback(
    (filters: TPeopleFilterParams) => {
      setQueryOptions(prev => ({
        ...prev,
        page: 1,
        filters: {
          ...prev.filters,
          ...filters,
        },
      }))
    },
    [setQueryOptions],
  )

  const onChangeSortOrder = value => {
    setQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions(prev => ({...prev, page}))
    },
    [setQueryOptions],
  )

  return (
    <ErrorBoundary fallback={CrashScreen}>
      {propertyResponse.loading ? (
        <Spinner />
      ) : (
        <div className={'CommonAreaDetails'} data-testid={'CommonAreaDetailsView'}>
          <Section>
            <Breadcrumbs
              showBack
              goBackUrl={`/properties/${propertyId}/other_access`}
              crumbs={breadcrumbsProps}
            />
          </Section>
          <Section className='common-area-details'>
            <div className='details'>
              <div className='information'>
                <div className='name'>
                  <Icon icon='circle' theme='info' size={'md'} />
                  <h3>{commonAreaDetails.unitName}</h3>
                </div>
                <span className='body-light-14 black'>######</span>
              </div>
            </div>
            <InputToggle isChecked={true} label='Active' onValueChange={() => null} />
          </Section>

          <Section>
            <Row>
              <Column>
                <>
                  <Search>
                    <SearchFilterInput
                      placeholder='Search events'
                      value={queryOptions.searchTerm || ''}
                      onValueChange={onChangeSearchQuery}
                    />
                    <SearchFilters filter={PeopleFilters} onSubmit={onSubmitFilter} />
                    <SearchSortBy
                      value={queryOptions.orderBy[0] || ''}
                      options={sortOptions}
                      onChange={onChangeSortOrder}
                    />
                  </Search>

                  <Panel theme={'white'}>
                    <DataGrid
                      selectableRows
                      columns={[
                        {name: 'Name', key: 'fullname'},
                        {name: 'Action', key: 'lockAction'},
                        {name: 'Category', key: 'category'},

                        {name: 'Date/Time', key: 'date'},
                      ]}
                      rows={events}
                    />
                  </Panel>
                  <TableFooter itemCount={1}>
                    <Paginator
                      itemCount={1}
                      perPage={queryOptions.limit}
                      currentPage={queryOptions.page}
                      onPageChange={onChangePage}
                    />
                    <TableNumberOfItems
                      value={queryOptions.limit}
                      onValueChange={onChangeNumberOfItems}
                    />
                  </TableFooter>
                </>
              </Column>
            </Row>
          </Section>
        </div>
      )}
    </ErrorBoundary>
  )
}

export default CommonAreaDetails
