import React, {useEffect, useState} from 'react'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'

const activityActionOptions = [
  {id: 'open', name: 'Open door'},
  {id: 'closed', name: 'Close door'},
  {id: 'lock', name: 'Lock'},
  {id: 'unlock', name: 'Unlock'},
  {id: 'onetouchlock', name: 'One touch lock'},
  {id: 'keypad_pin_managed', name: 'Pin operation'},
  {id: 'lock_user_add', name: 'Add user'},
  {id: 'lock_useer_remove', name: 'Remove user'},
  {id: 'lock_accesstype_changed', name: 'Access update'},
]

const useActivityEventsFilter = (initialValue?: string[]) => {
  const [events, setEvents] = useState<CheckboxItem[]>(activityActionOptions)

  useEffect(() => {
    setEvents(
      activityActionOptions.map(item => ({
        ...item,
        checked: !!initialValue?.includes(item.id),
      })),
    )
  }, [])

  return [events, setEvents] as const
}

export default useActivityEventsFilter
