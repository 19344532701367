import {useQuery} from '@apollo/client'
import {
  TGetServicePersonAccessesResponse,
  TGetServicePersonAccessesVariables,
} from '../../data/graphql/queries/people/types'
import {GET_SERVICE_PERSON_ACCESSES} from '../../data/graphql/queries/people'
import useToast from '../useToast'

type ServicePersonAccessesProps = {
  // profileId: number
  personId: number
}

const useServicePersonAccesses = ({personId}: ServicePersonAccessesProps) => {
  const {showErrorToast} = useToast()
  const response = useQuery<
    TGetServicePersonAccessesResponse,
    TGetServicePersonAccessesVariables
  >(GET_SERVICE_PERSON_ACCESSES, {
    skip: !personId,
    variables: {
      personId,
      accessesCondition: {
        personId: personId ? Number(personId) : 0,
        isActive: true,
        isDeleted: false,
        // personProfileId: profileId ? Number(profileId) : 0,
      },
      profilesCondition: {
        personId: personId ? Number(personId) : 0,
        isDeleted: false,
      },
    },
    onError() {
      showErrorToast('Request Error', 'Unable to Retrieve Person Accesses Data')
    },
  })

  return {
    person: response?.data?.db?.person,
    profiles: response?.data?.db?.profiles.nodes,
    accesses: response?.data?.db?.accesses.nodes,
    response,
  }
}

export default useServicePersonAccesses
