import './ServiceAccessPoints.style.scss'

import {FC} from 'react'
import AccessSchedule, {
  TAccessScheduleFields,
} from '../../../components/AccessSchedule/AccessSchedule'
import Modal from '../../../components/Modal/Modal'
import Button from '../../../components/Button/Button'
import {TAccessPointsValue} from '../useAccessPoints'
import AccessPointsTable from '../../../components/AccessPointsTable'
import useServiceAccessPoints from './useServiceAccessPoints'
import useServiceUnitRow from './useServiceUnitRow'
import {isCommonAreaBuilding} from '../../../functions/devices.function'

export type TAccessPointsProps = {
  personId: number
  profileId: number
  isVendorIdentityCreated: boolean
  defaultSchedule: TAccessScheduleFields
  invalid?: boolean
  errorMessage?: string
  value: TAccessPointsValue<TAccessScheduleFields>
  noSchedule?: boolean
  onChange: (value: TAccessPointsValue<TAccessScheduleFields>) => void
}

const columns = [
  {
    key: 'schedule',
    name: 'Access Schedule',
  },
  {
    key: 'status',
    name: 'Status',
  },
  {
    key: 'pendingSchedule',
    name: 'Pending Schedule',
  },
  {
    key: 'isEnabled',
    name: 'Enabled',
  },
]

const AccessPoints: FC<TAccessPointsProps> = ({
  personId,
  profileId,
  defaultSchedule,
  value,
  invalid,
  errorMessage,
  isVendorIdentityCreated,
  noSchedule,
  onChange,
}) => {
  const serviceData = useServiceAccessPoints({
    value,
    personId,
    defaultSchedule,
    isVendorIdentityCreated,
    onChange,
  })
  const unitRow = useServiceUnitRow({noSchedule, serviceData, value, personId, profileId})
  const isCommonArea = isCommonAreaBuilding(serviceData.building)

  return (
    <>
      {!noSchedule && (
        <Modal
          width={800}
          isOpen={serviceData.isSchedulOpen}
          className='access-schedule'
          closeModal={serviceData.closeScheduleModal}
        >
          <AccessSchedule
            limitedAccess
            pinOnly={isCommonArea && !serviceData.commonAreaAppAccessAllowed}
            noRecurring={isCommonArea}
            schedule={serviceData.schedule.data}
            onChange={serviceData.setSchedule}
          />

          <div className='schedule-actions'>
            <Button
              className='apply-schedule-button'
              onClick={serviceData.applyCustomSchedule}
            >
              Ok
            </Button>
            <Button theme='outline' onClick={serviceData.closeScheduleModal}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}

      <AccessPointsTable
        value={value}
        loading={serviceData.loading}
        columns={columns}
        sortComparator={serviceData.unitsSortComparator}
        renderUnitRow={unitRow.render}
        invalid={invalid}
        errorMessage={errorMessage}
        properties={serviceData.properties}
        activeProperty={serviceData.property}
        activeBuilding={serviceData.building}
        isPropertySelected={serviceData.isPropertySelected}
        checkBuildingSelection={serviceData.isBuildingSelected}
        renderAccessValue={unitRow.getScheduleString}
        onPressCustomAccess={serviceData.openScheduleModal}
        onChangeProperty={serviceData.selectProperty}
        onChangeBuilding={serviceData.selectBuilding}
        onToggleBuilding={serviceData.toggleBuilding}
        onToggleProperty={serviceData.toggleProperty}
      />
    </>
  )
}

export default AccessPoints
