import './Transactions.style.scss'

import {useCallback, useEffect, useState} from 'react'
import DataGrid from '../../../components/DataGrid'
import Paginator from '../../../components/Paginator'
import Panel from '../../../components/Panel'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import TableFooter from '../../../components/TableFooter/TableFooter'
import usePinTransactions, {TTransaction} from '../../../hooks/data/usePinTransactions'
import Button from '../../../components/Button'
import {toCommonDateTimeFormat} from '../../../functions'
import {useNavigate} from 'react-router-dom'
import Section from '../../../components/Grid/Section'
import useUserPersonId from '../../../hooks/useUserPersonId'
import useTableSort from '../../../hooks/useTableSort'

type TableRow = {
  personId: string
  vendorId: string | null
  unit: string
  operation: string
  person: string
  datetime: string
  actions: React.ReactElement
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'person:asc', value: 'TARGET_PERSON_NAME_ASC', label: 'Name Asc'},
  {sortKey: 'person:desc', value: 'TARGET_PERSON_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Desc'},
  {
    sortKey: 'operation:asc',
    value: 'PIN_OPERATION_TYPE_ASC',
    label: 'Operation Type Asc',
  },
  {
    sortKey: 'operation:desc',
    value: 'PIN_OPERATION_TYPE_DESC',
    label: 'Operation TypeDesc',
  },
  {sortKey: 'datetime:asc', value: 'CREATED_DT_ASC', label: 'Event Type  Asc'},
  {
    sortKey: 'datetime:desc',
    value: 'CREATED_DT_DESC',
    label: 'Event Type Desc',
  },
]

const tableColumns = [
  {key: 'person', name: 'Person Name', sortable: true},
  {key: 'unit', name: 'Unit Number', sortable: true},
  {key: 'operation', name: 'Operation', sortable: true},
  {key: 'datetime', name: 'Event time', sortable: true},
  {key: 'actions', name: '', sortable: true},
]

const Transactions = () => {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState<TableRow[]>([])
  const initiatorPersonId = useUserPersonId()

  const {queryOptions, debouncedSearchTerm, upsertQueryOptions, onChangeNumberOfItems} =
    useQueryOptions({
      limit: 10,
      page: 1,
      orderBy: [],
      searchTerm: '',
    })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const transactions = usePinTransactions(debouncedSearchTerm, {
    ...queryOptions,
    initiatorPersonId,
    status: 'Failed',
  })

  const renderTransactionActions = useCallback(
    (transaction: TTransaction) => (
      <div className='actions-row'>
        <Button
          size={'sm'}
          theme={'outline'}
          onClick={e => {
            e.stopPropagation()
            transactions.retryTransaction(transaction)
          }}
        >
          {transactions.actionLoading ? 'Loading ...' : 'Retry'}
        </Button>
        <Button
          size={'sm'}
          theme={'danger'}
          onClick={e => {
            e.stopPropagation()
            transactions.cancelTransaction(+transaction.asyncTransactionId)
          }}
        >
          {transactions.actionLoading ? 'Loading ...' : 'Cancel'}
        </Button>
      </div>
    ),
    [
      transactions.retryTransaction,
      transactions.cancelTransaction,
      transactions.actionLoading,
    ],
  )

  useEffect(() => {
    setTableData(
      Object.values(transactions.data).map(transaction => ({
        vendorId: transaction.vendorId,
        personId: transaction.targetPersonId,
        unit: transaction.unitNumber,
        operation: transaction.pinOperationType + ' pin code',
        person: transaction.targetPersonName,
        datetime: toCommonDateTimeFormat(transaction.createdDt),
        actions: renderTransactionActions(transaction),
      })),
    )
  }, [renderTransactionActions, transactions.data])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSort.value])

  const openVendorUser = useCallback(
    (index: number) => {
      const vendorId = tableData[index].vendorId
      const personId = tableData[index].personId

      if (vendorId && personId) {
        navigate(`/people/vendors/${vendorId}/user/${personId}`)
      }
    },
    [navigate, tableData],
  )

  const onChangeSearchQuery = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions({
        searchTerm,
        page: 1,
      })
    },
    [upsertQueryOptions],
  )

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  return (
    <Section className='Transactions'>
      <Search>
        <SearchFilterInput
          placeholder='Search people'
          value={queryOptions.searchTerm || ''}
          onValueChange={onChangeSearchQuery}
        />
        <SearchSortBy
          value={queryOptions.orderBy[0] || ''}
          options={sortOptions}
          onChange={onChangeSortOrder}
        />
      </Search>
      <Panel theme={'white'}>
        <DataGrid
          selectableRows
          order={tableSort.order}
          loading={transactions.loading}
          columns={tableColumns}
          rows={tableData}
          selectedColumn={tableSort.column}
          selectedColumnChange={tableSort.setSortColumn}
          onRowSelect={openVendorUser}
        />
      </Panel>

      <TableFooter itemCount={transactions.totalCount}>
        <Paginator
          itemCount={transactions.totalCount}
          perPage={queryOptions.limit as number}
          currentPage={queryOptions.page as number}
          onPageChange={p =>
            upsertQueryOptions({...(queryOptions as any), page: p as number})
          }
        />
        <TableNumberOfItems
          value={queryOptions.limit}
          onValueChange={onChangeNumberOfItems}
        />
      </TableFooter>
    </Section>
  )
}

export default Transactions
