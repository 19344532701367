import './Vendors.style.scss'

import React, {useCallback, useContext, useEffect, useState} from 'react'
import Column from '../../../components/Grid/Column'
import Panel from '../../../components/Panel'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import DataGrid from '../../../components/DataGrid'
import Button from '../../../components/Button'
import Paginator from '../../../components/Paginator'
import Badge from '../../../components/Badge'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import {useNavigate} from 'react-router-dom'
import {BadgeProps} from '../../../components/Badge/Badge'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import VendorFilters, {TVendorsFilterFields} from './VendorFilters'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import {toCommonDateFormat} from '../../../functions'
import useAllVendorsCompanyViews from '../../../hooks/data/useAllVendorsCompanyViews'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'

const PAGE_SIZE = 10

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'company:asc', value: 'VENDOR_NAME_ASC', label: 'Company Asc'},
  {sortKey: 'company:desc', value: 'VENDOR_NAME_DESC', label: 'Company Desc'},
  {sortKey: 'activeDate:asc', value: 'ACTIVE_DATE_ASC', label: 'Active Date Asc'},
  {sortKey: 'activeDate:desc', value: 'ACTIVE_DATE_DESC', label: 'Active Date Desc'},
  {sortKey: 'deactiveDate:asc', value: 'DEACTIVE_DATE_ASC', label: 'Deactive Date Asc'},
  {
    sortKey: 'deactiveDate:desc',
    value: 'DEACTIVE_DATE_DESC',
    label: 'Deactive Date Desc',
  },
]

const Vendors = () => {
  const navigate = useNavigate()
  const {setQuery} = useContext(ExportTableContext)
  const [vendorsTableData, setVendorsTableData] = useState<any[]>([])

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    setQueryOptions,
    onChangeNumberOfItems,
  } = useQueryOptions<TVendorsFilterFields>({
    limit: PAGE_SIZE,
    page: 1,
    orderBy: ['VENDOR_NAME_ASC'],
    filters: {
      isActive: 'active',
    },
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    vendors: vendorsList,
    response: vendorsResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = useAllVendorsCompanyViews(debouncedSearchTerm, queryOptions)

  useEffect(() => {
    if (vendorsList) {
      setVendorsTableData(
        vendorsList.map(item => ({
          company: <CellWithAvatar name={item.vendorName} />,
          status: getDisplayBadge(item.isVendorActive ? 'Active' : 'Inactive'),
          activeDate: toCommonDateFormat(item.activeDate) || '—',
          deactiveDate: (
            <div className={`d-flex align-center space-between`}>
              {toCommonDateFormat(item.deactiveDate) || '—'}
              {item.isVendorActive ? (
                <Button
                  size='sm'
                  id='add-staff'
                  theme={'secondary-outline'}
                  onClick={e => {
                    e.stopPropagation()
                    navigate('/people/vendors/' + item.vendorId + '/add-staff')
                  }}
                >
                  + Add
                </Button>
              ) : null}
            </div>
          ),
        })),
      )
    }
  }, [vendorsList])

  const getDisplayBadge = (type: string) => {
    let theme: BadgeProps['theme']

    switch (type) {
      case 'Active':
        theme = 'info'
        break
      case 'Inactive':
        theme = 'default'
        break
      default:
        theme = 'default'
    }

    return (
      <Badge theme={theme} size={'sm'}>
        {type}
      </Badge>
    )
  }

  const dataForTableQuery = useCallback(async () => {
    try {
      const {data} = await queryForDownloadTable()
      const logs = data?.transactionalDb?.allVendorCompanyViews?.nodes || []

      const tableData = logs.map(item =>
        Object.values({
          company: item.vendorName || '—',
          status: item.isVendorActive ? 'Active' : 'Inactive',
          property: item.propertyName || '—',
          activeDate: toCommonDateFormat(item.activeDate) || '—',
          deactiveDate: toCommonDateFormat(item.deactiveDate) || '—',
        }),
      )

      tableData.unshift(['Company', 'Status', 'Property', 'Active Date', 'Deactive Date'])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryVariables, queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const onChangeSortOrder = value => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const onChangeSearchQuery = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions(prev => ({
        ...prev,
        searchTerm,
        page: 1,
      }))
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [setQueryOptions],
  )

  const onSubmitFilter = useCallback(
    (filters: TVendorsFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const emptyVendorsTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Vendors table is empty...',
  })

  const itemCount =
    vendorsResponse.data?.transactionalDb.allVendorCompanyViews.totalCount || 0

  const handleSelectRow = useCallback(
    (index: number) => {
      if (vendorsList) {
        navigate(vendorsList[index].vendorId)
      }
    },
    [vendorsList],
  )
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Vendors'} data-testid={'VendorsView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search vendors'
                    value={queryOptions.searchTerm}
                    onValueChange={onChangeSearchQuery}
                  />
                  <SearchFilters
                    filter={VendorFilters}
                    initialValue={queryOptions.filters}
                    onSubmit={onSubmitFilter}
                  />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>

                <Panel theme={'white'}>
                  <DataGrid
                    selectableRows
                    order={tableSort.order}
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    loading={vendorsResponse.loading}
                    columns={[
                      {name: 'Company', key: 'company', sortable: true},
                      {name: 'Status', key: 'status'},
                      {name: 'Active Date', key: 'activeDate', sortable: true},
                      {name: 'Deactive Date', key: 'deactiveDate', sortable: true},
                    ]}
                    emptyTableComponent={emptyVendorsTable}
                    rows={vendorsTableData}
                    onRowSelect={handleSelectRow}
                  />
                </Panel>
                <TableFooter itemCount={itemCount} loading={vendorsResponse.loading}>
                  <Paginator
                    itemCount={itemCount}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Vendors
