// components/CheckboxGroup/CheckboxGroup.tsx

import React, {FC} from 'react'
import './CheckboxGroup.style.scss'

export interface CheckboxGroupProps {
  id?: string
  children?: React.ReactNode
  theme?: 'default' | 'none'
}

const defaultProps: CheckboxGroupProps = {
  theme: 'default',
}

const CheckboxGroup: FC<CheckboxGroupProps> = (props: CheckboxGroupProps) => {
  props = {...defaultProps, ...props}

  return (
    <fieldset
      id={props.id}
      className={`checkbox-group checkbox-group-${props.theme}`}
      data-testid={'CheckboxGroup'}
    >
      {props.children}
    </fieldset>
  )
}

export default CheckboxGroup
