import {gql} from '@apollo/client'

export const DELETE_MY_USER_ACCOUNT = gql`
  mutation DeleteMyUserAccount($input: DeleteMyUserAccountInput!) {
    utility {
      deleteMyUserAccount(input: $input) {
        ... on DeleteMyUserAccountSuccess {
          success
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`