import './Credentials.style.scss'

import InputText from '../../../components/InputText/InputText'
import Button from '../../../components/Button'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import useToast from '../../../hooks/useToast'
import {
  clearPhoneNumberFormatting,
  formatPhoneNumber,
  generatePassword,
  isValidPhoneNumber,
} from '../../../functions'
import * as Yup from 'yup'

type TCredentialsProps = {
  storedPhone?: string
  storedEmail?: string
  storedPassword?: string
  onSubmit?: (email: string, pasword: string, phoneNumber: string) => void
}

const validators = {
  noSpaces: /^\S*$/g,
  minLength: /.{8,}/g,
  withCapitalLetter: /[A-Z]+/g,
  withNumber: /[0-9]+/g,
  withSpecialChar: /[#@$?_!]+/g,
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required().label('Email').email('Email is not valid'),
  phone: Yup.string()
    .required()
    .label('Phone Number')
    .test('isValidNumber', 'Phone number is not valid', isValidPhoneNumber),
  password: Yup.string()
    .required()
    .label('Password')
    .matches(validators.noSpaces, 'Cannot contain any spaces')
    .matches(validators.minLength, 'Must be at least 8 characters long')
    .matches(validators.withCapitalLetter, 'Must contain at least 1 capital letter')
    .matches(validators.withNumber, 'Must contain at least 1 number')
    .matches(validators.withSpecialChar, 'Must contain at least 1 special character'),
})

const Credentials = ({
  storedPhone = '',
  storedEmail = '',
  storedPassword = '',
  onSubmit,
}: TCredentialsProps) => {
  const {showInfoToast} = useToast()

  const {values, touched, errors, handleChange, setFieldValue, submitForm} = useFormik({
    initialValues: {
      email: storedEmail,
      phone: storedPhone,
      password: storedPassword,
    },
    validateOnChange: true,
    validationSchema,
    onSubmit: values => {
      onSubmit?.(values.email, values.password, values.phone)
    },
  })

  useEffect(() => {
    setFieldValue('email', storedEmail)
    setFieldValue('password', storedPassword)
    setFieldValue('phone', storedPhone)
  }, [storedPhone, storedEmail, storedPassword, setFieldValue])

  return (
    <div className='Credentials'>
      <div className='formRow'>
        <InputText
          size={'xs'}
          id={'building-account-phone'}
          label={'Mobile Phone'}
          name={'phone'}
          disabled={!!storedPhone}
          invalid={touched.phone && !!errors.phone}
          invalidMessage={errors.phone}
          value={formatPhoneNumber(values.phone)}
          onChange={e => {
            setFieldValue('phone', clearPhoneNumberFormatting(e.target.value))
          }}
        />

        {!!storedPhone && (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(values.phone)
              showInfoToast('Copy phone number', 'Phone number has been copied')
            }}
          >
            Copy
          </Button>
        )}
      </div>

      <div className='formRow'>
        <InputText
          size={'xs'}
          id={'building-account-email'}
          type={'email'}
          label={'Email'}
          name={'email'}
          disabled={!!storedEmail}
          invalid={touched.email && !!errors.email}
          invalidMessage={errors.email}
          value={values.email}
          onChange={handleChange}
        />

        {!!storedEmail && (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(values.email)
              showInfoToast('Copy email', 'Email has been copied')
            }}
          >
            Copy
          </Button>
        )}
      </div>

      <div className='formRow'>
        <InputText
          size={'xs'}
          id={'building-account-password'}
          label={'Password'}
          name={'password'}
          invalid={touched.password && !!errors.password}
          invalidMessage={errors.password}
          value={values.password}
          onChange={handleChange}
        />

        <Button
          theme={'outline'}
          onClick={() => {
            setFieldValue('password', generatePassword())
          }}
        >
          Generate
        </Button>

        <Button
          theme={storedPassword ? 'default' : 'outline'}
          disabled={!values.password}
          onClick={() => {
            navigator.clipboard.writeText(values.password)
            showInfoToast('Copy password', 'Password has been copied')
          }}
        >
          Copy
        </Button>
      </div>

      <Button
        disabled={!values.email || !values.password}
        className='submit'
        onClick={submitForm}
      >
        Save credentials
      </Button>
    </div>
  )
}

export default Credentials
