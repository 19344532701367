// components/Spinner/Spinner.tsx

import React, {FC} from 'react'
import './Spinner.style.scss'
import Icon from '../Icon'
import classNames from 'classnames'

export interface SpinnerProps {
  label?: string
  fullScreen?: boolean
  overlay?: boolean
  visible?: boolean
}

const defaultProps: SpinnerProps = {
  label: 'Loading',
  fullScreen: true,
  overlay: false,
  visible: true,
}

const Spinner: FC<SpinnerProps> = (props: SpinnerProps) => {
  props = {...defaultProps, ...props}

  if (!props.visible) {
    return null
  }

  return (
    <div
      className={classNames('spinner', {
        'spinner-full-screen': props.fullScreen,
        'spinner-overlay': props.overlay,
      })}
      data-testid={'spinner'}
    >
      <Icon icon={'loader2'} /> {props.label}
    </div>
  )
}

export default Spinner
