// views/Search/Search.tsx

import './Search.style.scss'
import {FC} from 'react'
import classNames from 'classnames'
import Icon from '../../../components/Icon/Icon'

type TSearchLayoutTogggleOption = {
  label: string
  value: string
}

type TSearchLayoutTogggleProps = {
  value: 'table' | 'grid'
  onChange: (value: 'table' | 'grid') => void
}

const SearchLayoutTogggle: FC<TSearchLayoutTogggleProps> = ({value, onChange}) => {
  return (
    <div className='view-toggle'>
      <div
        className={classNames({
          'option-container': true,
          'active': value === 'table',
        })}
        data-testid='layout-to-table'
        onClick={() => onChange('table')}
      >
        <Icon icon={'menu'} size={20} />
      </div>
      <div
        className={classNames({
          'option-container': true,
          'active': value === 'grid',
        })}
        onClick={() => onChange('grid')}
        data-testid='layout-to-grid'
      >
        <Icon icon={'grid'} size={20} />
      </div>
    </div>
  )
}

export default SearchLayoutTogggle
