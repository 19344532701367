// Badge.tsx

import React, {FC} from 'react'
import './Badge.style.scss'

export interface BadgeProps {
  theme?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'warning'
    | 'success'
    | 'info'
    | 'outline'
    | 'dark'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  shape?: 'pill' | 'square'
  label?: string
  role?: string
  children?: React.ReactNode
  testId?: string
}

const defaultProps: BadgeProps = {
  theme: 'default',
  size: 'md',
  shape: 'square',
  role: 'status',
  testId: '',
}

const Badge: FC<BadgeProps> = (props: BadgeProps) => {
  props = {...defaultProps, ...props}

  return (
    <div
      className={`badge ${props.theme} ${props.size} ${props.shape}`}
      data-testid={props.testId || 'Badge'}
      role={props.role}
      aria-label={props.label}
    >
      {props.children}
    </div>
  )
}

export default Badge
