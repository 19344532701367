// forms/NewPassword/NewPassword.tsx

import {FC, FormEvent, useState, useEffect} from 'react'
import {getAuth, confirmPasswordReset, verifyPasswordResetCode} from 'firebase/auth'
import {useNavigate, useLocation, Link} from 'react-router-dom'

import './NewPassword.style.scss'
import InputText from '../../components/InputText/InputText'
import Button from '../../components/Button'
import {randomString, formatIdentityError} from '../../functions'
import ButtonRow from '../../components/ButtonRow'
import Checklist from '../../components/Checklist'

export interface NewPasswordProps {
  id?: string
  value?: any
  valueChange?: any
}

const defaultProps: NewPasswordProps = {
  id: randomString(),
}

const validators = {
  noSpaces: /^\S*$/,
  minLength: /\w{8,}/,
  withCapitalLetter: /[A-Z]+/,
  withNumber: /[0-9]+/,
  withSpecialChar: /[#@$?_!]+/,
}

const errorType = {
  CONFIRM: 'confirm',
  VERIFY: 'verify',
}

const NewPassword: FC<NewPasswordProps> = (props: NewPasswordProps) => {
  props = {...defaultProps, ...props}
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const oobCode = params.get('oobCode')
  const tenantId = params.get('tenantId')

  const auth = getAuth()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>({message: '', type: ''})

  const checklist = [
    {
      label: 'Cannot contain any spaces',
      checked: validators.noSpaces.test(password),
    },
    {
      label: 'Must be at least 8 characters long',
      checked: validators.minLength.test(password),
    },
    {
      label: 'Must contain at least 1 capital letter',
      checked: validators.withCapitalLetter.test(password),
    },
    {
      label: 'Must contain at least 1 number',
      checked: validators.withNumber.test(password),
    },
    {
      label: 'Must contain at least 1 special character',
      checked: validators.withSpecialChar.test(password),
    },
  ]

  useEffect(() => {
    setLoading(true)

    const verifyCode = async () => {
      auth.tenantId = tenantId

      try {
        const email = await verifyPasswordResetCode(auth, oobCode as string)
        setEmail(email)
      } catch (error) {
        setError({message: 'Password reset link has expired!', type: errorType.VERIFY})
      }

      setLoading(false)
    }

    verifyCode()
  }, [])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    try {
      await confirmPasswordReset(auth, oobCode as string, password)
      navigate('/', {replace: true})
    } catch (error: any) {
      const errorMessage = error.code
      setError({message: formatIdentityError(errorMessage), type: errorType.CONFIRM})
    }

    setLoading(false)
  }

  const isDisabled = (): boolean => {
    return !checklist.every(item => item.checked === true)
  }

  return (
    <>
      {error.type === errorType.VERIFY ? (
        <>
          <p>{error.message}</p>
          <p>
            Please request a new forgot password link&nbsp;
            <Link to='/' replace>
              here
            </Link>
          </p>
        </>
      ) : (
        <>
          <p>
            Thanks for confirming your account. Please create a new password for: {email}
          </p>

          <form
            id={props.id}
            className={'new-password-form'}
            onSubmit={e => handleSubmit(e)}
          >
            {loading ? (
              <div>Loading...</div>
            ) : (
              <>
                <InputText
                  id={'new-password-email'}
                  type={'password'}
                  label={'Password'}
                  placeholder={'Enter a secure password'}
                  value={password}
                  suffixIcon='eye'
                  onValueChange={setPassword}
                />
                {error.type === errorType.CONFIRM && (
                  <div className={`color-red error-message`}>{error.message}</div>
                )}
              </>
            )}
            <Checklist title={''} items={checklist} />
            <ButtonRow>
              <Button
                theme={'default'}
                width={'block'}
                type={'submit'}
                disabled={isDisabled()}
              >
                SAVE PASSWORD
              </Button>
              <Button type={'button'} theme={'link'} width={'block'} to={'/'}>
                Cancel
              </Button>
            </ButtonRow>
          </form>
        </>
      )}
    </>
  )
}

export default NewPassword
