// views/ForgotPassword/ForgotPassword.tsx

import './ForgotPassword.style.scss'
import ForgotPasswordForm from '../../forms/ForgotPassword'
import BackButton from '../..//components/BackButton'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'

const ForgotPassword = () => {
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'forgot-password-container'}>
        <div className='forgot-password-back-button'>
          <BackButton />
        </div>

        <div className={'forgot-password'} data-testid={'ForgotPassword'}>
          <img alt='logo' className={'brand-mark'} src={'/images/apthub-mark.svg'} />
          <h2 className={'color-black'}>Forgot Password?</h2>
          <p>
            Enter your email to request a password reset. You’ll receive an email with a
            secure link to create a new password and regain access to your account.
          </p>

          <ForgotPasswordForm />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default ForgotPassword
