import {FC} from 'react'
import './CrashScreen.style.scss'

const CrashScreen: FC = () => {
  return (
    <div className={'CrashScreen'} data-testid={'CrashScreen'}>
      <p className='h6-semibold-16'>
        Oops, section crashed! We&apos;re on it. Thanks for your patience!
      </p>
    </div>
  )
}

export default CrashScreen
