import React, {FC} from 'react'
import ConfirmModal from '../../../../components/ConfirmModal'
import {TConfirmModalProps} from '../../../../components/ConfirmModal/ConfirmModal'

type RevokeExistingAccessProps = Omit<
  TConfirmModalProps,
  'message' | 'title' | 'confirmLabel'
> & {
  loading?: boolean
  personType?: string
  accesses: {
    personProfileId: number
    installedDeviceId: number
  }[]
}

const RevokeExistingAccess: FC<RevokeExistingAccessProps> = ({
  loading,
  personType,
  accesses,
  ...props
}) => {
  return (
    <ConfirmModal
      {...props}
      title={'Access Already Granted'}
      message={
        <p>
          <p>
            <span>This user currently has access to this unit under the role of </span>
            <b>{personType}</b>
          </p>
          <p>
            To proceed with inviting them under a <b>Resident</b> role, please revoke
            their existing access first.
          </p>
          <p>Do you want to revoke it now?</p>
        </p>
      }
      confirmLabel={loading ? 'Loading...' : 'Revoke Access'}
    />
  )
}

export default React.memo(RevokeExistingAccess)
