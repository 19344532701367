import {useEffect, useState} from 'react'
import {useQuery} from '@apollo/client'
import {GET_FLOOR_PLANS} from '../../data/graphql/queries/properties'
import {
  TFloorPlansResponse,
  TFloorPlansVariables,
} from '../../data/graphql/queries/properties/types'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import useToast from '../useToast'

const useFloorplanFilter = (initialValue?: number[]) => {
  const {showToast} = useToast()
  const [floorplans, setFloorplans] = useState<CheckboxItem[]>([])

  const allBuildings = useQuery<TFloorPlansResponse, TFloorPlansVariables>(
    GET_FLOOR_PLANS,
    {
      variables: {
        condition: {
          isActive: true,
          isDeleted: false,
        },
        orderBy: ['NAME_ASC'],
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Floor Plans Data',
          type: 'error',
        })
      },
    },
  )
  const floorplansList = allBuildings.data?.transactionalDb.allFloorPlans.nodes

  useEffect(() => {
    if (floorplansList) {
      const newBuidlings: CheckboxItem[] = []

      floorplansList.forEach(plan => {
        newBuidlings.push({
          id: +plan.floorPlanId,
          name: `${plan.name} (${plan.description})`,
          checked: !!initialValue?.includes(+plan.floorPlanId),
        })
      })

      setFloorplans(newBuidlings)
    }
  }, [floorplansList])

  const getSelectedFloorplans = () => {
    return floorplans.filter(({checked}) => !!checked).map(({id}) => +id)
  }

  const clearFloorplans = () => {
    return floorplans.map(({checked}) => !!checked)
  }

  return {floorplans, setFloorplans, getSelectedFloorplans, clearFloorplans}
}

export default useFloorplanFilter
