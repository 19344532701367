import {FC} from 'react'

const Refresh: FC = () => (
  <g>
    <path
      d='M5.04329 7.85793C6.38376 5.55105 8.88171 4 11.7419 4C16.0177 4 19.4839 7.46618 19.4839 11.7419C19.4839 16.0177 16.0177 19.4839 11.7419 19.4839C7.46618 19.4839 4 16.0177 4 11.7419'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M8.83875 7.87097H4.96778V4' strokeLinecap='round' strokeLinejoin='round' />
  </g>
)

export default Refresh
