/* eslint-disable @typescript-eslint/ban-ts-comment */
// views/Overview/Overview.tsx
import './ActivityLogOverview.style.scss'

import {useQuery} from '@apollo/client'
import {useCallback, useContext, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import Avatar from '../../components/Avatar'
import Badge from '../../components/Badge'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import DataGrid from '../../components/DataGrid'
import ExportTable from '../../components/ExportTable/ExportTable'
import Section from '../../components/Grid/Section'
import Icon from '../../components/Icon'
import Modal, {ModalProps} from '../../components/Modal/Modal'
import Paginator from '../../components/Paginator'
import Panel from '../../components/Panel'
import {
  ALL_LOCK_ACTIVITY_LOGS,
  ALL_LOCK_ACTIVITY_VIEWS_LOGS,
  GET_PERSON_DETAILS,
} from '../../data/graphql/queries/activityLogs'
import {capitalize, formatDateTime, toPrettyDate} from '../../functions'
import {SortOptionsItem, useQueryOptions} from '../../hooks/useQueryOptions'
import Search from '../../layouts/People/Search/Search'
import SearchFilterInput from '../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../layouts/People/Search/SearchSortBy'
import ActivityModal, {IActivityModalProps} from '../ActivityModal/ActivityModal'

import PeopleFilters from '../../layouts/People/Filters/Filters'
import CrashScreen from '../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import TableFooter from '../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../components/TabelCountItems/TableNumberOfItems'
import useToast from '../../hooks/useToast'
import {
  IActivityLogView,
  IAllLockActivityLogsResponse,
  IAllLockActivityLogsViewsResponse,
  IGetPersonDetailsResponse,
  Node,
} from '../../data/graphql/queries/activityLogs/types'
import {getLogEventInfo} from '../../functions/logs'
import useTableSort from '../../hooks/useTableSort'
import ActivityFilters, {
  TActivityFilterFields,
} from '../GuestsOverview/ActivityFilters/ActivityFilters'
import {useGetEmptyTableMessage} from '../../hooks/filters/useGetEmptyTableMessage'
import useActivityLogOverview from '../../hooks/useActivityLogOverview'
import {ExportTableContext} from '../../components/ExportTable/ExportTableContextProvider'

interface Log {
  name: JSX.Element | string
  action: JSX.Element
  date: string | null
  category: string
  property: string
  unit: string
}

interface User {
  name: string
  email: string
  phone: string
  unitNumber?: string | number
  property?: string
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Number Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Number Desc'},
  {sortKey: 'building:asc', value: 'BUILDING_NAME_ASC', label: 'Building Name Asc'},
  {sortKey: 'building:desc', value: 'BUILDING_NAME_DESC', label: 'Building Name Desc'},
  {sortKey: 'date:asc', value: 'TIME_STAMP_ASC', label: 'Date Asc'},
  {sortKey: 'date:desc', value: 'TIME_STAMP_DESC', label: 'Date Desc'},
]

const ActivityLogOverview = () => {
  const {showToast} = useToast()
  const {setQuery} = useContext(ExportTableContext)
  const location = useLocation()
  const {logId} = useParams()
  const currentPath = location.pathname

  const [modalState, setModalState] = useState<Partial<ModalProps>>({})

  const [logs, setLogs] = useState<Log[]>([])
  const [user, setUser] = useState<User>()

  const {
    queryOptions,
    upsertQueryOptions,
    onChangeNumberOfItems,
    setQueryOptions,
    debouncedSearchTerm,
  } = useQueryOptions({
    limit: 10,
    page: 1,
    orderBy: ['TIME_STAMP_DESC'],
    searchTerm: '',
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    loading,
    data,
    variables: queryVariables,
    queryForDownloadTable,
  } = useActivityLogOverview(debouncedSearchTerm, queryOptions, {
    condition: {personId: Number(logId)},
  })

  const person = useQuery<IGetPersonDetailsResponse>(GET_PERSON_DETAILS, {
    variables: {
      personId: Number(logId),
      condition: {
        personId: Number(logId),
      },
    },
  })

  const breadcrumbsProps = {
    showBack: true,
    crumbs: [
      {name: 'Activity logs', url: '/activity-logs'},
      {
        name: user?.name || '-',
        url: `/activity-logs/${currentPath.split('/')[2]}`,
      },
    ],
  }

  const prepareLogs = useCallback(
    (
      logs: IAllLockActivityLogsViewsResponse['transactionalDb']['allLockActivityViews']['nodes'],
    ) => {
      if (!logs.length) return

      setLogs(
        logs.map(log => {
          const eventInfo = getLogEventInfo(log)
          const userName = eventInfo.userName
          return {
            name: userName ? (
              <div className='label-small-regular-12'>{userName}</div>
            ) : (
              '—'
            ),
            action: (
              <Badge theme={eventInfo.type} size={'sm'}>
                {eventInfo.label}
              </Badge>
            ),
            date: log.timeStamp
              ? formatDateTime(parseInt(log.timeStamp))
              : 'Invalid Date',
            category: capitalize(log.eventType || ''),
            property: log.propertyName || '—',
            building: log.buildingName || '—',
            unit: log.unitNumber || '—',
            personId: log.personId,
          }
        }),
      )
    },
    [user],
  )

  useEffect(() => {
    if (data && data.transactionalDb && data.transactionalDb.allLockActivityViews) {
      prepareLogs(data.transactionalDb.allLockActivityViews.nodes)
    }
  }, [data, prepareLogs])

  const handlePressRow = useCallback(
    index => {
      const activity = logs[index]

      // setModalState({
      //   isOpen: true,
      //   iconTheme: 'info',
      //   modalHeading: 'horizontal',
      //   title: capitalize('activity.type'),
      //   iconHeader: handleLogIcon(activity.type),
      // })
      // setActivityModalBody({
      //   name: userInfo.name,
      //   unit: userInfo.unitNumber,
      //   category: capitalize(activity.type),
      //   status: capitalize(activity.eventName),
      //   time: activity.date || '-',
      //   details: activity.message || '-',
      // })
    },
    [user],
  )

  const closeModal = useCallback(() => {
    setModalState({
      isOpen: false,
    })
  }, [])

  const onTypeSearchField = useCallback(
    (text: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm: text,
      })
    },
    [upsertQueryOptions],
  )

  const itemCount = data?.transactionalDb?.allLockActivityViews?.totalCount || 0

  const handleName = (name?: string, surname?: string) => {
    if (
      name?.toLocaleLowerCase() === 'system' ||
      surname?.toLocaleLowerCase() === 'system'
    )
      return 'System'

    if (name || surname) {
      return `${name || ''} ${surname || ''}`
    }

    return '-'
  }

  const dataForTableQuery = useCallback(async () => {
    const variables: Partial<any> = {...queryVariables}

    if ('first' in variables) {
      delete variables['first']
    }

    if ('offset' in variables) {
      delete variables['offset']
    }

    variables.orderBy = queryOptions.orderBy

    try {
      const {data} = await queryForDownloadTable()

      const logs: IActivityLogView[] = data.transactionalDb.allLockActivityViews.nodes

      const tableData = logs
        .map(log =>
          Object.values({
            category: capitalize(log.eventType || 'System'),
            action: log.event || '—',
            property: log.propertyName || '—',
            building: log.buildingName || '—',
            unit: log.unitNumber || '—',
            date: log.timeStamp
              ? formatDateTime(parseInt(log.timeStamp))
              : 'Invalid Date',
          }),
        )
        .reverse()

      tableData.unshift(['Category', 'Action', 'Property', 'Building', 'Unit', 'Date'])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    const details = person.data?.transactionalDb.personByPersonId
    if (details) {
      setUser({
        name: handleName(details.firstName, details.lastName),
        email: details.email || '-',
        phone: details.mobilePhone || '-',
        unitNumber: details.personUnitsByPersonId.nodes?.[0]?.unitByUnitId?.unitNumber,
        property:
          (details.personUnitsByPersonId.nodes?.[0]?.unitByUnitId.buildingByBuildingId
            .propertyByPropertyId?.propertyName || '-') + '',
      })
    }
  }, [!!person.data])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const onChangeSortOrder = value => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const onSubmitFilter = useCallback(
    (filters: TActivityFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const emptyTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Activity log table is empty...',
  })

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className='ActivityLogOverview' data-testid='ActivityLogOverview'>
        {/* <Modal
          {...modalState}
          closeModal={closeModal}
          modalBody={<ActivityModal {...activityModalBody} />}
        /> */}
        <Section>
          <Breadcrumbs {...breadcrumbsProps} />
        </Section>

        <Section spacing='none' id='actions-section'>
          <div className='d-flex align-center space-between actions'>
            <h3>Activity Log</h3>
            <ExportTable
              fileName={`${user?.name || ''}-logs${
                user?.unitNumber ? '-' + user?.unitNumber : ''
              }`}
            />
          </div>
        </Section>

        <Section spacing={'section-md'} id='guest-info-section'>
          <Card padding='none' theme='dark'>
            <CardBody padding='none' id='contact-card'>
              <div className='contact-el-grid first'>
                <div className='d-flex align-center'>
                  <Avatar
                    withinBorder
                    withBorders
                    src={'https://www.w3schools.com/howto/img_avatar.png'}
                  />
                  <div className='flex-column d-flex name-section wrap'>
                    <div className='mobile-page-header-20 black'>{user?.name}</div>
                    <div className='d-flex align-center property wrap'>
                      <div className='body-light-14 black'>{user?.property}</div>
                      {!!user?.unitNumber && (
                        <Badge size='sm' theme={'danger'}>
                          Unit {user?.unitNumber}
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='contact-el-grid'>
                <div className='flex-column d-flex'>
                  <div className='mobile-page-header-20 black'>Call</div>
                  <div className='body-light-14 black'>{user?.phone || '—'}</div>
                </div>
              </div>
              <div className='contact-el-grid'>
                <div className='flex-column d-flex'>
                  <div className='mobile-page-header-20 black'>Email</div>
                  <div className='body-light-14 black'>{user?.email || '—'}</div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Section>

        <Search>
          <SearchFilterInput
            placeholder='Search events'
            value={queryOptions.searchTerm || ''}
            onValueChange={onTypeSearchField}
          />
          <SearchFilters
            filter={ActivityFilters}
            initialValue={queryOptions.filters}
            onSubmit={onSubmitFilter}
          />
          <SearchSortBy
            value={queryOptions.orderBy[0] || ''}
            options={sortOptions}
            onChange={onChangeSortOrder}
          />
        </Search>

        <Section spacing={'none'} id='activity-logs'>
          <Panel theme={'white'}>
            <DataGrid
              selectedColumn={tableSort.column}
              id='logs-grid'
              loading={loading}
              withHeader={true}
              columns={[
                {name: 'Category', key: 'category'},
                {name: 'Action', key: 'action'},
                {name: 'Property', key: 'property'},
                {name: 'Building', key: 'building', sortable: true},
                {name: 'Unit', key: 'unit', sortable: true},
                {name: 'Date', key: 'date', sortable: true},
              ]}
              emptyTableComponent={emptyTable}
              rows={logs}
              order={tableSort.order}
            />
          </Panel>
          <TableFooter itemCount={itemCount} loading={loading}>
            <Paginator
              itemCount={itemCount}
              perPage={queryOptions.limit as number}
              currentPage={queryOptions.page as number}
              onPageChange={p =>
                upsertQueryOptions({
                  ...queryOptions,
                  page: p as number,
                })
              }
            />
            <TableNumberOfItems
              value={queryOptions.limit}
              onValueChange={onChangeNumberOfItems}
            />
          </TableFooter>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default ActivityLogOverview
