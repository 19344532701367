import {FC, useCallback, useContext, useEffect, useState} from 'react'
import './ExportTable.style.scss'
import Icon from '../Icon'
import {ExportTableContext} from './ExportTableContextProvider'

interface IProps {
  fileName: string
}

const ExportTable: FC<IProps> = ({fileName = 'table'}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const {query} = useContext(ExportTableContext)

  const handleClick = useCallback(async () => {
    if (!query.current) {
      return
    }
    setLoading(true)

    const data = await query.current()

    const escapeCSVValue = value => {
      value = value.replace(/"/g, '""')
      if (/[",\n]/.test(value)) {
        value = `"${value}"`
      }
      return value
    }

    const csvRows = data.map(row => row.map(value => escapeCSVValue(value)).join(','))

    const csvString = csvRows.join('\n')

    const blob = new Blob(['\uFEFF' + csvString], {type: 'text/csv;charset=UTF-8'})

    setLoading(false)

    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()

    URL.revokeObjectURL(url)
  }, [query, fileName])

  return (
    <div className='export'>
      <div
        onClick={handleClick}
        className={`desktop-label-semibold-14 export-table ${
          loading ? 'link-disabled' : ''
        }`}
      >
        <Icon size='md' icon='upload' theme='tooltip' />
        {loading ? 'Loading...' : 'Export'}
      </div>
    </div>
  )
}

export default ExportTable
