import './PendingVnedorInvitesPanel.style.scss'

import {useEffect, useState} from 'react'
import {formatDateTime} from '../../../functions'
import Card from '../../../components/Card/Card'
import Section from '../../../components/Grid/Section'
import DataGrid from '../../../components/DataGrid'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import {useParams} from 'react-router-dom'
import usePendingVendorInvites from '../../../hooks/data/usePendingVendorInvites'
import {getFullName} from '../../../functions/people.functions'
import Button from '../../../components/Button'
import EditInviteModal from './EditInviteModal'
import {TGetVendorPendingInvitesResponse} from '../../../data/graphql/queries/people/types'

type TPendingInviteRow = {
  id: string
  name: JSX.Element
  mobilePhone: string
  email: string
  expiresAt: string
  actions: JSX.Element
}

type TAppInvite =
  TGetVendorPendingInvitesResponse['transactionalDb']['invites']['nodes'][number]

const PendingVendorInvitesPanel = () => {
  const params = useParams<{vendorId: string}>()
  const vendorId = Number(params.vendorId)

  const invitesData = usePendingVendorInvites(vendorId)
  const [editingInvite, setEditingInvite] = useState<TAppInvite | null>(null)
  const [guestsTableData, setGuestsTableData] = useState<TPendingInviteRow[]>([])

  useEffect(() => {
    setGuestsTableData(
      invitesData.invites.map(invite => {
        return {
          id: invite.id,
          name: <CellWithAvatar name={getFullName(invite)} />,
          mobilePhone: invite.mobilePhone,
          email: invite.email,
          expiresAt: formatDateTime(new Date(invite.expirationDt)),
          actions: (
            <div className='hidden-actions'>
              <Button
                className='edit-button'
                onClick={() => {
                  setEditingInvite(invite)
                }}
              >
                Edit
              </Button>
            </div>
          ),
        }
      }),
    )
  }, [invitesData.invites])

  return (
    <Section className='PendingVendorInvitesPanel'>
      <EditInviteModal
        invite={editingInvite}
        isOpen={!!editingInvite}
        onClose={() => {
          setEditingInvite(null)
        }}
      />
      <h4 className='black desktop-h4-semibold-22'>Staff</h4>
      <Card padding='sm'>
        <DataGrid
          loading={invitesData.loading}
          selectableRows
          columns={[
            {key: 'name', name: 'Name'},
            {key: 'mobilePhone', name: 'Phone Number'},
            {key: 'email', name: 'Email'},
            {key: 'expiresAt', name: 'Expiration Date'},
            {key: 'actions', name: ''},
          ]}
          minHeight
          rows={guestsTableData}
        />
      </Card>
    </Section>
  )
}

export default PendingVendorInvitesPanel
