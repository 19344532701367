import {useCallback, useMemo} from 'react'

import {isUnitNumber} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {TDevicesFilterFields} from '../../views/Devices/DevicesFilter'
import {
  TAllDevicesResponse,
  TAllDevicesVariables,
} from '../../data/graphql/queries/devices/types'
import {useQuery} from '@apollo/client'
import {GET_ALL_DEVICES} from '../../data/graphql/queries/devices'

import {client} from '../../data/graphql'
import {batteryLevels} from '../filters/useBatteryFilter'
import useUserAccess from '../useUserAccess'
import useExcludedBuildingId from '../useExcludedBuildingId'

const useDevices = (
  searchTerm: string,
  options: Required<QueryOptions<TDevicesFilterFields>>,
  vars?: TAllDevicesVariables,
) => {
  const {properties} = useUserAccess()
  const devsBuildingId = useExcludedBuildingId()

  const variables = useMemo(() => {
    const filter: TAllDevicesVariables['filter'] = {
      propertyId: {
        in: properties,
      },
      buildingId: {
        notEqualTo: devsBuildingId,
      },
      and: [],
      isDeviceInstalled: {
        equalTo: true,
      },
    }

    if (searchTerm) {
      filter[isUnitNumber(searchTerm) ? 'unitNumber' : 'deviceType'] = {
        includesInsensitive: searchTerm,
      }
    }

    if (options?.filters?.unitFilters) {
      filter.and?.push({
        or: options?.filters?.unitFilters,
      })
    }

    if (options.filters.networkStatusFilters) {
      const unknownStatusIncluded =
        options.filters.networkStatusFilters.includes('unknown')
      const onlyUnknownStatus =
        options.filters.networkStatusFilters.length === 1 && unknownStatusIncluded

      const statusesWithoutUnknown = options.filters.networkStatusFilters.filter(
        status => status !== 'unknown',
      )

      const unknowStatusFilter = {
        deviceStatusCurrent: {
          isNull: true,
        },
      }

      const includedStatusesFilter = {
        deviceStatusCurrent: {
          in: statusesWithoutUnknown,
        },
      }

      if (unknownStatusIncluded && onlyUnknownStatus) {
        filter.and?.push({
          or: [unknowStatusFilter],
        })
      } else if (!unknownStatusIncluded && statusesWithoutUnknown.length > 0) {
        filter.and?.push({
          or: [includedStatusesFilter],
        })
      } else if (unknownStatusIncluded && statusesWithoutUnknown.length > 0) {
        filter.and?.push({
          or: [unknowStatusFilter, includedStatusesFilter],
        })
      }
    }

    if (options.filters.batteryLevelFilters) {
      filter.and?.push({
        or: options.filters.batteryLevelFilters.map(levelKey => {
          const [from, to] = batteryLevels[levelKey]

          return {
            and: [
              {deviceBatteryLevel: {greaterThanOrEqualTo: from}},
              {deviceBatteryLevel: {lessThanOrEqualTo: to}},
            ],
          }
        }),
      })
    }

    return {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      orderBy: options?.orderBy,
      ...vars,
      filter: {
        ...filter,
        ...vars?.filter,
      },
    }
  }, [options, searchTerm, vars])

  const response = useQuery<TAllDevicesResponse, TAllDevicesVariables>(GET_ALL_DEVICES, {
    variables,
  })

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {...variables}
    if ('first' in copiedVariables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query<TAllDevicesResponse, TAllDevicesVariables>({
      query: GET_ALL_DEVICES,
      variables: copiedVariables,
    })
  }, [variables])

  const devices = response?.data?.transactionalDb?.allDeviceInventoryViews?.nodes

  return {
    devices,
    response,
    variables,
    queryForDownloadTable,
  }
}

export default useDevices
