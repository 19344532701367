// views/Search/Search.tsx

import './Search.style.scss'
import {FC} from 'react'
import InputSelect from '../../../components/InputSelect'

export type TSearchSortByOption = {
  label: string
  value: string
}

type TSearchSortByProps = {
  value: string
  options: TSearchSortByOption[]
  onChange: (value: string) => void
}

const SearchSortBy: FC<TSearchSortByProps> = ({value, options, onChange}) => {
  return (
    <div className='sort-box '>
      <p>Sort by:</p>

      <InputSelect
        id='select'
        testId='sort-selector'
        allowNull={false}
        value={value}
        onValueChange={onChange}
        options={options}
      />
    </div>
  )
}

export default SearchSortBy
