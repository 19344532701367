import {BadgeProps} from '../components/Badge/Badge'
import {IActivityLog, IActivityLogView} from '../data/graphql/queries/activityLogs/types'
import {TQueryFilter} from '../data/graphql/queries/common/types'
import {capitalize} from './string.functions'

export enum LockSystemUser {
  MASTER_PIN = 'masterpin',
  AUTO_RELOCK = 'autorelock',
  MANUAL_LOCK = 'manuallock',
  MANUAL_UNLOCK = 'manualunlock',
}

export enum LockEvents {
  OPEN = 'open',
  CLOSED = 'closed',
  LOCK = 'lock',
  UNLOCK = 'unlock',
  ONETOUCHLOCK = 'onetouchlock',
  KEYPAD_PIN_MANAGED = 'keypad_pin_managed',
  USER_ADDED = 'lock_user_add',
  USER_REMOVED = 'lock_user_remove',
  ACCESS_CHANGED = 'lock_accesstype_changed',
  DELETE_PIN = 'delete',
  DISABLE_PIN = 'disable',
  ENABLE_PIN = 'enable',
  LOAD_PIN = 'load',
  LOCK_BATTERY_ALERT = 'lock_battery_alert',
  LOCK_BATTERY_CRITICAL_ALERT = 'keypad_battery_critical',
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export const getLogEventInfo = ({
  event,
  state,
  firstName,
  lastName,
  originalPayload,
}: IActivityLogView) => {
  const originalUserId = originalPayload?.User?.UserID
  const info: {
    label: string
    userName: string
    type: BadgeProps['theme']
  } = {
    label: '',
    type: 'info',
    userName: '',
  }

  if (firstName || lastName) {
    info.userName = `${firstName} ${lastName}`
  }

  if (event === LockEvents.LOCK || event === LockEvents.UNLOCK) {
    switch (originalUserId) {
      case LockSystemUser.MANUAL_LOCK: {
        info.label = 'Manual Lock'
        info.userName = 'MANUAL LOCK'
        break
      }
      case LockSystemUser.MANUAL_UNLOCK: {
        info.label = 'Manual Unlock'
        info.userName = 'MANUAL UNLOCK'
        break
      }
      case LockSystemUser.MASTER_PIN: {
        info.label = 'Unlock'
        info.userName = 'MASTER PIN'
        break
      }
      case LockSystemUser.AUTO_RELOCK: {
        info.label = 'Lock'
        info.userName = 'AUTO RELOCK'
        break
      }
      default: {
        info.label = capitalize(event)
      }
    }

    return info
  }

  switch (event) {
    case LockEvents.KEYPAD_PIN_MANAGED: {
      if (state === 'load') {
        info.label = 'Pin loaded'
      } else if (state === 'delete') {
        info.label = 'Pin deleted'
      } else if (state === 'enable') {
        info.label = 'Pin enabled'
      } else if (state === 'disabled') {
        info.label = 'Pin disabled'
      } else {
        info.label = 'Pin managed'
      }

      break
    }
    case LockEvents.ONETOUCHLOCK: {
      info.label = 'One touch lock'
      info.userName = 'ONE TOUCH LOCK'
      break
    }
    case LockEvents.OPEN: {
      info.label = 'Door opened'
      info.userName = 'DOOR OPENED'
      break
    }
    case LockEvents.CLOSED: {
      info.label = 'Door closed'
      info.userName = 'DOOR CLOSED'
      break
    }
    case LockEvents.USER_ADDED: {
      info.label = 'User addedd'
      break
    }
    case LockEvents.USER_REMOVED: {
      info.label = 'User removed'
      break
    }
    case LockEvents.ACCESS_CHANGED: {
      info.label = 'Access changed'
      break
    }
    case LockEvents.LOAD_PIN: {
      info.label = 'Pin loaded'
      break
    }
    case LockEvents.DELETE_PIN: {
      info.label = 'Pin deleted'
      break
    }
    case LockEvents.ENABLE_PIN: {
      info.label = 'Pin enabled'
      break
    }
    case LockEvents.DISABLE_PIN: {
      info.label = 'Pin disabled'
      break
    }
    case LockEvents.LOCK_BATTERY_ALERT: {
      info.label = 'Low Battery'
      info.type = 'danger'
      break
    }
    case LockEvents.LOCK_BATTERY_CRITICAL_ALERT: {
      info.label = 'Critical Low Battery'
      info.type = 'danger'
      break
    }
    case LockEvents.ONLINE: {
      info.label = 'Online'
      break
    }
    case LockEvents.OFFLINE: {
      info.label = 'Offline'
      info.type = 'danger'
      break
    }
    default: {
      info.label = event
    }
  }

  return info
}

export const getFullNameFilter = (name: string) => {
  const splitName = name.split(/\s/)
  const firstName = splitName[0]
  const lastName = splitName.length > 1 ? splitName.slice(1).join(' ') : undefined

  const result: TQueryFilter<{firstName: string; lastName: string}> = {or: []}

  if (!name) {
    return result
  }

  if (firstName && !lastName) {
    result.or?.push({
      firstName: {
        includesInsensitive: firstName,
      },
    })

    result.or?.push({
      lastName: {
        includesInsensitive: firstName,
      },
    })
  } else if (firstName && lastName) {
    result.or?.push({
      firstName: {
        includesInsensitive: firstName,
      },
      lastName: {
        includesInsensitive: lastName,
      },
    })

    result.or?.push({
      firstName: {
        includesInsensitive: lastName,
      },
      lastName: {
        includesInsensitive: firstName,
      },
    })
  }

  return result
}
