import {FC} from 'react'

const BatteryPartial: FC = () => (
  <g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.3448 8.23037V10.2301C23.3448 10.3807 23.3143 10.5298 23.2549 10.6689C23.1955 10.8079 23.1084 10.9342 22.9987 11.0403C22.889 11.1465 22.7589 11.2304 22.6157 11.2874C22.4726 11.3444 22.3193 11.3732 22.1647 11.3722L1.17411 11.3722C0.862974 11.3722 0.564559 11.2519 0.344411 11.0378C0.124263 10.8236 0.000384824 10.5332 -1.76133e-05 10.2301L-1.72161e-05 1.14356C0.00038334 0.840385 0.124216 0.549744 0.34432 0.33537C0.564425 0.120995 0.862835 0.000388919 1.17411 0H22.1707C22.4821 0 22.7807 0.120481 23.0009 0.33494C23.2211 0.549399 23.3448 0.840266 23.3448 1.14356V3.13447C23.5186 3.13447 23.6852 3.20169 23.8081 3.32136C23.931 3.44103 24 3.60333 24 3.77256V7.59228C24 7.76151 23.931 7.92381 23.8081 8.04348C23.6852 8.16314 23.5186 8.23037 23.3448 8.23037ZM1.31758 10.0963C1.44044 10.2159 1.60708 10.2832 1.78084 10.2832L21.5655 10.2788C21.6514 10.2788 21.7366 10.2622 21.816 10.2302C21.8954 10.1981 21.9675 10.151 22.0282 10.0918C22.0889 10.0325 22.137 9.96215 22.1698 9.88474C22.2026 9.80733 22.2193 9.72439 22.2191 9.64066V1.73155C22.2193 1.64782 22.2026 1.56488 22.1698 1.48747C22.137 1.41006 22.0889 1.33971 22.0282 1.28043C21.9675 1.22116 21.8954 1.17414 21.816 1.14205C21.7366 1.10997 21.6514 1.09345 21.5655 1.09345L1.77479 1.09345C1.60261 1.095 1.43797 1.16251 1.3165 1.28137C1.19503 1.40023 1.12648 1.56089 1.12569 1.7286L1.12569 9.64508C1.12569 9.81431 1.19472 9.97661 1.31758 10.0963ZM15.8543 2.55233V8.8139C15.8543 9.16631 15.6343 9.45199 15.3629 9.45199L2.41096 9.45199C2.13955 9.45199 1.91953 9.16631 1.91953 8.8139L1.91953 2.55233C1.91953 2.19992 2.13955 1.91424 2.41096 1.91424L15.3629 1.91424C15.6343 1.91424 15.8543 2.19992 15.8543 2.55233Z'
      fill='#5CC1A5'
    />
  </g>
)

export default BatteryPartial
