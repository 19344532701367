// components/Modal/Modal.tsx

import React, {FC} from 'react'
import './Modal.style.scss'
import HorizontalModalHeading from './HorizontalModalHeading'
import {IconProps} from '../Icon/Icon'
import VerticalModalHeading from './VerticalModalHeading'
import Portal from '../Portal'

export interface ModalProps {
  id?: string
  modalHeading?: 'horizontal' | 'vertical'
  modalBody?: React.ReactNode
  modalFooter?: React.ReactNode
  destroy?
  width?: number | string
  padding?: 'none' | 'md'
  iconHeader?: IconProps['icon']
  iconTheme?: IconProps['theme']
  title?: string
  headingDescription?: string
  isOpen?: boolean
  closeModal(e?: any): void
  children?: React.ReactNode
  onIconClick?: () => void
  className?: string
  verticalHeaderTextAlignment?: 'align-center' | 'align-start' | 'align-end'
}

const defaultProps: ModalProps = {
  width: 440,
  padding: 'md',
  className: '',
  closeModal() {
    return
  },
}

const Modal: FC<ModalProps> = (props: ModalProps) => {
  props = {...defaultProps, ...props}

  return (
    <Portal>
      <div
        onClick={e => e.stopPropagation()}
        className={`modal${props.isOpen ? '' : '-hidden'} ${props.className}`}
        data-testid={'Modal'}
      >
        <div className={'gobo'} onClick={e => props.closeModal(e)}></div>
        <div
          id={props.id}
          className={`modal-dialogue padding-${props.padding}`}
          style={{width: props.width, maxWidth: '100%'}}
        >
          {props.modalHeading ? (
            <div className={'modal-header'}>
              {props.modalHeading === 'horizontal' && (
                <HorizontalModalHeading
                  title={props.title}
                  icon={props.iconHeader}
                  closeModal={props.closeModal}
                  iconTheme={props.iconTheme}
                  onClick={props.onIconClick}
                />
              )}

              {props.modalHeading === 'vertical' && (
                <VerticalModalHeading
                  title={props.title}
                  icon={props.iconHeader}
                  closeModal={props.closeModal}
                  iconTheme={props.iconTheme}
                  description={props.headingDescription}
                  onClick={props.onIconClick}
                  textAlignment={props.verticalHeaderTextAlignment}
                />
              )}
            </div>
          ) : null}

          {props.modalBody && <div className={'modal-body'}>{props.modalBody}</div>}
          {props.children && <div className={'modal-body'}> {props.children}</div>}

          {props.modalFooter && <div className={'modal-gutter'}>{props.modalFooter}</div>}
        </div>
      </div>
    </Portal>
  )
}

export default Modal
