// views/Search/SearchFilters.tsx

import {FC, useState} from 'react'
import './Search.style.scss'
import Icon from '../../../components/Icon'
import Button from '../../../components/Button'
import Portal from '../../../components/Portal'
import {doesFilterContainAny} from '../../../functions/filters'

export type TFilterComponentProps<T = {[key: string]: any}> = {
  isOpen: boolean
  initialValue?: T
  onSubmit: (filters: T) => void
  onHide(): void
}

export type TSearchFiltersProps<T = {[key: string]: any}> = {
  filter: FC<TFilterComponentProps<T>>
  initialValue?: T
  onSubmit: (filters: T) => void
}

const SearchFilters: FC<TSearchFiltersProps> = ({filter, initialValue, onSubmit}) => {
  const FilterComponent = filter
  const filterIsActive = doesFilterContainAny(initialValue)
  const [isFilterOpened, setFilterFlag] = useState(false)

  const onOpenFilter = () => {
    setFilterFlag(true)
  }

  const onHideFilter = () => {
    setFilterFlag(false)
  }

  const onSubmitFilter = (data: {[key: string]: any}) => {
    onHideFilter()
    onSubmit(data)
  }

  return (
    <>
      <Portal>
        <FilterComponent
          isOpen={isFilterOpened}
          onHide={onHideFilter}
          onSubmit={onSubmitFilter}
          initialValue={initialValue}
        />
      </Portal>

      <Button
        id='filters-btn-open'
        fontStyle='body-light-14'
        capitalize
        theme={'white'}
        onClick={onOpenFilter}
        testId='open-filter-button'
      >
        <div className={'filter-btn-wrapper'}>
          Filters <Icon size='sm' icon={'filter'} />
          {filterIsActive && <div className='red-dot' />}
        </div>
      </Button>
    </>
  )
}
export default SearchFilters
