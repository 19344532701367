import {User} from 'firebase/auth'
import jwtDecode from 'jwt-decode'
import {useEffect, useState} from 'react'
import {useAuth} from 'reactfire'

type TToken = {
  apthub: {
    access: [
      {
        property_id: number
        building_id: number
        unit_id: number
        app_access: string
      },
    ]
    person_id: number
    person_type_code: string
    tenant_uuid: string
  }
}

const useUserPersonId = () => {
  const auth = useAuth()

  const [personId, setPersonId] = useState(0)

  useEffect(() => {
    updatePersonId(auth.currentUser)
  }, [auth.currentUser])

  const updatePersonId = async (user: User | null) => {
    if (!user) {
      setPersonId(0)
      return
    }

    try {
      const token = await user.getIdToken()
      const decodedToken = jwtDecode<TToken>(token)

      if (decodedToken.apthub.person_id) {
        setPersonId(+decodedToken.apthub.person_id)
      } else {
        setPersonId(0)
      }
    } catch (e) {
      setPersonId(0)
    }
  }

  return personId
}

export default useUserPersonId
