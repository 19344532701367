import {FC} from 'react'

const BatteryFull: FC = () => (
  <g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.3449 8.23037V10.2301C23.3449 10.3807 23.3143 10.5298 23.2549 10.6689C23.1955 10.8079 23.1084 10.9342 22.9988 11.0403C22.8891 11.1465 22.7589 11.2304 22.6158 11.2874C22.4726 11.3444 22.3193 11.3732 22.1647 11.3722L1.17416 11.3722C0.863019 11.3722 0.564605 11.2519 0.344457 11.0378C0.124309 10.8236 0.0004306 10.5332 2.81631e-05 10.2301L2.85602e-05 1.14356C0.000429117 0.840385 0.124262 0.549744 0.344366 0.33537C0.56447 0.120995 0.862881 0.000388919 1.17416 0H22.1707C22.4821 0 22.7808 0.120481 23.001 0.33494C23.2212 0.549399 23.3449 0.840266 23.3449 1.14356V3.13447C23.5186 3.13447 23.6853 3.20169 23.8081 3.32136C23.931 3.44103 24 3.60333 24 3.77256V7.59228C24 7.76151 23.931 7.92381 23.8081 8.04348C23.6853 8.16314 23.5186 8.23037 23.3449 8.23037ZM1.31763 10.0963C1.44049 10.2159 1.60713 10.2832 1.78089 10.2832L21.5655 10.2788C21.6515 10.2788 21.7366 10.2622 21.816 10.2302C21.8954 10.1981 21.9675 10.151 22.0283 10.0918C22.089 10.0325 22.1371 9.96215 22.1698 9.88474C22.2026 9.80733 22.2194 9.72439 22.2192 9.64066V1.73155C22.2194 1.64782 22.2026 1.56488 22.1698 1.48747C22.1371 1.41006 22.089 1.33971 22.0283 1.28043C21.9675 1.22116 21.8954 1.17414 21.816 1.14205C21.7366 1.10997 21.6515 1.09345 21.5655 1.09345L1.77484 1.09345C1.60266 1.095 1.43802 1.16251 1.31655 1.28137C1.19507 1.40023 1.12653 1.56089 1.12574 1.7286L1.12574 9.64508C1.12574 9.81431 1.19476 9.97661 1.31763 10.0963ZM20.8316 2.55233V8.8139C20.8316 9.16631 20.5383 9.45199 20.1764 9.45199L2.90951 9.45199C2.54768 9.45199 2.25436 9.16631 2.25436 8.8139L2.25436 2.55233C2.25436 2.19992 2.54768 1.91424 2.90951 1.91424L20.1764 1.91424C20.5383 1.91424 20.8316 2.19992 20.8316 2.55233Z'
      fill='#5CC1A5'
      stroke='currentColor'
    />
  </g>
)

export default BatteryFull
