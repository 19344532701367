import {useCallback, useMemo} from 'react'

import {getInFilter, prepareOrder} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {
  TGetEmployeesResponse,
  TGetEmployeesVariables,
  TPortalPersonListVariables,
} from '../../data/graphql/queries/people/types'
import {useQuery} from '@apollo/client'
import {GET_ALL_EMPLOYEES} from '../../data/graphql/queries/people'
import {client} from '../../data/graphql'
import useUserAccess from '../useUserAccess'

const useStaffData = (
  searchTerm: string,
  options: Required<QueryOptions>,
  condition?: TPortalPersonListVariables['condition'],
) => {
  const userAccess = useUserAccess()

  const variables = useMemo(() => {
    const {properties} = options.filters

    const filter: {[key: string]: any} = {
      personProfileId: {
        isNull: false,
      },
    }

    if (properties?.length) {
      filter.propertyId = getInFilter(properties)
    } else {
      filter.propertyId = getInFilter([...userAccess.properties, 0])
    }

    if (searchTerm) {
      filter.employeeName = {
        includesInsensitive: searchTerm,
      }
    }

    const orderBy = prepareOrder(options?.orderBy)

    return {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      filter,
      condition,
      ...(orderBy.length ? {orderBy} : {}),
    }
  }, [options, searchTerm, condition])

  const response = useQuery<TGetEmployeesResponse, TGetEmployeesVariables>(
    GET_ALL_EMPLOYEES,
    {
      variables,
    },
  )

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {...variables}
    if ('first' in copiedVariables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query({query: GET_ALL_EMPLOYEES, variables: copiedVariables})
  }, [variables])

  return {
    staff: response.data?.transactionalDb.allPortalEmployeeViews.nodes,
    response,
    variables,
    queryForDownloadTable,
  }
}

export default useStaffData
