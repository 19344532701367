import {useContext} from 'react'
import {ToastContext, ToastContextType} from '../contexts/ToastProvider'

const useToast = (): ToastContextType => {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('useToast should be used in toast provider')
  }

  return context
}

export default useToast
