// components/Alert/Alert.tsx

import {FC} from 'react'
import './Avatar.style.scss'

export interface AvatarProps {
  withBorders?: boolean
  withinBorder?: boolean
  square?: boolean
  src: string
  size?: 'md' | 'sm' | {width: number; height: number}
}

const defaultProps: Partial<AvatarProps> = {
  withBorders: false,
  size: 'md',
}

const Avatar: FC<AvatarProps> = (props: AvatarProps) => {
  props = {...defaultProps, ...props}

  return (
    <div className={`avatar `} data-testid={'AvatarTest'}>
      <img
        className={`avatar-img ${props.size} ${props.square ? 'square' : null} ${
          props.withBorders ? 'with-border' : ''
        }`}
        alt='avatar'
        src={props.src}
        style={typeof props.size === 'object' ? props.size : {}}
      />
      {props.withinBorder ? <div className='within-border-avatar' /> : null}
    </div>
  )
}

export default Avatar
