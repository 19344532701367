import {FC} from 'react'
import classNames from 'classnames'
import Badge, {BadgeProps} from '../Badge/Badge'

type ProfileHeaderBadgeCardProps = {
  title: string
  badgeLabel?: string
  badgeTheme?: BadgeProps['theme']
  className?: string
}

const ProfileHeaderBadgeCard: FC<ProfileHeaderBadgeCardProps> = ({
  title,
  badgeLabel,
  badgeTheme,
  className = '',
}) => (
  <div className={classNames('flex-column d-flex', className)}>
    <div className='mobile-page-header-20'>{title}</div>
    <div>
      <Badge size='sm' theme={badgeTheme}>
        {badgeLabel}
      </Badge>
    </div>
  </div>
)

export default ProfileHeaderBadgeCard
