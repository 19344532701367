import {useCallback, useEffect, useState} from 'react'

import {TBuildingWithUnits} from '../../data/graphql/queries/common/types'
import {TAllAccessPointsResponse} from '../../data/graphql/queries/properties/types'
import {TDevices} from './useDevicesAccessPoints'
import {isObjectEmpty} from '../../functions'
import {isCommonAreaBuilding} from '../../functions/devices.function'
import usePropertiesStructures from '../../hooks/data/usePropertiesStructures'

type TPropertyId = string
type TBuildingId = string
type TUnitId = string

const isDevicesData = (data: any): data is TDevices => {
  if (!Array.isArray(data)) {
    return false
  }

  if (!data.length) {
    return true
  }

  if (data[0].device && data[0].location) {
    return true
  }

  return false
}

type TBuildingAccesses<T> = {
  units: Record<TUnitId, T | null>
  data?: T | null
  buildingName: string
}

export type TAccessPointsValue<T> = Record<
  TPropertyId,
  {
    data?: T | null
    buildings: Record<TBuildingId, TBuildingAccesses<T>>
  }
>

type TProperty = TAllAccessPointsResponse['transactionalDb']['allProperties']['nodes'][0]
type TStructure = {propertyId?: string; buildingId?: string; unitId?: string}

type TAccessPointData<T> = {
  propertyId?: string
  buildingId?: string
  unitId?: string
  data?: T | null
}

export type TUseAccessPointsProps<T> = {
  defaultData?: T
  value: TAccessPointsValue<T>
  unitFilter?: (unit: {
    unitId: string
    unitNumber: string
    propertyId: string
    isCommonArea: boolean
  }) => boolean
  getStructureSpecificData?: (structure: TStructure, data: T) => T
  onChange: (value: TAccessPointsValue<T>) => void
}

export const useAccessPoints = <T>({
  defaultData,
  value,
  getStructureSpecificData,
  unitFilter,
  onChange,
}: TUseAccessPointsProps<T>) => {
  const [activeProperty, setActiveProperty] = useState<TProperty | null>(null)
  const [activeBuilding, setActiveBuilding] = useState<TBuildingWithUnits | null>(null)
  const [properties, setProperties] = useState<TProperty[]>([])

  const propertyAccesses = activeProperty ? value[activeProperty?.propertyId] : null
  const buildingAccesses = activeBuilding
    ? propertyAccesses?.buildings?.[activeBuilding?.buildingId]
    : null

  const propertiesStructures = usePropertiesStructures()
  const propertiesList = propertiesStructures.data

  const getPropertyBuildings = (property: TProperty) => {
    if (unitFilter) {
      return property.buildingsByPropertyId.nodes.reduce<TBuildingWithUnits[]>(
        (result, current) => {
          const isCommonArea = isCommonAreaBuilding(current)

          const buildingUnits = current.unitsByBuildingId.nodes.filter(
            ({unitId, unitNumber}) =>
              unitFilter({
                propertyId: property.propertyId,
                unitId,
                unitNumber,
                isCommonArea,
              }),
          )

          if (buildingUnits.length) {
            result.push({
              ...current,
              unitsByBuildingId: {
                nodes: buildingUnits,
              },
            })
          }

          return result
        },
        [],
      )
    }

    return property.buildingsByPropertyId.nodes
  }

  useEffect(() => {
    if (propertiesList?.length) {
      const filteredProperties = propertiesList.map(item => ({
        ...item,
        buildingsByPropertyId: {
          nodes: getPropertyBuildings(item),
        },
      }))

      setProperties(filteredProperties)
      setActiveProperty(filteredProperties[0])

      if (filteredProperties[0].buildingsByPropertyId.nodes.length) {
        const building = filteredProperties[0].buildingsByPropertyId.nodes[0]
        setActiveBuilding(building)
      }
    }
  }, [propertiesList, unitFilter])

  const toggleProperty = (enabled: boolean) => {
    const newValue = {...value}
    const propertyId = activeProperty?.propertyId

    if (propertyId) {
      const propertyAccesses = newValue[activeProperty?.propertyId]

      onToggleEntity(enabled, {
        propertyId,
        data: propertyAccesses?.data || defaultData || null,
      })
    }
  }

  const toggleBuilding = (enabled: boolean) => {
    if (activeProperty && activeBuilding) {
      const {buildingId} = activeBuilding
      const {propertyId} = activeProperty

      const data = propertyAccesses?.data || defaultData || null
      const structureData =
        getStructureSpecificData && data
          ? getStructureSpecificData({propertyId, buildingId}, data)
          : data

      onToggleEntity(enabled, {
        propertyId,
        buildingId,
        data: structureData,
      })
    }
  }

  const toggleUnit = useCallback(
    (enabled: boolean, unitId: string) => {
      if (activeProperty && activeBuilding) {
        const {buildingId} = activeBuilding
        const {propertyId} = activeProperty
        const data =
          buildingAccesses?.data || propertyAccesses?.data || defaultData || null
        const structureData =
          getStructureSpecificData && data
            ? getStructureSpecificData({unitId}, data)
            : data

        onToggleEntity(enabled, {
          propertyId,
          buildingId,
          unitId,
          data: structureData,
        })
      }
    },
    [
      value,
      activeBuilding?.buildingId,
      buildingAccesses?.data,
      propertyAccesses?.data,
      defaultData,
    ],
  )

  const onToggleEntity = (enabled: boolean, payload: TAccessPointData<T>) => {
    if (enabled) {
      onChange(getGrantedAccess(payload))
    } else {
      onChange(getDeniedAccess(payload))
    }
  }

  const getUpdatedPropertyBuildings = (
    propertyId = '',
    allAccesses: TAccessPointsValue<T>,
  ) => {
    const newValue = {...allAccesses}
    const property = newValue[propertyId]
    const buildings = activeProperty?.buildingsByPropertyId.nodes

    if (property && buildings) {
      return buildings.reduce<Record<TBuildingId, TBuildingAccesses<T>>>(
        (result, building) => {
          const buildingAccess = property.buildings[building.buildingId]
          const buildingUnits = buildingAccess?.units || {}

          result[building.buildingId] = {
            data: buildingAccess?.data || null,
            buildingName: building.buildingName,
            units: building?.unitsByBuildingId.nodes.reduce((result, current) => {
              let unitData: unknown = Object.hasOwn(buildingUnits, current.unitId)
                ? buildingUnits[current.unitId]
                : null

              if (isDevicesData(unitData) && unitData) {
                unitData = unitData.map(device => ({
                  ...device,
                  checked: true,
                }))
              }
              result[current.unitId] = unitData

              return result
            }, {}),
          }

          return result
        },
        {},
      )
    }

    return {}
  }

  const getGrantedAccess = ({
    propertyId = '',
    buildingId = '',
    unitId = '',
    data,
  }: TAccessPointData<T>) => {
    const newValue = {...value}

    if (!newValue[propertyId] && propertyId) {
      newValue[propertyId] = {
        buildings: {},
      }
    }

    const property = newValue[propertyId]
    const targetProperty = propertiesList?.find(item => item.propertyId === propertyId)
    const targetBuilding = targetProperty?.buildingsByPropertyId?.nodes.find(
      item => item.buildingId === buildingId,
    )

    if (!property?.buildings?.[buildingId] && buildingId && property && targetBuilding) {
      property.buildings[buildingId] = {
        data: null,
        buildingName: targetBuilding?.buildingName,
        units: {},
      }
    }

    const building = property?.buildings?.[buildingId]

    if (unitId && building) {
      if (!building.units) {
        building.units = {}
      }

      building.units[unitId] = data || null
    } else if (buildingId && building) {
      const isDevices = isDevicesData(data)

      building.data = data
      building.units =
        activeBuilding?.unitsByBuildingId.nodes.reduce((result, current) => {
          let prevUnitData: unknown = buildingAccesses?.units?.[current.unitId]

          if (prevUnitData && isDevices) {
            const devicesData = prevUnitData as TDevices
            prevUnitData = devicesData.map(device => {
              const isBuildginDeviceChecked =
                device.checked ||
                data.find(({id, checked}) => id === device.id && checked)

              return {
                ...device,
                checked: isBuildginDeviceChecked,
              }
            })
          }

          result[current.unitId] = prevUnitData || null

          return result
        }, {}) || {}
    } else if (propertyId && property) {
      property.data = data
      property.buildings = getUpdatedPropertyBuildings(propertyId, newValue)
    }

    return newValue
  }

  const toggleOffUnit = (
    value: TAccessPointsValue<T>,
    {propertyId = '', buildingId = '', unitId = ''}: TStructure,
  ) => {
    const building = value[propertyId]?.buildings[buildingId]

    if (building) {
      building.units = {...building.units}
      delete building.units[unitId]
    }

    return value
  }

  const toggleOffBuilding = (
    value: TAccessPointsValue<T>,
    {propertyId = '', buildingId = ''}: TStructure,
  ) => {
    const property = value[propertyId]

    if (property) {
      property.buildings = {...property.buildings}
      const buildingUnits = getBuildingDisabledUnits(property.buildings[buildingId])
      const building = property.buildings[buildingId]

      if (isObjectEmpty(buildingUnits)) {
        delete property.buildings[buildingId]
      } else if (building) {
        building.units = buildingUnits
      }
    }

    return value
  }

  const toggleOffProperty = (
    value: TAccessPointsValue<T>,
    {propertyId = ''}: TStructure,
  ) => {
    const buildings = value[propertyId]?.buildings || {}

    const buildingsUpdated = Object.entries(buildings).reduce<
      Record<TBuildingId, TBuildingAccesses<T>>
    >((result, current) => {
      const [key, value] = current
      const buildingUnits = getBuildingDisabledUnits(value)
      if (Object.values(buildingUnits).length) {
        result[key] = {
          ...value,
          units: buildingUnits,
        }
      }

      return result
    }, {})

    if (isObjectEmpty(buildingsUpdated)) {
      delete value[propertyId]
    } else {
      value[propertyId] = {
        ...value[propertyId],
        buildings: buildingsUpdated,
      }
    }

    return value
  }

  const getDeniedAccess = ({
    propertyId = '',
    buildingId = '',
    unitId = '',
  }: TAccessPointData<T>) => {
    const newValue = {...value}

    if (unitId) {
      toggleOffUnit(newValue, {propertyId, buildingId, unitId})
    } else if (buildingId) {
      toggleOffBuilding(newValue, {propertyId, buildingId})
    } else if (propertyId) {
      toggleOffProperty(newValue, {propertyId})
    }

    return newValue
  }

  const getBuildingDisabledUnits = (building: TBuildingAccesses<T> | null) => {
    const units = building?.units || {}

    return Object.entries(units).reduce<Record<TUnitId, T>>((result, [key, value]) => {
      if (value === null) {
        return result
      }

      if (!isDevicesData(value)) {
        result[key] = value
        return result
      }

      const hasDisabledDevice = value.some(lock => lock.disabled)

      if (hasDisabledDevice) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[key] = value.map(device => ({
          ...device,
          checked: device.disabled ? true : false,
        }))
      }

      return result
    }, {})
  }

  const isBuildingSelected = (building: TBuildingWithUnits | null) => {
    if (!building) {
      return false
    }

    const propertyId = activeProperty?.propertyId
    const buildingId = building.buildingId

    if (propertyId && buildingId) {
      const buildingValue = value[propertyId]?.buildings?.[buildingId]
      const selectedUnits = buildingValue?.units
      const numberOfSelectedUnits = selectedUnits ? Object.keys(selectedUnits).length : -1
      const isCommonArea = isCommonAreaBuilding(building)

      return (
        building.unitsByBuildingId.nodes.length === numberOfSelectedUnits ||
        selectedUnits === null ||
        !!(isCommonArea && buildingValue?.data)
      )
    }

    return false
  }

  const isPropertySelected = !!activeProperty?.buildingsByPropertyId.nodes.every(
    building => isBuildingSelected(building),
  )

  const selectProperty = useCallback((property: TProperty | null) => {
    if (property) {
      const building = property.buildingsByPropertyId.nodes[0]

      setActiveProperty(property)
      setActiveBuilding(building || null)
    }
  }, [])

  const updateCustomData = (accessPointData: TAccessPointData<T>) => {
    if (activeBuilding && activeProperty) {
      const {propertyId} = activeProperty
      const {buildingId} = activeBuilding
      const newValue = {...value}
      const property = newValue[propertyId]
      const propertyBuildings = newValue[propertyId]?.buildings
      const building = propertyBuildings?.[buildingId]
      const buildingUnits = building?.units

      if (property) {
        if (
          buildingUnits &&
          accessPointData.unitId &&
          accessPointData.data !== undefined
        ) {
          buildingUnits[accessPointData.unitId] = accessPointData.data
        } else if (building && accessPointData.buildingId) {
          propertyBuildings[accessPointData.buildingId] = {
            ...building,
            data: accessPointData.data,
          }
        } else if (accessPointData.propertyId) {
          newValue[accessPointData.propertyId] = {
            ...property,
            data: accessPointData.data,
          }
        }

        onChange(newValue)
      }
    }
  }

  return {
    property: activeProperty,
    building: activeBuilding,
    properties,

    toggleUnit,
    toggleProperty,
    toggleBuilding,
    selectProperty,
    selectBuilding: setActiveBuilding,
    updateCustomData,

    isBuildingSelected,
    isPropertySelected,
  }
}

export default useAccessPoints
