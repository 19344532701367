// views/Overview/Overview.tsx
import './VendorUserLayout.style.scss'

import Breadcrumbs from '../../components/Breadcrumbs'
import Button from '../../components/Button'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import Section from '../../components/Grid/Section'

import {FC, useState} from 'react'
import AccessSchedule, {TAccessType} from '../../components/AccessSchedule/AccessSchedule'
import {CrumbProps} from '../../components/Breadcrumbs/Crumb'
import InputSelect from '../../components/InputSelect/InputSelect'
import InputText from '../../components/InputText/InputText'
import PropertyDevices from '../../components/PropertyDevices/PropertyDevices'
import {clearPhoneNumberFormatting, formatPhoneNumber} from '../../functions'
import useToast from '../../hooks/useToast'
import InstallerAccessPoints from './InstallerAccessPoints'
import ServiceAccessPoints from './ServiceAccessPoints'
import UploadImage from './UploadImage/UploadImage'
import useDevicesAccessPoints from './useDevicesAccessPoints'
import useScheduleAccessPoints, {TServiceTaskAccess} from './useScheduleAccessPoints'
import useVendorUserOverview from './useVendorUserOverview'
import ConfirmTaskUpdates from './ConfirmTaskUpdates'
import useVendorUserContext from './VendorUserContext/useVendorContext'
import Spinner from '../../components/Spinner'
import useCurrentProfile from '../../hooks/useCurrentProfile'

export type TVendorUserLayoutSubmitData = {
  requestedLockAccesses: TServiceTaskAccess[]
  deletedLockAccesses: TServiceTaskAccess[]
  requestedPropertyCommonAreaAccess: {
    accessType: TAccessType
    propertyId: string
    startDate?: string
    endDate?: string
    pinOnly?: boolean
  } | null
  deletedPropertyCommonAreaAccess: string
  vendorType: 'installer' | 'service'
  accessType: 'app' | 'pin'

  sender: {
    lastName: string
    firstName: string
  }

  invitee: {
    mobilePhone: string
    lastName: string
    firstName: string
    email: string
    vendorId: number
    propertyId: number
  }

  tasks: {
    installationTasks: {
      devicesToInstall: {
        deviceInventoryId: string
      }[]
      devicesToUnassign: {deviceWorkAssignmentId: string}[]
    }
    serviceTasks: {
      installedDeviceId: number
    }[]
  }
}

export type TVendorUserLayoutProps = {
  userId?: number
  vendorId: number
  loading?: boolean
  isEditing?: boolean
  breadCrumbs: CrumbProps[]
  onSubmit: (data: TVendorUserLayoutSubmitData) => void
}

const userTypes = [
  {
    value: 'service',
    label: 'Service',
  },
  {
    value: 'installer',
    label: 'Installer',
  },
]

const VendorUserLayout: FC<TVendorUserLayoutProps> = ({
  userId,
  vendorId,
  loading,
  breadCrumbs,
  isEditing,
  onSubmit,
}) => {
  const {showToast} = useToast()
  const devices = useDevicesAccessPoints(userId)
  const {person, profile, loading: contextLoading} = useVendorUserContext()

  const adminProfile = useCurrentProfile()
  const schedule = useScheduleAccessPoints({
    personId: person?.id,
    profileId: profile?.personProfileId,
    limited: true,
  })

  const [submitData, setSubmitData] = useState<TVendorUserLayoutSubmitData | null>(null)

  const {values, touched, errors, handleBlur, handleChange, setFieldValue, submitForm} =
    useVendorUserOverview(
      {
        vendorId,
      },
      async ({invitee, sender}) => {
        try {
          const payload: TVendorUserLayoutSubmitData = {
            vendorType: 'installer',
            invitee: {
              ...invitee,
              propertyId: adminProfile.propertyId,
            },
            sender,
            tasks: {
              serviceTasks: [],
              installationTasks: {
                devicesToInstall: [],
                devicesToUnassign: [],
              },
            },
            requestedLockAccesses: [],
            deletedLockAccesses: [],
            requestedPropertyCommonAreaAccess: null,
            deletedPropertyCommonAreaAccess: '',
            accessType: schedule.values.schedule.accessType,
          }

          if (values.userType === 'installer') {
            payload.vendorType = 'installer'
            const {devicesToInstall, devicesToUnassign, propertyId} =
              await devices.submitForm()

            payload.requestedPropertyCommonAreaAccess = propertyId
              ? {
                  propertyId,
                  pinOnly: false,
                  accessType: 'app',
                }
              : null
            payload.tasks.installationTasks.devicesToInstall = devicesToInstall
            payload.tasks.installationTasks.devicesToUnassign = devicesToUnassign

            setSubmitData(payload)
          } else {
            payload.vendorType = 'service'
            const {
              lockAccessesRequested,
              lockAccessesDeleted,
              commonAreaPropertyAccessRequested,
              commonAreaPropertyAccessDeleted,
            } = await schedule.submitForm()
            payload.requestedLockAccesses = lockAccessesRequested
            payload.deletedLockAccesses = lockAccessesDeleted
            payload.deletedPropertyCommonAreaAccess = commonAreaPropertyAccessDeleted
            payload.requestedPropertyCommonAreaAccess = commonAreaPropertyAccessRequested

            onSubmit(payload)
          }
        } catch (e) {
          console.log(e)
          showToast({
            title: 'Internal error',
            message: 'Failed to set user accesses',
            type: 'error',
          })
        }
      },
    )

  const getSubmitLabel = () => {
    if (loading) {
      return 'Loading ...'
    } else if (isEditing && values.userType === 'installer') {
      return 'Update work assignments'
    } else if (isEditing && values.userType === 'service') {
      return 'Update vendor accesses'
    } else if (!isEditing) {
      return 'Send vendor invite'
    }

    return 'Submit'
  }

  if (contextLoading) {
    return <Spinner />
  }

  return (
    <div className='VendorUserLayout' data-testid='ResidentsOverview'>
      <ConfirmTaskUpdates
        isOpen={!!submitData}
        userId={userId}
        installationTasks={submitData?.tasks.installationTasks.devicesToInstall}
        assignmentsToUnassign={submitData?.tasks.installationTasks.devicesToUnassign}
        onCancel={() => {
          setSubmitData(null)
        }}
        onConfirm={() => {
          if (submitData) {
            onSubmit(submitData)
          }
          setSubmitData(null)
        }}
      />

      <Section>
        <Breadcrumbs showBack={true} crumbs={breadCrumbs} />
      </Section>

      <Section spacing='none' id='actions-section'>
        <h3>{userId ? '' : 'Add '}Staff</h3>
      </Section>

      <Section spacing={'section-md'} id='guest-info-section'>
        <Card padding='none' theme='dark'>
          <CardBody padding='none' id='contact-card'>
            <UploadImage />
          </CardBody>
        </Card>
      </Section>

      <Section spacing={'none'} id='overview-section'>
        <h4>Overview</h4>
        <form className={'add-staff-form'} data-testid={'VendorUserLayoutForm'}>
          <InputText
            id={'input-company-name'}
            type={'text'}
            label={'Company Name'}
            theme={'white'}
            placeholder={'Vendor Company'}
            value={values.vendorName}
            disabled={true}
          />
          <InputSelect
            label='User Type'
            id={'input-type'}
            size='md'
            allowNull={false}
            value={values.userType}
            options={userTypes}
            onValueChange={value => {
              setFieldValue('userType', value)
            }}
          />
          <InputText
            id={'input-first-name'}
            type={'text'}
            label={'First Name'}
            theme={'white'}
            invalid={!!(touched.firstName && errors.firstName)}
            invalidMessage={errors.firstName}
            placeholder={'First Name'}
            value={values.firstName}
            name={'firstName'}
            disabled={!!userId}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <InputText
            id={'input-last-name'}
            type={'text'}
            label={'Last Name'}
            invalid={!!(touched.lastName && errors.lastName)}
            invalidMessage={errors.lastName}
            theme={'white'}
            placeholder={'Last Name'}
            value={values.lastName}
            name={'lastName'}
            disabled={!!userId}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <InputText
            id={'input-phone'}
            type={'text'}
            label={'Phone Number'}
            theme={'white'}
            invalid={!!(touched.phone && errors.phone)}
            invalidMessage={errors.phone}
            placeholder={'Phone'}
            value={formatPhoneNumber(values.phone)}
            name={'phone'}
            disabled={!!userId}
            onBlur={handleBlur}
            onChange={value => {
              setFieldValue(
                'phone',
                clearPhoneNumberFormatting(formatPhoneNumber(value.target.value)),
              )
            }}
          />
          <InputText
            id={'input-email'}
            type={'text'}
            invalid={!!(touched.email && errors.email)}
            invalidMessage={errors.email}
            label={'Email Address'}
            theme={'white'}
            placeholder={'Email'}
            value={values.email}
            name={'email'}
            disabled={!!userId}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </form>
      </Section>

      <Section spacing={'none'} id='access-section'>
        {values.userType === 'installer' ? (
          <PropertyDevices
            vendorUserId={userId}
            value={devices.values.propertyDevices}
            onChange={value => {
              devices.setFieldValue('propertyDevices', value)
            }}
          />
        ) : (
          <AccessSchedule
            limitedAccess
            schedule={schedule.values.schedule}
            onChange={value => {
              schedule.setFieldValue('schedule', value)
            }}
          />
        )}
      </Section>

      <Section spacing={'none'} id='access-points-section'>
        {values.userType === 'installer' ? (
          <InstallerAccessPoints
            vendorUserId={userId}
            defaultSchedule={devices.values.propertyDevices}
            value={devices.values.accessPoints}
            getUnitDevicesByUnitId={devices.getUnitDevicesByUnitId}
            onChange={value => {
              devices.setFieldValue('accessPoints', value)
            }}
          />
        ) : (
          <ServiceAccessPoints
            personId={Number(userId)}
            profileId={profile ? Number(profile?.personProfileId) : 0}
            defaultSchedule={schedule.values.schedule}
            value={schedule.values.accessPoints}
            isVendorIdentityCreated={schedule.values.isVendorIdentityCreated}
            onChange={value => {
              schedule.setFieldValue('accessPoints', value)
            }}
          />
        )}
      </Section>

      <Section spacing={'none'} id='actions'>
        <Button onClick={() => submitForm()}>{getSubmitLabel()}</Button>
        <Button theme='outline'>Cancel</Button>
      </Section>
    </div>
  )
}

export default VendorUserLayout
