export const isFailureCode = (code: number) => code >= 400

export const getGraphqlError = (responseData: any) => {
  if (responseData && typeof responseData === 'object') {
    return Object.values<{code?: number}>(responseData).find(value => !!value?.code)
  }

  return null
}

export const getGraphqlStatus = (responseData: any): number => {
  if (responseData && typeof responseData === 'object') {
    const values = Object.values<{code?: number}>(responseData)

    const code = values.find(value => !!value?.code)?.code || 200

    return code
  }

  return 200
}
