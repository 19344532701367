import Filters from '../../../components/Filters'
import AccordionGroup from '../../../components/Accordion/AccordionGroup'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'
import StructuresFilterNode from '../../../forms/Filters/FilterNodes/StructuresFilterNode'
import useStructuresFilter, {
  TFilterItem,
} from '../../../hooks/filters/useStructuresFilter'
import useFloorplanFilter from '../../../hooks/filters/useFloorplanFilter'
import CheckboxListFilterNode from '../../../forms/Filters/FilterNodes/CheckboxListFilterNode'
import {useParams} from 'react-router-dom'
import {useMemo} from 'react'

export type TUnitsFilterFields = {
  unitFilters?: TFilterItem[]
  floorplan?: number[]
}

const UnitsFilters = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TUnitsFilterFields>) => {
  const params = useParams<{propertyId: string}>()
  const propertyId = useMemo(
    () => (params.propertyId ? [+params.propertyId] : []),
    [params.propertyId],
  )

  const {structures, setStructures, getSelectedIds, clearSelections} =
    useStructuresFilter(initialValue?.unitFilters, {propertyId})

  const {floorplans, setFloorplans, getSelectedFloorplans, clearFloorplans} =
    useFloorplanFilter()

  const onApplyFilter = () => {
    const unitFilters = getSelectedIds()
    const floorplan = getSelectedFloorplans()
    onSubmit?.({
      unitFilters,
      floorplan: floorplan.length ? floorplan : undefined,
    })
  }

  const onClearFilter = () => {
    clearSelections()
    clearFloorplans()
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <StructuresFilterNode value={structures} onChange={setStructures} />

        <CheckboxListFilterNode
          value={floorplans}
          title={'Floor Plan'}
          onChange={setFloorplans}
        />
      </AccordionGroup>
    </Filters>
  )
}

export default UnitsFilters
