import {
  TCommonAreasByGroupRespone,
  TCommonAreasByGroupVariables,
} from './../../../data/graphql/queries/properties/types'
import {useLazyQuery} from '@apollo/client'
import {useCallback, useEffect, useState} from 'react'
import {GET_COMMON_AREAS_FOR_GROUPS} from '../../../data/graphql/queries/properties'
import useUserAccess from '../../../hooks/useUserAccess'
import {DeviceMaker} from '../../../data/graphql/types'
import useToast from '../../../hooks/useToast'

export type TResult = {
  data: {
    [propertyId: string]: string[]
  }
  loading: boolean
}

const useCommonAreasVendorUnits = () => {
  const {showErrorToast} = useToast()
  const userAccess = useUserAccess()
  const [getCommonAreasRequest] = useLazyQuery<
    TCommonAreasByGroupRespone,
    TCommonAreasByGroupVariables
  >(GET_COMMON_AREAS_FOR_GROUPS)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<TResult['data']>({})

  const refetchCommonAreasData = useCallback(async () => {
    setLoading(true)

    const commonAreasByProperty: TResult['data'] = {}

    try {
      const buildRequest = (propertyId: number) => {
        return getCommonAreasRequest({
          variables: {
            input: {
              propertyId,
              deviceMaker: DeviceMaker.BRIVO,
            },
          },
        })
      }

      const responses = await Promise.all(userAccess.properties.map(buildRequest))

      responses.forEach((response, index) => {
        const commonAreas = response.data?.lock.commonAreas.vendor
        const propertyId = userAccess.properties[index]

        if (commonAreas) {
          commonAreasByProperty[propertyId] = commonAreas.map(({lockName}) =>
            lockName.toLowerCase(),
          )
        }
      })

      setData(commonAreasByProperty)
    } catch (e) {
      showErrorToast('Common areas', 'Failed to get list of common areas')
    }

    setLoading(false)
  }, [getCommonAreasRequest, showErrorToast, userAccess.properties])

  useEffect(() => {
    if (!userAccess.properties.length) {
      setLoading(false)
      setData({})
    }

    refetchCommonAreasData()
  }, [refetchCommonAreasData, userAccess.properties])

  return {data, loading}
}

export default useCommonAreasVendorUnits
