import React, {FC, ForwardRefRenderFunction} from 'react'
import Avatar from '../Avatar'
import Badge from '../Badge'
import Card from '../Card'
import './PersonCard.style.scss'
import {ResidentPreview} from '../../views/People/Residents/Residents'

type TPersonCardProps = {
  person: ResidentPreview
  onClick?: (person: ResidentPreview) => void
}

const PersonCard: ForwardRefRenderFunction<HTMLDivElement, TPersonCardProps> = (
  {person, onClick},
  forwardedRef,
) => {
  return (
    <Card padding='md'>
      <div ref={forwardedRef} className='card-content' onClick={() => onClick?.(person)}>
        <Avatar withinBorder src={'https://www.w3schools.com/howto/img_avatar.png'} />
        <h5 className='person-name'>{person.name}</h5>
        <p className='property-name'>{person.property}</p>
        <Badge theme={'danger'} size={'sm'}>
          {person.unit}
        </Badge>
      </div>
    </Card>
  )
}

export default React.memo(React.forwardRef(PersonCard))
