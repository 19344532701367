import {useMemo} from 'react'
import {useQuery} from '@apollo/client'
import {
  TGetVendorPendingInvitesResponse,
  TGetVendorPendingInvitesVariables,
} from '../../data/graphql/queries/people/types'
import {GET_PENDING_VENDOR_INVITES} from '../../data/graphql/queries/people'
import useToast from '../useToast'

const EXPIRATION_DT = new Date().toISOString()

const usePendingVendorInvites = (vendorId?: number) => {
  const {showToast} = useToast()

  const response = useQuery<
    TGetVendorPendingInvitesResponse,
    TGetVendorPendingInvitesVariables
  >(GET_PENDING_VENDOR_INVITES, {
    variables: {
      orderBy: ['EXPIRATION_DT_ASC'],
      filter: {
        isDeleted: {
          equalTo: false,
        },
        expirationDt: {
          greaterThan: EXPIRATION_DT,
        },
        miscInfo: {
          contains: {
            vendorInfo: {
              vendorId,
            },
          },
        },
      },
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Failed to fetch vendor pending invites',
        type: 'error',
      })
    },
  })

  const invites = useMemo(() => {
    return response.data?.transactionalDb.invites.nodes || []
  }, [response.data?.transactionalDb.invites.nodes])

  return {
    invites,
    response,
    loading: response.loading,
  }
}

export default usePendingVendorInvites
