// components/TableFooter/TableFooter.tsx

import React, {FC, ReactNode} from 'react'
import './TableFooter.style.scss'

export interface ITableFooterProps {
  children: ReactNode[]
  loading?: boolean
  itemCount?: number
}

const TableFooter: FC<ITableFooterProps> = (props: ITableFooterProps) => {
  return (
    <div className={'TableFooter'} data-testid={'TableFooterTestID'}>
      {!props.loading && props.itemCount ? props.children : null}
    </div>
  )
}

export default TableFooter
