import {useSubscription} from '@apollo/client'
import {LOCK_SUBSCRIPTION} from '../../../data/graphql/subscriptions'
import {
  TLockSubscriptionResponse,
  TLockSubscriptionVariables,
} from '../../../data/graphql/subscriptions/types'
import useUserAccess from '../../useUserAccess'
import usePinEventHandler from './lockEventHandlers/usePinEventHandler'
import useUserPersonId from '../../useUserPersonId'

const useLockSubscription = () => {
  const adminPersonId = useUserPersonId()
  const {properties} = useUserAccess()
  const {
    onMasterPinUpdateFailure,
    onMasterPinUpdateSuccess,
    onUserPinFailure,
    onUserPinSuccess,
  } = usePinEventHandler()

  useSubscription<TLockSubscriptionResponse, TLockSubscriptionVariables>(
    LOCK_SUBSCRIPTION,
    {
      skip: !properties?.length,
      variables: {
        filter: {
          initiatingPersonIds: [adminPersonId],
          propertyIds: properties,
          buildingIds: [],
          installedDeviceIds: [],
          unitIds: [],
        },
      },
      onData(response) {
        const data = response.data.data?.lockChannel

        if (!data) {
          return
        }

        switch (data.__typename) {
          case 'LockChannelMasterPinOperationFailure':
            onMasterPinUpdateFailure(data)
            break
          case 'LockChannelMasterPinOperationSuccess':
            onMasterPinUpdateSuccess(data)
            break
          case 'LockChannelUserPinOperationFailure':
            onUserPinFailure(data)
            break
          case 'LockChannelUserPinOperationSuccess':
            onUserPinSuccess(data)
            break
        }
      },
    },
  )
}

export default useLockSubscription
