// components/Accordion/AccordionGroup.tsx

import React, {FC, useRef} from 'react'
import './Accordion.style.scss'
import {randomString} from '../../functions'

export interface AccordionGroupProps {
  id?: string
  children?: React.ReactNode
}

const defaultProps: AccordionGroupProps = {
  id: randomString(),
}

const AccordionGroup: FC<AccordionGroupProps> = (props: AccordionGroupProps) => {
  const group = useRef(null)
  props = {...defaultProps, ...props}

  return (
    <div
      id={props.id}
      ref={group}
      className={'accordion-group'}
      data-testid={'AccordionGroup'}
    >
      {props.children}
    </div>
  )
}

export default AccordionGroup
