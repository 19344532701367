import React from 'react'
import Accordion from '../../../components/Accordion'
import AccordionBody from '../../../components/Accordion/AccordionBody'
import AccordionHead from '../../../components/Accordion/AccordionHead'
import InputText from '../../../components/InputText/InputText'

export type TDateRangeFilterProps = {
  title?: string
  maxToDate?: string
  minToDate?: string
  maxFromDate?: string
  minFromDate?: string
  value: TValue
  onChange: (value: TValue) => void
}

type TValue = {
  from: string
  to: string
}

const DateRangeFilterNode = ({
  title,
  value,
  minToDate,
  maxToDate,
  minFromDate,
  maxFromDate,
  onChange,
}: TDateRangeFilterProps) => {
  const onFieldChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      [target.name]: target.value,
    })
  }

  return (
    <Accordion transparent>
      <AccordionHead>
        <div className='d-flex space-between align-center'>
          <div className='desktop-label-semibold-14'>{title}</div>
        </div>
      </AccordionHead>

      <AccordionBody>
        <InputText
          type={'date'}
          label={'Start Date'}
          theme={'white'}
          name='from'
          min={minFromDate}
          max={maxFromDate}
          value={value.from}
          onChange={onFieldChange}
        />

        <InputText
          type={'date'}
          label={'End Date'}
          theme={'white'}
          name='to'
          min={minToDate}
          max={maxToDate}
          value={value.to}
          onChange={onFieldChange}
        />
      </AccordionBody>
    </Accordion>
  )
}

export default DateRangeFilterNode
