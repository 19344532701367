// components/Meter/Meter.tsx

import React, {FC} from 'react'
import './Meter.style.scss'

export interface MeterProps {
  id?: string
  value?: number
  label?: string
  size?: number
  stroke?: number
}

const defaultProps: MeterProps = {
  value: 0,
  size: 170,
  stroke: 5,
}

const Meter: FC<MeterProps> = (props: MeterProps) => {
  props = {...defaultProps, ...props}

  props.size = props.size || 200
  props.stroke = props.stroke || 5

  const meterStyle = {
    ['--s' as any]: props.size,
    ['--b' as any]: props.stroke,
    ['--v' as any]: props.value,
    height: props.size + 'px',
    width: props.size + 'px',
  }

  return (
    <div id={props.id} className='meter' data-testid={'Meter'} style={meterStyle}>
      <div className={'ring'} />
      <div className={'donut-segment'} />
      <div className={'hole'} />
      <div className={'meter-content'}>
        <div className={'meter-value'}>{props.value}%</div>
        {props.label && <div className={'meter-label'}>{props.label}</div>}
      </div>
    </div>
  )
}

export default Meter
