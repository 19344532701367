import React, {useCallback, useContext, useEffect, useState} from 'react'
import Badge from '../../../components/Badge'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import {useNavigate} from 'react-router-dom'
import {BadgeProps} from '../../../components/Badge/Badge'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import VendorFilters, {TVendorsFilterFields} from '../../People/Vendors/VendorFilters'
import People from './People'
import useAllVendorsCompanyViews from '../../../hooks/data/useAllVendorsCompanyViews'
import {toCommonDateFormat} from '../../../functions'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'

const PAGE_SIZE = 10

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'status:asc', value: 'IS_ACTIVE_ASC', label: 'Is Active Asc'},
  {sortKey: 'status:desc', value: 'IS_ACTIVE_DESC', label: 'Is Active Desc'},

  {sortKey: 'company:asc', value: 'VENDOR_NAME_ASC', label: 'Company Asc'},
  {sortKey: 'company:desc', value: 'VENDOR_NAME_DESC', label: 'Company Desc'},
  {sortKey: 'activeDate:asc', value: 'ACTIVE_DATE_ASC', label: 'Active Date Asc'},
  {sortKey: 'activeDate:desc', value: 'ACTIVE_DATE_DESC', label: 'Active Date Desc'},
  {sortKey: 'deactiveDate:asc', value: 'DEACTIVE_DATE_ASC', label: 'Deactive Date Asc'},
  {
    sortKey: 'deactiveDate:desc',
    value: 'DEACTIVE_DATE_DESC',
    label: 'Deactive Date Desc',
  },
]

const Vendors = () => {
  const navigate = useNavigate()
  const {setQuery} = useContext(ExportTableContext)
  const [vendorsTableData, setVendorsTableData] = useState<any[]>([])

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    setQueryOptions,
    onChangeNumberOfItems,
  } = useQueryOptions<TVendorsFilterFields>({
    limit: PAGE_SIZE,
    page: 1,
    orderBy: ['VENDOR_NAME_ASC'],
    filters: {},
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    vendors: vendorsList,
    response: vendorsResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = useAllVendorsCompanyViews(debouncedSearchTerm, queryOptions)

  useEffect(() => {
    if (vendorsList) {
      setVendorsTableData(
        vendorsList.map(item => ({
          company: <CellWithAvatar name={item.vendorName} />,
          status: getDisplayBadge(item.isActive ? 'Active' : 'Inactive'),
          property: item.propertyName || '—',
          activeDate: toCommonDateFormat(item.activeDate) || '—',
          deactiveDate: toCommonDateFormat(item.deactiveDate) || '—',
        })),
      )
    }
  }, [vendorsList])

  const getDisplayBadge = (type: string) => {
    let theme: BadgeProps['theme']

    switch (type) {
      case 'Active':
        theme = 'info'
        break
      case 'Inactive':
        theme = 'default'
        break
      default:
        theme = 'default'
    }

    return (
      <Badge theme={theme} size={'sm'}>
        {type}
      </Badge>
    )
  }

  const dataForTableQuery = useCallback(async () => {
    try {
      const {data} = await queryForDownloadTable()
      const logs = data?.transactionalDb?.allVendorCompanyViews?.nodes || []

      const tableData = logs.map(item =>
        Object.values({
          company: item.vendorName || '—',
          status: item.isActive ? 'Active' : 'Inactive',
          property: item.propertyName || '—',
          activeDate: toCommonDateFormat(item.activeDate) || '—',
          deactiveDate: toCommonDateFormat(item.deactiveDate) || '—',
        }),
      )

      tableData.unshift(['Company', 'Status', 'Property', 'Active Date', 'Deactive Date'])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryVariables, queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const onChangeSortOrder = value => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const onChangeSearchQuery = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions(prev => ({
        ...prev,
        searchTerm,
        page: 1,
      }))
    },
    [upsertQueryOptions],
  )

  const onSubmitFilter = useCallback(
    (filters: TVendorsFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const emptyVendorsTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Vendors table is empty...',
  })

  const itemCount =
    vendorsResponse.data?.transactionalDb?.allVendorCompanyViews?.totalCount || 0

  return (
    <People
      onChangeNumberOfItems={onChangeNumberOfItems}
      upsertQueryOptions={upsertQueryOptions}
      onTypeSearchField={onChangeSearchQuery}
      onPressRow={(index: number) => {
        if (vendorsList) {
          navigate(`/reports/people/vendors/${vendorsList[index].vendorId}`)
        }
      }}
      emptyTableComponent={emptyVendorsTable}
      Filters={VendorFilters}
      queryOptions={queryOptions}
      tableProps={{
        order: tableSort.order,
        selectedColumn: tableSort.column,
        selectedColumnChange: tableSort.setSortColumn,
        rows: vendorsTableData,
        columns: [
          {name: 'Company', key: 'company', sortable: true},
          {name: 'Status', key: 'status', sortable: true},
          {name: 'Active Date', key: 'activeDate', sortable: true},
          {name: 'Deactive Date', key: 'deactiveDate', sortable: true},
        ],
      }}
      count={itemCount}
      loading={vendorsResponse.loading}
      className={'Vendors'}
      testId={'VendorsReports'}
      searchPlaceholder={'Search vendors'}
      onChangeSortOrder={onChangeSortOrder}
      onSubmitFilter={onSubmitFilter}
      sortOptions={sortOptions}
    />
  )
}

export default Vendors
