import {useCallback, useEffect, useState, useContext} from 'react'
import './Units.style.scss'

import {useNavigate, useParams} from 'react-router-dom'
import DataGrid from '../../../components/DataGrid'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import Paginator from '../../../components/Paginator'
import Panel from '../../../components/Panel'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import {useQuery} from '@apollo/client'
import {
  TAllBuidlingsVariables,
  TAllBuildingsResponse,
  TAllUnitsOfProperyResponse,
  TUnitOfProperty,
} from '../../../data/graphql/queries/common/types'
import {GET_ALL_BUILDING} from '../../../data/graphql/queries/common'
import {formatMoney, toCommonDateFormat} from '../../../functions'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import UnitsFilters, {TUnitsFilterFields} from './UnitsFilter'
import useUnitsData from '../../../hooks/data/useUnitsData'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import useToast from '../../../hooks/useToast'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import useTableSort from '../../../hooks/useTableSort'

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'unitNumber:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Asc'},
  {sortKey: 'unitNumber:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Desc'},
]

const Units = () => {
  const {setQuery} = useContext(ExportTableContext)
  const {showToast} = useToast()
  const params = useParams<{propertyId: string}>()
  const propertyId = params.propertyId ? +params.propertyId : -1
  const navigate = useNavigate()

  const [units, setUnits] = useState<any>([])
  const [unitsData, setUnitsData] =
    useState<TAllUnitsOfProperyResponse['transactionalDb']['allUnits']>()

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    onChangeNumberOfItems,
    setQueryOptions,
  } = useQueryOptions<TUnitsFilterFields>({
    page: 1,
    orderBy: ['UNIT_NUMBER_ASC'],
    searchTerm: '',
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const buildingsResponse = useQuery<TAllBuildingsResponse, TAllBuidlingsVariables>(
    GET_ALL_BUILDING,
    {
      variables: {
        condition: {
          isActive: true,
          isDeleted: false,
          propertyId,
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Buildings Data',
          type: 'error',
        })
      },
    },
  )

  const {
    response: unitsResponse,
    variables,
    queryForDownloadTable,
  } = useUnitsData(debouncedSearchTerm, queryOptions)

  useEffect(() => {
    const unitsData = unitsResponse.data?.transactionalDb?.allUnits?.nodes

    if (unitsData && unitsResponse.data) {
      setUnitsData(unitsResponse.data.transactionalDb.allUnits)
      const units = unitsData.map(unit => prepareData(unit))
      setUnits(units)
    }
  }, [unitsResponse.data?.transactionalDb?.allUnits])

  const prepareData = useCallback((unit: TUnitOfProperty, forTable?: boolean) => {
    const leasesByUnitId = unit.leasesByUnitId.nodes[0]

    const resident = unit.leasesByUnitId.nodes[0]?.leaseholder
    const residentName = resident
      ? `${resident.firstName} ${resident.lastName}`
      : undefined

    const data = {
      unitNumber: unit.unitNumber || '—',
      residentName: forTable ? (
        residentName || '—'
      ) : (
        <CellWithAvatar name={residentName} />
      ),
      floorplan: unit.floorPlanByFloorPlanId?.name || '—',
      unitDescription: unit.unitTypeByUnitTypeId.description || '—',
      buildingName: unit.buildingByBuildingId.buildingName || '—',
      rentDue: formatMoney(leasesByUnitId?.rentAmount) || '—',
      leaseStart: toCommonDateFormat(leasesByUnitId?.leaseBeginDate) || '—',
      leaseEnd: toCommonDateFormat(leasesByUnitId?.leaseEndDate) || '—',
      ...(forTable ? {} : {unitId: unit.unitId || '—'}),
    }

    return data
  }, [])

  const dataForTableQuery = useCallback(async () => {
    try {
      const {data} = await queryForDownloadTable()
      const logs = data?.transactionalDb?.allUnits?.nodes
      const tableData = logs.map(unit => Object.values(prepareData(unit, true)))

      tableData.unshift([
        'Unit',
        'Resident',
        'Floorplan',
        'Description',
        'Building Name',
        'Rent Due',
        'Lease Start',
        'Lease End',
      ])
      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [variables])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryOptions, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const onSubmitFilter = useCallback((filters: TUnitsFilterFields) => {
    setQueryOptions(prev => ({
      ...prev,
      page: 1,
      filters: filters,
    }))
  }, [])

  const onTypeSearchField = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm,
      })
    },
    [upsertQueryOptions],
  )

  const onPressRow = useCallback(
    (index: number) => {
      if (!units[index]) return
      navigate(`/properties/${propertyId}/units/${units[index].unitId}`)
    },
    [units],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [upsertQueryOptions],
  )

  const onChangeSortOrder = value => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Units'} data-testid={'UnitsView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search units'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onTypeSearchField}
                  />
                  <SearchFilters filter={UnitsFilters} onSubmit={onSubmitFilter} />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>

                <Panel theme={'white'}>
                  <DataGrid
                    selectableRows
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    onRowSelect={onPressRow}
                    loading={unitsResponse.loading || buildingsResponse.loading}
                    perPage={queryOptions.limit}
                    columns={[
                      {name: 'Unit', key: 'unitNumber', sortable: true},
                      {name: 'Resident', key: 'residentName'},
                      {name: 'Floorplan', key: 'floorplan'},
                      {name: 'Description', key: 'unitDescription'},
                      {name: 'Building Name', key: 'buildingName'},
                      {name: 'Rent Due', key: 'rentDue'},
                      {name: 'Lease Start', key: 'leaseStart'},
                      {name: 'Lease End', key: 'leaseEnd'},
                    ]}
                    rows={units}
                  />
                </Panel>
                <TableFooter
                  itemCount={unitsData?.totalCount}
                  loading={unitsResponse.loading || buildingsResponse.loading}
                >
                  <Paginator
                    itemCount={unitsData?.totalCount || 0}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Units
