import './PropertyDevices.style.scss'

import {FC, useEffect, useMemo, useState} from 'react'
import CollapsibleCheckboxList, {
  CheckboxItem,
} from '../CollapsibleCheckboxList/CollapsibleCheckboxList'
import InputLabel from '../InputLabel/InputLabel'
import Row from '../Grid/Row'
import useUnavailableDevices from '../../hooks/data/useUnavailableDevices'
import {getInventoryId} from '../../functions/lock.functions'
import {arrayToObject} from '../../functions'
import Spinner from '../Spinner'
import useVendorsByPersonVendorIds from '../../hooks/data/useVendorsByPersonVendorIds'
import {flatDevices, groupDevices} from '../../functions/devices.function'
import {TDeviceItem} from './usePropertyDevices'
import PropertyDeviceLabel from './PropertyDeviceLabel'

export type TDeviceCheckboxItem = TDeviceItem &
  CheckboxItem & {
    disabled: boolean
  }

type TPropertyDevicesProps = {
  vendorUserId?: number
  unitId?: string
  value: TDeviceCheckboxItem[]
  disabled?: boolean
  className?: string
  onChange?: (devices: TDeviceCheckboxItem[]) => void

  replacements?: string[]
  onReplace?: (id: string, inventoryId: string) => void
  onCancelReplace?: (id: string, inventoryId: string) => void
}

const PropertyDevices: FC<TPropertyDevicesProps> = ({
  vendorUserId,
  unitId,
  value,
  replacements,
  onChange,
  onReplace,
  onCancelReplace,
}) => {
  const [personIds, setPersonIds] = useState<number[]>([])

  const {loading, vendorByPersonVendorId} = useVendorsByPersonVendorIds(personIds)
  const {devicesByInventoryId} = useUnavailableDevices(vendorUserId)

  const groupedDevices = useMemo(() => groupDevices(value), [value])

  const replaceDevice = (device: TDeviceCheckboxItem) => {
    if (!unitId) {
      return
    }

    onReplace?.(
      device.id.toString(),
      getInventoryId(unitId, device.device.typeId, device.location.typeId),
    )
  }

  const cancelReplacement = (device: TDeviceCheckboxItem) => {
    if (!unitId) {
      return
    }

    onCancelReplace?.(
      device.id.toString(),
      getInventoryId(unitId, device.device.typeId, device.location.typeId),
    )
  }

  const enhancedDevices = useMemo(() => {
    return arrayToObject(
      value.map(item => {
        const inventoryId = unitId
          ? getInventoryId(unitId, item.device.typeId, item.location.typeId)
          : ''

        const isInstalled = !!devicesByInventoryId[inventoryId]?.installedDeviceId

        const assignedVendorId = devicesByInventoryId[inventoryId]?.assignedVendorId
        let name = item.name

        if (isInstalled) {
          name += ' (installed)'
        } else if (
          assignedVendorId &&
          Number(assignedVendorId) !== Number(vendorUserId)
        ) {
          name += ' (assigned)'
        }

        return {
          ...item,
          name,
          isInstalled,
          assignedVendorId,
        }
      }),
      'id',
    )
  }, [unitId, value, devicesByInventoryId])

  useEffect(() => {
    const newPersonVendorIds = new Set<number>()

    Object.values(enhancedDevices).forEach(({assignedVendorId}) => {
      if (assignedVendorId) {
        newPersonVendorIds.add(+assignedVendorId)
      }
    })

    setPersonIds(prev => {
      const next = Array.from(newPersonVendorIds)

      if (!prev.length && !next.length) {
        return prev
      }

      return next
    })
  }, [vendorByPersonVendorId, enhancedDevices])

  const onChangeDevices = (key: 'locks' | 'thermostats', devices: CheckboxItem[]) => {
    onChange?.(
      flatDevices({
        ...groupedDevices,
        [key]: devices.map((device, index) => ({
          ...groupedDevices[key][index],
          ...device,
        })),
      }),
    )
  }

  const renderDeviceLabel = (id: string | number = '') => {
    const device = enhancedDevices[id]

    const assignedVendorCompanyId = device.assignedVendorId
      ? vendorByPersonVendorId[device.assignedVendorId]?.vendorId
      : null
    const inventoryId = getInventoryId(
      unitId || '',
      device.device.typeId,
      device.location.typeId,
    )

    return (
      <PropertyDeviceLabel
        isInstalled={device.isInstalled}
        currentVendorId={vendorUserId}
        assignedVendorId={Number(device.assignedVendorId)}
        assignedVendorCompanyId={assignedVendorCompanyId}
        deviceName={device.name}
        hasReplacement={!!replacements?.includes(inventoryId)}
        onReplace={() => replaceDevice(device)}
        onCancel={() => cancelReplacement(device)}
      />
    )
  }

  return (
    <div className={`access-devices`}>
      <Spinner visible={loading} overlay />

      <div>
        <h4>Available devices</h4>

        <Row>
          {!!groupedDevices.locks.length && (
            <div style={{flex: 1}}>
              <InputLabel>Locks</InputLabel>
              <CollapsibleCheckboxList
                countPreview={groupedDevices.locks.length}
                items={groupedDevices.locks}
                update={value => onChangeDevices('locks', value)}
                renderLabel={renderDeviceLabel}
              />
            </div>
          )}

          {!!groupedDevices.thermostats.length && (
            <div style={{flex: 1}}>
              <InputLabel>Thremostats</InputLabel>
              <CollapsibleCheckboxList
                countPreview={groupedDevices.thermostats.length}
                items={groupedDevices.thermostats}
                update={value => onChangeDevices('thermostats', value)}
                renderLabel={renderDeviceLabel}
              />
            </div>
          )}
        </Row>
      </div>
    </div>
  )
}

export default PropertyDevices
