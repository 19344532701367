import {useEffect, useState} from 'react'
import {capitalize} from '../../functions'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'

export const batteryLevels = {
  critical: [0, 0.25],
  low: [0.26, 0.5],
  partial: [0.51, 0.75],
  full: [0.76, 1],
}

const useBatteryFilter = (initialValue?: string[]) => {
  const [value, setValue] = useState<CheckboxItem[]>([])

  useEffect(() => {
    setValue(
      Object.entries(batteryLevels).map(([key, [from, to]]) => ({
        id: key,
        name: `${capitalize(key)} (${from * 100}% to ${to * 100}%)`,
        checked: !!initialValue?.includes(key),
      })),
    )
  }, [])

  const getSelected = () => {
    return value.filter(({checked}) => checked).map(({id}) => id.toString())
  }

  const clearSelections = () => {
    setValue(item => ({
      ...item,
      checked: false,
    }))
  }
  return {value, setValue, getSelected, clearSelections}
}

export default useBatteryFilter
