import React, {useState} from 'react'
import './PropertyDetails.style.scss'
import Column from '../../components/Grid/Column'
import Icon from '../../components/Icon'
import Row from '../../components/Grid/Row'
import Section from '../../components/Grid/Section'
import Tabbar from '../../components/Tabbar'
import Breadcrumbs from '../../components/Breadcrumbs'
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'
import ExportTable from '../../components/ExportTable/ExportTable'
import Button from '../../components/Button'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import useToast from '../../hooks/useToast'
import {capitalize} from '../../functions'
import Spinner from '../../components/Spinner/Spinner'
import useGetProperty from '../../hooks/data/useGetProperty'

const images = [
  'https://i.pinimg.com/564x/35/3e/f4/353ef4335ff42535eaff06b6312e89cc.jpg',
  'https://i.pinimg.com/564x/e6/78/1f/e6781ffa08e1da1aa1a1ae43662a4bdd.jpg',
  'https://i.pinimg.com/564x/62/90/7b/62907b9d628eecc9c41963f87daaaa97.jpg',
  'https://i.pinimg.com/564x/b0/49/2a/b0492a464bbca7e87312b732d282da7d.jpg',
  'https://i.pinimg.com/564x/0b/c9/5e/0bc95e7725ad5033d68aa9673f56053a.jpg',
]

const PropertyDetails = () => {
  const {showToast} = useToast()
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams<{propertyId: string}>()
  const currentPath = location.pathname
  const initialPanel = currentPath.split('/')[3]

  const [panel, setPanel] = useState(initialPanel)

  const property = useGetProperty(params.propertyId, {
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Property Data',
        type: 'error',
      })
    },
  })

  const address = property.data?.addressByAddressId

  const handleChangeTab = (panelValue: string) => {
    navigate(`/properties/${params.propertyId}/${panelValue}`)
    setPanel(panelValue)
  }

  const getAddress = () => {
    if (address) {
      const {city, address1, zipCode} = address
      const state = address.stateByStateId.code || ''

      return `${address1} - ${city}, ${state} ${zipCode}`
    }

    return ''
  }

  const breadcrumbs = [
    {name: 'Properties', url: '/properties/'},
    {
      name: property.data?.propertyName || '—',
      url: `/properties/${params.propertyId}/overview`,
    },
    {name: 'Overview', url: ''},
  ]

  return (
    <ErrorBoundary fallback={CrashScreen}>
      {property.loading ? (
        <Spinner />
      ) : (
        <div className={'PropertyDetails'} data-testid={'PropertyDetails'}>
          <Section id='navigation'>
            <Breadcrumbs showBack goBackUrl='/properties/' crumbs={breadcrumbs} />

            {panel !== 'message_setup' && (
              <ExportTable fileName={capitalize(initialPanel)} />
            )}
          </Section>

          <Section id='galery'>
            <div className='image-grid'>
              <div className='item1'>
                <img src={images[4]} alt='Picture1' />
              </div>
              <div className='item2'>
                <img src={images[0]} alt='Picture2' />
              </div>
              <div className='item3'>
                <img src={images[1]} alt='Picture3' />
              </div>
              <div className='item4'>
                <img src={images[3]} alt='Picture4' />
              </div>
              <div className='item5'>
                <p className='body-light-14'>+15 Photos </p>{' '}
                <Icon icon='chevron-right' size='xs' />
              </div>
            </div>
          </Section>

          <Section id='contact'>
            <div className='contact-container'>
              <div className='main-info'>
                <div className='image-wrapper'>
                  <img
                    src='https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2021/05/trademark_a_name_title_image.jpg'
                    alt='logo'
                  />
                  <div className='circle' />
                  <div className='edit'>
                    <Icon size={'xs'} icon='edit-2' />
                  </div>
                </div>
                <div className='info'>
                  <h3>{property.data?.propertyName}</h3>
                  <span className='body-light-14'>{getAddress()}</span>
                </div>
              </div>
              <div className='actions-staff'>
                <div>
                  <Icon icon='phone' size='sm' />
                  <div className='desktop-label-semibold-14 number'>(###) ###-####</div>
                </div>
                <Button>EMAIL</Button>
              </div>
            </div>
          </Section>

          <Section spacing='none'>
            <Row>
              <Column xs={'12'}>
                <Tabbar
                  tabs={[
                    {label: 'Overview', key: 'overview'},
                    {label: 'Units', key: 'units'},
                    {label: 'Other Access', key: 'other_access'},
                    {label: 'Staff', key: 'staff'},
                  ]}
                  selected={panel}
                  onTabChange={handleChangeTab}
                />
              </Column>
            </Row>
          </Section>

          <Outlet />
        </div>
      )}
    </ErrorBoundary>
  )
}

export default PropertyDetails
