import React, {FC} from 'react'
import {Navigate, useSearchParams} from 'react-router-dom'

type TNavigateWithParamsProps = {
  to: string
  replace?: boolean
  state?: any
}

const NavigateWithParams: FC<TNavigateWithParamsProps> = ({to, ...props}) => {
  const [params] = useSearchParams()
  const toWithParams = `${to}?${params.toString()}`

  return <Navigate to={toWithParams} {...props} />
}

export default React.memo(NavigateWithParams)
