import {useQuery} from '@apollo/client'
import useUserPersonId from './useUserPersonId'
import {GET_ALL_EMPLOYEES} from '../data/graphql/queries/people'
import {
  TGetEmployeesResponse,
  TGetEmployeesVariables,
} from '../data/graphql/queries/people/types'

const useUserEmployee = () => {
  const personId = useUserPersonId()
  const query = useQuery<TGetEmployeesResponse, TGetEmployeesVariables>(
    GET_ALL_EMPLOYEES,
    {
      variables: {
        first: 1,
        condition: {
          personId,
        },
      },
      skip: !personId,
    },
  )

  const data = query.data?.transactionalDb?.allPortalEmployeeViews.nodes[0]

  return {
    data,
    query,
    loading: query.loading,
  }
}

export default useUserEmployee
