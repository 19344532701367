import {FC, useCallback, useEffect, useState} from 'react'
import Badge from '../../components/Badge'
import DataGrid from '../../components/DataGrid'
import Section from '../../components/Grid/Section'
import Icon from '../../components/Icon'
import Panel from '../../components/Panel'
import Search from '../../layouts/People/Search/Search'
import SearchFilterInput from '../../layouts/People/Search/SearchFilterInput'
import Paginator from '../../components/Paginator/Paginator'
import {capitalize, convertToNiceDate} from '../../functions'
import {SortOptionsItem, useQueryOptions} from '../../hooks/useQueryOptions'
import {LockEvents, getLogEventInfo} from '../../functions/logs'
import SearchFilters from '../../layouts/People/Search/SearchFilters'
import ActivityFilters, {TActivityFilterFields} from './ActivityFilters/ActivityFilters'
import useLockActivityLogs from '../../hooks/useLockActivityLogs'
import TableNumberOfItems from '../../components/TabelCountItems/TableNumberOfItems'
import TableFooter from '../../components/TableFooter/TableFooter'
import SearchSortBy from '../../layouts/People/Search/SearchSortBy'

const sortOptions: SortOptionsItem[] = [
  {value: '', label: 'All '},
  {value: 'DEVICE_ASC', label: 'Device Asc'},
  {value: 'DEVICE_DESC', label: 'Device Desc'},
  {value: 'EVENT_ASC', label: 'Event Asc'},
  {value: 'EVENT_DESC', label: 'Event Desc'},
  {value: 'MESSAGE_ASC', label: 'Message Asc'},
  {value: 'MESSAGE_DESC', label: 'Message Desc'},
  {value: 'TIME_STAMP_ASC', label: 'Date Asc'},
  {value: 'TIME_STAMP_DESC', label: 'Date Desc'},
]

type ActivityLogProps = {
  unitId?: number
  personId?: number
  preserveParams?: string[]
}

const ActivityLog: FC<ActivityLogProps> = ({unitId = 0, preserveParams}) => {
  const [activityData, setActivityData] = useState<any[]>([])

  const {queryOptions, upsertQueryOptions, setQueryOptions, onChangeNumberOfItems} =
    useQueryOptions<TActivityFilterFields>({
      page: 1,
      orderBy: ['TIME_STAMP_DESC'],
      searchTerm: '',
      preserveParams,
    })

  const {loading, data} = useLockActivityLogs('', queryOptions, {
    condition: {
      unitId,
    },
  })

  const allLogs = data?.transactionalDb?.allLockActivityViews.nodes

  useEffect(() => {
    if (allLogs && !loading) {
      setActivityData(
        allLogs.map(item => {
          const eventInfo = getLogEventInfo(item)

          return {
            device: (
              <div className='d-flex align-center th-icon'>
                <Icon icon='lock' theme='primary' size={'md'} />
                {getDeviceType(item.device, item.event)}
              </div>
            ),
            event: (
              <Badge size={'sm'} theme={eventInfo.type}>
                {eventInfo.label}
              </Badge>
            ),
            otherUser: '',
            property: item.unitNumber ? 'Unit ' + item.unitNumber : '—',
            date: convertToNiceDate(+item.timeStamp),
          }
        }),
      )
    }
  }, [allLogs, loading])

  const getDeviceType = (device: string, event: string) => {
    if (event === LockEvents.OPEN || event === LockEvents.CLOSED) {
      return 'Door'
    } else if (device === 'unknown') {
      return 'Lock'
    } else if (device) {
      return capitalize(device)
    }

    return device
  }

  const onSubmitFilter = useCallback(
    (filters: TActivityFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const onChangeSortOrder = value => {
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const itemCount = data?.transactionalDb.allLockActivityViews.totalCount || 0

  return (
    <Section spacing={'none'} id='activity-logs'>
      <h4 style={{paddingBottom: '15px'}}>Activity Log</h4>

      <Search>
        <SearchFilterInput
          placeholder='Search events'
          value={''}
          onValueChange={() => null}
        />
        <SearchFilters
          filter={ActivityFilters}
          initialValue={queryOptions.filters}
          onSubmit={onSubmitFilter}
        />
        <SearchSortBy
          value={queryOptions.orderBy[0] || ''}
          options={sortOptions}
          onChange={onChangeSortOrder}
        />
      </Search>

      <Panel theme={'white'}>
        <DataGrid
          id='guests-grid'
          loading={loading}
          withHeader={false}
          columns={[
            {key: 'device', name: ''},
            {key: 'event', name: ''},
            {key: 'otherUser', name: ''},
            {key: 'property', name: ''},
            {key: 'date', name: ''},
          ]}
          rows={activityData}
        />
      </Panel>

      <TableFooter itemCount={itemCount} loading={loading}>
        <Paginator
          itemCount={itemCount}
          perPage={queryOptions.limit}
          currentPage={queryOptions.page}
          onPageChange={(page: number) => {
            upsertQueryOptions(prev => ({...prev, page}))
          }}
        />
        <TableNumberOfItems
          value={queryOptions.limit}
          onValueChange={onChangeNumberOfItems}
        />
      </TableFooter>
    </Section>
  )
}

export default ActivityLog
