import {useQuery} from '@apollo/client'
import {GET_RESIDENT_INVITES_LIST} from '../../data/graphql/queries/people'
import {
  TGetInviteResidentsResponse,
  TGetInvitesResidentsVariables,
} from '../../data/graphql/queries/people/types'
import useToast from '../useToast'
import useUserAccess from '../useUserAccess'

const useInviteResidentsList = (variables?: Partial<TGetInvitesResidentsVariables>) => {
  const {showToast} = useToast()
  const userAccess = useUserAccess()

  const query = useQuery<TGetInviteResidentsResponse, TGetInvitesResidentsVariables>(
    GET_RESIDENT_INVITES_LIST,
    {
      variables: {
        ...variables,
        first: 10,
        offset: 0,
        filter: {
          ...variables?.filter,
          buildingId: {
            notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
          },
          propertyId: {
            in: userAccess.properties,
          },
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Person List',
          type: 'error',
        })
      },
    },
  )

  return {
    totalCount: query.data?.db.residents.totalCount,
    data: query.data?.db.residents.nodes,
    loading: query.loading,
    query,
  }
}

export default useInviteResidentsList
