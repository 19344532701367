import {FC} from 'react'
import Close from '../Close'
import Icon from '../Icon'
import {IconProps} from '../Icon/Icon'

interface IProps {
  iconTheme?: IconProps['theme']
  icon?: IconProps['icon']
  title?: string
  description?: string
  closeModal(): void
  onClick?: () => void
  textAlignment?: 'align-center' | 'align-start' | 'align-end'
}

const VerticalModalHeading: FC<IProps> = ({
  iconTheme,
  icon,
  title,
  description,
  closeModal,
  onClick,
  textAlignment = 'align-center',
}) => {
  return (
    <div className={`vertical-header d-flex flex-column ${textAlignment}`}>
      {icon && iconTheme ? (
        <Icon icon={icon} size='md' theme={iconTheme} onClick={onClick} />
      ) : null}
      {title && <h6>{title}</h6>}
      {description && <p className='body-light-14 text-align-center'>{description}</p>}
      <Close onClick={closeModal} size='md' />
    </div>
  )
}

export default VerticalModalHeading
