// views/NewPassword/NewPassword.tsx

import './NewPassword.style.scss'
import NewPasswordForm from '../../forms/NewPassword'
import CrashScreen from '../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

const NewPassword = () => {
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'new-password-container'}>
        <div className='new-password-back-button'>{/* <BackButton /> */}</div>

        <div className={'new-password'} data-testid={'NewPassword'}>
          <img alt='logo' className={'brand-mark'} src={'/images/apthub-mark.svg'} />
          <h2 className={'color-black'}>Create New Password</h2>
          <NewPasswordForm />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default NewPassword
