import {TPersonTypeCode} from '../data/graphql/queries/common/types'
import useUserPersonType from './useUserPersonType'

/**
 * Hook that returns building id that should be excluded from queries results.
 * If user is in dev mode or is developer admin, it returns -1 (to not exclude any buildings),
 * otherwise it returns the building id from the environment variable.
 */
const useExcludedBuildingId = (): number => {
  const devModeValue = localStorage.getItem('devMode')
  const personTypeCode = useUserPersonType()
  const isDevMode = devModeValue !== null ? JSON.parse(devModeValue) : false

  const envValue = Number(process.env.REACT_APP_DEVS_BUILDING_ID)

  if (isDevMode) {
    return -1
  } else if (personTypeCode === TPersonTypeCode.DA) {
    return -1
  }

  return envValue
}

export default useExcludedBuildingId
