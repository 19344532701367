import Filters from '../../../components/Filters'
import AccordionGroup from '../../../components/Accordion/AccordionGroup'
import useDateRangeFilter from '../../../hooks/filters/useDateRangeFilter'
import DateRangeFilterNode from '../../../forms/Filters/FilterNodes/DateRangeFilterNode'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'
import StructuresFilterNode from '../../../forms/Filters/FilterNodes/StructuresFilterNode'
import useStructuresFilter, {
  TFilterItem,
} from '../../../hooks/filters/useStructuresFilter'
import {useState} from 'react'

import IsActiveAccessNode, {
  TIsPersonActive,
} from '../../../forms/Filters/FilterNodes/IsActiveStatusNode'
import useLeaseTypeFilter from '../../../hooks/filters/useLeaseTypeFilter'
import CheckboxListFilterNode from '../../../forms/Filters/FilterNodes/CheckboxListFilterNode'
import useLeaseExpiredFilter, {
  TLeaseExpiredValue,
} from '../../../hooks/filters/useLeaseExpiredFilter'
import RadioButtonFilterNode from '../../../forms/Filters/FilterNodes/RadioButtonFilterNode'

export type TResidentFilterFields = {
  unitFilters?: TFilterItem[]
  moveInEnd?: string
  moveInStart?: string
  moveOutEnd?: string
  moveOutStart?: string
  leaseTypes?: number[]
  isPersonAccessActive?: TIsPersonActive
  leaseExpired?: TLeaseExpiredValue
}

const ResidentsFilters = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TResidentFilterFields>) => {
  const isReports = window.location.pathname.includes('reports')

  const leaseTypes = useLeaseTypeFilter(initialValue?.leaseTypes)
  const {structures, setStructures, getSelectedIds, clearSelections} =
    useStructuresFilter(initialValue?.unitFilters)
  const [moveInRange, setMoveInRange] = useDateRangeFilter({
    to: initialValue?.moveInEnd,
    from: initialValue?.moveInStart,
  })
  const [moveOutRange, setMoveOutRange] = useDateRangeFilter({
    to: initialValue?.moveOutEnd,
    from: initialValue?.moveOutStart,
  })
  const [isPersonAccessActive, setPersonAccess] = useState<TIsPersonActive>(
    initialValue?.isPersonAccessActive || 'all',
  )
  const leaseExpired = useLeaseExpiredFilter(initialValue?.leaseExpired)

  const onChangePersonAccessFilter = (value: string) => {
    setPersonAccess(value as TIsPersonActive)
  }

  const onApplyFilter = () => {
    const unitFilters = getSelectedIds()

    onSubmit?.({
      unitFilters: unitFilters,
      moveInEnd: moveInRange.to || undefined,
      moveInStart: moveInRange.from || undefined,
      moveOutEnd: moveOutRange.to || undefined,
      moveOutStart: moveOutRange.from || undefined,
      leaseTypes: leaseTypes.getSelectedIds(),
      leaseExpired: leaseExpired.value,
      isPersonAccessActive,
    })
  }

  const onClearFilter = () => {
    leaseExpired.clear()
    leaseTypes.clear()
    clearSelections()
    setMoveInRange({
      to: '',
      from: '',
    })
    setMoveOutRange({
      to: '',
      from: '',
    })

    setPersonAccess('all')
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <StructuresFilterNode value={structures} onChange={setStructures} />
        <DateRangeFilterNode
          value={moveInRange}
          title={'Move in date'}
          onChange={setMoveInRange}
        />
        <DateRangeFilterNode
          value={moveOutRange}
          title={'Move out date'}
          onChange={setMoveOutRange}
        />
        <CheckboxListFilterNode
          value={leaseTypes.types}
          title={'Lease type'}
          onChange={leaseTypes.setTypes}
        />

        <RadioButtonFilterNode
          groupName='leaseExpiredFilter'
          title={'Lease expired'}
          value={leaseExpired.value}
          options={leaseExpired.options}
          onChange={leaseExpired.setValue}
        />

        {isReports && (
          <IsActiveAccessNode
            value={isPersonAccessActive}
            onChange={onChangePersonAccessFilter}
            title={'Access'}
          />
        )}
      </AccordionGroup>
    </Filters>
  )
}

export default ResidentsFilters
