import {useCallback, useEffect, useMemo} from 'react'

import {
  DeviceTypes,
  TAccessTypeCode,
  TPersonTypeCode,
  TResidentTypeCode,
} from '../../data/graphql/queries/common/types'
import {
  getGreaterThanOrEqualFilter,
  getLessThanOrEqualFilter,
  isUnitNumber,
  prepareOrder,
} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {useLazyQuery} from '@apollo/client'
import {
  GET_PORTAL_PERSON_LIST,
  GET_UNITS_INVITES_AND_DEVICES,
} from '../../data/graphql/queries/people'
import {
  TGetInvitesResidentsVariables,
  TGetInvitesResponse,
  TGetInvitesVariables,
} from '../../data/graphql/queries/people/types'
import {client} from '../../data/graphql'
import {format} from 'date-fns'
import useUserAccess from '../useUserAccess'
import useInviteResidentsList from './useInviteResidentsList'
import {TInviteResident} from '../../data/graphql/queries/entities'
import {TFilterItem} from '../filters/useStructuresFilter'

const usePendingInvitesData = (
  searchTerm: string,
  options: Required<
    QueryOptions<{
      unitFilters?: TFilterItem[]
      moveInEnd?: string
      moveInStart?: string
      moveOutEnd?: string
      moveOutStart?: string
    }>
  >,
) => {
  const {properties} = useUserAccess()

  const variables = useMemo(() => {
    const {unitFilters, moveInStart, moveInEnd, moveOutStart, moveOutEnd} =
      options.filters
    const result: Partial<TGetInvitesResidentsVariables> = {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      filter: {
        propertyId: {
          in: properties,
        },
        personAccessType: {
          equalTo: TAccessTypeCode.NC,
        },
        residentTypeCode: {
          notEqualTo: TResidentTypeCode.G,
        },
        buildingId: {
          notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
        },
        and: [
          {
            or: [
              {
                moveOutDate: getGreaterThanOrEqualFilter(
                  format(new Date(), 'yyyy-LL-dd'),
                ),
              },
              {
                moveOutDate: {
                  isNull: true,
                },
              },
            ],
          },
        ],
      },
    }

    if (unitFilters && result.filter) {
      result.filter.or = unitFilters
    }

    if (moveInStart) {
      result.filter?.and?.push({
        moveInDate: getGreaterThanOrEqualFilter(moveInStart),
      })
    }

    if (moveInEnd) {
      result.filter?.and?.push({
        moveInDate: getLessThanOrEqualFilter(moveInEnd),
      })
    }

    if (moveOutStart) {
      result.filter?.and?.push({
        moveOutDate: getGreaterThanOrEqualFilter(moveOutStart),
      })
    }

    if (moveOutEnd) {
      result.filter?.and?.push({
        moveOutDate: getLessThanOrEqualFilter(moveOutEnd),
      })
    }

    if (typeof searchTerm === 'string' && result.filter) {
      const fieldName = isUnitNumber(searchTerm) ? 'unitNumber' : 'personName'
      result.filter[fieldName] = {
        includesInsensitive: searchTerm,
      }
    }

    const orderBy = prepareOrder(options?.orderBy)

    if (orderBy.length) {
      result.orderBy = orderBy
    }

    return result
  }, [
    options.filters,
    options.limit,
    options?.orderBy,
    options.page,
    properties,
    searchTerm,
  ])

  const {data: residents, query: response} = useInviteResidentsList(variables)

  const [getInvitesAndDevices, invitesAndDevicesResponse] = useLazyQuery<
    TGetInvitesResponse,
    TGetInvitesVariables
  >(GET_UNITS_INVITES_AND_DEVICES)

  const invites = invitesAndDevicesResponse?.data?.transactionalDb?.allAppInvites?.nodes
  const devices =
    invitesAndDevicesResponse?.data?.transactionalDb?.allInstalledDevices?.nodes

  const prepareVariablesForInviteQuery = useCallback(
    (residents: TInviteResident[]): TGetInvitesVariables => ({
      invitesFilter: {
        expirationDt: {
          greaterThan: new Date().toISOString(),
        },
        email: {
          in: residents.map(({email}) => email),
        },
        miscInfo: {
          contains: {
            personTypeCode: TPersonTypeCode.R,
          },
        },
        isDeleted: {
          equalTo: false,
        },
      },
      devicesCondition: {
        isDeleted: false,
        isActive: true,
      },
      devicesFilter: {
        deviceTypeId: {
          in: [DeviceTypes.YALE_622, DeviceTypes.YALE_ASSURE_2, DeviceTypes.HONEYWELL_T6],
        },
        unitId: {
          in: residents.map(({unitId}) => unitId),
        },
      },
    }),
    [],
  )

  useEffect(() => {
    if (residents?.length) {
      getInvitesAndDevices({
        variables: prepareVariablesForInviteQuery(residents),
      })
    }
  }, [residents, prepareVariablesForInviteQuery, getInvitesAndDevices])

  const invitedEmails = useMemo(() => {
    if (!invites) {
      return []
    }

    return invites.map(({email}) => email)
  }, [invites])

  const installedDevices = useMemo(() => {
    if (!devices) {
      return {}
    }

    return devices.reduce<{[key: string]: Array<(typeof devices)[number]>}>(
      (result, device) => {
        const unitId = device.unitId

        if (!result[unitId]) {
          result[unitId] = []
        }

        result[unitId].push(device)

        return result
      },
      {},
    )
  }, [devices])

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {
      ...variables,
    }

    if ('first' in variables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    const allAccesses = await client.query({
      query: GET_PORTAL_PERSON_LIST,
      variables: copiedVariables,
    })

    const residents = allAccesses.data?.transactionalDb.allPortalPersonViews.nodes || []

    let inviteQuery
    if (residents.length) {
      inviteQuery = await client.query({
        query: GET_UNITS_INVITES_AND_DEVICES,
        variables: prepareVariablesForInviteQuery(residents),
      })
    }

    const invites = inviteQuery?.data?.transactionalDb?.allAppInvites?.nodes || []
    const invitedEmails = invites.map(({email}) => email)
    return {accessesList: residents, invitedEmails}
  }, [variables, prepareVariablesForInviteQuery])

  return {
    residents,
    response,
    variables,
    invitedEmails,
    installedDevices,
    invitesAndDevicesResponse,
    queryForDownloadTable,
  }
}

export default usePendingInvitesData
