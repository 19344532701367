import {useLazyQuery, useMutation} from '@apollo/client'
import {GET_BUILDING_ACCOUNT_SECRET} from '../../data/graphql/queries/people'
import {
  TGetBuildingAccountSecretResponse,
  TGetBuildingAccountSecretVariables,
} from '../../data/graphql/queries/people/types'
import {useCallback} from 'react'
import {
  TStoreBuildingSecretResponse,
  TStoreBuildingSecretVariables,
} from '../../data/graphql/mutations/people/types'
import {STORE_BUILDING_ACCOUNT_SECRET} from '../../data/graphql/mutations/people'
import useToast from '../../hooks/useToast'
import {ADD_BUILDING_ACCOUNT} from '../../data/graphql/mutations/utilities'
import {
  TAddBuildingAccountResponse,
  TAddBuildingAccountVariables,
} from '../../data/graphql/mutations/utilities/types'

const useBuildingAccountSecret = () => {
  const {showErrorToast, showInfoToast} = useToast()
  const [getSecret, getSecretQuery] = useLazyQuery<
    TGetBuildingAccountSecretResponse,
    TGetBuildingAccountSecretVariables
  >(GET_BUILDING_ACCOUNT_SECRET)

  const [storeSecret, storeSecretResponse] = useMutation<
    TStoreBuildingSecretResponse,
    TStoreBuildingSecretVariables
  >(STORE_BUILDING_ACCOUNT_SECRET, {
    onCompleted: () => {
      getSecretQuery.refetch()

      showInfoToast(
        'Store building secret',
        'Building secret has been stored successfully',
      )
    },
    onError: () => {
      showErrorToast('Store building secret', 'Failed to store building secret')
    },
  })
  const [createBuildingAccount, createAccountResponse] = useMutation<
    TAddBuildingAccountResponse,
    TAddBuildingAccountVariables
  >(ADD_BUILDING_ACCOUNT, {
    onCompleted: () => {
      showInfoToast('Create building account', 'Building account has been created')
    },
    onError: () => {
      showErrorToast('Create building account', 'Failed to create building account')
    },
  })

  const data = getSecretQuery.data?.utility.getBuildingPersonSecret.personSecret

  const createAccount = useCallback(
    async (input: TAddBuildingAccountVariables['input']) => {
      return createBuildingAccount({
        variables: {
          input,
        },
      })
    },
    [createBuildingAccount],
  )

  const store = useCallback(
    async (input: TStoreBuildingSecretVariables['input']) => {
      return storeSecret({
        variables: {
          input,
        },
      })
    },
    [storeSecret],
  )

  const fetch = useCallback(
    async (input: TGetBuildingAccountSecretVariables['input']) => {
      return getSecret({
        fetchPolicy: 'network-only',
        variables: {
          input,
        },
      })
    },
    [getSecret],
  )

  return {
    data,
    fetch,
    store,
    createAccount,
    loading:
      createAccountResponse.loading ||
      storeSecretResponse.loading ||
      getSecretQuery.loading,
  }
}

export default useBuildingAccountSecret
