import {useEffect, useState} from 'react'
import {capitalize} from '../../functions'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import {AccessScheduleTypes} from '../../data/graphql/mutations/lock/types'

const types = [
  AccessScheduleTypes.ALWAYS,
  AccessScheduleTypes.TEMPORARY,
  AccessScheduleTypes.RECURRING,
]

const useLockAccessScheduleFilter = (initialValue?: string[]) => {
  const [accessesTypes, setAccessesTypes] = useState<CheckboxItem[]>([])

  useEffect(() => {
    setAccessesTypes(
      types.map(value => ({
        id: value,
        name: capitalize(value),
        checked: !!initialValue?.includes(value),
      })),
    )
  }, [])

  const getSelectedTypes = () => {
    return accessesTypes.filter(({checked}) => checked).map(({id}) => id.toString())
  }

  return {accessesTypes, setAccessesTypes, getSelectedTypes}
}

export default useLockAccessScheduleFilter
