import './Drawer.style.scss'
import React, {FC} from 'react'

export type DrawerProps = {
  isOpen: boolean
  drawerPosition?: 'left' | 'right' | null
  onClickHide?: () => void
  children?: React.ReactNode
}

const defaultProps: DrawerProps = {
  drawerPosition: 'right',
  isOpen: true,
}

const Drawer: FC<DrawerProps> = (props: DrawerProps) => {
  props = {...defaultProps, ...props}

  const handleClickDropSadow = (event: React.MouseEvent<Element>) => {
    if ((event.target as Element).className?.includes?.('drop-shadow')) {
      props.onClickHide?.()
    }
  }

  return (
    <div
      data-testid={'Drawer'}
      onClick={handleClickDropSadow}
      className={`drop-shadow drop-shadow-${
        props.isOpen ? null : `hidden-${props.drawerPosition}`
      }`}
    >
      <div
        className={`drawer drawer-${props.drawerPosition} drawer-${
          props.isOpen ? null : `hidden-${props.drawerPosition}`
        } `}
      >
        {props.children}
      </div>
    </div>
  )
}

export default Drawer
