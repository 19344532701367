import {useMemo} from 'react'

import {
  TGetAccessPointsDevicesResponse,
  TGetAccessPointsDevicesVariables,
} from '../../data/graphql/queries/devices/types'
import {useQuery} from '@apollo/client'
import {GET_ACCESS_POINTS_DEVICES} from '../../data/graphql/queries/devices'
import useUserAccess from '../useUserAccess'

type TUnit = {
  [key: string]: {
    [key: string]: {
      installedDeviceId: string
      lockId: string
    }
  }
}

const useUnitsDevices = (
  deviceTypeIds: number[],
  condition: {
    propertyId?: number
    isDeviceInstalled: boolean
  },
) => {
  const userAccess = useUserAccess()
  const response = useQuery<
    TGetAccessPointsDevicesResponse,
    TGetAccessPointsDevicesVariables
  >(GET_ACCESS_POINTS_DEVICES, {
    skip: !userAccess.properties?.length,
    variables: {
      condition,
      filter: {
        deviceTypeId: {
          in: deviceTypeIds,
        },
        propertyId: {
          in: userAccess.properties,
        },
      },
    },
  })

  const devices = response?.data?.transactionalDb?.allDeviceInventoryViews?.nodes

  const data = useMemo<{[key: string]: TUnit}>(() => {
    if (!devices) {
      return {}
    }

    return devices.reduce(
      (result, {unitId, deviceTypeId, deviceLocationTypeId, ...data}) => {
        if (!result[unitId]) {
          result[unitId] = {}
        }

        if (!result[unitId][deviceTypeId]) {
          result[unitId][deviceTypeId] = {}
        }

        result[unitId][deviceTypeId][deviceLocationTypeId] = data

        return result
      },
      {},
    )
  }, [devices])

  return {
    data,
    response,
  }
}

export default useUnitsDevices
