import {Link} from 'react-router-dom'
import './NotFound.styles.scss'

import Icon from '../../components/Icon'

const NotFound = () => {
  return (
    <div className='not-found'>
      <Link to={'/'}>
        <img className={'brand'} src={`/images/apthub-logo.svg`} alt={'AptHub'} />
      </Link>
      <h1>404 - Page Not Found</h1>
      <p>Oops! It seems like the page you&apos;re looking for doesn&apos;t exist.</p>
      <Link to={'/'} className='link'>
        <Icon icon={'chevron-left'} />
        <span>Go back to the home page</span>
      </Link>
    </div>
  )
}

export default NotFound
