export const isCommonError = (data?: any): data is TCommonError => {
  return data?.code && data?.message
}

export type TCommonError = {
  code: number
  message: string
  status: string
  source: string
}

export type TPageInfo = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string
  endCursor: string
}

export enum DepartmentCode {
  MGR = 'MGR',
  VPO = 'VPO',
  APTHUB_MGR = 'APTHUB_MGR',
}

export enum DeviceMaker {
  YALE = 'YALE',
  BRIVO = 'BRIVO',
  NEST = 'NEST',
  HONEYWELL = 'HONEYWELL',
}

export enum YaleUserType {
  USER = 'USER',
  LIMITED = 'LIMITED',
  MANAGER = 'MANAGER',
  UNVERIFIED = 'UNVERIFIED',
}

export enum SecretType {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  PASSWORD = 'PASSWORD',
  MASTER_PIN_CODE = 'MASTER_PIN_CODE',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  BUILDING_ACCOUNT_EMAIL_PASSWORD = 'BUILDING_ACCOUNT_EMAIL_PASSWORD',
}
