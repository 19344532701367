// components/Menu/MenuDivider.tsx

import React, {FC} from 'react'
import './Menu.style.scss'

const MenuDivider: FC = () => {
  return <li className={'menu-divider'} data-testid={'MenuDivider'}></li>
}

export default MenuDivider
