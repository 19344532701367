import {useMutation} from '@apollo/client'
import {INVITE_VENDOR} from '../../../data/graphql/mutations/people'
import {
  TInviteVendorResponse,
  TInviteVendorVariables,
} from '../../../data/graphql/mutations/people/types'
import {useCallback} from 'react'
import {TGetVendorPendingInvitesResponse} from '../../../data/graphql/queries/people/types'
import useToast from '../../../hooks/useToast'
import {GET_PENDING_VENDOR_INVITES} from '../../../data/graphql/queries/people'

type TAppInvite =
  TGetVendorPendingInvitesResponse['transactionalDb']['invites']['nodes'][number]

const useResendVendorInvite = () => {
  const {showInfoToast, showErrorToast} = useToast()
  const [mutate, response] = useMutation<TInviteVendorResponse, TInviteVendorVariables>(
    INVITE_VENDOR,
    {
      awaitRefetchQueries: true,
      refetchQueries: [GET_PENDING_VENDOR_INVITES],
    },
  )

  const resend = useCallback(async (invite: TAppInvite) => {
    try {
      await mutate({
        variables: {
          input: {
            sender: {
              lastName: 'Platform',
              firstName: 'Apthub',
            },
            tasks: {
              serviceTasks: invite.miscInfo.vendorInfo.vendorTasks.serviceTasks,
              installationTasks: invite.miscInfo.vendorInfo.vendorTasks.installationTasks,
            },
            requestedThermostatAccesses: [],
            requestedLockAccesses: invite.miscInfo.lockAccesses,
            requestedCommonAreaLockAccess: invite.miscInfo.commonAreaLockAccess,
            invitee: {
              email: invite.email,
              lastName: invite.lastName,
              firstName: invite.firstName,
              mobilePhone: invite.mobilePhone,
              vendorId: invite.miscInfo.vendorInfo.vendorId,
            },
          },
        },
      })

      showInfoToast('Success', 'New invitation has been sent to vendor')
    } catch (e) {
      console.log(e)
      showErrorToast(
        'Request failed',
        'Failed to update vendor data and send a new invitation',
      )

      throw e
    }
  }, [])

  return {
    response,
    loading: response.loading,
    error: response.error,
    resend,
  }
}

export default useResendVendorInvite
