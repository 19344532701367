// views/PropertyDetails/Staff/Staff.tsx

import {useState} from 'react'
import './MessageSetup.style.scss'

import Button from '../../../components/Button'
import Section from '../../../components/Grid/Section'
import InputText from '../../../components/InputText/InputText'
import InputTextarea from '../../../components/InputTextarea/InputTextarea'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'

const MessageSetup = () => {
  const [formState, setFormState] = useState<any>({
    email: 'info@northvillageestates.com',
    message: `Hello {Insert First Name}, 
Thank you for joining our community! Etiam porta sem malesuada magna mollis euismod. Etiam porta sem malesuada magna mollis euismod.`,
    rejectionMessage: `Hello {Insert First Name}, 
Thank you for joining our community! Etiam porta sem malesuada magna mollis euismod. Etiam porta sem malesuada magna mollis euismod.`,
  })

  const handleChange = (name: string, value: string | number) => {
    setFormState({...formState, [name]: value})
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className='message-setup-header d-flex space-between align-center'>
        <h4 className='black'>Message Setup</h4>
        <div className='d-flex space-between'>
          <Button theme='outline'>Cancel</Button>
          <Button id='save-btn'>Save</Button>
        </div>
      </div>

      <Section id='form-message-setup'>
        <form
          onSubmit={e => {
            //
          }}
        >
          <InputText
            id={'email'}
            type={'email'}
            label={'From Email'}
            placeholder={'Email Address'}
            value={formState.email}
            onValueChange={v => handleChange('email', v)}
          />

          <InputTextarea
            id={'onboarding-message'}
            label={'Onboarding Message'}
            placeholder={'Message'}
            value={formState.message}
            onValueChange={v => handleChange('message', v.toString())}
          />

          <InputTextarea
            id={'rejection-message'}
            label={'Rejection Message'}
            placeholder={'Message'}
            value={formState.rejectionMessage}
            onValueChange={v => handleChange('rejectionMessage', v.toString())}
          />
        </form>
      </Section>
    </ErrorBoundary>
  )
}

export default MessageSetup
