import {FC} from 'react'

const Search: FC = () => (
  <g>
    <path d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' />
    <path d='M20.9999 21L16.6499 16.65' />
  </g>
)

export default Search
