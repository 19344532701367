import {FC} from 'react'

const BatteryPartial: FC = () => (
  <g>
    <path
      d='M23.3448 10.3053L23.3448 8.30557C23.5186 8.30557 23.6852 8.23834 23.8081 8.11867C23.931 7.99901 24 7.83671 24 7.66747L24 3.84776C24 3.67852 23.931 3.51622 23.8081 3.39656C23.6852 3.27689 23.5186 3.20966 23.3448 3.20966L23.3448 1.21875C23.3448 0.915462 23.2211 0.624594 23.001 0.410135C22.7808 0.195677 22.4821 0.0751952 22.1707 0.0751952L1.17413 0.0751943C0.862852 0.0755842 0.564441 0.196191 0.344337 0.410565C0.124232 0.624939 0.00040117 0.91558 8.0295e-07 1.21875L4.05764e-07 10.3053C0.000401341 10.6084 0.12428 10.8988 0.344428 11.113C0.564576 11.3271 0.862991 11.4474 1.17413 11.4474L22.1647 11.4474C22.3193 11.4484 22.4726 11.4196 22.6157 11.3626C22.7589 11.3056 22.889 11.2217 22.9987 11.1155C23.1084 11.0094 23.1955 10.8831 23.2549 10.7441C23.3143 10.605 23.3449 10.4559 23.3448 10.3053V10.3053ZM1.78086 10.3584C1.6071 10.3584 1.44046 10.2911 1.3176 10.1715C1.19473 10.0518 1.12571 9.88951 1.12571 9.72028L1.12571 1.80379C1.1265 1.63609 1.19504 1.47543 1.31652 1.35656C1.43799 1.2377 1.60262 1.17019 1.77481 1.16865L21.5655 1.16865C21.6515 1.16865 21.7366 1.18516 21.816 1.21725C21.8954 1.24933 21.9675 1.29636 22.0282 1.35563C22.0889 1.4149 22.1371 1.48526 22.1698 1.56266C22.2026 1.64007 22.2193 1.72302 22.2191 1.80674L22.2191 9.71586C22.2193 9.79958 22.2026 9.88252 22.1698 9.95993C22.1371 10.0373 22.0889 10.1077 22.0282 10.167C21.9675 10.2262 21.8954 10.2733 21.816 10.3053C21.7366 10.3374 21.6515 10.3539 21.5655 10.3539L1.78086 10.3584Z'
      fill='#F0B84C'
    />
    <path
      d='M6.56451 8.8891L6.56451 2.62753C6.56451 2.27513 6.49117 1.98944 6.4007 1.98944L2.08339 1.98944C1.99292 1.98944 1.91958 2.27512 1.91958 2.62753L1.91958 8.8891C1.91958 9.24151 1.99292 9.52719 2.08339 9.52719L6.4007 9.52719C6.49117 9.52719 6.56451 9.24151 6.56451 8.8891Z'
      fill='#F0B84C'
    />
  </g>
)

export default BatteryPartial
