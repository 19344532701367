import {FC} from 'react'

const Menu: FC = () => (
  <g>
    <line x1='3' y1='12' x2='21' y2='12'></line>
    <line x1='3' y1='6' x2='21' y2='6'></line>
    <line x1='3' y1='18' x2='21' y2='18'></line>
  </g>
)

export default Menu
