import {useQuery} from '@apollo/client'
import {
  TAllPersonHouseAccountsResponse,
  TAllPersonHouseAccountsVariables,
} from '../../data/graphql/queries/common/types'
import {useMemo} from 'react'
import {arrayToObject} from '../../functions'
import {GET_PERSON_HOUSE_ACCOUNT} from '../../data/graphql/queries/common'

const usePersonHouseAccounts = () => {
  const response = useQuery<
    TAllPersonHouseAccountsResponse,
    TAllPersonHouseAccountsVariables
  >(GET_PERSON_HOUSE_ACCOUNT, {
    variables: {
      condition: {
        isActive: true,
        isDeleted: false,
      },
    },
  })

  const data = response?.data?.transactionalDb?.accounts.nodes

  const byBuildingId = useMemo(() => {
    if (!data) {
      return {}
    }

    return arrayToObject(data, 'buildingId')
  }, [data])

  return {
    response,
    byBuildingId,
    accounts: data,
    loading: response.loading,
  }
}

export default usePersonHouseAccounts
