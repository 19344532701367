// components/Dropdown/Dropdown.tsx

import React, {FC, useEffect, useRef, useState} from 'react'
import './Dropdown.style.scss'

export interface DropdownProps {
  direction?: 'down' | 'up'
  align?: 'right' | 'left'
  toggle?: React.ReactNode
  children?: React.ReactNode
  disableCloseEvents?: boolean
}

const defaultProps: DropdownProps = {
  direction: 'down',
  align: 'left',
  disableCloseEvents: false,
}

const Dropdown: FC<DropdownProps> = (props: DropdownProps) => {
  props = {...defaultProps, ...props}

  const ref: any = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const close = () => {
    setIsOpen(false)
  }

  /** Handles the Click Outside Events */
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        close()
      }
    }

    if (!props.disableCloseEvents) {
      document.addEventListener('click', handleClickOutside, true)
      return () => document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref, props.disableCloseEvents])

  /** esc key is pressed close the dropdown */
  useEffect(() => {
    const keyHandler = ({keyCode}) => {
      if (keyCode !== 27) return
      close()
    }

    if (!props.disableCloseEvents) {
      document.addEventListener('keydown', keyHandler)
      return () => document.removeEventListener('keydown', keyHandler)
    }
  })

  return (
    <div ref={ref} className={`dropdown`} data-testid={'Dropdown'}>
      <div className={'dropdown-toggle'} onClick={toggle}>
        {props.toggle}
      </div>
      {isOpen && (
        <div
          className={`dropdown-menu direction-${props.direction} align-${props.align}`}
        >
          {props.children}
        </div>
      )}
    </div>
  )
}

export default Dropdown
