import './ConfirmModal.style.scss'

import Button from '../Button'
import Modal, {ModalProps} from '../Modal/Modal'

export type TConfirmModalProps = Omit<ModalProps, 'closeModal'> & {
  message?: string | React.ReactElement
  confirmLabel?: string
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmModal = ({
  message = '',
  confirmLabel = 'Confirm',
  onCancel,
  onConfirm,
  ...props
}: TConfirmModalProps) => {
  return (
    <Modal {...props} className='confirm-modal' closeModal={onCancel}>
      <h4 className='title'>{props.title}</h4>
      <p className='message'>{message}</p>

      <form>
        <div className={'controls'}>
          <Button width='standard' onClick={onConfirm}>
            {confirmLabel}
          </Button>

          <Button width='standard' theme={'outline'} onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default ConfirmModal
