import './StructuresFilterNode.style.scss'

import Accordion from '../../../../components/Accordion'
import AccordionBody from '../../../../components/Accordion/AccordionBody'
import AccordionHead from '../../../../components/Accordion/AccordionHead'
import {CheckboxItem} from '../../../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import InputCheckbox from '../../../../components/InputCheckbox/InputCheckbox'
import BuildingStrucutreFilterNode, {
  TBuildingFilterNodeItem,
} from './BuildingStrucutreFilterNode'

type TPropertyItem = CheckboxItem & {
  buildings: TBuildingFilterNodeItem[]
  containsSelected: boolean
}

export type TStructureFilterNodeProps = {
  value: TPropertyItem[]
  forbiddenLevels?: ('building' | 'property')[]
  onChange: (value: TPropertyItem[]) => void
}

const StructuresFilterNode = ({
  value,
  forbiddenLevels,
  onChange,
}: TStructureFilterNodeProps) => {
  const onChangeBuilding = (propertyId: string, building: TBuildingFilterNodeItem) => {
    const result = value.map(property => {
      if (property.id === propertyId) {
        const modifiedProperty = {
          ...property,
          containsSelected:
            !!property.containsSelected ||
            !!building.containsSelected ||
            !!building.checked,
          buildings: property.buildings.map(oldBuilding =>
            oldBuilding.id === building.id ? building : oldBuilding,
          ),
        }

        if (modifiedProperty.containsSelected) {
          modifiedProperty.checked = true
        }

        return modifiedProperty
      }

      return property
    })

    onChange(result)
  }

  const onChangeProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      value.map(property => {
        if (property.id === e.target.value) {
          const buildings = property.checked
            ? property.buildings.map(b => ({
                ...b,
                checked: false,
                containsSelected: false,
                units: b.units.map(u => ({...u, checked: false})),
              }))
            : property.buildings
          return {
            ...property,
            checked: !property.checked,
            buildings,
            containsSelected: buildings.some(({checked}) => checked),
          }
        }

        return property
      }),
    )
  }

  return (
    <Accordion transparent className={'structures-filter'}>
      <AccordionHead>
        <div className='d-flex space-between align-center'>
          <div className='desktop-label-semibold-14'>Property</div>
        </div>
      </AccordionHead>

      <AccordionBody>
        {value.map(({name, buildings, checked, id, containsSelected}) => (
          <Accordion key={name} transparent>
            <AccordionHead>
              <div className='d-flex align-center'>
                {!forbiddenLevels?.includes('property') && (
                  <InputCheckbox
                    value={id.toString()}
                    isChecked={checked || containsSelected}
                    handleChange={onChangeProperty}
                  />
                )}
                <div className='desktop-label-semibold-14'>{name}</div>
              </div>
            </AccordionHead>

            <AccordionBody>
              {buildings.map(building => {
                return (
                  <BuildingStrucutreFilterNode
                    key={building.id}
                    propertyId={id.toString()}
                    building={building}
                    forbidBildingLevel={!!forbiddenLevels?.includes('building')}
                    onChange={onChangeBuilding}
                  />
                )
              })}
            </AccordionBody>
          </Accordion>
        ))}
      </AccordionBody>
    </Accordion>
  )
}

export default StructuresFilterNode
