import {useEffect, useState} from 'react'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import {GET_ALL_TYPES} from '../../data/graphql/queries/common'
import {useQuery} from '@apollo/client'
import {TAllTypesResponse} from '../../data/graphql/queries/common/types'

const useServiceTicketCategoryFilter = (initialValue?: number[]) => {
  const allTypes = useQuery<TAllTypesResponse>(GET_ALL_TYPES)
  const categoryTypes =
    allTypes.data?.transactionalDb?.allServiceTicketCategoryTypes.nodes

  const [events, setCategories] = useState<CheckboxItem[]>([])

  useEffect(() => {
    const categories = categoryTypes || []

    setCategories(
      categories.map(item => ({
        id: item.id,
        name: item.description,
        checked: !!initialValue?.includes(+item.id),
      })),
    )
  }, [categoryTypes])

  return [events, setCategories] as const
}

export default useServiceTicketCategoryFilter
