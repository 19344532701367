import './MasterPinCell.style.scss'

import React, {FC, useCallback, useMemo, useState} from 'react'
import Icon from '../../../../components/Icon'
import classNames from 'classnames'
import {gql, useLazyQuery, useMutation} from '@apollo/client'
import {
  GET_ALL_DEVICES,
  GET_LOCK_MASTER_PIN,
  REQUEST_MASTER_PIN_RESET,
} from '../../../../data/graphql/queries/devices'
import {
  TGetLockMasterPinResponse,
  TGetLockMasterPinVariables,
  TRequestMasterPinResetResponse,
  TRequestMasterPinResetVariable,
} from '../../../../data/graphql/queries/devices/types'
import {DeviceMaker} from '../../../../data/graphql/types'
import useToast from '../../../../hooks/useToast'
import ConfirmModal from '../../../../components/ConfirmModal'

export type MasterPinStatus = 'Pending' | 'Not Enabled' | 'Enabled'

type MasterPinCellProps = {
  lockId: string
  lockName: string
  unitNumber: string
  pinStatus: MasterPinStatus
  installedDeviceId: number
  className?: string
}

const MasterPinCell: FC<MasterPinCellProps> = ({
  lockId,
  lockName,
  unitNumber,
  installedDeviceId,
  pinStatus,
  className,
  ...props
}) => {
  const pinMask = '******'
  const {showToast} = useToast()
  const [isVisible, setVisible] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)

  const [resetPin, resetPinResponse] = useMutation<
    TRequestMasterPinResetResponse,
    TRequestMasterPinResetVariable
  >(REQUEST_MASTER_PIN_RESET, {
    refetchQueries: [GET_ALL_DEVICES],
    awaitRefetchQueries: true,
    variables: {
      input: {
        deviceId: lockId,
        deviceMaker: DeviceMaker.YALE,
      },
    },
    onCompleted() {
      showToast({
        type: 'info',
        title: 'Master Pin',
        message: 'Master Pin reset requested',
      })
    },
    onError() {
      showToast({
        type: 'error',
        title: 'Master Pin',
        message: 'Failed to request master pin reset',
      })
    },
  })

  const [getLockMasterPin, response] = useLazyQuery<
    TGetLockMasterPinResponse,
    TGetLockMasterPinVariables
  >(GET_LOCK_MASTER_PIN, {
    onCompleted() {
      setVisible(true)
    },
    onError() {
      showToast({
        type: 'error',
        title: 'Master Pin',
        message: 'Failed to fetch master pin',
      })
    },
  })

  const pin = response.data?.lock?.decryptMasterPinFromDatabase.pin
  const isLoading = response.loading || resetPinResponse.loading

  const toggleModal = () => {
    if (isLoading) {
      return
    }

    setModalVisible(!isModalVisible)
  }

  const toggleMasterPin = useCallback(async () => {
    if (isLoading || pinStatus !== 'Enabled') {
      return
    }

    if (pin) {
      return setVisible(value => !value)
    }

    return getLockMasterPin({
      variables: {
        input: {
          installedDeviceId,
          deviceMaker: DeviceMaker.YALE,
        },
      },
    })
  }, [pin, getLockMasterPin, installedDeviceId, isLoading, pinStatus])

  const pinText = useMemo(() => {
    if (response.loading || resetPinResponse.loading) {
      return 'Loading'
    } else if (pinStatus === 'Pending') {
      return 'Pending'
    } else if (pinStatus === 'Not Enabled') {
      return 'Not enabled'
    } else if (isVisible && pin) {
      return pin
    }

    return pinMask
  }, [pinStatus, pin, response.loading, isVisible, pinMask, resetPinResponse.loading])

  const handleResetPinConfirmation = () => {
    toggleModal()

    resetPin()
  }

  return (
    <div className={classNames(className, 'master-pin-cell')} {...props}>
      <ConfirmModal
        title={'Reset master pin'}
        message={`Are you sure you want to reset master pin for ${lockName} lock in unit ${unitNumber}`}
        isOpen={isModalVisible}
        onConfirm={handleResetPinConfirmation}
        onCancel={toggleModal}
      />

      <div
        className={classNames('label-small-regular-12 blacks master-pin-mask', {
          'master-pin-mask-hidden': pinText === pinMask,
        })}
        onClick={toggleMasterPin}
      >
        {pinText}
      </div>

      {pinStatus === 'Enabled' && (
        <Icon
          size='sm'
          title={isVisible ? 'Hide pin' : 'Show pin'}
          icon={isVisible ? 'eye-off' : 'eye'}
          onClick={toggleMasterPin}
        />
      )}

      {pinStatus !== 'Pending' && (
        <Icon
          title={'Reset master pin'}
          icon={'refresh'}
          size='sm'
          onClick={toggleModal}
        />
      )}

      {pinStatus === 'Pending' && <Icon icon={'loader'} size='sm' />}
    </div>
  )
}

export default MasterPinCell
