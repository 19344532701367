import {useCallback, useState} from 'react'
import {RadioOption} from '../../components/InputRadioGroup/InputRadioGroup'

export type TLeaseExpiredValue = 'yes' | 'no' | ''

const options: RadioOption[] = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'yes',
    label: 'Expired',
  },
  {
    value: 'no',
    label: 'Not expired',
  },
]

const useLeaseExpiredFilter = (initialValue: TLeaseExpiredValue = '') => {
  const [value, setValue] = useState<string>(initialValue)

  const clear = useCallback(() => {
    setValue('')
  }, [])

  return {options, value: value as TLeaseExpiredValue, setValue, clear}
}

export default useLeaseExpiredFilter
