import {FC} from 'react'

const ChevronsRight: FC = () => (
  <g>
    <polyline points='13 17 18 12 13 7' />
    <polyline points='6 17 11 12 6 7' />
  </g>
)

export default ChevronsRight
