/* eslint-disable @typescript-eslint/ban-ts-comment */
// views/Overview/Overview.tsx
//@ts-nocheck
//@ts-ignore
import {FC, useEffect, useRef} from 'react'
import * as d3 from 'd3'

const marginSvgHours = {
  top: 20,
  left: 20,
  right: 20,
  bottom: 20,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const height = 223 - marginSvgHours.top - marginSvgHours.bottom

export interface BarChartData {
  key: string
  name: string
  value: number
}

type TBarChartProps = {
  data: BarChartData[]
}

const BarChart: FC<TBarChartProps> = ({data: chartData}) => {
  const secondTickinitialPosYTransform = useRef(0)

  const svgRef = useRef<SVGSVGElement>()

  let svgHoursOfUsingGraph

  const createYAxisAndScale = (data: Array<BarChartData>) => {
    const max = d3.max(data, d => d.value)

    const middleValue = Math.round((0 + max) / 2)

    const yScale = d3.scaleLinear().range([height, 0]).domain([max, 0]).nice()

    const newValues = [0, middleValue, max]

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues([0, middleValue, max])
      .tickSize(0)
      .tickPadding(0)
      .tickFormat(function (d, i) {
        return max - d
      })

    let addedYAxis
    let yScaleElement

    if (d3.select('#yScale').empty()) {
      yScaleElement = svgHoursOfUsingGraph.append('g').datum(yScale).attr('id', 'yScale')
    } else {
      yScaleElement = d3.select('#yScale')
      yScaleElement.datum(yScale.domain([max, 0]).range([height, 0]).nice())
    }

    yScaleElement.call(yScale)

    if (d3.select('#yAxis').empty()) {
      addedYAxis = svgHoursOfUsingGraph.append('g').attr('id', 'yAxis').call(yAxis)
      addedYAxis
        .selectAll('text')
        .attr('class', 'y-axis-text')
        .attr('font-size', '1rem')
        .attr('fill', '#636363')
        .attr('font-weight', 'light')
        .attr('font-family', 'sans-serif')
        .style('text-anchor', 'start')
        .attr('dx', '-20px')
        .attr('transform', `translate(${0}, ${0})`)
        .attr('class', 'body-small-light-12')

      addedYAxis.selectAll('.domain').style('stroke', 'transparent')
    } else {
      // update ticks values of existing yAxis

      const ticks = d3.select('#yAxis').selectAll('.tick')

      newValues.forEach((value, index) => {
        const tick = ticks._groups[0][index]
        const tickText = d3.select(tick).select('text')

        tickText.text(max - value)
      })
    }

    return {yScale, addedYAxis, max}
  }

  const updateCurrentXAxis = (data: Array<BarChartData>, width: number) => {
    const xScale = d3.scaleBand().domain(data).range([0, width]).padding(0.5)
    let xAxisUpdated
    if (!d3.select('#xAxis').empty()) {
      const xAxisUpdateFunction = d3
        .axisBottom(xScale)
        .tickFormat(el => el.name)
        .tickSize(0)
        .tickPadding(10)

      xAxisUpdated = d3.select('#xAxis')

      xAxisUpdated.call(xAxisUpdateFunction)

      xAxisUpdated
        .selectAll('.tick')
        .select('text')
        .transition()
        .duration(500)
        .attr('transform', 'translate(0, 0)')
    }

    return {xScale, xAxisUpdated}
  }

  const drawRects = (data: Array<BarChartData>, newWidht?: number) => {
    // update old ticks values without recreating them
    const {yScale, max} = createYAxisAndScale(data)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const width = newWidht ? newWidht : width
    const {xScale, xAxisUpdated} = updateCurrentXAxis(data, newWidht || width)

    const average = max - Math.round(d3.mean(data, d => d.value))

    const xAxis = xAxisUpdated
      ? xAxisUpdated
      : d3
          .axisBottom(xScale)
          .tickFormat(el => el.name)
          .tickSize(0)
          .tickPadding(10)

    if (d3.select('#xAxis').empty()) {
      const addedXAxis = svgHoursOfUsingGraph
        .append('g')
        .attr('id', 'xAxis')
        .attr('transform', `translate(0, ${height})`)
        .call(xAxis)

      addedXAxis
        .selectAll('text')
        .attr('fill', '#636363')
        .attr('font-size', '1rem')
        .attr('font-weight', 'light')
        .attr('font-family', 'sans-serif')
        .attr('class', 'body-small-light-12')

      addedXAxis.selectAll('.domain').style('stroke', 'transparent')
    }

    const bars = svgHoursOfUsingGraph.selectAll('.bar').data(data, el => el.key)

    const bandWidth = xScale.bandwidth()
    const rectBorderRadius = (bandWidth / 100) * 10

    // check bar contain rects and update them
    function onEnter(event, data) {
      const rect = d3.select(this)

      const avgY = +d3.select('.avg-line').attr('y1')
      const barY = +rect.attr('y')

      const color = avgY < barY ? 'rgb(240, 76, 92)' : '#008489'
      const text = svgHoursOfUsingGraph
        .append('text')
        .attr('class', 'tooltip')
        .attr('y', () => height - yScale(data.value) - 10)
        .attr('fill', color)
        .text(rect.attr('value'))
        .attr('text-anchor', 'center')
        .attr('font-size', '1rem')
        .attr('font-weight', 'light')
        .attr('font-family', 'sans-serif')

      const textWidth = text.node().getBBox().width
      const barWidth = rect.attr('width')

      text.attr('x', el => {
        return xScale(data) + (+barWidth - textWidth) / 2
      })

      text
        .transition()
        .duration(500)
        .attr('x', el => {
          return xScale(data) + (+barWidth - textWidth) / 2
        })
        .attr('y', () => height - yScale(data.value) - 10)
        .attr('fill', color)

      rect
        .interrupt()
        .transition()
        .duration(500)
        .style('fill', color)
        .attr('y', height - yScale(data.value))
        .attr('rx', rectBorderRadius)
        .attr('ry', rectBorderRadius)
        .attr('height', yScale(data.value))
        .attr('width', bandWidth)
        .attr('x', el => {
          return xScale(data)
        })
    }

    function onLeave(e, data) {
      d3.select(this)
        .transition()
        .duration(500)
        .attr('rx', rectBorderRadius)
        .attr('ry', rectBorderRadius)
        .style('fill', '#EEF1F2')
        .attr('y', height - yScale(data.value))
        .attr('height', yScale(data.value))
        .attr('width', bandWidth)
        .attr('x', el => {
          return xScale(data)
        })
      d3.select('.tooltip').remove()
    }
    if (!bars.empty()) {
      bars
        .on('mouseenter', null)
        .on('mouseleave', null)
        .on('click', null)
        .on('mouseenter', onEnter)
        .on('mouseleave', onLeave)
        .transition()
        .duration(500)
        .attr('value', el => el.value)
        .attr('x', el => {
          return xScale(el)
        })
        .attr('y', el => height - yScale(el.value))
        .attr('height', el => yScale(el.value))
        .attr('width', bandWidth)
    } else {
      bars
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('value', el => el.value)
        .style('fill', '#EEF1F2')
        .attr('y', height)
        .attr('x', el => xScale(el))
        .attr('width', bandWidth)
        .attr('rx', rectBorderRadius)
        .attr('ry', rectBorderRadius)
        .on('mouseenter', onEnter)
        .on('mouseleave', onLeave)
        .transition()
        .duration(500)
        .attr('height', el => yScale(el.value))
        .attr('y', el => height - yScale(el.value))
    }

    const posYOfAvgText = yScale(average) + 2
    moveSecondTick(yScale(max / 2), posYOfAvgText)

    if (d3.selectAll('.avg')._groups[0].length > 0) {
      d3.selectAll('.avg-line')
        .raise()
        .transition()
        .duration(500)
        .attr('y1', yScale(average))
        .attr('y2', yScale(average))
        .attr('x2', width - 35)

      d3.selectAll('.avg')
        .raise()
        .transition()
        .duration(500)
        .attr('y', posYOfAvgText)
        .attr('x', 5)
    } else {
      svgHoursOfUsingGraph
        .append('line')
        .attr('class', 'avg-line')
        .attr('x1', 0)
        .attr('y1', yScale(average))
        .attr('x2', width - 35)
        .attr('y2', yScale(average))
        .attr('stroke', '#008489')
        .attr('stroke-width', 1.5)
        .attr('stroke-dasharray', '10 10')
        .attr('transform', `translate(25, 0)`)
        .attr('stroke-linecap', 'round')
        .raise()

      svgHoursOfUsingGraph
        .append('text')
        .attr('class', 'avg label-small-regular-12')
        .attr('x', 5)
        .attr('y', posYOfAvgText)
        .attr('text-anchor', 'end')
        .attr('fill', '#008489')
        .attr('font-size', '1rem')
        .attr('font-weight', 'light')
        .attr('font-family', 'sans-serif')
        .text(`Avg`)
        .raise()
    }
  }

  function moveSecondTick(secondTickYPos, avgTextYPos) {
    const secondTick = d3
      .select('#yAxis')
      .selectAll('.tick')
      .filter((_, i) => i === 1)

    const toClose = Math.abs(secondTickYPos - avgTextYPos) < 20
    const movePosition =
      toClose && secondTickYPos < avgTextYPos ? 'up' : toClose ? 'down' : 'none'

    const secondTickTranslatePosY =
      secondTickinitialPosYTransform.current ||
      +secondTick.attr('transform').split(',')[1].split(')')[0]
    if (!secondTickinitialPosYTransform.current)
      secondTickinitialPosYTransform.current = secondTickTranslatePosY

    const valueToTransform = Math.abs(secondTickTranslatePosY - secondTickYPos) + 10
    const valueWithPosition =
      movePosition === 'up'
        ? valueToTransform
        : movePosition === 'down'
        ? -valueToTransform
        : 0
    secondTick
      .transition()
      .duration(800)
      .attr(
        'transform',
        `translate(0, ${secondTickinitialPosYTransform.current - valueWithPosition})`,
      )
  }

  useEffect(() => {
    if (svgRef.current) {
      d3.select(svgRef.current).selectAll('*').remove()

      const newWidth =
        svgRef.current?.parentElement?.offsetWidth -
        marginSvgHours.left -
        marginSvgHours.right
      // eslint-disable-next-line react-hooks/exhaustive-deps
      svgHoursOfUsingGraph = d3
        .select(svgRef.current)
        .style('background-color', 'white')
        .style('overflow', 'visible')
        .attr('width', newWidth + marginSvgHours.left + marginSvgHours.right)
        .attr('height', height + marginSvgHours.top + marginSvgHours.bottom)
        .append('g')
        .attr('transform', `translate(${marginSvgHours.left}, ${marginSvgHours.top})`)

      drawRects(chartData, newWidth)
    }
  }, [chartData])

  useEffect(() => {
    function onResize() {
      const newWidth =
        svgRef.current?.parentElement?.offsetWidth -
        marginSvgHours.left -
        marginSvgHours.right

      drawRects(chartData, newWidth)
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!chartData?.length) {
    return null
  }

  return (
    <svg
      data-testid='bar-chart'
      ref={(ref: SVGSVGElement) => {
        svgRef.current = ref
      }}
    />
  )
}

export default BarChart
