import React from 'react'
import Filters from '../../../components/Filters'
import AccordionGroup from '../../../components/Accordion/AccordionGroup'
import usePropertiesFilter from '../../../hooks/filters/usePropertieFilter'
import CheckboxListFilterNode from '../../../forms/Filters/FilterNodes/CheckboxListFilterNode'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'

export type TStaffFilterFields = {
  properties?: number[]
}

const StaffFilters = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TStaffFilterFields>) => {
  const [properties, setProperties] = usePropertiesFilter(initialValue?.properties)

  const onApplyFilter = () => {
    onSubmit?.({
      properties: properties.filter(({checked}) => !!checked).map(({id}) => +id),
    })
  }

  const onClearFilter = () => {
    const clearedProperties = properties.map(item => ({...item, checked: false}))

    setProperties(clearedProperties)
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <CheckboxListFilterNode
          title={'Property'}
          value={properties}
          onChange={setProperties}
        />
      </AccordionGroup>
    </Filters>
  )
}

export default StaffFilters
