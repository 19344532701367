import {useApolloClient} from '@apollo/client'
import useToast from '../../../useToast'
import {GET_ALL_DEVICES} from '../../../../data/graphql/queries/devices'

import {useCallback} from 'react'
import {
  TAllDevicesResponse,
  TAllDevicesVariables,
} from '../../../../data/graphql/queries/devices/types'
import {
  LockChannelMasterPinOperationFailure,
  LockChannelMasterPinOperationSuccess,
  LockChannelUserPinOperationFailure,
  LockChannelUserPinOperationSuccess,
} from '../../../../data/graphql/subscriptions/types'
import useUserPersonId from '../../../useUserPersonId'
import {
  GET_ASYNC_TRANSACTIONS,
  GET_PERSON_BY_ID,
} from '../../../../data/graphql/queries/people'
import {
  TGetPersonByIdResponse,
  TGetPersonByIdVariables,
} from '../../../../data/graphql/queries/people/types'

const usePinEventHandler = () => {
  const client = useApolloClient()
  const {showToast} = useToast()
  const adminPersonId = useUserPersonId()

  const getInstalledDevice = useCallback(
    async (installedDeviceId: number) => {
      const response = await client.query<TAllDevicesResponse, TAllDevicesVariables>({
        query: GET_ALL_DEVICES,
        variables: {
          condition: {
            installedDeviceId: installedDeviceId,
          },
        },
      })

      return response.data.transactionalDb?.allDeviceInventoryViews.nodes[0]
    },
    [client],
  )

  const getPersonById = useCallback(
    async (personId: number) => {
      const response = await client.query<
        TGetPersonByIdResponse,
        TGetPersonByIdVariables
      >({
        query: GET_PERSON_BY_ID,
        variables: {
          personId,
        },
      })

      return response.data.transactionalDb?.person
    },
    [client],
  )

  const onMasterPinUpdateFailure = useCallback(
    async (data: LockChannelMasterPinOperationFailure) => {
      try {
        const device = await getInstalledDevice(data.installedDeviceId)

        if (!device) {
          return
        }

        showToast({
          type: 'error',
          title: 'Master Pin',
          message: `Failed to reset master pin for ${device?.deviceLocation} in unit ${device?.unitNumber}`,
        })
      } catch (e) {
        // ignore error
      }
    },
    [getInstalledDevice, showToast],
  )

  const onMasterPinUpdateSuccess = useCallback(
    async (data: LockChannelMasterPinOperationSuccess) => {
      try {
        const device = await getInstalledDevice(data.installedDeviceId)

        if (!device) {
          return
        }

        showToast({
          type: 'info',
          title: 'Master Pin',
          message: `Master pin for ${device?.deviceLocation} in unit ${device?.unitNumber} updated`,
        })
      } catch (e) {
        // ignore error
      }
    },
    [getInstalledDevice, showToast],
  )

  const onUserPinSuccess = useCallback(
    async (data: LockChannelUserPinOperationSuccess) => {
      if (data.initiatingPersonId !== adminPersonId) {
        return
      }

      try {
        client.cache.evict({
          id: 'AsyncTransactionView:' + data.asyncTransactionId,
        })
      } catch (e) {
        // ignore error
      }
    },
    [adminPersonId, client.cache],
  )

  const onUserPinFailure = useCallback(
    async (data: LockChannelUserPinOperationFailure) => {
      if (data.initiatingPersonId !== adminPersonId) {
        return
      }

      try {
        client.refetchQueries({
          include: [GET_ASYNC_TRANSACTIONS],
        })

        const [device, person] = await Promise.all([
          getInstalledDevice(data.installedDeviceId),
          getPersonById(data.targetPersonId),
        ])

        if (!device || !person) {
          return
        }

        const fullName = `${person.firstName} ${person.lastName}`

        showToast({
          type: 'error',
          title: 'User Pin Failure',
          message: `Failed to update pin for ${fullName} on ${device?.deviceLocation} for unit ${device?.unitNumber} updated`,
        })
      } catch (e) {
        // ignore error
      }
    },
    [adminPersonId, client, getInstalledDevice, getPersonById, showToast],
  )

  return {
    onMasterPinUpdateFailure,
    onMasterPinUpdateSuccess,
    onUserPinFailure,
    onUserPinSuccess,
  }
}

export default usePinEventHandler
