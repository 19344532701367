// components/Paginator/Paginator.tsx

import React, {FC} from 'react'
import './Paginator.style.scss'
import Button from '../Button'
import Icon from '../Icon'
import ButtonRow from '../ButtonRow'

export interface PaginatorProps {
  itemCount: number
  perPage: number
  currentPage: number
  showFirstLast?: boolean
  showNextPrevious?: boolean
  onPageChange: (page: number) => void
  // onPerPageChange?: () => void;
}

const defaultProps: PaginatorProps = {
  itemCount: 0,
  perPage: 50,
  currentPage: 1,
  showFirstLast: true,
  showNextPrevious: true,
  onPageChange: e => e,
}

const Paginator: FC<PaginatorProps> = (props: PaginatorProps) => {
  props = {...defaultProps, ...props}

  const totalPageCount = (): number => {
    return Math.ceil(props.itemCount / props.perPage)
  }

  const currentFlag = (p): boolean => {
    return props.currentPage === p
  }

  const firstFlag = (): boolean => {
    return props.currentPage === 1
  }

  const lastFlag = (): boolean => {
    return props.currentPage === totalPageCount()
  }

  const breadcrumbArray = (): number[] => {
    const total = totalPageCount()
    const current = props.currentPage
    const maxShownPages = 5 // Change this to adjust how many pages to show

    let start = current - Math.floor(maxShownPages / 2)
    start = Math.max(start, 1)

    let end = start + maxShownPages - 1
    if (end > total) {
      end = total
      start = Math.max(end - maxShownPages + 1, 1)
    }

    const pages: number[] = []
    for (let i: number = start; i <= end; i++) {
      pages.push(i)
    }

    return pages
  }

  if (totalPageCount() >= 1) {
    return (
      <div className={'paginator'} data-testid={'Paginator'}>
        {/* FIRST BUTTON */}
        {props.showFirstLast && (
          <Button
            theme={'white'}
            size={'sm'}
            width={'square'}
            disabled={firstFlag()}
            testId={'btn-first'}
            onClick={() => props.onPageChange(1)}
          >
            <Icon size='xs' icon={'chevrons-left'} />
          </Button>
        )}

        {/* PREVIOUS BUTTON */}
        {props.showNextPrevious && (
          <Button
            theme={'white'}
            size={'sm'}
            width={'square'}
            disabled={firstFlag()}
            testId={'btn-previous'}
            onClick={() => props.onPageChange(props.currentPage - 1)}
          >
            <Icon size='xs' icon={'chevron-left'} />
          </Button>
        )}

        {breadcrumbArray().map((p, index) => {
          return (
            <Button
              key={index}
              theme={`${currentFlag(p) ? 'primary' : 'white'}`}
              size={'sm'}
              width={'square'}
              testId='page-number'
              onClick={() => props.onPageChange(p)}
            >
              {p}
            </Button>
          )
        })}

        {/* NEXT BUTTON */}
        {props.showNextPrevious && (
          <Button
            theme={'white'}
            size={'sm'}
            width={'square'}
            disabled={lastFlag()}
            testId={'btn-next'}
            onClick={() => props.onPageChange(props.currentPage + 1)}
          >
            <Icon size='xs' icon={'chevron-right'} />
          </Button>
        )}

        {/* LAST BUTTON */}
        {props.showFirstLast && (
          <Button
            theme={'white'}
            size={'sm'}
            width={'square'}
            disabled={lastFlag()}
            testId={'btn-last'}
            onClick={() => props.onPageChange(totalPageCount())}
          >
            <Icon size='xs' icon={'chevrons-right'} />
          </Button>
        )}
      </div>
    )
  } else {
    return <p></p>
  }
}

export default Paginator
