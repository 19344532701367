// BackButton.tsx

import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import './BackButton.style.scss'
import Button, {ButtonSizeOptions} from '../Button/Button'

export interface BackButtonProps {
  label?: string
  to?: string
  size?: ButtonSizeOptions
}

const defaultProps: BackButtonProps = {
  label: 'Back',
  size: 'md',
}

const BackButton: FC<BackButtonProps> = ({to, ...props}: BackButtonProps) => {
  props = {...defaultProps, ...props}

  const navigate = useNavigate()

  const onPress = () => {
    if (typeof to === 'string') {
      navigate(to)
    } else {
      navigate(-1)
    }
  }

  return (
    <Button
      testId='back-btn'
      theme={'control-black'}
      icon={'chevron-left'}
      iconPosition={'left'}
      size={props.size}
      onClick={onPress}
      id={'back-btn'}
    >
      {props.label}
    </Button>
  )
}

export default BackButton
