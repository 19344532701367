import '../ResidentsOverview.style.scss'

import React from 'react'
import Card from '../../../components/Card/Card'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import Hr from '../../../components/Hr'
import Icon from '../../../components/Icon'
import CrashScreen from '../../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import UsageChart from './UsageChart'
import RecentActivity from './RecentActivity'
import Household from './Household'

const AppMetrics = () => {
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <Section>
        <Row>
          <Column xl={'8'}>
            <UsageChart />
          </Column>

          <Column xl={'4'}>
            <RecentActivity />
          </Column>
        </Row>
        <Row>
          <Column xl={'4'}>
            <Card padding='sm'>
              <div className='graph pl-20'>
                <Column>
                  <h6>Energy Usage</h6>
                  <div className='body-small-light-12'>This Week</div>

                  <div className='d-flex time-in-app-statistic align-center'>
                    <div className='page-statistic-26'>###,### kWh</div>
                    <Icon icon='arrow-up' role='icon' size='xs' theme='danger' />
                    <div className='body-light-14 precentage'>#.#%</div>
                  </div>
                  <Hr />
                </Column>
              </div>
            </Card>
          </Column>
          <Column xl={'8'}>
            <Household />
          </Column>
        </Row>
      </Section>
    </ErrorBoundary>
  )
}

export default AppMetrics
