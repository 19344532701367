// views/Search/SearchFilterInput.tsx

import './Search.style.scss'
import {FC} from 'react'
import InputText from '../../../components/InputText/InputText'

export type TSearchFilterInputProps = {
  value: string
  placeholder: string
  onValueChange: (value: string) => void
}

const SearchFilterInput: FC<TSearchFilterInputProps> = ({
  value,
  placeholder = 'Search',
  onValueChange,
}) => {
  const handleChange = (value: string | number) => {
    onValueChange(value.toString())
  }

  return (
    <InputText
      id={'people-search'}
      type={'search'}
      placeholder={placeholder}
      theme={'gray'}
      value={value}
      suffixIcon='search'
      onValueChange={handleChange}
      inputTestId='people-search'
    />
  )
}

export default SearchFilterInput
