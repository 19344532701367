import {FC} from 'react'

const ChevronsLeft: FC = () => (
  <g>
    <polyline points='11 17 6 12 11 7' />
    <polyline points='18 17 13 12 18 7' />
  </g>
)

export default ChevronsLeft
