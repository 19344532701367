import {FC} from 'react'
import './People.style.scss'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import Panel from '../../../components/Panel'
import DataGrid from '../../../components/DataGrid'
import Paginator from '../../../components/Paginator'
import {QueryOptionsHook, SortOptionsItem} from '../../../hooks/useQueryOptions'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import {DataGridProps} from '../../../components/DataGrid/DataGrid'
import {QueryOptions} from '../../../models'
import ErrorBoundary from '../../../components/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'

export interface PeopleQueryParams {
  type: 'residents' | 'staff' | 'admins' | 'guests' | 'vendors'
}

export interface IPeopleProps {
  onChangeNumberOfItems: (value: string) => void
  upsertQueryOptions: QueryOptionsHook<any>['upsertQueryOptions']
  onSubmitFilter: (filters: any) => void
  onTypeSearchField: (text: string) => void
  onChangeSortOrder: (value: string) => void
  onPressRow: (indext: number) => void
  emptyTableComponent?: JSX.Element | string
  sortOptions: SortOptionsItem[]
  queryOptions: QueryOptions
  Filters?: any
  count: number
  searchPlaceholder?: string
  loading: boolean
  className?: string
  testId?: string

  tableProps: DataGridProps
}

const People: FC<IPeopleProps> = ({
  onChangeNumberOfItems,
  onSubmitFilter,
  upsertQueryOptions,
  onTypeSearchField,
  onChangeSortOrder,
  onPressRow,
  sortOptions,
  Filters,
  queryOptions,
  tableProps,
  count,
  loading,
  className,
  testId,
  searchPlaceholder,
  emptyTableComponent,
}) => {
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={className} data-testid={testId}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder={searchPlaceholder || ''}
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onTypeSearchField}
                  />

                  {Filters ? (
                    <SearchFilters
                      initialValue={queryOptions.filters}
                      filter={Filters}
                      onSubmit={onSubmitFilter}
                    />
                  ) : null}

                  <SearchSortBy
                    value={queryOptions?.orderBy?.[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>
                <Panel theme={'white'}>
                  <DataGrid
                    selectableRows
                    {...tableProps}
                    onRowSelect={onPressRow}
                    loading={loading}
                    emptyTableComponent={emptyTableComponent}
                  />
                </Panel>
                <TableFooter itemCount={count} loading={loading}>
                  <Paginator
                    itemCount={count}
                    perPage={queryOptions.limit as number}
                    currentPage={queryOptions.page as number}
                    onPageChange={p =>
                      upsertQueryOptions({
                        ...queryOptions,
                        page: p as number,
                      })
                    }
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit || 10}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default People
