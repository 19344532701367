import {useCallback, useMemo, useRef} from 'react'
import {
  getGreaterThanOrEqualFilter,
  getLessThanOrEqualFilter,
  prepareOrder,
} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {useQuery} from '@apollo/client'
import {
  TAllVendorsCompanyViews,
  TAllVendorsCompanyViewsVariables,
} from '../../data/graphql/queries/people/types'
import {GET_ALL_VENDORS_COMPANY_VIEWS} from '../../data/graphql/queries/people'
import useToast from '../useToast'
import {client} from '../../data/graphql'
import {TVendorsFilterFields} from '../../views/People/Vendors/VendorFilters'
import {transformPersonActiveStatus} from '../../functions/people.functions'

const useAllVendorsCompanyViews = (
  searchTerm: string,
  options: Required<QueryOptions<TVendorsFilterFields>>,
) => {
  const {showToast} = useToast()

  const variables = useMemo(() => {
    const filter: {[key: string]: any} = {
      and: [],
    }

    const {activeDateFrom, activeDateTo, deactiveDateFrom, deactiveDateTo, isActive} =
      options.filters

    if (searchTerm) {
      filter.vendorName = {
        includesInsensitive: searchTerm,
      }
    }

    if (activeDateFrom) {
      filter.and.push({
        activeDate: getGreaterThanOrEqualFilter(activeDateFrom),
      })
    }

    if (activeDateTo) {
      filter.and.push({
        activeDate: getLessThanOrEqualFilter(activeDateTo),
      })
    }

    if (deactiveDateFrom) {
      filter.and.push({
        deactiveDate: getGreaterThanOrEqualFilter(deactiveDateFrom),
      })
    }

    if (deactiveDateTo) {
      filter.and.push({
        deactiveDate: getLessThanOrEqualFilter(deactiveDateTo),
      })
    }

    const orderBy = prepareOrder(options?.orderBy)
    const condition: TAllVendorsCompanyViewsVariables['condition'] = {}

    if (isActive !== undefined) {
      condition.isVendorActive = transformPersonActiveStatus(isActive)
    }

    return {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      ...(Object.keys(condition).length ? {condition} : {}),
      ...(Object.keys(filter).length ? {filter} : {}),
      ...(orderBy.length ? {orderBy} : {}),
    }
  }, [options, searchTerm])

  const response = useQuery<TAllVendorsCompanyViews, TAllVendorsCompanyViewsVariables>(
    GET_ALL_VENDORS_COMPANY_VIEWS,
    {
      variables,
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Vendors Data',
          type: 'error',
        })
      },
    },
  )

  const vendors = useMemo(() => {
    return response.data?.transactionalDb.allVendorCompanyViews.nodes || []
  }, [response.data?.transactionalDb.allVendorCompanyViews.nodes])

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {
      ...variables,
    }

    if ('first' in variables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query<TAllVendorsCompanyViews, TAllVendorsCompanyViewsVariables>({
      query: GET_ALL_VENDORS_COMPANY_VIEWS,
      variables: copiedVariables,
    })
  }, [variables])

  return {
    vendors,
    response,
    variables,
    queryForDownloadTable,
  }
}

export default useAllVendorsCompanyViews
