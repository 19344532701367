import './ResidentsOverview.style.scss'

import {useEffect, useState} from 'react'
import Badge from '../../components/Badge'
import Card from '../../components/Card/Card'
import DataGrid from '../../components/DataGrid'
import {capitalize, formatMoney} from '../../functions'
import {BadgeProps} from '../../components/Badge/Badge'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import Section from '../../components/Grid/Section'

interface IPaymentHistory {
  date: string
  amount: number
  unit: number
  status: string
  memo: string
  name: string
}

const PaymentHistory = () => {
  const [paymentHistory, setPaymentHistory] = useState<any[]>([])
  const [paymentHistoryResponse] = useState<IPaymentHistory[]>([
    {
      date: '###',
      amount: 0,
      unit: 0,
      status: '###',
      memo: '###',
      name: '#### ####',
    },
  ])

  function handlePaymentStatusBadge(status: string) {
    let theme: BadgeProps['theme']

    switch (status) {
      case 'complete':
        theme = 'success'
        break
      case 'pending':
        theme = 'danger'
        break
      default:
        theme = 'danger'
        break
    }

    return (
      <Badge theme={theme} size={'sm'}>
        {capitalize(status)}
      </Badge>
    )
  }

  useEffect(() => {
    setPaymentHistory(
      paymentHistoryResponse.map(item => ({
        ...item,
        date: item.date,
        status: handlePaymentStatusBadge(item.status),
        amount: formatMoney(item.amount),
      })),
    )
  }, [paymentHistoryResponse])

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <Section>
        <Card padding='sm' theme='default'>
          <DataGrid
            columns={[
              {key: 'name', name: 'Name'},
              {key: 'unit', name: 'Unit'},
              {key: 'amount', name: 'Amount'},
              {key: 'date', name: 'Date'},
              {key: 'status', name: 'Status'},
              {key: 'memo', name: 'Memo'},
            ]}
            minHeight
            rows={paymentHistory}
          />
        </Card>
      </Section>
    </ErrorBoundary>
  )
}

export default PaymentHistory
