import {useQuery} from '@apollo/client'
import {useCallback, useMemo} from 'react'

import {QueryOptions} from '../models'
import {LockEvents} from '../functions/logs'
import {ALL_LOCK_ACTIVITY_VIEWS_LOGS} from '../data/graphql/queries/activityLogs'
import {
  IAllLockActivityLogsVariables,
  IAllLockActivityLogsViewsResponse,
} from '../data/graphql/queries/activityLogs/types'
import {
  getGreaterThanOrEqualFilter,
  getLessThanOrEqualFilter,
  isUnitNumber,
} from '../functions/filters'
import {TQueryFilter} from '../data/graphql/queries/common/types'
import useToast from './useToast'
import {client} from '../data/graphql'
import {TActivityFilterFields} from '../views/GuestsOverview/ActivityFilters/ActivityFilters'
import useUserAccess from './useUserAccess'
import useExcludedBuildingId from './useExcludedBuildingId'

const useLockActivityLogs = (
  searchTerm: string,
  options: Required<QueryOptions<TActivityFilterFields & {defaultUnit?: number}>>,
  variables?: IAllLockActivityLogsVariables,
  skip?: boolean,
) => {
  const {properties} = useUserAccess()
  const {showToast} = useToast()
  const devsBuildingId = useExcludedBuildingId()

  const filtersQuery = useMemo(() => {
    const {events, unitFilters, dateEnd = '', dateStart = ''} = options.filters
    const timestampStart = Date.parse(dateStart)
    const timestampEnd = Date.parse(dateEnd)
    const searchFilter = {
      [isUnitNumber(searchTerm) ? 'unitNumber' : 'fullName']: {
        includesInsensitive: searchTerm,
      },
    }

    const filter: IAllLockActivityLogsVariables['filter'] = {
      or: unitFilters || [],
      and: [],
    }

    if (searchTerm) {
      filter.and?.push(searchFilter)
    }

    if (!isNaN(timestampStart)) {
      filter.and?.push({
        timeStamp: getGreaterThanOrEqualFilter<number>(timestampStart),
      })
    }

    if (!isNaN(timestampEnd)) {
      filter.and?.push({
        timeStamp: getLessThanOrEqualFilter<number>(timestampEnd),
      })
    }

    const pinOperationsChecked = !!events?.some(
      event => event === LockEvents.KEYPAD_PIN_MANAGED,
    )

    const deviceEvents =
      events?.reduce((result: any[], event) => {
        if (
          event === LockEvents.CLOSED ||
          event === LockEvents.OPEN ||
          event === LockEvents.LOCK ||
          event === LockEvents.UNLOCK ||
          event === LockEvents.ONETOUCHLOCK ||
          event === LockEvents.ACCESS_CHANGED ||
          event === LockEvents.USER_ADDED
        ) {
          result.push({event: {equalTo: event}})
        }

        return result
      }, []) || []

    const pinEvents = pinOperationsChecked
      ? [
          {
            event: {
              equalTo: LockEvents.KEYPAD_PIN_MANAGED,
            },
          },
          {
            event: {
              equalTo: LockEvents.LOAD_PIN,
            },
          },
          {
            event: {
              equalTo: LockEvents.DELETE_PIN,
            },
          },
          {
            event: {
              equalTo: LockEvents.DISABLE_PIN,
            },
          },
          {
            event: {
              equalTo: LockEvents.ENABLE_PIN,
            },
          },
        ]
      : []

    if ([...pinEvents, ...deviceEvents].length) {
      filter.and?.push({
        or: [...pinEvents, ...deviceEvents],
      })
    }

    return filter
  }, [searchTerm, options?.filters])

  const response = useQuery<
    IAllLockActivityLogsViewsResponse,
    IAllLockActivityLogsVariables
  >(ALL_LOCK_ACTIVITY_VIEWS_LOGS, {
    variables: {
      first: options.limit,
      offset: options ? options.limit * (options.page - 1) : 0,
      orderBy: options?.orderBy,
      ...variables,
      filter: {
        propertyId: {
          in: properties,
        },
        buildingId: {
          notEqualTo: devsBuildingId,
        },
        error: {
          isNull: true,
        },
        errorName: {
          isNull: true,
        },
        event: {
          isNull: false,
        },
        or: [
          {
            action: {
              notEqualTo: 'intent',
            },
          },
          {
            action: {
              equalTo: 'digest',
            },
          },
          {
            action: {
              isNull: true,
            },
          },
        ],
        ...filtersQuery,
        and: [
          {
            or: [
              {
                and: [
                  {
                    state: {
                      notEqualTo: 'intentFailed',
                    },
                  },
                  {
                    state: {
                      notEqualTo: 'commitFailed',
                    },
                  },
                ],
              },
              {
                state: {
                  isNull: true,
                },
              },
            ],
          },
          ...(filtersQuery.and ? filtersQuery.and : []),
        ],
        ...variables?.filter,
      },
    },
    skip,
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Activity Log Data',
        type: 'error',
      })
    },
  })

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {...variables}
    if ('first' in copiedVariables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query({
      query: ALL_LOCK_ACTIVITY_VIEWS_LOGS,
      variables: copiedVariables,
    })
  }, [variables])

  return {...response, queryForDownloadTable}
}

export default useLockActivityLogs
