import {FC} from 'react'

const Loader2: FC = () => (
  <g>
    <line x1='12' y1='2' x2='12' y2='6' strokeWidth='2' />
    <line x1='16.24' y1='7.76' x2='19.07' y2='4.93' strokeWidth='2' />
    <line x1='18' y1='12' x2='22' y2='12' strokeWidth='1.5' />
    <line x1='16.24' y1='16.24' x2='19.07' y2='19.07' strokeWidth='1' />
    <line x1='12' y1='18' x2='12' y2='22' strokeWidth='1.5' />
    <line x1='4.93' y1='19.07' x2='7.76' y2='16.24' strokeWidth='2' />
    <line x1='2' y1='12' x2='6' y2='12' strokeWidth='2' />
    <line x1='4.93' y1='4.93' x2='7.76' y2='7.76' strokeWidth='2' />
  </g>
)

export default Loader2
