import React, {ForwardRefRenderFunction} from 'react'
import './Card.style.scss'

export type CardProps = {
  id?: string
  theme?: 'default' | 'gray' | 'semi-dark' | 'dark'
  padding?: 'none' | 'xs' | 'sm' | 'md' | 'lg'
  image?: string
  noShadow?: boolean
  noMargin?: boolean
  imageWidth?: string
  imageHeight?: string
  imagePosition?: 'top' | 'side'
  children?: React.ReactNode
  className?: string
  onClick?: () => void
}

const defaultProps: CardProps = {
  theme: 'default',
  padding: 'sm',
  imageHeight: '100px',
  imageWidth: '20%',
  imagePosition: 'top',
}

const Card: ForwardRefRenderFunction<HTMLDivElement, CardProps> = (
  {className = '', ...props}: CardProps,
  forwardedRef,
) => {
  props = {...defaultProps, ...props}

  const widthStyle = props.imagePosition === 'side' ? props.imageWidth : ''
  const heightStyle = props.imagePosition === 'top' ? props.imageHeight : ''

  return (
    <div
      id={props.id}
      ref={forwardedRef}
      className={`card ${props.noShadow ? 'no-shadow' : null} ${props.theme}
        ${props.noMargin ? 'no-margin' : null} 
        ${props.image ? 'image-' + props.imagePosition : ''} 
        ${props.padding !== 'none' ? 'padding-' + props.padding : null} ${className}`}
      data-testid={'Card'}
      onClick={props.onClick}
    >
      {props.image && (
        <div
          className={`card-image`}
          style={{
            backgroundImage: `url(${props.image})`,
            minHeight: `${heightStyle}`,
            minWidth: `${widthStyle}`,
          }}
        ></div>
      )}
      <div>{props.children}</div>
    </div>
  )
}

export default React.memo(React.forwardRef(Card))
