import './AccessSchedule.style.scss'

import InputText from '../../components/InputText/InputText'
import InputRadio from '../../components/InputRadioGroup/InputRadio'
import {FC, useRef} from 'react'
import TimePicker from '../TimePicker'
import format from 'date-fns/format'
import {TTimePickerValue} from '../TimePicker/TimePicker'
import {addDays, addHours, addYears} from 'date-fns'
import {AccessScheduleTypes} from '../../data/graphql/mutations/lock/types'
import classNames from 'classnames'
import {convertHoursTo24} from '../../functions/lock.functions'
import {getMondayBasedDayIndex} from '../../functions'

export type TAccessType = 'app' | 'pin'

export type TPinTransactionData = {
  personId: number
  installedDeviceId: number
  status: 'failure' | 'pending'
}

export type TAccessScheduleFields = {
  accessType: TAccessType
  accessScheduleType: AccessScheduleTypes
  startDate: Date
  endDate: Date | null
  weekDays: number[]

  startTime: TTimePickerValue
  endTime: TTimePickerValue
}

export type TAccessScheduleProps = {
  disabled?: boolean
  noRecurring?: boolean
  pinOnly?: boolean
  className?: string
  schedule: TAccessScheduleFields
  onlyAccessType?: 'pin' | 'app'
  limitedAccess?: boolean
  onChange?: (value: TAccessScheduleFields) => void
}

const AccessSchedule: FC<TAccessScheduleProps> = ({
  disabled,
  schedule,
  pinOnly,
  noRecurring,
  limitedAccess,
  className = '',
  onChange,
}) => {
  const minDate = useRef(format(new Date(), "yyyy-MM-dd'T'kk:mm")).current
  const daysList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

  const getScheduleDaysShift = (endDate: Date, selectedDays: number[]) => {
    if (!endDate || !selectedDays?.length) {
      return 0
    }

    const now = new Date()
    const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
    const todaysIndex = getMondayBasedDayIndex(now)

    if (endDate > now && selectedDays.includes(todaysIndex)) {
      return 0
    }

    const shiftedDays = [
      ...days.slice(todaysIndex),
      ...days.slice(0, todaysIndex),
      ...days.slice(todaysIndex, 1),
    ]

    const nearestWeekDay =
      selectedDays.find(dayIndex => {
        const isTodayLaterTime = dayIndex === todaysIndex && endDate > now

        if (isTodayLaterTime || dayIndex > todaysIndex) {
          return true
        }

        return false
      }) || selectedDays[0]

    const daysToShift = shiftedDays.indexOf(days[nearestWeekDay])

    if (shiftedDays[daysToShift] === days[todaysIndex] && endDate < now) {
      return 7
    }

    return daysToShift
  }

  const onChangeField = (name: keyof TAccessScheduleFields, value: any) => {
    const result = {
      ...schedule,
      [name]: value,
    }

    if (name === 'weekDays') {
      result.weekDays = result.weekDays.sort()
    }

    if (name === 'accessScheduleType' && value === AccessScheduleTypes.TEMPORARY) {
      result.startDate = new Date()
      result.endDate = addHours(result.startDate, 1)
    }

    if (result.accessScheduleType === AccessScheduleTypes.RECURRING) {
      const {startTime, endTime} = result

      result.startDate = new Date()
      result.startDate.setHours(convertHoursTo24(startTime), startTime.minutes, 0)
      result.endDate = addHours(new Date(), 1)
      result.endDate.setHours(convertHoursTo24(endTime), endTime.minutes, 0)

      const daysToShift = getScheduleDaysShift(result.endDate, result.weekDays)

      if (daysToShift) {
        result.startDate = addDays(result.startDate, daysToShift)
        result.endDate = addDays(result.endDate, daysToShift)
      }

      result.endDate = addYears(result.endDate, 1)
    }

    onChange?.(result)
  }

  const getDateValue = (value: Date | null) => {
    if (!value) {
      return ''
    }
    return format(value, "yyyy-MM-dd'T'kk:mm")
  }

  return (
    <div className={`access-schedule ${className}`}>
      <div>
        <h4>Access</h4>

        <div className={'access-schedule-row'}>
          <div className='radio-buttons'>
            {!pinOnly && (
              <InputRadio
                value={'app'}
                selected={schedule.accessType}
                label='App Access'
                onValueChange={() => {
                  onChangeField('accessType', 'app')
                }}
              />
            )}

            <InputRadio
              value={'pin'}
              selected={schedule.accessType}
              label='Pin Only'
              onValueChange={() => {
                onChangeField('accessType', 'pin')
              }}
            />
          </div>
        </div>
      </div>

      <div className={'access-schedule-section'}>
        <h4>Access Dates</h4>
        <div className={'access-schedule-row'}>
          <div className='radio-buttons'>
            {!limitedAccess && (
              <InputRadio
                value={AccessScheduleTypes.ALWAYS}
                selected={schedule.accessScheduleType}
                label='Always'
                onValueChange={() => {
                  onChangeField('accessScheduleType', AccessScheduleTypes.ALWAYS)
                }}
              />
            )}
            <InputRadio
              value={AccessScheduleTypes.TEMPORARY}
              selected={schedule.accessScheduleType}
              label='Temporary'
              onValueChange={() => {
                onChangeField('accessScheduleType', AccessScheduleTypes.TEMPORARY)
              }}
            />

            {!noRecurring && (
              <InputRadio
                value={AccessScheduleTypes.RECURRING}
                selected={schedule.accessScheduleType}
                label='Recurring'
                onValueChange={() => {
                  onChangeField('accessScheduleType', AccessScheduleTypes.RECURRING)
                }}
              />
            )}
          </div>

          {schedule.accessScheduleType === AccessScheduleTypes.TEMPORARY && (
            <div className='date-range'>
              <InputText
                id={'start-access-date'}
                type={'datetime-local'}
                label={'Start Date'}
                theme={'white'}
                value={getDateValue(schedule.startDate)}
                min={minDate}
                onValueChange={value => {
                  if (value) {
                    onChangeField('startDate', new Date(value))
                  }
                }}
              />
              <InputText
                id={'end-access-date'}
                type={'datetime-local'}
                label={'End Date'}
                theme={'white'}
                value={getDateValue(schedule.endDate)}
                min={getDateValue(schedule.startDate)}
                onValueChange={value => {
                  if (value) {
                    onChangeField('endDate', new Date(value))
                  }
                }}
              />
            </div>
          )}

          {schedule.accessScheduleType === AccessScheduleTypes.RECURRING && (
            <>
              <div className='week-day-picker'>
                {daysList.map((label, index) => (
                  <div
                    key={index}
                    data-testid={'dayPickerItem_' + index}
                    className={classNames('week-day', {
                      active: schedule.weekDays.includes(index),
                    })}
                    onClick={() => {
                      if (schedule.weekDays.includes(index)) {
                        onChangeField(
                          'weekDays',
                          schedule.weekDays.filter(current => current !== index),
                        )
                      } else {
                        onChangeField('weekDays', [...schedule.weekDays, index])
                      }
                    }}
                  >
                    <span className='label'>{label}</span>
                  </div>
                ))}
              </div>

              <div className='time-pickers'>
                <TimePicker
                  disabled={disabled}
                  className='start-time-picker time-picker'
                  label={'Start time'}
                  value={schedule.startTime}
                  onChange={value => {
                    onChangeField('startTime', value)
                  }}
                />

                <TimePicker
                  disabled={disabled}
                  className='time-picker'
                  label={'End time'}
                  value={schedule.endTime}
                  onChange={value => {
                    onChangeField('endTime', value)
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccessSchedule
