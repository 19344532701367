// components/DataGrid/DataGrid.tsx

import React, {FC} from 'react'
import './DataGrid.style.scss'
import DataGridRow from './DataGridRow'
import DataGridCell from './DataGridCell'
import Icon from '../Icon'
import Spinner from '../Spinner'

interface Column {
  key: string
  name: string
  sortable?: boolean
}

export interface DataGridProps {
  id?: string
  zebraStripe?: boolean
  selectedColumn?: string
  selectedColumnChange?: (k) => void
  columns: Column[]
  order?: 'desc' | 'asc'
  rows: {
    rowKey?: string
    rowClassname?: string
    [key: string]: any
  }[]
  selectableRows?: boolean
  onRowSelect?: (index: number) => void
  loading?: boolean
  withHeader?: boolean
  perPage?: number
  emptyTableComponent?: JSX.Element | string
  minHeight?: boolean
}

const defaultProps: DataGridProps = {
  zebraStripe: false,
  columns: [],
  rows: [],
  selectableRows: false,
  selectedColumnChange: k => k,
  loading: false,
  withHeader: true,
  perPage: 10,
  onRowSelect() {
    //
  },
}

const DataGrid: FC<DataGridProps> = (props: DataGridProps) => {
  props = {...defaultProps, ...props}

  /**
   * Sort column
   */
  const sortColumn = (key: string) => {
    props.selectedColumnChange && props.selectedColumnChange(key)
  }

  /**
   * Selects a row by id/key
   */
  const selectRow = (id: number) => {
    props.onRowSelect?.(id)
  }

  const emptyComponent = props.emptyTableComponent || 'The table is currently empty...'

  return (
    <div
      id={props.id}
      className={'data-grid'}
      data-testid={'DataGrid'}
      style={{
        minHeight:
          props.perPage && !props.minHeight ? `${props.perPage * 60}px` : '300px',
      }}
    >
      <table>
        <thead style={{display: props.withHeader ? 'table-header-group' : 'none'}}>
          <DataGridRow>
            {props.columns.map((col, index) => (
              <DataGridCell
                key={col.key}
                columnKey={col.key}
                header={true}
                sortable={col.sortable}
                selected={props.selectedColumn === col.key}
                onSelect={k => {
                  if (col.sortable) {
                    sortColumn(k)
                  }
                }}
              >
                <div className='table-header-cell-content'>
                  {col.name}

                  {!!col.sortable && (
                    <div className='table-header-cell-icon'>
                      {props.selectedColumn === col.key && (
                        <Icon
                          size={'sm'}
                          icon={props.order === 'asc' ? 'chevron-up' : 'chevron-down'}
                        />
                      )}
                    </div>
                  )}
                </div>
              </DataGridCell>
            ))}
          </DataGridRow>
        </thead>

        <tbody>
          {!props.loading
            ? props.rows.map((row, index) => (
                <DataGridRow
                  id={index}
                  key={row.rowKey ?? index}
                  selectable={props.selectableRows}
                  className={row.rowClassname}
                  onRowSelect={selectRow}
                >
                  {props.columns.map(col => (
                    <DataGridCell key={col.key} className={col.key}>
                      {row[col.key]}
                    </DataGridCell>
                  ))}
                </DataGridRow>
              ))
            : null}
        </tbody>
      </table>

      {props.loading || !props.rows.length ? (
        <div className='empty-wrapper'>
          {props.loading ? <Spinner /> : emptyComponent}
        </div>
      ) : null}
    </div>
  )
}

export default DataGrid
