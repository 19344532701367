import './ProfileHeader.style.scss'
import {FC} from 'react'
import Avatar from '../Avatar'
import classNames from 'classnames'
import Badge from '../Badge'
import {BadgeProps} from '../Badge/Badge'

type ProfileHeaderPersonCardProps = {
  name?: string
  subtitle?: string
  badgeLabel?: string
  badgeTheme?: BadgeProps['theme']
  hideSubtitle?: boolean
  hideBadge?: boolean
  className?: string
}

const ProfileHeaderPersonCard: FC<ProfileHeaderPersonCardProps> = ({
  name = '—',
  subtitle = '—',
  badgeLabel = '—',
  badgeTheme = 'info',
  className = '',
  hideSubtitle,
  hideBadge,
}) => (
  <div className={classNames('d-flex align-center person-card', className)}>
    <Avatar
      withinBorder
      withBorders
      src={'https://www.w3schools.com/howto/img_avatar.png'}
    />
    <div className='flex-column d-flex name-section wrap'>
      <div className='mobile-page-header-20' data-testid='person-name-section'>
        {name}
      </div>
      <div className='d-flex align-center person-card-info wrap'>
        {!hideSubtitle && (
          <div className='body-light-14 person-card-subtitle'>{subtitle}</div>
        )}

        {!hideBadge && (
          <Badge size='sm' theme={badgeTheme}>
            {badgeLabel}
          </Badge>
        )}
      </div>
    </div>
  </div>
)

export default ProfileHeaderPersonCard
