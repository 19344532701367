// InputRadio.tsx

import React, {FC, useRef} from 'react'
import './InputRadio.style.scss'
import {randomString} from '../../functions'

export interface InputRadioProps {
  id?: string
  label?: string
  disabled?: boolean
  invalid?: boolean
  groupName?: string
  value?: string | number
  selected?: string | number
  onValueChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  testId?: string
}

const InputRadio: FC<InputRadioProps> = (props: InputRadioProps) => {
  const id = useRef(randomString()).current

  props = {id, ...props}

  return (
    <div className={'input-radio'} data-testid={props.testId || 'InputRadio'}>
      <input
        type='radio'
        id={props.id}
        className={`${props.invalid ? 'is-invalid' : ''}`}
        disabled={props.disabled}
        name={props.groupName}
        value={props.value}
        checked={props.value === props.selected}
        onChange={props.onValueChange}
      />

      <label className='body-light-14' htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  )
}

export default InputRadio
