import {gql} from '@apollo/client'

export const LOCK_SUBSCRIPTION = gql`
  subscription LockSubscription($filter: LockChannelFilter) {
    lockChannel(filter: $filter) {
      ... on LockChannelMasterPinOperationFailure {
        installedDeviceId
        unitId
        message
        buildingId
        propertyId
        initiatingPersonId
      }
      ... on LockChannelMasterPinOperationSuccess {
        installedDeviceId
        unitId
        buildingId
        propertyId
        initiatingPersonId
      }
      ... on LockChannelUserPinOperationSuccess {
        installedDeviceId
        unitId
        buildingId
        propertyId
        initiatingPersonId
        targetPersonId
        operationType
        asyncTransactionId
      }
      ... on LockChannelUserPinOperationFailure {
        installedDeviceId
        unitId
        buildingId
        propertyId
        initiatingPersonId
        targetPersonId
        operationType
        asyncTransactionId
        message
      }
    }
  }
`
