import './StaffOverview.style.scss'
import Button from '../../components/Button'

import Modal from '../../components/Modal/Modal'
import InputText from '../../components/InputText/InputText'
import {useFormik} from 'formik'
import {string as yupString, object as yupObject} from 'yup'
import {
  clearPhoneNumberFormatting,
  formatPhoneNumber,
  isValidPhoneNumber,
} from '../../functions'
import {useEffect} from 'react'

type TFields = {
  phone: string
}

type TPhoneNumberModalProps = {
  isOpen: boolean
  isLoading: boolean
  onCloseRequest: () => void
  onSubmit: (value: TFields) => void
}

const validationSchema = yupObject().shape({
  phone: yupString()
    .required()
    .label('Phone Number')
    .test('isValidNumber', 'Phone number is not valid', isValidPhoneNumber),
})

const PhoneNumberModal = ({
  isOpen,
  isLoading,
  onCloseRequest,
  onSubmit,
}: TPhoneNumberModalProps) => {
  const {values, errors, touched, setFieldValue, submitForm, resetForm} =
    useFormik<TFields>({
      validationSchema,
      initialValues: {
        phone: '',
      },
      onSubmit,
    })

  useEffect(() => {
    if (!isOpen) {
      resetForm()
    }
  }, [isOpen])

  return (
    <Modal id='employee-phone-modal' isOpen={isOpen} closeModal={onCloseRequest}>
      <h4>Invite employee</h4>

      <form>
        <InputText
          name='phone'
          invalid={!!errors.phone && !!touched.phone}
          invalidMessage={errors.phone}
          label={'Phone number'}
          value={formatPhoneNumber(values.phone)}
          onChange={value => {
            setFieldValue(
              'phone',
              clearPhoneNumberFormatting(formatPhoneNumber(value.target.value)),
            )
          }}
        />

        <div className={'modal-controls'}>
          <Button width='standard' disabled={isLoading} onClick={submitForm}>
            {isLoading ? 'Loading...' : 'Invite'}
          </Button>

          <Button
            width='standard'
            theme={'outline'}
            disabled={isLoading}
            onClick={onCloseRequest}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default PhoneNumberModal
