import {useLazyQuery} from '@apollo/client'
import {GET_PERSON_DEVICE_ACCESSES} from '../data/graphql/queries/people'
import {
  TGetPersonDeviceAccessesResponse,
  TGetPersonDeviceAccessesVariables,
} from '../data/graphql/queries/people/types'
import useToast from './useToast'
import {useCallback} from 'react'
import useRevokeAccessToSelectedLocks from './data/useRevokeAccessToSelectedLocks'

type Props = {
  unitId: number
  personProfileId: number
  cb?: () => void
}

export const useRevokeGuestUnitLockAcceses = ({unitId, personProfileId, cb}: Props) => {
  const {showToast} = useToast()
  const revokeAccess = useRevokeAccessToSelectedLocks()

  const [getPersonAccesses, personAccessesResponse] = useLazyQuery<
    TGetPersonDeviceAccessesResponse,
    TGetPersonDeviceAccessesVariables
  >(GET_PERSON_DEVICE_ACCESSES, {
    variables: {
      condition: {
        personProfileId,
        isActive: true,
        unitId,
      },
    },
  })

  const getRevokeAccessesData = useCallback(async () => {
    const accessesResponse = await getPersonAccesses()
    const allPersonAccesses =
      accessesResponse.data?.transactionalDb?.allPersonAccesses?.nodes
    const installedDeviceIds =
      allPersonAccesses?.map(access => Number(access.installedDeviceId)) || []

    return {
      personProfileId,
      installedDeviceIds,
    }
  }, [getPersonAccesses, personProfileId])

  const revokeYaleAccesses = useCallback(
    async (personProfileId: number, installedDeviceIds: number[]) => {
      try {
        const data = await revokeAccess.revoke(personProfileId, installedDeviceIds)
        const isRevokeFailed = data?.failed?.length

        if (isRevokeFailed) {
          throw new Error('Failed to revoke access to unit locks')
        }

        showToast({
          title: 'Unit access',
          message: 'Access to the unit has been revoked',
          type: 'info',
        })
      } catch (e) {
        showToast({
          title: 'Unit access',
          message: 'Failed to revoke access to unit locks',
          type: 'error',
        })
      }
    },
    [revokeAccess.revoke, showToast],
  )

  const revokeLockAccesses = useCallback(async () => {
    try {
      const {personProfileId, installedDeviceIds} = await getRevokeAccessesData()

      const revokeAcccesesList = [revokeYaleAccesses(personProfileId, installedDeviceIds)]

      await Promise.allSettled(revokeAcccesesList)

      cb?.()
    } catch (e) {
      showToast({
        title: 'Request Error',
        message: 'Failed to revoke user accesses',
        type: 'error',
      })
    }
  }, [getRevokeAccessesData, revokeYaleAccesses, showToast])

  return {
    revokeLockAccesses,
    loading: personAccessesResponse.loading || revokeAccess.loading,
  }
}
