import React from 'react'
import './Row.scss'

export type RowProps = {
  id?: string
  className?: string
  children?: React.ReactNode | React.ReactNode[]
}

const Row: React.FC<RowProps> = ({children, className = '', id}) => {
  return (
    <div id={id || ''} className={`row ${className}`}>
      {children}
    </div>
  )
}

export default Row
