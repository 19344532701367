import {useCallback, useMemo, useState} from 'react'
import {getKeyByValue} from '../functions'

type TSortOrder = 'desc' | 'asc'

type TSortableColumns = {sortKey: string; value: string}[]

const useTableSort = (columns: TSortableColumns, defaultValue = '') => {
  const sortableColumns = useMemo(getSortableColumns, [columns])
  const defaultColumn = getSortDataFromValue(defaultValue)

  const [column, setColumn] = useState(defaultColumn.column)
  const [order, setOrder] = useState<TSortOrder>(defaultColumn.order)
  const [value, setValue] = useState(defaultValue)

  function getSortableColumns() {
    return columns.reduce((result, column) => {
      result[column.sortKey] = column.value

      return result
    }, {})
  }

  function getSortDataFromValue(value: string) {
    const sortKey = getKeyByValue(sortableColumns, value)

    if (sortKey) {
      const [column, order] = sortKey.split(':')
      return {column, order: order as TSortOrder}
    }

    return {
      column: '',
      order: 'asc' as TSortOrder,
    }
  }

  const setSortColumn = useCallback(
    (key: string) => {
      if (column !== key) {
        setColumn(key)
        setOrder('asc')
        setValue(sortableColumns[key + ':asc'])
      } else {
        const newOrder = order === 'asc' ? 'desc' : 'asc'
        setOrder(newOrder)

        setValue(sortableColumns[`${key}:${newOrder}`])
      }
    },
    [order, column, sortableColumns],
  )

  const setSortValue = useCallback(
    (value: string) => {
      const sortData = getSortDataFromValue(value)

      setValue(value)
      setColumn(sortData.column)
      setOrder(sortData.order)
    },
    [sortableColumns],
  )

  return {
    column,
    value,
    order,
    setSortValue,
    setSortColumn,
  }
}

export default useTableSort
