import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import './UploadImage.style.scss'

const UploadImage = () => {
  return (
    <div className='UploadImage d-flex align-center'>
      <div className='image'>
        <div className='line'>
          <Icon icon='image' size='lg' />
        </div>
      </div>
      <div className='content'>
        <Button>Upload Image</Button>
        <div className=' hint body-light-14 black'>
          JPG or PNG. Maximum Upload file size: 10 MB
        </div>
      </div>
    </div>
  )
}

export default UploadImage
