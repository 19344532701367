import React from 'react'
import Accordion from '../../../components/Accordion'
import AccordionBody from '../../../components/Accordion/AccordionBody'
import AccordionHead from '../../../components/Accordion/AccordionHead'
import CollapsibleCheckboxList from '../../../components/CollapsibleCheckboxList'
import {CheckboxItem} from '../../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'

export type TBuildingsFilterProps = {
  title?: string
  value: CheckboxItem[]
  onChange: (value: CheckboxItem[]) => void
}

const CheckboxListFilterNode = ({title, value, onChange}: TBuildingsFilterProps) => {
  return (
    <Accordion transparent>
      <AccordionHead>
        <div className='d-flex space-between align-center'>
          <div className='desktop-label-semibold-14'>{title}</div>
        </div>
      </AccordionHead>

      <AccordionBody>
        <CollapsibleCheckboxList countPreview={5} items={value} update={onChange} />
      </AccordionBody>
    </Accordion>
  )
}

export default CheckboxListFilterNode
