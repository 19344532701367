import {useCallback, useMemo} from 'react'
import {useParams} from 'react-router-dom'

import {DeviceMaker} from '../../data/graphql/types'

import useLockAccesses from '../../hooks/useLockAccesses'
import {TServiceTaskAccess} from '../../layouts/VendorUserLayout/useScheduleAccessPoints'

import {TVendorUserLayoutSubmitData} from '../../layouts/VendorUserLayout/VendorUserLayout'
import {TAccessType} from '../../components/AccessSchedule/AccessSchedule'
import {useMutation} from '@apollo/client'
import {
  TCreatePinOnlyUserResponse,
  TCreatePinOnlyUserVariables,
} from '../../data/graphql/mutations/people/types'
import {CREATE_PIN_ONLY_USER} from '../../data/graphql/mutations/people'
import useVendorUserContext from '../../layouts/VendorUserLayout/VendorUserContext/useVendorContext'
import {TPersonTypeCode, TResidentTypeCode} from '../../data/graphql/queries/common/types'

type TCommonAreaAccess = {
  accessType: TAccessType
  propertyId: string
  startDate?: string
  endDate?: string
}

const useServiceVendorUserOverview = () => {
  const {person, profile, vendor} = useVendorUserContext()
  const profileId = profile ? Number(profile.personProfileId) : 0
  const vendorId = vendor ? Number(vendor.id) : 0
  const personId = person ? Number(person.id) : 0

  const {
    updateAppAccesses,
    updatePinAccesses,
    revokeLockAccesses,
    grantCommonAreaAccesses,
    revokeCommonAreaAccesses,
    refreshPersonAccesses,
  } = useLockAccesses({personId, profileId, personType: TPersonTypeCode.V})

  const [createPinOnlyUserRequest] = useMutation<
    TCreatePinOnlyUserResponse,
    TCreatePinOnlyUserVariables
  >(CREATE_PIN_ONLY_USER)

  const grantPinAccesses = useCallback(
    async (
      input: TVendorUserLayoutSubmitData,
      person?: {
        personId: number
        personProfileId: number
        yaleUserId?: string
      },
    ) => {
      const pinAccesses: TServiceTaskAccess[] = input.requestedLockAccesses
      const commonAreasPinAccesses: TCommonAreaAccess | null =
        input.requestedPropertyCommonAreaAccess?.accessType === 'pin'
          ? input.requestedPropertyCommonAreaAccess
          : null

      return await Promise.allSettled([
        updatePinAccesses(pinAccesses, person?.yaleUserId),
        grantCommonAreaAccesses(commonAreasPinAccesses, person?.personId),
      ])
    },
    [grantCommonAreaAccesses, updatePinAccesses],
  )

  const grantAppAccesses = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      const appAccesses: TServiceTaskAccess[] = input.requestedLockAccesses.filter(
        ({accessLevel}) => accessLevel === 'app',
      )
      const commonAreasAppAccesses: TCommonAreaAccess | null =
        input.requestedPropertyCommonAreaAccess?.accessType === 'app'
          ? input.requestedPropertyCommonAreaAccess
          : null

      return await Promise.allSettled([
        updateAppAccesses({
          accesses: appAccesses,
          personType: TPersonTypeCode.V,
          residentType: TResidentTypeCode.NAR,
        }),
        grantCommonAreaAccesses(commonAreasAppAccesses),
      ])
    },
    [grantCommonAreaAccesses, updateAppAccesses],
  )

  const createPinOnlyUser = useCallback(
    async (
      invitee: TVendorUserLayoutSubmitData['invitee'],
      deviceMaker: DeviceMaker,
      yaleLockId?: string,
    ) => {
      const input: TCreatePinOnlyUserVariables['input'] = {
        deviceMaker,
        email: invitee.email,
        firstName: invitee.firstName,
        lastName: invitee.lastName,
        phone: invitee.mobilePhone,
        propertyId: invitee.propertyId,
        vendorId,
      }

      if (yaleLockId) {
        input.yale = {
          credentialType: 'PIN',
          deviceId: yaleLockId,
        }
      }

      const response = await createPinOnlyUserRequest({
        variables: {
          input,
        },
      })

      const person = response.data?.lock.createPinOnlyUser
      const yaleUserId = person?.yale?.userId

      if (!person || (yaleLockId && !yaleUserId)) {
        throw Error('Failed to create unverified yale user')
      }

      return {
        yaleUserId,
        personId: person.personId,
        personProfileId: person.personProfileId,
      }
    },
    [createPinOnlyUserRequest, vendorId],
  )

  const createBrivoPinUser = useCallback(
    (invitee: TVendorUserLayoutSubmitData['invitee']) => {
      return createPinOnlyUser(invitee, DeviceMaker.BRIVO)
    },
    [createPinOnlyUser],
  )

  const createYalePinUser = useCallback(
    (invitee: TVendorUserLayoutSubmitData['invitee'], yaleLockId?: string) => {
      return createPinOnlyUser(invitee, DeviceMaker.YALE, yaleLockId)
    },
    [createPinOnlyUser],
  )

  const revokeAccesses = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      return await Promise.allSettled([
        revokeCommonAreaAccesses(input.deletedPropertyCommonAreaAccess),
        revokeLockAccesses(input.deletedLockAccesses),
      ])
    },
    [revokeCommonAreaAccesses, revokeLockAccesses],
  )

  const result = useMemo(
    () => ({
      revokeAccesses,
      grantAppAccesses,
      grantPinAccesses,
      createBrivoPinUser,
      createYalePinUser,
      refreshPersonAccesses,
    }),
    [
      createBrivoPinUser,
      createYalePinUser,
      grantAppAccesses,
      grantPinAccesses,
      refreshPersonAccesses,
      revokeAccesses,
    ],
  )

  return result
}

export default useServiceVendorUserOverview
