import {useCallback, useState} from 'react'
import {getPersonTypeById} from '../../../../functions/people.functions'
import useRevokeAccessToSelectedLocks from '../../../../hooks/data/useRevokeAccessToSelectedLocks'
import useToast from '../../../../hooks/useToast'
import ArrayUtils from '../../../../functions/array.functions'

type Access = {
  personProfileId: number
  installedDeviceId: number
}

const useRevokeExistingAccessModal = () => {
  const {showInfoToast, showErrorToast} = useToast()
  const revokeAccess = useRevokeAccessToSelectedLocks()

  const [isVisible, setVisibilityFlag] = useState(false)
  const [personType, setPersonType] = useState('')
  const [accesses, setAccesses] = useState<Access[]>([])

  const open = useCallback((personTypeId: string, access: Access[]) => {
    setAccesses(access)
    setVisibilityFlag(true)
    setPersonType(getPersonTypeById(personTypeId))
  }, [])

  const close = useCallback(() => {
    setVisibilityFlag(false)
    setPersonType('')
    setAccesses([])
  }, [])

  const confirm = useCallback(async () => {
    try {
      const response = await revokeAccess.revoke(
        accesses[0].personProfileId,
        ArrayUtils.extractField(accesses, 'installedDeviceId'),
      )

      if (!response?.revoked.length) {
        throw new Error('Failed to revoke access')
      }

      showInfoToast('Access revoked', 'Access to the unit has been revoked')
    } catch (error) {
      showErrorToast('Access revocation failed', 'Failed to revoke access')
    }

    close()
  }, [revokeAccess.revoke, accesses, close])

  return {
    isLoading: revokeAccess.loading,
    isVisible,
    personType,
    accesses,
    open,
    close,
    confirm,
  }
}

export default useRevokeExistingAccessModal
