import './StructuresFilterNode.style.scss'

import {FC} from 'react'
import Accordion from '../../../../components/Accordion'
import AccordionBody from '../../../../components/Accordion/AccordionBody'
import AccordionHead from '../../../../components/Accordion/AccordionHead'
import CollapsibleCheckboxList from '../../../../components/CollapsibleCheckboxList'
import {CheckboxItem} from '../../../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import InputCheckbox from '../../../../components/InputCheckbox/InputCheckbox'

export type TBuildingFilterNodeItem = CheckboxItem & {
  units: CheckboxItem[]
  containsSelected: boolean
}

export type TBuildingStructureFilterNodeProps = {
  building: TBuildingFilterNodeItem
  propertyId: string
  forbidBildingLevel?: boolean
  onChange: (propertyId: string, value: TBuildingFilterNodeItem) => void
}

const BuildingStructureFilterNode: FC<TBuildingStructureFilterNodeProps> = ({
  building,
  propertyId,
  forbidBildingLevel,
  onChange,
}) => {
  const onChangeUnits = (units: CheckboxItem[]) => {
    const containsSelected = units.some(({checked}) => checked)
    const modifiedBuilding = {
      ...building,
      containsSelected: units.some(({checked}) => checked),
      units: units,
    }

    if (containsSelected) {
      modifiedBuilding.checked = true
    }
    onChange(propertyId, modifiedBuilding)
  }

  const onChangeBuilding = () => {
    const units = building.checked
      ? building.units.map(unit => ({...unit, checked: false}))
      : building.units

    onChange(propertyId, {
      ...building,
      checked: !building.checked,
      units,
      containsSelected: units.some(({checked}) => checked),
    })
  }

  return (
    <Accordion transparent>
      <AccordionHead>
        <div className='d-flex align-center'>
          {!forbidBildingLevel && (
            <InputCheckbox
              isChecked={building.checked || building.containsSelected}
              handleChange={onChangeBuilding}
            />
          )}

          <div className='desktop-label-semibold-14'>{building.name}</div>
        </div>
      </AccordionHead>

      <AccordionBody>
        <CollapsibleCheckboxList
          countPreview={3}
          update={onChangeUnits}
          items={building.units}
        />
      </AccordionBody>
    </Accordion>
  )
}

export default BuildingStructureFilterNode
