import ProfilePicture from '../../ProfilePicture'

interface IProps {
  image?: string
  name?: string
}

function CellWithAvatar({image, name}: IProps) {
  return (
    <div className='d-flex align-center cell-with-avatar' data-testid='CellWithAvatar'>
      <ProfilePicture image={image} size={'sm'} />
      <div className='name' style={{maxWidth: '150px'}}>
        {name || '—'}
      </div>
    </div>
  )
}

export default CellWithAvatar
