import './InstallerAccessPoints.style.scss'

import {FC, useEffect, useState} from 'react'
import Button from '../../../components/Button/Button'
import {CheckboxItem} from '../../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import Modal from '../../../components/Modal/Modal'
import PropertyDevices from '../../../components/PropertyDevices/PropertyDevices'
import {TAccessPointsValue} from '../useAccessPoints'
import useInstallerContext from '../InstallerContext/useIntallerContext'

export type TDevices = TDeviceItem[]

export type TDeviceItem = CheckboxItem & {
  disabled: boolean
  device: {
    code: string
    typeId: string
    description: string
    classTypeId: string
  }
  location: {
    code: string
    typeId: string
    description: string
  }
}

export type TAccessPointDevices = {
  data: TDevices
  propertyId: string
  buildingId: string
  unitId: string
}

export type TStructure = {propertyId: string; buildingId: string; unitId: string}

type TPropertyDevicesModalProps = {
  isOpen: boolean
  vendorUserId?: number
  value: TAccessPointsValue<TDevices>
  structure: TStructure
  defaultDevices: TDevices
  onApply: (devices: TDevices, structure: TStructure) => void
  onCloseRequest: () => void
  getStructureDevices: (structure: TStructure, allDevices: TDevices) => TDevices
}

const PropertyDevicesModal: FC<TPropertyDevicesModalProps> = ({
  isOpen,
  vendorUserId,
  value,
  structure,
  defaultDevices,
  onApply,
  onCloseRequest,
  getStructureDevices,
}) => {
  const installerContext = useInstallerContext()
  const [devices, setDevices] = useState<TDevices>([])
  const [replacements, setReplacements] = useState<string[]>([])

  useEffect(() => {
    if (isOpen) {
      setReplacements(installerContext.replacements)
    } else {
      setReplacements([])
    }
  }, [installerContext.replacements, isOpen])

  const getDevices = () => {
    const {unitId, buildingId, propertyId} = structure

    const property = value[propertyId]
    const building = property?.buildings[buildingId]
    const unit = building?.units?.[unitId]

    const sourceDevices = unit || building?.data || property?.data || defaultDevices

    return getStructureDevices(structure, sourceDevices)
  }

  const replaceDevice = (id: string, inventoryId: string) => {
    setReplacements(prev => [...prev, inventoryId])

    setDevices(
      devices.map(item => {
        if (item.id === id) {
          return {
            ...item,
            disabled: false,
            checked: false,
          }
        }

        return item
      }),
    )
  }

  const cancelDeviceReplacement = (id: string, inventoryId: string) => {
    setReplacements(prev => prev.filter(value => value !== inventoryId))
    const devicesValue = getDevices()

    setDevices(
      devices.map(item => {
        if (item.id === id) {
          const defaultDeviceValue = devicesValue.find(device => device.id === id)

          return {
            ...item,
            disabled: !!defaultDeviceValue?.disabled,
            checked: !!defaultDeviceValue?.checked,
          }
        }

        return item
      }),
    )
  }

  const applyChanges = () => {
    installerContext.replaceDevices(replacements)
    onApply(devices, structure)
  }

  useEffect(() => {
    setDevices(getDevices())
  }, [defaultDevices, getStructureDevices, structure, value])

  return (
    <Modal
      width={'auto'}
      isOpen={isOpen}
      className='access-schedule'
      closeModal={onCloseRequest}
    >
      <PropertyDevices
        vendorUserId={vendorUserId}
        unitId={structure.unitId}
        value={devices}
        onChange={setDevices}
        replacements={replacements}
        onReplace={replaceDevice}
        onCancelReplace={cancelDeviceReplacement}
      />

      <div className='schedule-actions'>
        <Button className='apply-schedule-button' onClick={applyChanges}>
          Ok
        </Button>

        <Button theme='outline' onClick={onCloseRequest}>
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default PropertyDevicesModal
