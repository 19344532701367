// layouts/People/People.tsx

import React, {ReactNode} from 'react'
import './Filters.style.scss'
import Drawer from '../Drawer'
import Button from '../Button'
import Column from '../Grid/Column'

export type TFilterProps<T = any> = {
  children: ReactNode | ReactNode[]
  isOpen: boolean
  onSubmit: (filters: T) => void
  onClear: () => void
  onHide(): void
}

const Filters = ({children, isOpen, onHide, onSubmit, onClear}: TFilterProps) => {
  return (
    <Drawer onClickHide={onHide} drawerPosition={'right'} isOpen={isOpen}>
      <div className='filters' data-testid='Filters'>
        <Column>
          <div className='d-flex space-between filters-top-control filters-header'>
            <h6>Filters</h6>
            <Button theme='link' size='md' onClick={onClear}>
              Clear All
            </Button>
          </div>

          <div className='filters-content'>{children}</div>

          <div className='filters-bottom-control'>
            <Button width='block' onClick={onSubmit}>
              Apply Filters
            </Button>
            <Button theme='link' size='md' onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Column>
      </div>
    </Drawer>
  )
}

export default Filters
