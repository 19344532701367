import React, {FC, ReactElement, useState} from 'react'
import './Accordion.style.scss'
import {randomString} from '../../functions'
import AccordionHead from './AccordionHead'
import classNames from 'classnames'

export interface AccordionProps {
  id?: string
  transparent?: boolean
  children?: React.ReactNode
  className?: string
}

const defaultProps: AccordionProps = {
  id: randomString(),
  className: '',
}

const Accordion: FC<AccordionProps> = (props: AccordionProps) => {
  props = {...defaultProps, ...props}

  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const onActiveChange = index => {
    toggleExpanded()
  }

  if (props.children) {
    React.Children.map(props.children, (child: React.ReactNode, index) => {
      if (
        child &&
        ['object'].indexOf(typeof child) &&
        (child as ReactElement).type === AccordionHead
      ) {
        //
      }
    })
  }

  return (
    <div
      id={props.id}
      className={classNames({
        accordion: true,
        expanded: !!expanded,
        transparent: !!props.transparent,
        [props.className || '']: true,
      })}
      onChange={onActiveChange}
      data-testid={'Accordion'}
    >
      {props.children}
    </div>
  )
}

export default Accordion
