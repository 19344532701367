import './PropertyDevices.style.scss'

import {FC} from 'react'

type TPropertyDeviceLabelProps = {
  deviceName: string
  isInstalled: boolean
  hasReplacement: boolean
  currentVendorId?: number
  assignedVendorId: number | null
  assignedVendorCompanyId: string | null
  onCancel: () => void
  onReplace: () => void
}

const PropertyDeviceLabel: FC<TPropertyDeviceLabelProps> = ({
  isInstalled,
  currentVendorId,
  assignedVendorId,
  assignedVendorCompanyId,
  deviceName,
  hasReplacement,
  onReplace,
  onCancel,
}) => {
  if (
    !isInstalled &&
    assignedVendorCompanyId &&
    Number(assignedVendorId) !== Number(currentVendorId)
  ) {
    return (
      <a
        target='_blank'
        href={`/people/vendors/${assignedVendorCompanyId}/user/${assignedVendorId}`}
        className='link'
        rel='noreferrer'
      >
        {deviceName}
      </a>
    )
  } else if (isInstalled && !hasReplacement) {
    return (
      <span>
        <span className='device-label'>{deviceName}</span>
        <span className='link' onClick={onReplace}>
          Replace
        </span>
      </span>
    )
  } else if (isInstalled && hasReplacement) {
    return (
      <span>
        <span className='device-label'>{deviceName}</span>
        <span className='link' onClick={onCancel}>
          Cancel
        </span>
      </span>
    )
  }

  return <span>{deviceName}</span>
}

export default PropertyDeviceLabel
