import {FC, useMemo} from 'react'
import VendorUserContext, {TVendorUserContext} from './VendorUserContext'
import usePersonProfiles from '../../../hooks/data/usePersonProfiles'
import {PersonType} from '../../../data/graphql/queries/enums'
import useCurrentProfile from '../../../hooks/useCurrentProfile'
import usePersonData from '../../../hooks/data/usePersonData'
import useVendorData from '../../../hooks/data/useVendorData'

type VendorUserContextProviderProps = {
  children: React.ReactElement | React.ReactElement[]
  personId?: string | number
  vendorId?: string | number
}

const VendorUserContextProvider: FC<VendorUserContextProviderProps> = ({
  children,
  personId,
  vendorId,
}) => {
  const adminProfile = useCurrentProfile()
  const personData = usePersonData(personId)
  const vendorData = useVendorData(vendorId)
  const personProfiles = usePersonProfiles({
    variables: {
      condition: {
        propertyId: adminProfile.propertyId,
        personId: Number(personId),
        personTypeId: PersonType.VENDOR,
        isDeleted: false,
      },
    },
  })

  const vendor = useMemo<TVendorUserContext['vendor']>(() => {
    if (!vendorData.data) {
      return null
    }

    return {
      id: vendorData.data.vendorId,
      name: vendorData.data.vendorName,
    }
  }, [vendorData.data])

  const result = useMemo(
    () => ({
      vendor: vendor,
      profile: personProfiles.data?.[0] || null,
      loading: personData.loading || personProfiles.loading || vendorData.loading,
      person: personData.data || null,
    }),
    [
      vendor,
      personProfiles.data,
      personProfiles.loading,
      personData.loading,
      personData.data,
      vendorData.loading,
    ],
  )

  return (
    <VendorUserContext.Provider value={result}>{children}</VendorUserContext.Provider>
  )
}

export default VendorUserContextProvider
