import {useContext} from 'react'
import {AuthContext} from '../../contexts/AuthContext'
import {getAuth, sendPasswordResetEmail} from 'firebase/auth'
import {useMutation, useLazyQuery} from '@apollo/client'
import {UPDATE_PERSON_BY_ID} from '../../data/graphql/queries/auth'
import './UserSettings.style.scss'
import useToast from '../../hooks/useToast'
import useUserPersonId from '../../hooks/useUserPersonId'
import {
  TUpdatePersonByIdResponse,
  TUpdatePersonByIdVariables,
} from '../../data/graphql/queries/auth/types'
import {GET_PORTAL_PEOPLE_COUNT} from '../../data/graphql/queries/people'
import {
  TPortalPeopleCountResponse,
  TPortalPeopleCountVariables,
} from '../../data/graphql/queries/people/types'

const useUserSettingsUpdate = () => {
  const auth: any = getAuth()
  const personId = useUserPersonId()
  const {user, setUser} = useContext(AuthContext)
  const {showToast} = useToast()

  const [updatePersonById] = useMutation<
    TUpdatePersonByIdResponse,
    TUpdatePersonByIdVariables
  >(UPDATE_PERSON_BY_ID, {
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    onCompleted: response => {
      setUser({
        ...user,
        ...response.transactionalDb.updatePersonByPersonId.person,
      })
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Update Person',
        type: 'error',
      })
    },
  })

  const [getPerson] = useLazyQuery<
    TPortalPeopleCountResponse,
    TPortalPeopleCountVariables
  >(GET_PORTAL_PEOPLE_COUNT, {
    variables: {
      condition: {
        email: '',
      },
    },
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Person Data',
        type: 'error',
      })
    },
  })

  const requestPasswordUpdate = async () => {
    return sendPasswordResetEmail(auth, auth.currentUser.email)
  }

  const checkEmailAvailability = async (email = '') => {
    const getPersonResponse = await getPerson({
      variables: {
        condition: {
          isPersonActive: true,
          email: email.toLowerCase(),
        },
      },
    })

    return {
      isAvailable: !getPersonResponse.data?.transactionalDb.people.totalCount,
    }
  }

  const updatePerson = (patch: TUpdatePersonByIdVariables['input']['personPatch']) => {
    return updatePersonById({
      variables: {
        input: {
          personId: Number(personId),
          personPatch: patch,
        },
      },
    })
  }

  return {
    updatePerson,
    requestPasswordUpdate,
    checkEmailAvailability,
  }
}

export default useUserSettingsUpdate
