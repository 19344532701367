import './Note.style.scss'

import React, {FC, useEffect, useState} from 'react'
import Card from '../../../components/Card'
import CardBody from '../../../components/Card/CardBody'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import Icon from '../../../components/Icon/Icon'
import InputTextarea from '../../../components/InputTextarea/InputTextarea'
import Button from '../../../components/Button/Button'
import {useParams} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/client'
import {
  TGetPersonAccessByIdResponse,
  TGetPersonAccessByIdVariables,
} from '../../../data/graphql/queries/people/types'
import {UPDATE_NOTE} from '../../../data/graphql/mutations/people'
import {
  TUpdateNoteResponse,
  TUpdateNoteVariables,
} from '../../../data/graphql/mutations/people/types'
import useToast from '../../../hooks/useToast'
import {GET_PERSON_ACCESS_BY_ID} from '../../../data/graphql/queries/people'

export interface INoteProps {
  name?: string
}

const Note: FC<INoteProps> = ({name}) => {
  const {showToast} = useToast()

  const routeParams = useParams<{personAccessId: string}>()
  const personAccessId = routeParams.personAccessId ? +routeParams.personAccessId : -1

  const personAccessQuery = useQuery<
    TGetPersonAccessByIdResponse,
    TGetPersonAccessByIdVariables
  >(GET_PERSON_ACCESS_BY_ID, {
    variables: {personAccessId},
  })

  const [updateNote, updateNoteResponse] = useMutation<
    TUpdateNoteResponse,
    TUpdateNoteVariables
  >(UPDATE_NOTE, {
    update: () => {
      return personAccessQuery.refetch()
    },
    onError() {
      showToast({
        title: 'Request Failed',
        message: 'Failed to update user notes',
        type: 'error',
      })
    },
  })

  const noteInfo = personAccessQuery.data?.transactionalDb?.personAccess
  const noteText = noteInfo?.notes || ''

  const [value, setValue] = useState(noteText)
  const [isEditing, setEditingFlag] = useState(!noteText)
  const isLoading = updateNoteResponse.loading

  useEffect(() => {
    setValue(noteText)
    setEditingFlag(false)
  }, [noteText])

  const onDelete = () => {
    updateNote({
      variables: {
        input: {
          personAccessId: personAccessId.toString(),
          personAccessPatch: {
            notes: null,
          },
        },
      },
    })
  }

  const onUpdate = () => {
    updateNote({
      variables: {
        input: {
          personAccessId: personAccessId.toString(),
          personAccessPatch: {
            notes: value,
          },
        },
      },
    })
  }

  const onCancel = () => {
    setEditingFlag(false)
  }

  const onEdit = () => {
    setValue(noteText)
    setEditingFlag(true)
  }

  const getSubmitLabel = () => {
    if (isLoading) {
      return 'Loading ...'
    } else if (noteText) {
      return 'Update Note'
    }

    return 'Add Note'
  }

  return (
    <div className='note'>
      {!isEditing && noteText ? (
        <Card padding='none'>
          <CardBody id='card-body-padding-md' padding='none'>
            <CellWithAvatar name={name} />
            <div className='body-small-light-12 black text'>
              <span>{noteText}</span>
            </div>
            <Icon icon={'edit'} className='edit-icon' onClick={onEdit} />
          </CardBody>
        </Card>
      ) : (
        <>
          <InputTextarea
            value={value}
            placeholder='Add notes here'
            id='component-id'
            label=''
            theme='gray'
            onValueChange={setValue}
          />
          <div className='d-flex'>
            <Button disabled={!value || isLoading} onClick={onUpdate}>
              {getSubmitLabel()}
            </Button>

            {noteText && (
              <>
                <Button
                  disabled={isLoading}
                  id='btn-cancel-note'
                  theme='outline'
                  onClick={onDelete}
                >
                  {isLoading ? 'Loading...' : 'Delete Note'}
                </Button>

                <Button id='btn-cancel-note' theme='outline' onClick={onCancel}>
                  Cancel
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(Note)
