// components/InputDate/InputDate.tsx

import React, {FC} from 'react'
import './Hr.style.scss'

export interface HrProps {
  id?: string
}

const defaultProps: HrProps = {}

const Hr: FC<HrProps> = (props: HrProps) => {
  props = {...defaultProps, ...props}

  return <hr id={props.id} className={'Hr'} data-testid={'HrTest'} />
}

export default Hr
