import './ServiceTickets.style.scss'

import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import DataGrid from '../../../../components/DataGrid'
import Column from '../../../../components/Grid/Column'
import Row from '../../../../components/Grid/Row'
import Section from '../../../../components/Grid/Section'
import Paginator from '../../../../components/Paginator'
import Panel from '../../../../components/Panel'
import {SortOptionsItem, useQueryOptions} from '../../../../hooks/useQueryOptions'
import Search from '../../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../../layouts/People/Search/SearchSortBy'
import {formatRelativeTime} from '../../../../functions'
import CrashScreen from '../../../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../../components/ErrorBoundary/ErrorBoundary'
import TableFooter from '../../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../../components/TabelCountItems/TableNumberOfItems'
import ServiceTicketsFilters, {TServiceTicketFilterFields} from './ServiceTicketsFilter'
import {ServiceTicketCategories} from '../../../../data/graphql/queries/properties/types'
import Badge from '../../../../components/Badge/Badge'
import Icon from '../../../../components/Icon/Icon'
import {IconOptions} from '../../../../components/Icon/Icons.d'
import useServiceTickets from '../../../../hooks/data/useServiceTickets'
import {ExportTableContext} from '../../../../components/ExportTable/ExportTableContextProvider'

const categoryIconMap: {[key in ServiceTicketCategories]: IconOptions} = {
  [ServiceTicketCategories.APPLIANCE]: 'tool',
  [ServiceTicketCategories.EXTERIOR]: 'home',
  [ServiceTicketCategories.DOORS_AND_LOCKS]: 'lock',
  [ServiceTicketCategories.ELECTRICAL]: 'zap',
  [ServiceTicketCategories.FLOORING]: 'home',
  [ServiceTicketCategories.GENERAL]: 'tool',
  [ServiceTicketCategories.GROUNDS]: 'image',
  [ServiceTicketCategories.HEATING_AND_COOLING]: 'thermometr',
  [ServiceTicketCategories.INSPECTION]: 'settings',
  [ServiceTicketCategories.PLUMBING]: 'droplet',
  [ServiceTicketCategories.RECREATIONAL]: 'users',
  [ServiceTicketCategories.SAFETY]: 'unlock',
  [ServiceTicketCategories.COMMUNICATIONS]: 'phone',
  [ServiceTicketCategories.PREVENTATIVE_MAINTENANCE]: 'sliders',
  [ServiceTicketCategories.SMART_HOME]: 'home',
}

const sortOptions: SortOptionsItem[] = [
  {value: '', label: 'All '},
  {value: 'MODIFIED_DT_ASC', label: 'Updated Date Asc'},
  {value: 'MODIFIED_DT_DESC', label: 'Updated Date Desc'},
]

type TTicketRecord = {
  name: React.ReactNode
  category: React.ReactNode
  location: string
  description: string
  date: React.ReactNode
}

type TServiceTickets = {
  ticketIds: number[]
}

const ServiceTickets: FC<TServiceTickets> = ({ticketIds}) => {
  const {setQuery} = useContext(ExportTableContext)
  const params = useParams<{propertyId: string}>()
  const propertyId = params.propertyId ? +params.propertyId : -1

  const [ticketsData, setTicketsData] = useState<TTicketRecord[]>([])

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    onChangeNumberOfItems,
    setQueryOptions,
  } = useQueryOptions<TServiceTicketFilterFields>(
    {
      page: 1,
      orderBy: ['MODIFIED_DT_ASC'],
    },
    false,
  )

  const ticketsQuery = useServiceTickets(debouncedSearchTerm, queryOptions, {
    filter: {
      serviceTicketStatusTypeId: {
        in: ticketIds,
      },
    },
    condition: {
      propertyId,
    },
  })

  const {queryForDownloadTable} = ticketsQuery

  useEffect(() => {
    if (ticketsQuery.tickets?.nodes) {
      setTicketsData(
        ticketsQuery.tickets.nodes.map(data => {
          return {
            name: (
              <div className='technician-cell'>
                <Icon
                  theme={'info'}
                  icon={
                    data.categoryTypeId
                      ? categoryIconMap[+data.categoryTypeId]
                      : 'lert-circle'
                  }
                />
                <span className='technician-name'>
                  {data.technicianName || 'No Technician'}
                </span>

                <span className='body-light-14 unit-number'>{data.unitNumber}</span>
              </div>
            ),
            category: (
              <Badge size='sm' theme={'info'}>
                {data.categoryTypeDescription}
              </Badge>
            ),
            location: data.locationTypeDescription || '',
            description: getTicketDescription(data),
            date: (
              <span className='body-light-14 date-cell'>
                {formatRelativeTime(new Date(data.modifiedDt))}
              </span>
            ),
          }
        }),
      )
    } else {
      setTicketsData([])
    }
  }, [ticketsQuery.tickets?.nodes])

  const dataForTableQuery = useCallback(async () => {
    try {
      const {data} = await queryForDownloadTable()

      const ticketsData = data?.transactionalDb.allServiceTickets.nodes || []
      const tableData = ticketsData.map(data =>
        Object.values({
          technicianName: data.technicianName || 'No Technician',
          unitNumber: data.unitByUnitId?.unitNumber || '-',
          category: data.categoryType?.description || '-',
          location: data.location?.description || '-',
          description: getTicketDescription(data),
          date: formatRelativeTime(new Date(data.modifiedDt)),
        }),
      )

      tableData.unshift([
        'Technician Name',
        'Unit Number',
        'Category',
        'Location',
        'Description',
        'Date',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, setQuery, queryOptions])

  const getTicketDescription = (
    ticket: ReturnType<typeof useServiceTickets>['tickets']['nodes'][number],
  ) => {
    let description = ''
    const {issueTypeDescription, serviceComments} = ticket

    if (issueTypeDescription) {
      description += issueTypeDescription

      if (serviceComments) {
        description += ': '
      }
    }

    if (serviceComments) {
      description += serviceComments.charAt(0).toUpperCase() + serviceComments.slice(1)
    }

    return description
  }

  const onSubmitFilter = useCallback(
    (filters: TServiceTicketFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        page: 1,
        filters: filters,
      }))
    },
    [setQueryOptions],
  )

  const onTypeSearchField = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm,
      })
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [upsertQueryOptions],
  )

  const onChangeSortOrder = value => {
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'ServiceTickets'} data-testid={'ServiceTicketsView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search ticketsData'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onTypeSearchField}
                  />
                  <SearchFilters
                    filter={ServiceTicketsFilters}
                    onSubmit={onSubmitFilter}
                  />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>

                <Panel theme={'white'}>
                  <DataGrid
                    loading={ticketsQuery.response.loading}
                    perPage={queryOptions.limit}
                    withHeader={false}
                    columns={[
                      {name: '', key: 'name'},
                      {name: '', key: 'category'},
                      {name: '', key: 'location'},
                      {name: '', key: 'description'},
                      {name: '', key: 'date'},
                    ]}
                    rows={ticketsData}
                  />
                </Panel>
                <TableFooter
                  itemCount={ticketsQuery.tickets?.totalCount}
                  loading={ticketsQuery.response.loading}
                >
                  <Paginator
                    itemCount={ticketsQuery.tickets?.totalCount || 0}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default React.memo(ServiceTickets)
