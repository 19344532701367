import {FC} from 'react'

const ArrowUp: FC = () => (
  <g>
    <line x1='12' y1='19' x2='12' y2='5' />
    <polyline points='5 12 12 5 19 12' />
  </g>
)

export default ArrowUp
