import React, {useEffect, useMemo, useState} from 'react'
import Icon from '../../components/Icon'
import './Toast.style.scss'

export interface ToastProps {
  id?: string
  type: 'error' | 'info'
  title?: string
  message?: string
}

const Toast: React.FC<ToastProps> = ({message, title, type, id}) => {
  const [hide, setHide] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setHide(true)
    }, 4500)
  }, [])

  const ToastIcon = useMemo(() => {
    switch (type) {
      case 'info':
        return <Icon icon={'info'} theme={'info'} size={'lg'} />
      case 'error':
        return <Icon icon={'x-circle'} theme={'error'} size={'lg'} />
    }
  }, [type])

  return (
    <div key={id} className={`toast-wrapper ${hide ? 'hide' : ''}`} id='toast-wrapper'>
      {ToastIcon}
      <div className='toast-content'>
        <h6>{title}</h6>
        <span>{message}</span>
      </div>
    </div>
  )
}

export default Toast
