import {FC} from 'react'

const MoreVertical: FC = () => (
  <g>
    <circle cx='12' cy='12' r='1' />
    <circle cx='12' cy='5' r='1' />
    <circle cx='12' cy='19' r='1' />
  </g>
)

export default MoreVertical
