import {QueryHookOptions, useQuery} from '@apollo/client'
import {GET_PROPERTY} from '../../data/graphql/queries/properties'
import {
  TPropertyResponse,
  TPropertyVariables,
} from '../../data/graphql/queries/properties/types'

const useGetProperty = (
  propertyId?: string,
  options?: QueryHookOptions<TPropertyResponse, TPropertyVariables>,
) => {
  const response = useQuery<TPropertyResponse, TPropertyVariables>(GET_PROPERTY, {
    skip: !propertyId,
    variables: {
      propertyId: Number(propertyId),
    },
    ...options,
  })

  return {
    loading: response.loading,
    data: response?.data?.transactionalDb?.propertyByPropertyId,
    response,
  }
}

export default useGetProperty
