// layouts/People/People.tsx

import './Filters.style.scss'
import React, {useEffect, useMemo, useState} from 'react'
import InputText from '../../../components/InputText/InputText'
import Accordion from '../../../components/Accordion'
import AccordionBody from '../../../components/Accordion/AccordionBody'
import AccordionGroup from '../../../components/Accordion/AccordionGroup'
import AccordionHead from '../../../components/Accordion/AccordionHead'
import CollapsibleCheckboxList from '../../../components/CollapsibleCheckboxList'
import {CheckboxItem} from '../../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import {GET_BUILDINGS_WITH_UNITS} from '../../../data/graphql/queries/common'
import Filters from '../../../components/Filters'
import {useQuery} from '@apollo/client'
import {
  TAllBuidlingsVariables,
  TAllBuildingsWithUnitsResponse,
} from '../../../data/graphql/queries/common/types'
import useToast from '../../../hooks/useToast'

export type TPeopleFilterParams = {
  unitId?: number
}

export type TFilterProps<T = any> = {
  isOpen: boolean
  onHide(): void
  onSubmit?: (value: T) => void
  propertyId?: number
}

const PeopleFilters = ({isOpen, onHide, onSubmit, propertyId}: TFilterProps) => {
  const {showToast} = useToast()
  const [units, setUnits] = useState<CheckboxItem[]>([])

  const allUnits = useQuery<TAllBuildingsWithUnitsResponse, TAllBuidlingsVariables>(
    GET_BUILDINGS_WITH_UNITS,
    {
      variables: {
        condition: {
          isActive: true,
          isDeleted: false,
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Units Data',
          type: 'error',
        })
      },
    },
  )

  const buildingsList = allUnits.data?.transactionalDb.allBuildings.nodes

  useEffect(() => {
    initUnitsList()
  }, [buildingsList])

  const initUnitsList = () => {
    if (buildingsList) {
      const newUnits: CheckboxItem[] = []

      buildingsList.forEach(building => {
        building.unitsByBuildingId.nodes.forEach(unit => {
          newUnits.push({
            id: +unit.unitId,
            name: `${unit.unitNumber} / ${building.buildingName}`,
          })
        })
      })

      setUnits(newUnits)
    }
  }

  const onApplyFilter = () => {
    const unitId = units.find(({checked}) => !!checked)?.id

    onSubmit?.({
      unitId,
    })
  }

  const onClearFilter = () => {
    initUnitsList()
  }

  const selectedUnit = useMemo(() => units.find(({checked}) => !!checked), [units])

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <div className='filters-content'>
        <InputText
          id={'people-search'}
          type={'search'}
          placeholder={`Search`}
          theme={'default-inverted'}
          value={''}
          suffixIcon={'search'}
          size='xs'
          onValueChange={() => {
            //
          }}
        />
      </div>
      <AccordionGroup>
        <Accordion transparent>
          <AccordionHead>
            <div className='d-flex space-between align-center'>
              <div className='desktop-label-semibold-14'>Unit</div>
              <div className='body-small-light-12'>
                {selectedUnit ? selectedUnit.name : 'None'}
              </div>
            </div>
          </AccordionHead>
          <AccordionBody>
            <CollapsibleCheckboxList
              multiple={false}
              countPreview={5}
              items={units}
              update={setUnits}
            />
          </AccordionBody>
        </Accordion>
      </AccordionGroup>
    </Filters>
  )
}

export default PeopleFilters
