import {FC} from 'react'

const Thermometr: FC = () => (
  <path
    strokeLinejoin='round'
    strokeLinecap='round'
    d='M15.014 14.79V3.506a2.505 2.505 0 1 0-5.01 0v11.282a4.509 4.509 0 1 0 5.01 0z'
  />
)

export default Thermometr
