import './ActivityFilters.style.scss'

import Filters from '../../../components/Filters'
import useActivityEventsFilter from '../../../hooks/filters/useActivityEventsFilter'
import CheckboxListFilterNode from '../../../forms/Filters/FilterNodes/CheckboxListFilterNode'
import useDateRangeFilter from '../../../hooks/filters/useDateRangeFilter'
import DateRangeFilterNode from '../../../forms/Filters/FilterNodes/DateRangeFilterNode'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'
import {FC} from 'react'

export type TActivityFilterFields = {
  events?: string[]
  dateStart?: string
  dateEnd?: string
}

const ActivityFilters: FC<TFilterComponentProps<TActivityFilterFields>> = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}) => {
  const [events, setEvents] = useActivityEventsFilter()
  const [dateRange, setDateRange] = useDateRangeFilter()

  const onApplyFilter = () => {
    const eventIds = events.filter(({checked}) => !!checked).map(({id}) => id.toString())

    onSubmit?.({
      events: eventIds.length ? eventIds : undefined,
      dateStart: dateRange.from || undefined,
      dateEnd: dateRange.to || undefined,
    })
  }

  const onClearFilter = () => {
    setEvents(events.map(({id, name}) => ({id, name, checked: false})))
    setDateRange({to: '', from: ''})
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <CheckboxListFilterNode title={'Event type'} value={events} onChange={setEvents} />
      <DateRangeFilterNode title={'Date'} value={dateRange} onChange={setDateRange} />
    </Filters>
  )
}

export default ActivityFilters
