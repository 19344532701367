import React, {createContext, useCallback, useMemo, useState} from 'react'
import Toast, {ToastProps} from '../components/Toast/Toast'
import ToasterContainer from '../components/Toast/ToasterContainer'
import {v4 as uuidv4} from 'uuid'

export interface ToastContextType {
  showToast: (props: ToastProps) => void
  showErrorToast: (title: string, message?: string) => void
  showInfoToast: (title: string, message?: string) => void
}

export const ToastContext = createContext<ToastContextType>({
  showToast: () => null,
  showErrorToast: () => null,
  showInfoToast: () => null,
})

const ToastProvider: React.FC<{children: any}> = ({children}) => {
  const [toasts, setToasts] = useState<ToastProps[]>([])

  const showToast = useCallback((props: ToastProps) => {
    const id = uuidv4()
    const toast = {...props, id}

    setToasts(toasts => [...toasts, toast])

    setTimeout(() => {
      setToasts(prev => {
        return prev.filter(item => item.id !== id)
      })
    }, 5000)
  }, [])

  const showErrorToast = useCallback(
    (title: string, message = '') => {
      showToast({
        title,
        message,
        type: 'error',
      })
    },
    [showToast],
  )

  const showInfoToast = useCallback(
    (title: string, message = '') => {
      showToast({
        title,
        message,
        type: 'info',
      })
    },
    [showToast],
  )

  const value = useMemo(
    () => ({
      showToast,
      showErrorToast,
      showInfoToast,
    }),
    [showInfoToast, showErrorToast, showToast],
  )

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToasterContainer>
        {toasts.map((props, index) => (
          <Toast {...props} key={index} />
        ))}
      </ToasterContainer>
    </ToastContext.Provider>
  )
}

export default ToastProvider
