import {gql} from '@apollo/client'

export const ALL_LOCK_ACTIVITY_LOGS = gql`
  query AllLockActivityLogs(
    $first: Int
    $orderBy: [LockActivityLogsOrderBy!]
    $offset: Int
    $condition: LockActivityLogCondition
    $filter: LockActivityLogFilter
  ) {
    transactionalDb {
      allLockActivityLogs(first: $first, orderBy: $orderBy, offset: $offset, condition: $condition, filter: $filter) {
        nodes {
          lockId
          unitByUnitId {
            unitId
            unitNumber

            building: buildingByBuildingId {
              buildingName
              property: propertyByPropertyId {
                propertyName
              }
            }
          }
          action
          status
          firstName
          lastName
          lockActivityLogId
          device
          event
          state
          timeStamp
          eventType
          userId
          email
          phone
          message
          transactionId
          reason
          createdDt
          personId
          eventId
          step
          error
          errorName
          originalPayload
        }
        totalCount
        pageInfo {
          startCursor
          hasNextPage
          hasPreviousPage
          endCursor
        }
      }
    }
  }
`

export const GET_PERSON_DETAILS = gql`
  query GetPerson($personId: BigInt!, $condition: PersonUnitCondition) {
     transactionalDb {
        personByPersonId(personId: $personId) {
        firstName
        email
        mobilePhone
        homePhone
        lastName
        isActive
          personUnitsByPersonId(condition: $condition) {
            nodes {
              unitByUnitId {
                buildingByBuildingId {
                  propertyByPropertyId {
                    propertyName
                  }
                }
                unitNumber
              }
            }
          }
        }
    }
  }
`

export const ALL_LOCK_ACTIVITY_VIEWS_LOGS = gql`
  query AllLockActivityLogsView(
    $first: Int
    $orderBy: [LockActivityViewsOrderBy!]
    $offset: Int
    $condition: LockActivityViewCondition
    $filter: LockActivityViewFilter
  ) {
    transactionalDb {
      allLockActivityViews(first: $first, orderBy: $orderBy, offset: $offset, condition: $condition, filter: $filter) {
        nodes {
          buildingName
          buildingId
          propertyName
          propertyId
          lockActivityLogId
          tenantUuid
          unitId
          unitNumber
          personId
          timeStamp
          device
          event
          eventType
          eventId
          userId
          lockId
          email
          fullName
          firstName
          lastName
          phone
          createdDt
          warningLevel
          createdId
          message
          step
          transactionId
          callingUserId
          state
          action
          reason
          otherUserId
          syncType
          status
          pinSecret
          error
          errorName
          originalPayload
        }
        totalCount
        pageInfo {
          startCursor
          hasNextPage
          hasPreviousPage
          endCursor
        }
      }
    }
  }
`

export const ALL_ACTIVITY_LOGS_DATES = gql`
  query AllLockActivityLogs($condition: LockActivityLogCondition, $filter: LockActivityLogFilter) {
    transactionalDb {
      allLockActivityLogs(condition: $condition, filter: $filter) {
        nodes {
          timeStamp
        }
      }
    }
  }
`
