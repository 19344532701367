export const getAccessTypeByCode = (code = '') => {
  const types = {
    NC: 'Unknown',
    O: 'Owner',
    R: 'Resident',
    G: 'Guest',
    P: 'Pin Code Entry',
    PA: 'Property Account',
    BA: 'Building Account',
  }

  return types[code] || ''
}

export const getPersonTypeByCode = (code = '') => {
  const types = {
    NC: 'Unknown',
    T: 'Tenant',
    R: 'Resident',
    V: 'Vendor',
    E: 'Employee',
    C: 'Contractor',
    G: 'Guest',
  }

  return types[code] || ''
}

export const getResidentTypeByCode = (code = '') => {
  const types = {
    NC: 'Unknown',
    G: 'Guarantor',
    HOH: 'Head of Household',
    CHOH: 'Co-Head of Household',
    AR: 'Adult Resident',
    CR: 'Child Resident',
  }

  return types[code] || ''
}
