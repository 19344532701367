import {useMemo} from 'react'

import {arrayToObject} from '../../../functions'
import {isCommonAreaBuilding} from '../../../functions/devices.function'
import useServicePersonAccesses from '../../../hooks/data/useServicePersonAccesses'

type TOtherPersonProfileAccessData = {
  personTypeId: number
}

type TUnitId = string
type TBuildingId = string
type TPropertyId = string

type TBuilding = {
  units: Record<TUnitId, TOtherPersonProfileAccessData | null>
  data?: TOtherPersonProfileAccessData
}

type TOtherPersonProfileAccesses = {
  units: Record<TUnitId, TOtherPersonProfileAccessData>
  structures: Record<
    TPropertyId,
    {
      data?: TOtherPersonProfileAccessData
      buildings: Record<TBuildingId, TBuilding>
    }
  >
}

const useOtherPersonProfilesAccess = (data: {personId?: number; profileId?: number}) => {
  const {profiles, accesses} = useServicePersonAccesses({
    personId: data.personId ? Number(data.personId) : 0,
  })

  const profilesMap = useMemo(() => {
    return arrayToObject(profiles, 'personProfileId')
  }, [profiles])

  const remoteSchedule = useMemo(() => {
    if (!accesses) {
      return {
        units: {},
        structures: {},
      }
    }

    return accesses.reduce<TOtherPersonProfileAccesses>(
      (result, current) => {
        const {
          propertyId,
          buildingId,
          buildingByBuildingId,
          unitId,
          miscInfo,
          personProfileId,
        } = current

        if (
          Number(personProfileId) === Number(data.profileId) ||
          (!miscInfo?.commonAreaLockAccess && !miscInfo?.lockAccess)
        ) {
          return result
        }

        if (!result.structures[propertyId]) {
          result.structures[propertyId] = {
            buildings: {},
          }
        }

        if (!result.structures[propertyId].buildings[buildingId]) {
          result.structures[propertyId].buildings[buildingId] = {
            units: {},
          }
        }

        if (isCommonAreaBuilding(buildingByBuildingId)) {
          result.structures[propertyId].buildings[buildingId].data = {
            personTypeId: profilesMap[personProfileId].personTypeId,
          }
          result.structures[propertyId].buildings[buildingId].units[unitId] = null
        } else {
          result.structures[propertyId].buildings[buildingId].units[unitId] = {
            personTypeId: profilesMap[personProfileId].personTypeId,
          }
        }

        result.units[unitId] = {
          personTypeId: profilesMap[personProfileId].personTypeId,
        }

        return result
      },
      {
        units: {},
        structures: {},
      },
    )
  }, [accesses, data.profileId, profilesMap])

  return {
    data: remoteSchedule,
  }
}

export default useOtherPersonProfilesAccess
