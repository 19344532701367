import './People.style.scss'

import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import Row from '../../components/Grid/Row'
import Column from '../../components/Grid/Column'
import Section from '../../components/Grid/Section'
import Tabbar from '../../components/Tabbar'
import ExportTable from '../../components/ExportTable/ExportTable'
import {capitalize} from '../../functions'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'

const PeopleLayout = () => {
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState('residents')

  useEffect(() => {
    const pathname = location.pathname

    setSelectedTab(pathname.split('/')[pathname.split('/').length - 1])
  }, [location.pathname])

  const tabs = [
    {label: 'Residents', key: 'residents', to: '/people/residents'},
    {label: 'Guests', key: 'guests', to: '/people/guests'},
    {label: 'Staff', key: 'staff', to: '/people/staff'},
    {label: 'Vendors', key: 'vendors', to: '/people/vendors'},
    {
      label: 'Pending Resident Invitations',
      key: 'pending-invite',
      to: '/people/pending-invite',
    },
    {
      label: 'Import Failures',
      key: 'import-failures',
      to: '/people/import-failures',
    },
  ]

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'people-layout'} data-testid={'PeopleLayout'}>
        <Section spacing={'md'}>
          <div className='d-flex space-between align-center'>
            <h3 className={'color-black overview-title'}>People</h3>
            <ExportTable fileName={capitalize(selectedTab)} />
          </div>
        </Section>

        <Section>
          <Row>
            <Column>
              <div className='d-flex align-end tabbar-place'>
                <Tabbar tabs={tabs} selected={selectedTab} onTabChange={setSelectedTab} />
              </div>

              <Outlet />
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default PeopleLayout
