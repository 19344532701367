import {FC} from 'react'

const Loader: FC = () => (
  <g>
    <path d='M12 2V6' />
    <path d='M12 18V22' />
    <path d='M4.92993 4.92993L7.75993 7.75993' />
    <path d='M16.24 16.24L19.07 19.07' />
    <path d='M2 12H6' />
    <path d='M18 12H22' />
    <path d='M4.92993 19.07L7.75993 16.24' />
    <path d='M16.24 7.75993L19.07 4.92993' />
  </g>
)

export default Loader
