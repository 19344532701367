import {useCallback, useMemo} from 'react'
import Icon from '../../../components/Icon'
import {IconProps} from '../../../components/Icon/Icon'
import './Tile.style.scss'
import {useDevicesStatus} from '../../../hooks/data/useDevicesStatus'
import {useNavigate} from 'react-router-dom'

type TileData = {
  icon: IconProps['icon']
  theme: IconProps['theme']
  count: number
  description: string
  link: string
}

const Tile = () => {
  const {counters} = useDevicesStatus()
  const navigate = useNavigate()

  const tiles = useMemo<TileData[]>(() => {
    return [
      {
        icon: 'zap',
        theme: 'warning',
        count: counters.locksLowBattery,
        description: 'Locks with Low Battery',
        link: '/devices/locks?limit=10&page=1&orderBy=DEVICE_BATTERY_LEVEL_ASC&searchTerm=',
      },
      {
        icon: 'lock',
        theme: 'info',
        count: 0,
        description: 'Jammed Locks',
        link: '/devices/locks',
      },
      {
        icon: 'wifi-off',
        theme: 'danger',
        count: counters.locksOffline,
        description: 'Locks Offline',
        link: '/devices/locks?limit=10&page=1&orderBy=DEVICE_STATUS_CURRENT_ASC&searchTerm=',
      },
      {
        icon: 'thermometr',
        theme: 'info',
        count: counters.theromstatsOffline,
        description: 'Thermostats Offline',
        link: '/devices/thermostats',
      },
    ]
  }, [counters])

  const handleClick = useCallback(
    (link: string) => {
      navigate(link)
    },
    [navigate],
  )

  return (
    <div className='Tile'>
      {tiles.map((tile, index) => (
        <div
          data-testid={'tile-box'}
          key={index}
          id={'box-' + index}
          className='box d-flex align-center'
          onClick={() => handleClick(tile.link)}
        >
          <Icon
            size='md'
            icon={tile.icon as IconProps['icon']}
            theme={tile.theme as IconProps['theme']}
          />
          <div className='info d-flex flex-column'>
            <h5 className='black'>{tile.count}</h5>
            <div className='body-small-light-12'>{tile.description}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Tile
