import {useQuery} from '@apollo/client'
import {GET_PROPERTIES_WITH_BUILDINGS} from '../../data/graphql/queries/properties'
import {
  TGetPropertiesWithBuildingsRespone,
  TGetPropertiesWithBuildingsVariables,
} from '../../data/graphql/queries/properties/types'

const usePropertiesWithBuildings = () => {
  const query = useQuery<
    TGetPropertiesWithBuildingsRespone,
    TGetPropertiesWithBuildingsVariables
  >(GET_PROPERTIES_WITH_BUILDINGS, {
    variables: {
      propertiesCondition: {
        isActive: true,
        isDeleted: false,
      },
      buildingsCondition: {
        isActive: true,
        isDeleted: false,
      },
    },
  })

  const data = query.data?.transactionalDb.properties.nodes

  return {
    loading: query.loading,
    data,
    query,
  }
}

export default usePropertiesWithBuildings
