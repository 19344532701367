import './PendingInvite.style.scss'
import React, {useCallback, useContext, useEffect, useState} from 'react'

import Column from '../../../components/Grid/Column'
import Panel from '../../../components/Panel'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import DataGrid from '../../../components/DataGrid'
import Paginator from '../../../components/Paginator'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import Search from '../../../layouts/People/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import ResidentsFilters, {TResidentFilterFields} from './PendingInviteFilters'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import usePendingInvitesData from '../../../hooks/data/usePendingInvitesData'
import {getResidentTypeByCode} from '../../../functions/types.function'
import {formatPhoneNumber, limitString} from '../../../functions'

import usePendingUserInvite from './usePendingUserInvite'
import useToast from '../../../hooks/useToast'
import Spinner from '../../../components/Spinner/Spinner'
import {TPersonTypeCode} from '../../../data/graphql/queries/common/types'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'
import PendingInviteCell from './PendingInviteCell'
import useResidentCurrentAccesses from './useResidentCurrentAccesses'
import RevokeExistingAccess from './RevokeExistingAccessModal'
import useRevokeExistingAccessModal from './RevokeExistingAccessModal/useRevokeExistingAccessModal'

export interface TResidentPreview {
  name: string
  nameWithAvatar: React.ReactNode
  property: string
  building: string
  unit: string
  unitId: string
  id: string
  residentType: string
  email: string
  phone: string
  invite?: React.ReactNode
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'nameWithAvatar:asc', value: 'PERSON_NAME_ASC', label: 'Name Asc'},
  {sortKey: 'nameWithAvatar:desc', value: 'PERSON_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'phone:asc', value: 'MOBILE_PHONE_ASC', label: 'Phone Asc'},
  {sortKey: 'phone:desc', value: 'MOBILE_PHONE_DESC', label: 'Phone Desc'},
  {sortKey: 'email:asc', value: 'EMAIL_ASC', label: 'Email Asc'},
  {sortKey: 'email:desc', value: 'EMAIL_DESC', label: 'Email Desc'},
  {sortKey: 'building:asc', value: 'BUILDING_NAME_ASC', label: 'Building Asc'},
  {sortKey: 'building:desc', value: 'BUILDING_NAME_DESC', label: 'Building Desc'},
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Desc'},
  {
    sortKey: 'residentType:asc',
    value: 'RESIDENT_TYPE_ASC',
    label: 'Resident Type Asc',
  },
  {
    sortKey: 'residentType:desc',
    value: 'RESIDENT_TYPE_DESC',
    label: 'Resident Type Desc',
  },
]

const PendingInvite = () => {
  const {showToast} = useToast()
  const {loading: loadingInvite, invite: inviteUser} = usePendingUserInvite()
  const {setQuery} = useContext(ExportTableContext)
  const [residents, setResidents] = useState<TResidentPreview[]>([])

  const revokeAccessModal = useRevokeExistingAccessModal()

  const {
    queryOptions,
    setQueryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    onChangeNumberOfItems,
  } = useQueryOptions<TResidentFilterFields>({
    page: 1,
    orderBy: ['PERSON_NAME_ASC'],
    searchTerm: '',
    filters: {},
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    invitedEmails,
    installedDevices,
    residents: accessesList,
    response: residentsResponse,

    invitesAndDevicesResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = usePendingInvitesData(debouncedSearchTerm, queryOptions)

  const currentAccesses = useResidentCurrentAccesses()

  useEffect(() => {
    if (accessesList) {
      setResidents(
        accessesList.map((access, index) => {
          const residentType = getResidentTypeByCode(access.residentTypeCode)

          return {
            name: access.personName,
            nameWithAvatar: <CellWithAvatar name={access.personName} />,
            property: access.propertyName,
            building: access.buildingName,
            unit: access.unitNumber,
            residentType: residentType,
            email: limitString(access.email, 35),
            phone: formatPhoneNumber(access.mobilePhone) || ' - ',
            unitId: access.unitId,
            id: access.personId,
            invite: (
              <PendingInviteCell
                index={index}
                person={access}
                invitedEmails={invitedEmails}
                installedDevices={installedDevices[access.unitId]}
                onPress={onPressInvite}
              />
            ),
          }
        }),
      )
    } else {
      setResidents([])
    }
  }, [accessesList, installedDevices, invitedEmails])

  async function onPressInvite(rowIndex: number) {
    if (!accessesList) {
      return
    }

    try {
      const resident = accessesList[rowIndex]

      const currnetAccesses = await currentAccesses.fetch({
        unitId: resident.unitId,
        personId: Number(resident.personId),
      })

      if (currnetAccesses?.length) {
        revokeAccessModal.open(
          currnetAccesses[0].profile.personTypeId,
          currnetAccesses.map(({installedDeviceId, profile}) => ({
            installedDeviceId: +installedDeviceId,
            personProfileId: +profile.id,
          })),
        )
      } else {
        await inviteUser(resident, installedDevices[resident.unitId])

        invitesAndDevicesResponse.updateQuery(data => {
          if (data?.transactionalDb?.allAppInvites) {
            const newInvites = [...data.transactionalDb.allAppInvites.nodes]
            newInvites.push({
              email: resident.email,
              mobilePhone: resident.mobilePhone,
              miscInfo: {personTypeCode: TPersonTypeCode.R},
            })
            return {
              ...data,
              transactionalDb: {
                ...data.transactionalDb,
                allAppInvites: {
                  ...data.transactionalDb.allAppInvites,
                  nodes: newInvites,
                },
              },
            }
          }
          return data
        })

        showToast({
          title: 'Success',
          message: 'Invite has been sent',
          type: 'info',
        })
      }
    } catch (e) {
      console.log(e)
      showToast({
        title: 'Request Error',
        message: 'Failed to invite user',
        type: 'error',
      })
    }
  }

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions({orderBy: [value]})
  }

  const onChangeSearchQuery = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions({
        searchTerm,
        page: 1,
      })
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [setQueryOptions],
  )

  const onSubmitFilter = useCallback(
    (filters: TResidentFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const dataForTableQuery = useCallback(async () => {
    try {
      const {accessesList, invitedEmails} = await queryForDownloadTable()

      const tableData = accessesList.map(access => {
        const residentType = getResidentTypeByCode(access.residentType)
        const invited = invitedEmails.includes(access.email)

        return Object.values({
          name: access.personName,
          residentType: residentType,
          phone: formatPhoneNumber(access.mobilePhone) || '-',
          email: access.email,
          building: access.buildingName,
          unit: access.unitNumber,
          invite: invited ? 'Invited' : 'Not invited',
        })
      })

      tableData.unshift([
        'Name',
        'Resident Type',
        'Phone',
        'Email',
        'Building',
        'Unit',
        'Invited',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryVariables])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const emptyPendingInvites = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Pending invites table is empty...',
  })

  const itemCount = residentsResponse.data?.db.residents.totalCount || 0
  const gridLoading = residentsResponse.loading || invitesAndDevicesResponse.loading

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'PendingInviteOverview'} data-testid={'PendingInviteOverview'}>
        <RevokeExistingAccess
          loading={revokeAccessModal.isLoading}
          personType={revokeAccessModal.personType}
          accesses={revokeAccessModal.accesses}
          isOpen={revokeAccessModal.isVisible}
          onCancel={revokeAccessModal.close}
          onConfirm={revokeAccessModal.confirm}
        />

        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search residents'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onChangeSearchQuery}
                  />
                  <SearchFilters
                    filter={ResidentsFilters}
                    initialValue={queryOptions.filters}
                    onSubmit={onSubmitFilter}
                  />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>

                <Panel theme={'white'}>
                  <DataGrid
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    selectableRows={false}
                    loading={gridLoading}
                    columns={[
                      {name: 'Name', key: 'nameWithAvatar', sortable: true},
                      {name: 'Resident Type', key: 'residentType', sortable: true},
                      {name: 'Phone', key: 'phone', sortable: true},
                      {name: 'Email', key: 'email', sortable: true},
                      {name: 'Building', key: 'building', sortable: true},
                      {name: 'Unit', key: 'unit', sortable: true},
                      {name: '', key: 'invite'},
                    ]}
                    emptyTableComponent={emptyPendingInvites}
                    rows={residents}
                  />

                  {loadingInvite && (
                    <div className='spinner-overlay'>
                      <Spinner />
                    </div>
                  )}
                </Panel>

                <TableFooter itemCount={itemCount} loading={gridLoading}>
                  <Paginator
                    itemCount={itemCount}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default PendingInvite
