import './Notes.style.scss'

import Column from '../../../../components/Grid/Column'
import Row from '../../../../components/Grid/Row'
import Section from '../../../../components/Grid/Section'
import CrashScreen from '../../../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../../components/ErrorBoundary/ErrorBoundary'
import Badge from '../../../../components/Badge/Badge'
import Card from '../../../../components/Card/Card'
import ProfilePicture from '../../../../components/ProfilePicture/ProfilePicture'
import {useQuery} from '@apollo/client'
import {GET_ALL_NOTES} from '../../../../data/graphql/queries/people'
import {
  TGetNotesResponse,
  TGetNotesVariables,
} from '../../../../data/graphql/queries/people/types'
import {formatRelativeTime} from '../../../../functions'
import {useNavigate, useParams} from 'react-router-dom'
import React, {useEffect} from 'react'

const Notes = () => {
  const navigate = useNavigate()
  const {propertyId} = useParams<{propertyId: string}>()

  const notesQuery = useQuery<TGetNotesResponse, TGetNotesVariables>(GET_ALL_NOTES, {
    variables: {
      first: 10,
      condition: {
        propertyId: propertyId ? +propertyId : -1,
        isActive: true,
        isDeleted: false,
      },
      filter: {
        notes: {
          isNull: false,
        },
      },
    },
  })

  useEffect(() => {
    notesQuery.refetch()
  }, [])

  const notes = notesQuery.data?.transactionalDb.allPersonAccesses

  const onPressNote = (personAccessId: string | undefined) => {
    if (personAccessId) {
      navigate(`/people/guests/${personAccessId}`)
    }
  }

  const getFullName = (
    person: null | {
      firstName: string
      lastName: string
    },
  ) => {
    if (!person) {
      return ''
    }

    return `${person.firstName} ${person.lastName}`
  }

  if (!notes?.nodes.length) {
    return null
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className='OverviewNotes'>
        <Section spacing='none'>
          <Row>
            <Column>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h6 className='h6' style={{marginRight: 10}}>
                  Guests Notes
                </h6>
                <Badge size={'sm'} theme={'info'}>
                  {notes?.nodes.length}
                </Badge>
              </div>
            </Column>
          </Row>

          <Row>
            {notes?.nodes.map(
              ({notes, personByPersonId, modifiedDt, personAccessId}, index) => (
                <Column xl={'6'} key={index}>
                  <Card onClick={() => onPressNote(personAccessId)}>
                    <div className='noteCard'>
                      <ProfilePicture image={''} size={'sm'} />

                      <div className='noteCardInfo'>
                        <div className='noteCardHeader'>
                          <span className='noteCardName desktop-label-semibold-14'>
                            {getFullName(personByPersonId)}
                          </span>
                          <span className='noteCardTime desktop-meta-data-medium-italic-10'>
                            {formatRelativeTime(new Date(modifiedDt))}
                          </span>
                        </div>
                        <span className='noteCardDescription body-small-light-12'>
                          {notes?.substring(0, 100)}
                        </span>
                      </div>
                    </div>
                  </Card>
                </Column>
              ),
            )}
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default React.memo(Notes)
