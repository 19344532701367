import {FC} from 'react'
import classNames from 'classnames'
import Button from '../Button'

type ProfileHeaderButtonCardProps = {
  label: string
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const ProfileHeaderButtonCard: FC<ProfileHeaderButtonCardProps> = ({
  label,
  onClick,
  className = '',
}) => (
  <div className={classNames('d-flex action-section', className)}>
    <Button onClick={onClick}>{label}</Button>
  </div>
)

export default ProfileHeaderButtonCard
