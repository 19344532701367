import React, {useState} from 'react'
import Button from '../Button'
import InputCheckbox from '../InputCheckbox/InputCheckbox'
import './CollapsibleCheckboxList.style.scss'

export interface CheckboxItem {
  id: number | string
  name: string
  checked?: boolean
  disabled?: boolean
}

export interface CollapsibleCheckboxListProps {
  items: CheckboxItem[]
  countPreview: number
  multiple?: boolean
  update?(items: CheckboxItem[]): void
  renderLabel?: (id?: string | number) => React.ReactNode
}

const CollapsibleCheckboxList: React.FC<CollapsibleCheckboxListProps> = ({
  items,
  renderLabel,
  countPreview,
  multiple = true,
  update,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleCheckboxChange = (item: CheckboxItem) => {
    const checkboxes = items.map(el => {
      const checked = multiple ? el.checked : false

      return {
        ...el,
        checked: item.id === el.id ? !el.checked : checked,
      }
    })

    if (update) {
      update(checkboxes)
    }
  }

  const handleViewAllClick = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={`collapsible-checkbox-list`}>
      <ul className={`collapsible-list`}>
        {items.slice(0, isExpanded ? items.length : countPreview).map(item => (
          <li key={item.id}>
            <InputCheckbox
              id={item.id.toString()}
              isChecked={!!item.checked}
              handleChange={() => handleCheckboxChange(item)}
              label={item.name}
              renderLabel={renderLabel}
              disabled={item.disabled}
            />
          </li>
        ))}
      </ul>

      {items.length > countPreview && (
        <Button theme='link' size='md' onClick={handleViewAllClick}>
          {!isExpanded ? 'View All...' : 'Hide...'}
        </Button>
      )}
    </div>
  )
}

export default CollapsibleCheckboxList
