import Filters from '../../../components/Filters'
import AccordionGroup from '../../../components/Accordion/AccordionGroup'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'
import RadioButtonFilterNode from '../../../forms/Filters/FilterNodes/RadioButtonFilterNode'
import useBooleanFilter, {
  TBooleanFilterValue,
} from '../../../hooks/filters/useBooleanFilter'

export type TImportFailuresFilterFields = {
  emailDuped?: TBooleanFilterValue
  mobilePhoneDuped?: TBooleanFilterValue
  emailMissing?: TBooleanFilterValue
  mobilePhoneMissing?: TBooleanFilterValue
}

const ImportFailuresFilter = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TImportFailuresFilterFields>) => {
  const emailDuped = useBooleanFilter(initialValue?.emailDuped)
  const mobilePhoneDuped = useBooleanFilter(initialValue?.mobilePhoneDuped)
  const emailMissing = useBooleanFilter(initialValue?.emailMissing)
  const mobilePhoneMissing = useBooleanFilter(initialValue?.mobilePhoneMissing)

  const onApplyFilter = () => {
    onSubmit?.({
      emailDuped: emailDuped.value,
      mobilePhoneDuped: mobilePhoneDuped.value,
      emailMissing: emailMissing.value,
      mobilePhoneMissing: mobilePhoneMissing.value,
    })
  }

  const onClearFilter = () => {
    emailDuped.clear()
    mobilePhoneDuped.clear()
    emailMissing.clear()
    mobilePhoneMissing.clear()
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <RadioButtonFilterNode
          groupName={'missingEmailFilter'}
          title='Missing email'
          value={emailMissing.value}
          options={emailMissing.options}
          onChange={emailMissing.setValue}
        />
        <RadioButtonFilterNode
          groupName={'missingPhoneFilter'}
          title='Missing mobile pgone'
          value={mobilePhoneMissing.value}
          options={mobilePhoneMissing.options}
          onChange={mobilePhoneMissing.setValue}
        />
        <RadioButtonFilterNode
          groupName={'emailDupedFilter'}
          title='Email duped'
          value={emailDuped.value}
          options={emailDuped.options}
          onChange={emailDuped.setValue}
        />
        <RadioButtonFilterNode
          groupName={'phoneDupedFilter'}
          title='Mobile phone duped'
          value={mobilePhoneDuped.value}
          options={mobilePhoneDuped.options}
          onChange={mobilePhoneDuped.setValue}
        />
      </AccordionGroup>
    </Filters>
  )
}

export default ImportFailuresFilter
