import './AccessPointsTable.style.scss'

import {FC, useEffect, useState} from 'react'
import DataGrid from '../DataGrid'
import InputToggle from '../InputToggle'
import Panel from '../Panel'
import Hr from '../Hr/Hr'
import InputInvalidMessage from '../InputInvalidMessage/InputInvalidMessage'
import classNames from 'classnames'
import Section from '../Grid/Section'
import Row from '../Grid/Row'
import Column from '../Grid/Column'
import Tabbar from '../Tabbar/Tabbar'
import {TBuildingWithUnits} from '../../data/graphql/queries/common/types'
import {isCommonAreaBuilding} from '../../functions/devices.function'
import Spinner from '../Spinner'

type TPropertyId = string
type TBuildingId = string
type TUnitId = string

type TBuildingAccesses<T> = {
  units: Record<TUnitId, T | null> | null
  data?: T | null
}

type TAccessPointsValue<T> = Record<
  TPropertyId,
  {
    data?: T | null
    buildings: Record<TBuildingId, TBuildingAccesses<T> | null>
  } | null
>

type TAccessPoint = {
  rowClassname?: string
  value: string
  label: string
  isEnabled: JSX.Element
}

type TProperty = {
  propertyId: string
  propertyName: string
  buildingsByPropertyId: {
    nodes: TBuildingWithUnits[]
  }
}

type TStructure = {
  propertyId?: string
  buildingId?: string
  unitId?: string
}

type TColumn = {
  key: string
  name: string
}

export type TAccessPointsProps = {
  columns: TColumn[]

  loading?: boolean
  invalid?: boolean
  value: TAccessPointsValue<unknown>
  errorMessage?: string
  properties?: TProperty[]
  activeProperty: TProperty | null
  activeBuilding: TBuildingWithUnits | null

  sortComparator?: (unitIdA: string, unitIdB: string) => number
  renderUnitRow: (unitId: string) => {
    rowClassname?: string
    isEnabled: JSX.Element
    [key: string]: any
  }
  renderAccessValue: (value: any, structure: TStructure) => string

  isPropertySelected: boolean
  checkBuildingSelection: (building: TBuildingWithUnits | null) => boolean

  onPressCustomAccess: (structure: TStructure) => void
  onChangeProperty: (value: TProperty) => void
  onChangeBuilding: (value: TBuildingWithUnits) => void
  onToggleBuilding: (enabled: boolean) => void
  onToggleProperty: (enabled: boolean) => void
}

const AccessPointsTable: FC<TAccessPointsProps> = ({
  columns,

  loading,
  value,
  invalid,
  errorMessage,
  properties,
  activeProperty,
  activeBuilding,

  isPropertySelected,
  sortComparator,
  checkBuildingSelection,
  renderAccessValue,
  renderUnitRow,

  onPressCustomAccess,
  onChangeProperty,
  onChangeBuilding,
  onToggleBuilding,
  onToggleProperty,
}) => {
  const propertyAccesses = activeProperty ? value[activeProperty?.propertyId] : null
  const buildingAccesses = activeBuilding
    ? propertyAccesses?.buildings?.[activeBuilding?.buildingId]
    : null

  const buildingsList = activeProperty?.buildingsByPropertyId.nodes

  const [accessPoints, setAccessPoints] = useState<TAccessPoint[]>([])

  const isBuildingSelected = checkBuildingSelection(activeBuilding)

  useEffect(() => {
    if (activeBuilding && activeProperty) {
      const data = activeBuilding.unitsByBuildingId.nodes
        .slice()
        .sort((a, b) => (a.unitNumber > b.unitNumber ? 1 : -1))
        .sort((a, b) => sortComparator?.(a.unitId, b.unitId) ?? 0)
        .map<TAccessPoint>(unit => ({
          value: unit.unitId,
          label: `${unit.unitType.description} ${unit.unitNumber}`,
          ...renderUnitRow(unit.unitId),
        }))

      setAccessPoints(data)
    } else {
      setAccessPoints([])
    }
  }, [activeBuilding, activeProperty, sortComparator, renderUnitRow])

  const onOpenBuildingData: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    onPressCustomAccess({
      buildingId: activeBuilding?.buildingId,
    })
  }

  const onOpenPropertyData: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    onPressCustomAccess({
      propertyId: activeProperty?.propertyId,
    })
  }

  const onPressPropertyTab = (propertyId: string) => {
    const property = properties?.find(property => property.propertyId === propertyId)

    if (property) {
      onChangeProperty(property)
    }
  }

  const getPropertyAccessLabel = () => {
    return renderAccessValue(propertyAccesses?.data, {
      propertyId: activeProperty?.propertyId,
    })
  }

  const getBuildingAccessLabel = () => {
    if (!isBuildingSelected) {
      return ''
    }
    const buildingAccess = buildingAccesses?.data

    const access =
      buildingAccesses === null ? null : buildingAccess || propertyAccesses?.data

    const renderedLabel = renderAccessValue(access, {
      buildingId: activeBuilding?.buildingId,
    })
    const isInherited = !!renderedLabel && !buildingAccess && !!access

    if (renderedLabel) {
      return `${renderedLabel} ${isInherited ? ' (inherited)' : ''}`
    } else if (access) {
      return 'No available devices'
    }

    return ''
  }

  const tabs = properties?.map(({propertyId, propertyName}) => ({
    key: propertyId,
    label: propertyName,
  }))

  return (
    <div id={'access-points'}>
      <Section className='tabbar-section'>
        <Row>
          <Column>
            <div className='d-flex align-end tabbar-place'>
              <Tabbar
                tabs={tabs}
                selected={activeProperty?.propertyId}
                onTabChange={onPressPropertyTab}
              />

              <div className={'full-access-row'}>
                <a className='schedule-link' onClick={onOpenPropertyData}>
                  {getPropertyAccessLabel()}
                </a>
                <div className={'property-access-toggle-wrapper'}>
                  <h6>Entire property access</h6>
                  <InputToggle
                    isChecked={isPropertySelected}
                    onValueChange={onToggleProperty}
                  />
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </Section>

      <div className='access-actions'>
        <div className='areas'>
          {buildingsList?.map(building => (
            <div
              key={building.buildingId}
              className={classNames({
                'active': activeBuilding?.buildingId === building.buildingId,
                'with-access':
                  !!propertyAccesses?.buildings?.[building.buildingId]?.units,
                'building': true,
              })}
              onClick={() => {
                onChangeBuilding(building)
              }}
            >
              <span>{building.buildingName}</span>
            </div>
          ))}
        </div>

        <Panel theme={'white'} id={'access-points-list'}>
          {!!activeBuilding && (
            <div className={'full-access-row'}>
              <a className='schedule-link' onClick={onOpenBuildingData}>
                {getBuildingAccessLabel()}
              </a>
              <h6>
                {isCommonAreaBuilding(activeBuilding)
                  ? 'All common areas'
                  : 'Entire building access'}
              </h6>
              <InputToggle
                isChecked={isBuildingSelected}
                onValueChange={onToggleBuilding}
              />
              <Hr />
            </div>
          )}

          <DataGrid
            id={'access-grid'}
            columns={[{key: 'label', name: 'Access Points'}, ...columns]}
            perPage={12}
            rows={accessPoints}
          />
        </Panel>
      </div>

      {!!loading && <Spinner />}

      {!!invalid && <InputInvalidMessage message={errorMessage} />}
    </div>
  )
}

export default AccessPointsTable
