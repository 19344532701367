import {
  TPerson,
  TPersonProfile,
  TVendorCompany,
} from './../../../data/graphql/queries/entities'
import React from 'react'

export type TVendoPerson = {
  id: TPerson['personId']
  firstName: TPerson['firstName']
  lastName: TPerson['lastName']
  mobilePhone: TPerson['mobilePhone']
  email: TPerson['email']
  miscInfo: TPerson['miscInfo']
  isIdentityCreated: TPerson['isIdentityCreated']
}

export type TVendorUserContext = {
  loading: boolean
  profile: TPersonProfile | null
  person: TVendoPerson | null
  vendor: TVendorCompany | null
}

const VendorUserContext = React.createContext<TVendorUserContext>({
  loading: false,
  person: null,
  profile: null,
  vendor: null,
})

export default VendorUserContext
