// components/TableNumberOfItems/TableNumberOfItems.tsx

import React, {FC} from 'react'
import './TableNumberOfItems.style.scss'
import InputSelect from '../InputSelect/InputSelect'

export interface ITableNumberOfItemsProps {
  onValueChange: (value: string) => void
  value: number
}

const countOptions = [
  {label: '10 per page', value: '10'},
  {label: '25 per page', value: '25'},
  {label: '50 per page', value: '50'},
]

const TableNumberOfItems: FC<ITableNumberOfItemsProps> = (
  props: ITableNumberOfItemsProps,
) => {
  return (
    <div className={'TableNumberOfItems'} data-testid={'ITableNumberOfItemsTestID'}>
      <InputSelect
        theme='white'
        allowNull={false}
        onValueChange={props.onValueChange}
        options={countOptions}
        value={String(props.value)}
      />
    </div>
  )
}

export default TableNumberOfItems
