import {useMemo} from 'react'
import {doesFilterContainAny} from '../../functions/filters'

export const useGetEmptyTableMessage = (
  queryOptions: any,
  message: {
    filtersAndQuery: string
    filter: string
    query: string
    default: string
  },
) => {
  const emptyMessage = useMemo(() => {
    const filtersExist = doesFilterContainAny(queryOptions.filters)
    if (filtersExist && queryOptions.searchTerm) {
      return message.filtersAndQuery
    }

    if (filtersExist && !queryOptions.searchTerm) {
      return message.filter
    }

    if (queryOptions.searchTerm) {
      return message.query
    }

    return message.default
  }, [queryOptions, message])

  return emptyMessage
}
