import './OtherAccess.style.scss'

import React, {useCallback, useEffect, useState, useMemo, useContext} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import DataGrid from '../../../components/DataGrid'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import Icon from '../../../components/Icon'
import {useQuery} from '@apollo/client'
import Badge from '../../../components/Badge'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import {
  TAllBuidlingsVariables,
  TAllBuildingsResponse,
  TAllUnitsOfProperyResponse,
  TAllUnitsVariables,
} from '../../../data/graphql/queries/common/types'
import {
  GET_ALL_BUILDING,
  GET_ALL_UNITS_FOR_OTHER_ACCESS,
} from '../../../data/graphql/queries/common'
import {formatRelativeTime} from '../../../functions'
import Paginator from '../../../components/Paginator/Paginator'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import useToast from '../../../hooks/useToast'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {client} from '../../../data/graphql'
import useTableSort from '../../../hooks/useTableSort'

type TableColumns = {
  area: React.ReactNode
  description: React.ReactNode
  thermostat: React.ReactNode
  lock: React.ReactNode
  lastAccess: React.ReactNode
  status: React.ReactNode
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'status:asc', value: 'IS_ACTIVE_ASC', label: 'Is Active Asc'},
  {sortKey: 'status:desc', value: 'IS_ACTIVE_DESC', label: 'Is Active Desc'},
  {sortKey: 'area:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Asc'},
  {sortKey: 'area:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Desc'},
]

const OtherAccess = () => {
  const {showToast} = useToast()
  const navigate = useNavigate()
  const {setQuery} = useContext(ExportTableContext)
  const params = useParams<{propertyId: string}>()
  const propertyId = params.propertyId ? +params.propertyId : -1
  const [commonAreas, setCommonAreas] = useState<TableColumns[]>([])
  const [buildingIds, setBuildingIds] = useState<string[]>([])
  const [itemsCount, setItemsCount] = useState(0)
  const buildingsResponse = useQuery<TAllBuildingsResponse, TAllBuidlingsVariables>(
    GET_ALL_BUILDING,
    {
      variables: {
        condition: {
          isActive: true,
          isDeleted: false,
          propertyId,
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Buildings Data',
          type: 'error',
        })
      },
    },
  )

  useEffect(() => {
    const buildings = buildingsResponse.data?.transactionalDb.allBuildings.nodes
    if (buildings) {
      setBuildingIds(buildings.map(({buildingId}) => buildingId))
    } else {
      setBuildingIds([])
    }
  }, [buildingsResponse.data?.transactionalDb.allBuildings.nodes])

  const {queryOptions, upsertQueryOptions, debouncedSearchTerm, onChangeNumberOfItems} =
    useQueryOptions({
      limit: 10,
      page: 1,
      orderBy: ['IS_ACTIVE_ASC'],
      searchTerm: '',
    })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const onChangeSortOrder = value => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const queryVariables = useMemo(() => {
    const filter: {[key: string]: any} = {
      or: buildingIds.map(id => ({
        buildingId: {
          equalTo: +id,
        },
      })),
    }

    if (debouncedSearchTerm) {
      filter.unitNumber = {
        includes: debouncedSearchTerm,
      }
    }

    filter.unitTypeId = {
      equalTo: 2, // CA will be refactored
    }

    return {
      filter,
      first: queryOptions.limit,
      offset: queryOptions.limit * (queryOptions.page - 1),
      orderBy: queryOptions?.orderBy,
      leasesCondition: {
        isActive: true,
        isDeleted: false,
      },
      lockActivityLogsByUnitIdOrderBy2: 'TIME_STAMP_DESC',
      lockActivityLogsByUnitIdFilter2: {
        or: [
          {
            event: {
              equalTo: 'lock',
            },
          },
          {
            event: {
              equalTo: 'unlock',
            },
          },
        ],
      },
      lockActivityLogsByUnitIdFirst2: 1,
    }
  }, [
    debouncedSearchTerm,
    buildingIds,
    queryOptions.limit,
    queryOptions.page,
    queryOptions.orderBy,
  ])

  const commonAreasResponse = useQuery<TAllUnitsOfProperyResponse, TAllUnitsVariables>(
    GET_ALL_UNITS_FOR_OTHER_ACCESS,
    {
      variables: queryVariables,
      skip: buildingIds.length === 0 || buildingsResponse.loading,
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Units Data',
          type: 'error',
        })
      },
    },
  )

  const prepareData = useCallback((commonAreas, forTable?) => {
    const areas = commonAreas.map(area => {
      const item = {
        ...(forTable ? {} : {unitId: area.unitId}),
        area: forTable ? (
          area.unitNumber
        ) : (
          <div className='d-flex align-center name-td'>
            <Icon theme='primary' icon={'home'} />
            {area.unitNumber}
          </div>
        ),
        description: '#####',
        thermostat: '#####',
        lock: '#####',
        lastAccess: area.lockActivityLogsByUnitId.nodes?.[0]?.timeStamp
          ? formatRelativeTime(
              parseInt(area.lockActivityLogsByUnitId.nodes?.[0]?.timeStamp || 0),
            )
          : '—',
        status: forTable ? (
          area.isActive ? (
            'Active'
          ) : (
            'Disabled'
          )
        ) : (
          <div className='d-flex space-between align-center status'>
            <Badge size='sm' theme={area.isActive ? 'success' : 'default'}>
              {area.isActive ? 'Active' : 'Disabled'}
            </Badge>
            <Icon size='sm' icon='chevron-right' />
          </div>
        ),
      }
      return forTable ? Object.values(item) : item
    })

    if (forTable) {
      return areas
    }
    setCommonAreas(areas)
  }, [])

  useEffect(() => {
    if (!commonAreasResponse.data) return
    setItemsCount(commonAreasResponse.data?.transactionalDb.allUnits.totalCount)
    prepareData(commonAreasResponse.data.transactionalDb.allUnits.nodes)
  }, [commonAreasResponse, prepareData])

  const dataForTableQuery = useCallback(async () => {
    const variables: TAllUnitsVariables = {...queryVariables}

    if ('first' in variables) {
      delete variables['first']
    }

    if ('offset' in variables) {
      delete variables['offset']
    }

    if (queryOptions?.orderBy) {
      variables.orderBy = queryOptions.orderBy
    } else {
      delete variables['orderBy']
    }

    try {
      const {data} = await client.query<TAllUnitsOfProperyResponse, TAllUnitsVariables>({
        query: GET_ALL_UNITS_FOR_OTHER_ACCESS,
        variables,
      })

      const logs = data.transactionalDb.allUnits.nodes

      const tableData = prepareData(logs, true)

      tableData.unshift([
        'Name',
        'Description Type',
        'Thermostat',
        'Lock',
        'Last Access',
        'Status',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryVariables])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const onTypeSearchField = useCallback(
    (text: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm: text,
      })
    },
    [upsertQueryOptions],
  )

  const onPressRow = useCallback(
    (index: number) => {
      //
    },
    [navigate, commonAreas, propertyId],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [upsertQueryOptions],
  )

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className='other-access-header d-flex space-between align-center'>
        <h4 className='black'>Other Access</h4>
      </div>

      <div className={'OtherAccess'} data-testid={'OtherAccessView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search access'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onTypeSearchField}
                  />
                  {/* <SearchFilters filter={PeopleFilters} onSubmit={() => null} /> */}
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>
                <div id='other-access'>
                  <DataGrid
                    selectableRows
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    onRowSelect={onPressRow}
                    loading={commonAreasResponse.loading}
                    columns={[
                      {name: 'Name', key: 'area', sortable: true},
                      {name: 'Description Type', key: 'description'},
                      {name: 'Thermostat', key: 'thermostat'},
                      {name: 'Lock', key: 'lock'},
                      {name: 'Last Access', key: 'lastAccess'},
                      {name: 'Status', key: 'status', sortable: true},
                    ]}
                    rows={commonAreas}
                  />
                </div>
                <TableFooter itemCount={itemsCount} loading={commonAreasResponse.loading}>
                  <Paginator
                    itemCount={itemsCount}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default OtherAccess
