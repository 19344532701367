import './BuildingAccounts.style.scss'

import React from 'react'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'
import EmailRegistration from './EmailRegistration'
import Row from '../../components/Grid/Row'
import Column from '../../components/Grid/Column'
import ManufacturerRegistration from './ManufacturerRegistration'

const BuildingAccounts = () => {
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'BuildingAccounts'} data-testid={'BuildingAccounts'}>
        <Row>
          <Column md={'6'}>
            <EmailRegistration />
          </Column>

          <Column md={'6'}>
            <ManufacturerRegistration />
          </Column>
        </Row>
      </div>
    </ErrorBoundary>
  )
}

export default React.memo(BuildingAccounts)
