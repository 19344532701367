import React from 'react'

type TErrorBoundaryProps = {
  fallback: React.FunctionComponent
  children: React.ReactNode
}

type TErrorBoundaryState = {hasError: boolean}

class ErrorBoundary extends React.Component<TErrorBoundaryProps, TErrorBoundaryState> {
  constructor(props: TErrorBoundaryProps) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError() {
    return {hasError: true}
  }

  componentDidCatch(error, info) {
    console.log(JSON.stringify(error), JSON.stringify(info.componentStack))
  }

  render(): React.ReactNode {
    const Fallback = this.props.fallback

    if (this.state.hasError) {
      return <Fallback />
    }

    return this.props.children
  }
}

export default ErrorBoundary
