// views/MobileSignup/MobileSignup.tsx

import './MobileSignup.style.scss'
import BackButton from '../../components/BackButton'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'
import {useSearchParams} from 'react-router-dom'
import {useMemo} from 'react'

const MobileSignup = () => {
  const host = window.location.host.split(':')[0]

  const [searchParams] = useSearchParams()

  const appPackageSuffix = useMemo(() => {
    const uri = process.env.REACT_APP_GRAPHQL_URI

    if (uri?.includes('dev.')) {
      return '.dev'
    } else if (uri?.includes('staging.')) {
      return '.staging'
    }

    return ''
  }, [])

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'mobile-signup-container'}>
        <div className='mobile-signup-back-button'>
          <BackButton />
        </div>

        <div className={'mobile-signup'} data-testid={'MobileSignup'}>
          <img alt='logo' className={'brand-mark'} src={'/images/apthub-mark.svg'} />
          <h2 className={'color-black'}>Download the app</h2>
          <p>Apthub is available on all iOS & Android devices</p>

          {/* TODO: temporary links until apps are live*/}
          <div className={'download-container'}>
            <a href='https://apps.apple.com/us/app/apthub/id6467773304'>
              <img
                alt='download-ios'
                className={'download-mark'}
                id='ios'
                src={'/images/download_ios.svg'}
              />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.precocity.apthub'>
              <img
                alt='download-android'
                className={'download-mark'}
                src={'/images/download_android.svg'}
              />
            </a>
          </div>

          <p className='open-app-container'>
            <span className={'color-black'}>Already have the app?</span>{' '}
            <a
              className='open-app-link'
              href={`com.precocity.apthub${appPackageSuffix}://${host}/mobile-signup?code=${searchParams.get(
                'code',
              )}`}
            >
              Open it!
            </a>
          </p>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default MobileSignup
