// components/Panel/Panel.tsx

import React, {FC} from 'react'
import './Panel.style.scss'
import classNames from 'classnames'

export interface PanelProps {
  id?: string
  theme?: 'white' | 'gray' | 'transparent'
  children?: React.ReactNode
  className?: string
}

const defaultProps: PanelProps = {}

const Panel: FC<PanelProps> = (props: PanelProps) => {
  props = {...defaultProps, ...props}

  return (
    <div
      className={classNames('panel', props.theme, props.className)}
      id={props.id}
      data-testid={'Panel'}
    >
      {props.children}
    </div>
  )
}

export default Panel
