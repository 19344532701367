import {useCallback, useMemo} from 'react'

import {
  getGreaterThanOrEqualFilter,
  getInFilter,
  getLessThanOrEqualFilter,
} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {
  GET_SERVICE_TICKETS,
  GET_SERVICE_TICKETS_INFO,
} from '../../data/graphql/queries/properties'
import useToast from '../useToast'
import {useQuery} from '@apollo/client'
import {
  TServiceTicketsInfoResponse,
  TServiceTicketsResponse,
  TServiceTicketsVariables,
} from '../../data/graphql/queries/properties/types'
import {TServiceTicketFilterFields} from '../../views/PropertyDetails/Overview/ServiceTickets/ServiceTicketsFilter'
import useUserAccess from '../useUserAccess'
import {client} from '../../data/graphql'
import {arrayToObject} from '../../functions'

const useServiceTickets = (
  searchTerm: string,
  options: Required<QueryOptions<TServiceTicketFilterFields>>,
  vars?: TServiceTicketsVariables,
) => {
  const {showToast} = useToast()
  const personAccesses = useUserAccess()

  const variables = useMemo(() => {
    const {unitFilters, category, issueLocation, updatedFrom, updatedTo} = options.filters

    const structureFilter: TServiceTicketsVariables['filter'] = {
      or: unitFilters || [],
    }

    const filter: TServiceTicketsVariables['filter'] = {
      serviceTicketCategoryTypeId: getInFilter(category),
      serviceTicketIssueLocationTypeId: getInFilter(issueLocation),
      and: [],
    }

    if (searchTerm) {
      filter.unitNumber = {
        includesInsensitive: searchTerm,
      }
    }

    if (updatedFrom) {
      filter.and!.push({
        modifiedDt: getGreaterThanOrEqualFilter(updatedFrom),
      })
    }

    if (updatedTo) {
      filter.and!.push({
        modifiedDt: getLessThanOrEqualFilter(updatedTo),
      })
    }

    if (structureFilter.or?.length) {
      filter.and!.push(structureFilter)
    }

    return {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      ...vars,
      filter: {
        ...filter,
        ...vars?.filter,
      },
      condition: {
        isActive: true,
        isDeleted: false,
        ...vars?.condition,
      },
      orderBy: options?.orderBy,
    }
  }, [options, searchTerm, vars])

  const infoResponse = useQuery<TServiceTicketsInfoResponse>(GET_SERVICE_TICKETS_INFO, {
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Failed to fetch service tickets',
        type: 'error',
      })
    },
  })

  const ticketsResponse = useQuery<TServiceTicketsResponse, TServiceTicketsVariables>(
    GET_SERVICE_TICKETS,
    {
      variables,
      skip: !personAccesses.properties?.length,
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Failed to fetch service tickets',
          type: 'error',
        })
      },
    },
  )

  const tickets = useMemo(() => {
    const infoData = infoResponse.data?.transactionalDb
    const ticketsData = ticketsResponse.data?.transactionalDb

    if (!infoData || !ticketsData) {
      return {
        totalCount: 0,
        nodes: [],
      }
    }

    const issueTypesMap = arrayToObject(infoData.issueTypes.nodes, 'id')
    const categoryTypesMap = arrayToObject(infoData.categoryTypes.nodes, 'id')
    const issueLocationTypesMap = arrayToObject(infoData.issueLocationTypes.nodes, 'id')

    return {
      totalCount: ticketsData.serviceTickets.totalCount,
      nodes: ticketsData.serviceTickets.nodes.map(ticket => ({
        ...ticket,
        issueTypeDescription: issueTypesMap[ticket.issueTypeId]?.description,
        categoryTypeDescription: categoryTypesMap[ticket.categoryTypeId]?.description,
        locationTypeDescription:
          issueLocationTypesMap[ticket.locationTypeId]?.description,
      })),
    }
  }, [infoResponse.data, ticketsResponse.data])

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {
      ...variables,
    }

    if ('first' in variables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query({query: GET_SERVICE_TICKETS, variables: copiedVariables})
  }, [variables])

  return {
    tickets,
    response: ticketsResponse,
    variables,
    queryForDownloadTable,
  }
}

export default useServiceTickets
