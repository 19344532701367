import {gql} from '@apollo/client'

export const ADD_BUILDING_ACCOUNT = gql`
  mutation AddBuildingAccount($input: AddBuildingAccountInput!) {
    utility {
      addBuildingAccount(input: $input) {
        ... on AddBuildingAccountSuccess {
          buildingPersonId
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`
