// views/RolesPermissions/RolesPermissions.tsx

import './RolesPermissions.style.scss'
import Section from '../../components/Grid/Section'
import Button from '../../components/Button/Button'
import Search from '../../layouts/People/Search/Search'
import SearchFilterInput from '../../layouts/People/Search/SearchFilterInput'
import {useQueryOptions} from '../../hooks/useQueryOptions'
import {useCallback, useEffect, useState} from 'react'
import PeopleFilters from '../../layouts/People/Filters/Filters'
import SearchFilters from '../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../layouts/People/Search/SearchSortBy'
import DataGrid from '../../components/DataGrid/DataGrid'
import {useNavigate} from 'react-router-dom'
import Icon from '../../components/Icon/Icon'
import {format} from 'date-fns'
import Badge from '../../components/Badge/Badge'
import {capitalize} from '../../functions'
import CrashScreen from '../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'

const ROLES_AND_PERMISSIONS_MOCK = [
  {
    role: 'Super Admin',
    description: 'Has access to all features and functions of the system.',
    staffCount: 1,
    access: 'Full',
    createdDate: new Date('2022-01-01'),
    lastUpdatedDate: new Date('2022-01-01'),
    status: 'active',
  },
  {
    role: 'Admin',
    description:
      'Has access to most features and functions of the system, but cannot perform certain actions.',
    staffCount: 3,
    access: 'Limited',
    createdDate: new Date('2022-01-05'),
    lastUpdatedDate: new Date('2022-02-15'),
    status: 'active',
  },
  {
    role: 'Property Manager',
    description:
      'Has access to property management features, but cannot perform certain actions that are restricted to Super Admins and Admins.',
    staffCount: 10,
    access: 'Limited',
    createdDate: new Date('2022-02-15'),
    lastUpdatedDate: new Date('2022-03-25'),
    status: 'active',
  },
  {
    role: 'Leasing Specialist',
    description:
      'Has access to leasing and tenant management features, but cannot perform certain actions that are restricted to Super Admins and Admins.',
    staffCount: 5,
    access: 'Limited',
    createdDate: new Date('2022-03-25'),
    lastUpdatedDate: new Date('2022-04-15'),
    status: 'active',
  },
  {
    role: 'Asst. Property Manager',
    description:
      'Assists the Property Manager and has access to property management features, but cannot perform certain actions that are restricted to Super Admins and Admins.',
    staffCount: 2,
    access: 'Limited',
    createdDate: new Date('2022-04-15'),
    lastUpdatedDate: new Date('2022-05-01'),
    status: 'inactive',
  },
  {
    role: 'Leasing Specialist',
    description:
      'Has access to leasing and tenant management features, but cannot perform certain actions that are restricted to Super Admins and Admins.',
    staffCount: 7,
    access: 'Full',
    createdDate: new Date('2022-05-01'),
    lastUpdatedDate: new Date('2022-06-15'),
    status: 'active',
  },
  {
    role: 'Admin',
    description:
      'Has access to most features and functions of the system, but cannot perform certain actions.',
    staffCount: 4,
    access: 'Limited',
    createdDate: new Date('2022-06-15'),
    lastUpdatedDate: new Date('2022-07-20'),
    status: 'active',
  },
  {
    role: 'Property Manager',
    description:
      'Has access to property management features, but cannot perform certain actions that are restricted to Super Admins and Admins.',
    staffCount: 8,
    access: 'Full',
    createdDate: new Date('2022-07-20'),
    lastUpdatedDate: new Date('2022-08-31'),
    status: 'inactive',
  },
]

interface IRoleAndPermission {
  role: JSX.Element
  description: string
  staffCount: number
  access: 'Full' | 'Limited' | 'None'
  createdDate: string
  lastUpdatedDate: string
  status: JSX.Element
}

const RolesPermissions = () => {
  const navigate = useNavigate()

  const [rolesAndPermissions, setRolesAndPermissions] = useState<IRoleAndPermission[]>([])

  const {queryOptions, upsertQueryOptions} = useQueryOptions({
    limit: 10,
    page: 1,
    orderBy: [],
    searchTerm: '',
  })

  const onTypeSearchField = useCallback(
    (text: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm: text,
      })
    },
    [upsertQueryOptions],
  )

  const onPressRow = useCallback(
    (index: number) => {
      // navigate()
    },
    [navigate],
  )

  const prepareData = useCallback(data => {
    const rolesAndPermissions = data.map(element => ({
      ...element,
      role: (
        <div className='role-el'>
          <Icon icon='users' theme='info' />
          <span className='label-small-regular-12 black'>{element.role}</span>
        </div>
      ),
      createdDate: format(new Date(element.createdDate), 'dd/LL/yyyy'),
      lastUpdatedDate: format(new Date(element.lastUpdatedDate), 'dd/LL/yyyy'),
      status: (
        <Badge theme={element.status === 'active' ? 'success' : 'default'} size='sm'>
          {capitalize(element.status)}
        </Badge>
      ),
    }))

    return rolesAndPermissions
  }, [])

  useEffect(() => {
    const rolesAndPermissions = prepareData(ROLES_AND_PERMISSIONS_MOCK)

    setRolesAndPermissions(rolesAndPermissions)
  }, [prepareData])

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'RolesPermissions'} data-testid={'RolesPermissions'}>
        <Section id='header'>
          <h3 className={'color-black'}>Roles & Permissions</h3>
          <Button theme='outline' size='md'>
            + Add Vendor
          </Button>
        </Section>

        <Section id='roles-and-permissions-table'>
          <Search>
            <SearchFilterInput
              placeholder='Search roles'
              value={queryOptions.searchTerm || ''}
              onValueChange={onTypeSearchField}
            />
            <SearchFilters filter={PeopleFilters} onSubmit={() => null} />
            <SearchSortBy value={'1'} options={[]} onChange={() => undefined} />
          </Search>
          <div id='other-access'>
            <DataGrid
              selectableRows
              onRowSelect={onPressRow}
              loading={false}
              columns={[
                {name: 'Role', key: 'role'},
                {name: 'Description', key: 'description'},
                {name: 'Staff', key: 'staffCount'},
                {name: 'Access', key: 'access'},
                {name: 'Created', key: 'createdDate'},
                {name: 'Last Updated', key: 'lastUpdatedDate'},
                {name: 'Status', key: 'status'},
              ]}
              rows={rolesAndPermissions}
            />
          </div>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default RolesPermissions
