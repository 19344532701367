import {FC} from 'react'

const Close: FC = () => (
  <g>
    <path d='M18 6L6 18' />
    <path d='M6 6L18 18' />
  </g>
)

export default Close
