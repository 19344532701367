import {FC} from 'react'

const BatteryPartial: FC = () => (
  <g>
    <path
      d='M23.3448 10.2301L23.3448 8.23037C23.5186 8.23037 23.6852 8.16314 23.8081 8.04348C23.9309 7.92381 24 7.76151 24 7.59228L24 3.77256C24 3.60333 23.9309 3.44103 23.8081 3.32136C23.6852 3.20169 23.5186 3.13447 23.3448 3.13447L23.3448 1.14356C23.3448 0.840266 23.2211 0.549399 23.0009 0.33494C22.7807 0.120481 22.4821 -6.63487e-08 22.1707 -7.99603e-08L1.1741 -9.97751e-07C0.862822 0.000388919 0.564411 0.120995 0.344307 0.33537C0.124202 0.549744 0.000370423 0.840385 -2.99439e-05 1.14356L-3.03411e-05 10.2301C0.000370594 10.5332 0.12425 10.8236 0.344398 11.0378C0.564546 11.2519 0.86296 11.3722 1.1741 11.3722L22.1646 11.3722C22.3193 11.3732 22.4726 11.3444 22.6157 11.2874C22.7588 11.2304 22.889 11.1465 22.9987 11.0403C23.1084 10.9342 23.1954 10.8079 23.2548 10.6689C23.3142 10.5298 23.3448 10.3807 23.3448 10.2301V10.2301ZM1.78083 10.2832C1.60707 10.2832 1.44043 10.2159 1.31757 10.0963C1.1947 9.97661 1.12568 9.81431 1.12568 9.64508L1.12568 1.7286C1.12647 1.56089 1.19501 1.40023 1.31649 1.28137C1.43796 1.16251 1.6026 1.095 1.77478 1.09345L21.5655 1.09345C21.6514 1.09345 21.7366 1.10997 21.816 1.14205C21.8954 1.17414 21.9675 1.22116 22.0282 1.28043C22.0889 1.33971 22.137 1.41006 22.1698 1.48747C22.2025 1.56488 22.2193 1.64782 22.2191 1.73155L22.2191 9.64066C22.2193 9.72439 22.2025 9.80733 22.1698 9.88474C22.137 9.96215 22.0889 10.0325 22.0282 10.0918C21.9675 10.151 21.8954 10.1981 21.816 10.2302C21.7366 10.2622 21.6514 10.2788 21.5655 10.2788L1.78083 10.2832Z'
      fill='#F04C5C'
    />
    <path
      d='M12 3.5V5.83333'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='#F04C5C'
    />
    <path
      d='M12 8.16669H12.0051'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='#F04C5C'
    />
  </g>
)

export default BatteryPartial
