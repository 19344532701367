import {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'

const Portal = ({children}) => {
  const [modalContainer] = useState(document.createElement('div'))
  useEffect(() => {
    // Find the root element in your DOM
    let modalRoot = document.getElementById('modal')
    // If there is no root then create one
    if (!modalRoot) {
      const tempEl = document.createElement('div')
      tempEl.id = 'modal-root'
      document.body.append(tempEl)
      modalRoot = tempEl
    }
    // Append modal container to root
    modalRoot.appendChild(modalContainer)
    return function cleanup() {
      // On cleanup remove the modal container
      if (modalRoot) {
        modalRoot.removeChild(modalContainer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ReactDOM.createPortal(children, modalContainer)
}

export default Portal
