import './DeactivatePersonCell.style.scss'

import DeactivatePersonButton from '../DeactivatePersonButton'

interface DeactivatePersonCellProps {
  personProfileId: number
  className?: string
  children?: React.ReactNode
  onRevoke?: () => void
}

const DeactivatePersonCell = ({
  className = '',
  personProfileId,
  children,
  onRevoke,
}: DeactivatePersonCellProps) => {
  return (
    <div className={`deactivatePersonCell ${className}`}>
      {children}

      <DeactivatePersonButton
        personProfileId={personProfileId}
        className='deactivatePersonButton'
        onRevoke={onRevoke}
      />
    </div>
  )
}

export default DeactivatePersonCell
