import './InstallerAccessPoints.style.scss'

import {FC, useEffect, useMemo} from 'react'
import AccessPointsTable from '../../../components/AccessPointsTable/AccessPointsTable'
import {CheckboxItem} from '../../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import {TAccessPointsValue} from '../useAccessPoints'
import useInstallerAccessPoints from './useInstallerAccessPoints'
import useInstallerUnitRow from './useInstallerUnitRow'
import PropertyDevicesModal from './PropertyDevicesModal'

export type TDevices = TDeviceItem[]

export type TDeviceItem = CheckboxItem & {
  disabled: boolean
  device: {
    code: string
    typeId: string
    description: string
    classTypeId: string
  }
  location: {
    code: string
    typeId: string
    description: string
  }
}

export type TAccessPointDevices = {
  data: TDevices
  propertyId: string
  buildingId: string
  unitId: string
}

export type TStructure = {propertyId?: string; buildingId?: string; unitId?: string}

type TAccessPointsProps = {
  vendorUserId?: number
  defaultSchedule: TDevices
  invalid?: boolean
  errorMessage?: string
  value: TAccessPointsValue<TDevices>
  onChange: (value: TAccessPointsValue<TDevices>) => void
  getUnitDevicesByUnitId: (unitId: string) => TDevices
}

const columns = [
  {
    key: 'devices',
    name: 'Assigned Devices',
  },
  {
    key: 'isEnabled',
    name: 'Enabled',
  },
]

const InstallerAccessPoints: FC<TAccessPointsProps> = ({
  vendorUserId,
  defaultSchedule,
  getUnitDevicesByUnitId,
  value,
  invalid,
  errorMessage,
  onChange,
}) => {
  const installerData = useInstallerAccessPoints({
    vendorUserId,
    value,
    defaultDevices: defaultSchedule,
    onChange,
    getUnitDevicesByUnitId,
  })

  const unitRow = useInstallerUnitRow({
    vendorUserId,
    value,
    installerData,
  })

  useEffect(() => {
    if (installerData.properties?.length) {
      const property = installerData.properties[0]

      installerData.selectProperty(property)

      if (property.buildingsByPropertyId.nodes.length) {
        installerData.selectBuilding(property.buildingsByPropertyId.nodes[0])
      }
    }
  }, [installerData.properties])

  return (
    <>
      <PropertyDevicesModal
        vendorUserId={vendorUserId}
        isOpen={installerData.isDeviceModalOpen}
        value={value}
        defaultDevices={defaultSchedule}
        structure={installerData.modalStructure}
        onApply={installerData.applyCustomDevices}
        onCloseRequest={installerData.closeDevicesModal}
        getStructureDevices={installerData.getStructureDevices}
      />

      <AccessPointsTable
        value={value}
        columns={columns}
        invalid={invalid}
        errorMessage={errorMessage}
        properties={installerData.properties}
        activeProperty={installerData.property}
        activeBuilding={installerData.building}
        isPropertySelected={installerData.isPropertySelected}
        checkBuildingSelection={installerData.isBuildingSelected}
        renderUnitRow={unitRow.render}
        renderAccessValue={unitRow.getDevicesLabel}
        onPressCustomAccess={installerData.openDevicesModal}
        onChangeProperty={installerData.selectProperty}
        onChangeBuilding={installerData.selectBuilding}
        onToggleBuilding={installerData.toggleBuilding}
        onToggleProperty={installerData.toggleProperty}
      />
    </>
  )
}

export default InstallerAccessPoints
