import AccordionGroup from '../../../components/Accordion/AccordionGroup'
import Filters from '../../../components/Filters/Filters'
import StructuresFilterNode from '../../../forms/Filters/FilterNodes/StructuresFilterNode/StructuresFilterNode'
import useStructuresFilter, {
  TFilterItem,
} from '../../../hooks/filters/useStructuresFilter'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'

export type TThermostatsFilterFields = {
  unitFilters?: TFilterItem[]
}

const ThermostatsFilter = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TThermostatsFilterFields>) => {
  const {structures, setStructures, getSelectedIds, clearSelections} =
    useStructuresFilter(initialValue?.unitFilters)

  const onApplyFilter = () => {
    const unitFilters = getSelectedIds()
    onSubmit?.({
      unitFilters,
    })
  }

  const onClearFilter = () => {
    clearSelections()
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <StructuresFilterNode
          forbiddenLevels={['property']}
          value={structures}
          onChange={setStructures}
        />
      </AccordionGroup>
    </Filters>
  )
}

export default ThermostatsFilter
