import AccordionGroup from '../../components/Accordion/AccordionGroup'
import Filters from '../../components/Filters/Filters'
import CheckboxListFilterNode from '../../forms/Filters/FilterNodes/CheckboxListFilterNode'
import StructuresFilterNode from '../../forms/Filters/FilterNodes/StructuresFilterNode/StructuresFilterNode'
import useStructuresFilter, {TFilterItem} from '../../hooks/filters/useStructuresFilter'
import {TFilterComponentProps} from '../../layouts/People/Search/SearchFilters'
import useBatteryFilter from '../../hooks/filters/useBatteryFilter'
import useNetworkStatusFilter from '../../hooks/filters/useNetworkStatusFilter'

export type TDevicesFilterFields = {
  unitFilters?: TFilterItem[]
  networkStatusFilters?: string[]
  batteryLevelFilters?: string[]
}

const DevicesFilter = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TDevicesFilterFields>) => {
  const networkStatus = useNetworkStatusFilter(initialValue?.networkStatusFilters)
  const batteryLevel = useBatteryFilter(initialValue?.batteryLevelFilters)
  const {structures, setStructures, getSelectedIds, clearSelections} =
    useStructuresFilter(initialValue?.unitFilters)

  const onApplyFilter = () => {
    const unitFilters = getSelectedIds()
    onSubmit?.({
      unitFilters,
      networkStatusFilters: networkStatus.getSelected(),
      batteryLevelFilters: batteryLevel.getSelected(),
    })
  }

  const onClearFilter = () => {
    clearSelections()
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <StructuresFilterNode
          forbiddenLevels={['property']}
          value={structures}
          onChange={setStructures}
        />

        <CheckboxListFilterNode
          value={networkStatus.value}
          onChange={networkStatus.setValue}
          title={'Network status'}
        />
        <CheckboxListFilterNode
          value={batteryLevel.value}
          onChange={batteryLevel.setValue}
          title={'Battery level'}
        />
      </AccordionGroup>
    </Filters>
  )
}

export default DevicesFilter
