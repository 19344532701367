import {useCallback, useMemo} from 'react'

import {QueryOptions} from '../../models'
import {isUnitNumber, prepareOrder} from '../../functions/filters'
import {client} from '../../data/graphql'
import {
  TGetImportFailuresResponse,
  TGetImportFailuresVariables,
} from '../../data/graphql/queries/people/types'
import {useQuery} from '@apollo/client'
import useToast from '../useToast'
import {TImportFailuresFilterFields} from '../../views/People/ImportFailures/ImportFailuresFilter'
import {GET_IMPORT_FAILURES} from '../../data/graphql/queries/people'

const useImportFailures = (
  searchTerm: string,
  options: Required<QueryOptions<TImportFailuresFilterFields>>,
) => {
  const {showErrorToast} = useToast()
  // TODO: filter by property
  const variables = useMemo(() => {
    const orderBy = prepareOrder(options?.orderBy)
    const condition: TGetImportFailuresVariables['condition'] = {}
    const filter: TGetImportFailuresVariables['filter'] = {
      and: [],
    }

    if (options.filters.emailDuped) {
      condition.emailIsDuped = options.filters.emailDuped === 'yes'
    }

    if (options.filters.emailMissing) {
      condition.emailIsMissing = options.filters.emailMissing === 'yes'
    }

    if (options.filters.mobilePhoneDuped) {
      condition.mobilePhoneIsDuped = options.filters.mobilePhoneDuped === 'yes'
    }

    if (options.filters.mobilePhoneMissing) {
      condition.mobilePhoneIsMissing = options.filters.mobilePhoneMissing === 'yes'
    }

    if (searchTerm) {
      if (!isUnitNumber(searchTerm)) {
        filter.personName = {
          includesInsensitive: searchTerm,
        }
      } else {
        filter.unitNumber = {
          includesInsensitive: searchTerm,
        }
      }
    }

    return {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      filter,
      condition,
      ...(orderBy.length ? {orderBy} : {}),
    }
  }, [options, searchTerm])

  const query = useQuery<TGetImportFailuresResponse, TGetImportFailuresVariables>(
    GET_IMPORT_FAILURES,
    {
      variables,
      onError() {
        showErrorToast('Request Error', 'Failed to get import failures list')
      },
    },
  )

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Omit<TGetImportFailuresVariables, 'first' | 'offset'> = {
      ...variables,
    }

    if ('first' in copiedVariables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query({
      query: GET_IMPORT_FAILURES,
      variables: copiedVariables,
    })
  }, [variables])

  return {
    people: query.data?.db.ingestIssues.nodes,
    response: query,
    variables,
    queryForDownloadTable,
  }
}

export default useImportFailures
