import {useCallback, useState} from 'react'
import {RadioOption} from '../../components/InputRadioGroup/InputRadioGroup'

export type TBooleanFilterValue = 'yes' | 'no' | ''

const options: RadioOption[] = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
]

const useBooleanFilter = (initialValue: TBooleanFilterValue = '') => {
  const [value, setValue] = useState<string | TBooleanFilterValue>(initialValue)

  const clear = useCallback(() => {
    setValue('')
  }, [])

  return {options, value: value as TBooleanFilterValue, setValue, clear}
}

export default useBooleanFilter
