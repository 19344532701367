import './UnitDetails.style.scss'

import {useCallback} from 'react'
import Badge from '../../components/Badge'
import Section from '../../components/Grid/Section'
import Icon from '../../components/Icon'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Button from '../../components/Button/Button'
import {useParams} from 'react-router-dom'
import {formatPhoneNumber} from '../../functions'
import Spinner from '../../components/Spinner/Spinner'
import CrashScreen from '../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import DeviceCountTile from './DeviceCountTile'
import PeopleTile from './PeopleTile'
import ActivityLog from './ActivityLog'
import useUnitDetails from './useUnitDetails'

const UnitDetails = () => {
  const {
    loading,
    data: {property, residents, guests, locks, thermostats, leaseholder, unit},
  } = useUnitDetails()

  const {unitId, propertyId} = useParams()

  const breadcrumbsProps = {
    showBack: true,
    crumbs: [
      {name: 'Properties', url: '/properties/'},
      {name: property?.propertyName || '—', url: `/properties/${propertyId}`},
      {name: 'Units', url: `/properties/${propertyId}/units`},
      {name: unit?.number || '', url: `/properties/${propertyId}/units/${unitId}`},
    ],
  }

  const handlePressEmailButton = useCallback(() => {
    if (!leaseholder?.email) {
      return
    }

    const subject = `${leaseholder.firstName} ${leaseholder.lastName}`
    const mailtoLink = `mailto:${leaseholder.email}?subject=${encodeURIComponent(
      subject,
    )}`

    window.open(mailtoLink)
  }, [leaseholder])

  const handlePressPhoneButton = useCallback(() => {
    if (!leaseholder?.mobilePhone) {
      return
    }

    window.open(`tel:${leaseholder.mobilePhone}`)
  }, [leaseholder])

  if (loading) {
    return <Spinner />
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'UnitDetails'} data-testid={'UnitDetailsView'}>
        <Section id='navigation'>
          <Breadcrumbs {...breadcrumbsProps} />
        </Section>

        <Section id='contact'>
          <div className='contact-container'>
            <div className='main-info'>
              <Badge size='xl' theme='danger'>
                {unit?.number || '—'}
              </Badge>
              <div className='info'>
                <h3>{property?.propertyName || '—'}</h3>
                <span className='body-light-14'>{unit?.address || '—'}</span>
              </div>
            </div>
            {leaseholder ? (
              <div className='actions-staff'>
                <div className='phone' onClick={handlePressPhoneButton}>
                  <Icon icon='phone' size='sm' />
                  <div className='desktop-label-semibold-14 number'>
                    {formatPhoneNumber(leaseholder?.mobilePhone)}
                  </div>
                </div>
                <Button onClick={handlePressEmailButton}>EMAIL</Button>
              </div>
            ) : null}
          </div>
        </Section>

        <Section id='unit-details'>
          <div className='unit-cards-grid'>
            <PeopleTile
              unitId={unitId}
              unitNumber={unit?.number}
              type={'residents'}
              className='card1'
              people={residents}
            />
            <PeopleTile
              unitId={unitId}
              unitNumber={unit?.number}
              type={'guest'}
              className='card2'
              people={guests}
            />

            <DeviceCountTile
              className='card3'
              count={thermostats?.totalCount || 0}
              label={'Thermostat'}
            />
            <DeviceCountTile
              className='card4'
              count={locks?.totalCount || 0}
              label={'Lock'}
            />
          </div>
        </Section>

        <Section id='table'>
          <ActivityLog unitId={Number(unitId)} />
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default UnitDetails
