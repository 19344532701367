import {FC, useCallback, useMemo, useState} from 'react'
import InstallerContext from './InstallerContext'

const InstallerContextProvider: FC<any> = ({children}) => {
  const [replacements, setReplacements] = useState<string[]>([])

  const replaceDevices = useCallback((inventoryIds: string[]) => {
    setReplacements(data => {
      return Array.from(new Set([...data, ...inventoryIds]))
    })
  }, [])

  const result = useMemo(
    () => ({
      replacements,
      replaceDevices,
    }),
    [replacements, replaceDevices],
  )

  return <InstallerContext.Provider value={result}>{children}</InstallerContext.Provider>
}

export default InstallerContextProvider
