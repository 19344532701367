import {FC, FormEvent, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useLazyQuery, useMutation} from '@apollo/client'
import {
  getAuth,
  signInAnonymously,
  signInWithEmailAndPassword,
  deleteUser,
} from 'firebase/auth'
import './DeleteAccount.style.scss'
import {randomString, formatIdentityError} from '../../functions'
import InputText from '../../components/InputText/InputText'
import ButtonRow from '../../components/ButtonRow'
import Button from '../../components/Button'
import {GET_INFO_FOR_LOGIN} from '../../data/graphql/queries/auth'
import {DELETE_MY_USER_ACCOUNT} from '../../data/graphql/mutations/auth'
import {
  TInfoForLoginReponse,
  TInfoForLoginVariables,
} from '../../data/graphql/queries/auth/types'
import {
  TDeleteMyUserAccountVariables,
  TDeleteMyUserAccountResponse,
} from '../../data/graphql/mutations/auth/types'

export interface DeleteAccountFormProps {
  id?: string
  value?: any
  valueChange?: any
}

const defaultProps: DeleteAccountFormProps = {
  id: randomString(),
}

const DeleteAccountForm: FC<DeleteAccountFormProps> = (props: DeleteAccountFormProps) => {
  props = {...defaultProps, ...props}
  const auth = getAuth()
  const navigate = useNavigate()
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [formState, setFormState] = useState<any>({
    email: '',
    password: '',
    remember: true,
  })

  const [getInfoForLogin, {loading, error, data}] = useLazyQuery<
    TInfoForLoginReponse,
    TInfoForLoginVariables
  >(GET_INFO_FOR_LOGIN, {
    notifyOnNetworkStatusChange: true,
    onCompleted: response => {
      loginAndDelete(response?.utility?.getInfoForLogin?.idpTenantId)
    },
    onError: error => {
      setErrorMessage('Error logging in!')
      setPageLoading(false)
    },
  })

  const [deleteMyUserAccount] = useMutation<
    TDeleteMyUserAccountResponse,
    TDeleteMyUserAccountVariables
  >(DELETE_MY_USER_ACCOUNT, {
    notifyOnNetworkStatusChange: true,
    onError: error => {
      setErrorMessage('Error deleting account!')
      setPageLoading(false)
    },
  })

  const handleConfirmationModal = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      window.confirm(
        'Are you sure you want to delete your account? This action is NOT reversible.',
      )
    ) {
      handleDelete()
    }
  }

  const handleDelete = async () => {
    setErrorMessage('')
    setPageLoading(true)

    try {
      auth.tenantId = process.env.REACT_APP_GOOGLE_DEFAULT_IDP_TENANT || null

      if (
        auth.currentUser?.isAnonymous &&
        auth.currentUser?.tenantId !== process.env.REACT_APP_GOOGLE_DEFAULT_IDP_TENANT
      ) {
        await auth.signOut()
      }

      await signInAnonymously(auth)
      getInfoForLogin({
        variables: {input: {email: formState.email}},
      })
    } catch (error: any) {
      const errorMessageCode = error.code
      setErrorMessage(formatIdentityError(errorMessageCode))
      setPageLoading(false)
    }
  }

  const loginAndDelete = async (tenantId: string | null) => {
    auth.tenantId = tenantId
    const {email, password} = formState

    try {
      await signInWithEmailAndPassword(auth, email, password)
      const user = auth.currentUser

      if (user) {
        await Promise.allSettled([
          deleteMyUserAccount({
            variables: {input: {email: formState.email}},
          }),
          deleteUser(user),
        ])

        navigate('/')
      }
    } catch (error: any) {
      const {code} = error
      const formattedError = formatIdentityError(code)

      setErrorMessage(formattedError)
    }

    setPageLoading(false)
  }

  const handleChange = (name: string, value: string | number) => {
    setFormState({...formState, [name]: value})
  }

  return (
    <>
      {pageLoading && !errorMessage ? (
        <div>Loading...</div>
      ) : (
        <form
          id={props.id}
          className={'Delete-form'}
          data-testid={'DeleteAccountForm'}
          onSubmit={e => {
            handleConfirmationModal(e)
          }}
        >
          <InputText
            id={'delete-email'}
            type={'email'}
            label={'Email'}
            placeholder={'Email Address'}
            value={formState.email}
            onValueChange={v => handleChange('email', v)}
          />
          <InputText
            id={'delete-password'}
            type={'password'}
            label={'Password'}
            placeholder={'Password'}
            value={formState.password}
            suffixIcon='eye'
            onValueChange={v => handleChange('password', v)}
          />
          {errorMessage && (
            <div className={'color-red error-message'}>{errorMessage}</div>
          )}
          <ButtonRow>
            <Button
              id='delete-btn'
              size='lg'
              type={'submit'}
              theme={'danger'}
              width={'block'}
            >
              DELETE ACCOUNT
            </Button>
            <Button type={'button'} theme={'link'} width={'block'} to={'/'}>
              Cancel
            </Button>
          </ButtonRow>
        </form>
      )}
    </>
  )
}

export default DeleteAccountForm
