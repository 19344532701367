// views/DeleteAccount/DeleteAccount.tsx

import './DeleteAccount.style.scss'
import DeleteAccountForm from '../../forms/DeleteAccount'
import BackButton from '../../components/BackButton'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'

const DeleteAccount = () => {
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'delete-account-container'}>
        <div className='delete-account-back-button'>{/* <BackButton /> */}</div>
        <div className={'login'} data-testid={'Login'}>
          <img alt='logo' className={'brand-mark'} src={'/images/apthub-mark.svg'} />
          <h2 className={'color-black'}>Delete Account</h2>
          <p>Provide your credentials to delete your account.</p>

          <DeleteAccountForm />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default DeleteAccount
