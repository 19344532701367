import {createContext, FC, RefObject, useMemo, useRef} from 'react'

export const ExportTableContext = createContext<{
  query: RefObject<(() => Promise<string[][]>) | null>
  setQuery(newQuery: () => Promise<string[][]>): void
}>({
  query: {current: null},
  setQuery: () => {
    //
  },
})

interface IProps {
  children: React.ReactNode
}

const ExportTableContextProvider: FC<IProps> = props => {
  const query = useRef<(() => Promise<string[][]>) | null>(null)

  const setQuery = (newQuery: () => Promise<string[][]>) => {
    query.current = newQuery
  }
  const contextValue = useMemo(() => {
    return {query, setQuery}
  }, [query])

  return (
    <ExportTableContext.Provider value={contextValue}>
      {props.children}
    </ExportTableContext.Provider>
  )
}

export default ExportTableContextProvider
