import {FC} from 'react'
import Close from '../Close'
import Icon from '../Icon'
import {IconProps} from '../Icon/Icon'

interface IProps {
  iconTheme?: IconProps['theme']
  icon?: IconProps['icon']
  title?: string
  closeModal(): void
  onClick?: () => void
}

const HorizontalModalHeading: FC<IProps> = ({
  iconTheme,
  icon,
  title,
  closeModal,
  onClick,
}) => {
  return (
    <div className='horizontal-header d-flex align-center space-between'>
      <div className='d-flex align-center'>
        {icon && iconTheme ? (
          <Icon icon={icon} size='md' theme={iconTheme} onClick={onClick} />
        ) : null}
        {title && <h6>{title}</h6>}
      </div>
      <Close onClick={closeModal} size='md' />
    </div>
  )
}

export default HorizontalModalHeading
