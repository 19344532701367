import './ActivityFilters.style.scss'

import Filters from '../../../components/Filters'
import useActivityEventsFilter from '../../../hooks/filters/useActivityEventsFilter'
import CheckboxListFilterNode from '../../../forms/Filters/FilterNodes/CheckboxListFilterNode'
import useDateRangeFilter from '../../../hooks/filters/useDateRangeFilter'
import DateRangeFilterNode from '../../../forms/Filters/FilterNodes/DateRangeFilterNode'
import useStructuresFilter, {
  TFilterItem,
} from '../../../hooks/filters/useStructuresFilter'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'
import StructuresFilterNode from '../../../forms/Filters/FilterNodes/StructuresFilterNode/StructuresFilterNode'
import AccordionGroup from '../../../components/Accordion/AccordionGroup'

export type TActivityFilterFields = {
  unitFilters?: TFilterItem[]
  events?: string[]
  dateStart?: string
  dateEnd?: string
}

const ActivityFilters = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TActivityFilterFields>) => {
  const {structures, setStructures, getSelectedIds, clearSelections} =
    useStructuresFilter(initialValue?.unitFilters)
  const [events, setEvents] = useActivityEventsFilter(initialValue?.events)
  const [dateRange, setDateRange] = useDateRangeFilter({
    to: initialValue?.dateEnd,
    from: initialValue?.dateStart,
  })

  const onApplyFilter = () => {
    const eventIds = events.filter(({checked}) => !!checked).map(({id}) => id.toString())
    const unitFilters = getSelectedIds()

    onSubmit?.({
      unitFilters,
      events: eventIds.length ? eventIds : undefined,
      dateStart: dateRange.from || undefined,
      dateEnd: dateRange.to || undefined,
    })
  }

  const onClearFilter = () => {
    clearSelections()
    setEvents(events.map(({id, name}) => ({id, name, checked: false})))
    setDateRange({to: '', from: ''})
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <StructuresFilterNode value={structures} onChange={setStructures} />
        <CheckboxListFilterNode
          title={'Event type'}
          value={events}
          onChange={setEvents}
        />
        <DateRangeFilterNode title={'Date'} value={dateRange} onChange={setDateRange} />
      </AccordionGroup>
    </Filters>
  )
}

export default ActivityFilters
