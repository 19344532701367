/**
 * Formats a number as US currency
 */
export function formatMoney(amount: number | string): string {
  if (amount === undefined) {
    return ''
  }

  if (typeof amount !== 'number') {
    try {
      amount = Number(amount)
    } catch {
      return '$0.00'
    }
  }

  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return dollarUS.format(amount)
}
