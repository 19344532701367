import React from 'react'

export type TInstallerContext = {
  replacements: string[]
  replaceDevices: (inventoryId: string[]) => void
}

const InstallerContext = React.createContext<TInstallerContext>({
  replacements: [],
  replaceDevices: () => undefined,
})

export default InstallerContext
