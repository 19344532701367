import './EmailRegistration.style.scss'

import Panel from '../../../components/Panel'
import Section from '../../../components/Grid/Section'
import InputSelect from '../../../components/InputSelect'
import useBuildingAccountSecret from '../useBuildingAccountSecret'
import Spinner from '../../../components/Spinner'
import Credentials from '../Credentials'
import {SecretType} from '../../../data/graphql/types'
import useBuildingSecretForm from '../useBuildingSecretForm'
import {useEffect} from 'react'

const EmailRegistration = () => {
  const form = useBuildingSecretForm()
  const secret = useBuildingAccountSecret()

  useEffect(() => {
    secret.fetch({
      secretType: SecretType.BUILDING_ACCOUNT_EMAIL_PASSWORD,
      buildingPersonId: Number(form.buildingPerson?.person.id),
    })
  }, [secret.fetch, form.buildingPerson?.person.id])

  return (
    <Section className='EmailRegistration'>
      <h4 className='black'>Gmail account credentials</h4>

      <Panel theme={'white'}>
        <Spinner overlay visible={form.loading || secret.loading} />

        <InputSelect
          allowNull={true}
          defaultOptionLabel='None'
          label={'Select Property'}
          value={form.propertyId}
          options={form.properties}
          onValueChange={id => {
            if (!id) {
              form.setBuildingId('')
            }

            form.setPropertyId(id)
          }}
        />

        <InputSelect
          allowNull={true}
          disabled={!form.propertyId}
          defaultOptionLabel='None'
          label={'Select Building'}
          value={form.buildingId}
          options={form.buildings}
          onValueChange={form.setBuildingId}
        />

        {!!form.buildingId && (
          <Credentials
            storedPhone={form.buildingPerson?.person.mobilePhone}
            storedEmail={form.buildingPerson?.person.email}
            storedPassword={secret.data}
            onSubmit={async (email, password, mobilePhone) => {
              let buildingPersonId: string | undefined = form.buildingPerson?.person.id

              if (!buildingPersonId) {
                const response = await secret.createAccount({
                  buildingId: Number(form.buildingId),
                  email,
                  mobilePhone,
                })

                buildingPersonId =
                  response.data?.utility.addBuildingAccount.buildingPersonId
              }

              secret.store({
                personSecret: password,
                secretType: SecretType.BUILDING_ACCOUNT_EMAIL_PASSWORD,
                buildingPersonId: Number(buildingPersonId),
              })
            }}
          />
        )}
      </Panel>
    </Section>
  )
}

export default EmailRegistration
