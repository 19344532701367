import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import DataGrid from '../../components/DataGrid'

import Column from '../../components/Grid/Column'
import Row from '../../components/Grid/Row'
import Section from '../../components/Grid/Section'
import Paginator from '../../components/Paginator'
import Panel from '../../components/Panel'
import './LockActivityLogs.style.scss'
import Badge from '../../components/Badge'
import {IActivityLogView} from '../../data/graphql/queries/activityLogs/types'
import {capitalize, formatDateTime} from '../../functions'
import {useQueryOptions, SortOptionsItem} from '../../hooks/useQueryOptions'
import Search from '../../layouts/People/Search/Search'
import SearchFilterInput from '../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../layouts/People/Search/SearchSortBy'
import useLockActivityLogs from '../../hooks/useLockActivityLogs'
import ActivityFilters, {
  TActivityFilterFields,
} from '../GuestsOverview/ActivityFilters/ActivityFilters'
import {getLogEventInfo} from '../../functions/logs'
import {ExportTableContext} from '../../components/ExportTable/ExportTableContextProvider'
import CrashScreen from '../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import TableNumberOfItems from '../../components/TabelCountItems/TableNumberOfItems'
import TableFooter from '../../components/TableFooter/TableFooter'
import {useGetEmptyTableMessage} from '../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../hooks/useTableSort'

interface Log {
  name: JSX.Element | string
  action: JSX.Element
  date: string | null
  category: string
  property: string
  unit: string
  personId: string
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'name:asc', value: 'FULL_NAME_ASC', label: 'Full Name Asc'},
  {sortKey: 'name:desc', value: 'FULL_NAME_DESC', label: 'Full Name Desc'},
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Number Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Number Desc'},
  {sortKey: 'building:asc', value: 'BUILDING_NAME_ASC', label: 'Building Name Asc'},
  {sortKey: 'building:desc', value: 'BUILDING_NAME_DESC', label: 'Building Name Desc'},
  {sortKey: 'date:asc', value: 'TIME_STAMP_ASC', label: 'Date Asc'},
  {sortKey: 'date:desc', value: 'TIME_STAMP_DESC', label: 'Date Desc'},
]

const LocksActivityLogs = () => {
  const {setQuery} = useContext(ExportTableContext)
  const navigate = useNavigate()
  const [logs, setLogs] = useState<Log[]>([])
  const [logsCount, setLogsCount] = useState(0)
  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    setQueryOptions,
    onChangeNumberOfItems,
  } = useQueryOptions<TActivityFilterFields>({
    page: 1,
    orderBy: ['TIME_STAMP_DESC'],
    searchTerm: '',
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    loading,
    data,
    variables: queryVariables,
    queryForDownloadTable,
  } = useLockActivityLogs(debouncedSearchTerm, queryOptions)

  const prepareLogs = useCallback((logs: IActivityLogView[]) => {
    return logs.map(log => {
      const eventInfo = getLogEventInfo(log)
      const userName = eventInfo.userName

      return {
        name: userName ? <div className='label-small-regular-12'>{userName}</div> : '—',
        action: (
          <Badge theme={eventInfo.type} size={'sm'}>
            {eventInfo.label}
          </Badge>
        ),
        date: log.timeStamp ? formatDateTime(parseInt(log.timeStamp)) : 'Invalid Date',
        category: capitalize(log.eventType || ''),
        property: log.propertyName || '—',
        building: log.buildingName || '—',
        unit: log.unitNumber || '—',
        personId: log.personId,
      }
    })
  }, [])

  useEffect(() => {
    if (data?.transactionalDb?.allLockActivityViews) {
      const logs = prepareLogs(data.transactionalDb.allLockActivityViews.nodes)
      setLogs(logs)
      setLogsCount(data.transactionalDb.allLockActivityViews.totalCount)
    }
  }, [data, prepareLogs])

  const dataForTableQuery = useCallback(async () => {
    const variables: Partial<any> = {...queryVariables}

    if ('first' in variables) {
      delete variables['first']
    }

    if ('offset' in variables) {
      delete variables['offset']
    }

    variables.orderBy = queryOptions.orderBy

    try {
      const {data} = await queryForDownloadTable()

      const logs: IActivityLogView[] = data.transactionalDb.allLockActivityViews.nodes

      const tableData = logs
        .map(log =>
          Object.values({
            name: log.fullName || '—',
            action: log.event || '—',
            category: capitalize(log.eventType || 'System'),
            property: log.propertyName || '—',
            building: log.buildingName || '—',
            unit: log.unitNumber || '—',
            date: log.timeStamp
              ? formatDateTime(parseInt(log.timeStamp))
              : 'Invalid Date',
          }),
        )
        .reverse()

      tableData.unshift([
        'Name',
        'Action',
        'Category',
        'Property',
        'Building',
        'Unit',
        'Date',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const onTypeSearchField = useCallback(
    (text: string) => {
      upsertQueryOptions(prev => ({
        ...prev,
        page: 1,
        searchTerm: text,
      }))
    },
    [upsertQueryOptions],
  )

  const onPressRow = useCallback(
    (index: number) => {
      if (logs[index]?.personId) {
        navigate(`/activity-logs/${logs[index].personId}`)
      }
    },
    [navigate, logs],
  )

  const onChangeSortOrder = value => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const onSubmitFilter = useCallback(
    (filters: TActivityFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const emptyTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Activity logs table is empty...',
  })

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'LocksActivityLogs'} data-testid={'LocksActivityLogsView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search lock'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onTypeSearchField}
                  />
                  <SearchFilters
                    filter={ActivityFilters}
                    initialValue={queryOptions.filters}
                    onSubmit={onSubmitFilter}
                  />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>
                <Panel theme={'white'}>
                  <DataGrid
                    selectableRows
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    onRowSelect={onPressRow}
                    loading={loading}
                    emptyTableComponent={emptyTable}
                    columns={[
                      {name: 'Name', key: 'name', sortable: true},
                      {name: 'Action', key: 'action'},
                      {name: 'Category', key: 'category'},
                      {name: 'Property', key: 'property'},
                      {name: 'Building', key: 'building', sortable: true},
                      {name: 'Unit', key: 'unit', sortable: true},
                      {name: 'Date', key: 'date', sortable: true},
                    ]}
                    rows={logs || []}
                  />
                </Panel>
                <TableFooter itemCount={logsCount} loading={loading}>
                  <Paginator
                    itemCount={logsCount}
                    perPage={queryOptions.limit as number}
                    currentPage={queryOptions.page as number}
                    onPageChange={page => {
                      upsertQueryOptions(prev => ({...prev, page}))
                    }}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default LocksActivityLogs
