import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {FirebaseAppProvider} from 'reactfire'
import AuthContextProvider from './contexts/AuthContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// display console warnings for all components
const consoleError = console.error
console.error = (...args) => {
  if (typeof args[0] === 'string' && args[0].startsWith('Warning: ')) {
    consoleError(...args)
  }
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </FirebaseAppProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
