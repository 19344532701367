import {useCallback} from 'react'
import {
  TGetPersonDeviceAccessesResponse,
  TGetPersonDeviceAccessesVariables,
} from './../../../data/graphql/queries/people/types'
import {GET_PERSON_DEVICE_ACCESSES} from './../../../data/graphql/queries/people/index'
import {useLazyQuery} from '@apollo/client'

const useResidentCurrentAccesses = () => {
  const [getPersonAccesses, query] = useLazyQuery<
    TGetPersonDeviceAccessesResponse,
    TGetPersonDeviceAccessesVariables
  >(GET_PERSON_DEVICE_ACCESSES)

  const fetch = useCallback(
    async (params: {personId: number | number; unitId: string | number}) => {
      const response = await getPersonAccesses({
        fetchPolicy: 'network-only',
        variables: {
          condition: {
            isActive: true,
            isDeleted: false,
            unitId: Number(params.unitId),
            personId: Number(params.personId),
          },
        },
      })

      return response.data?.transactionalDb.allPersonAccesses.nodes
    },
    [getPersonAccesses],
  )

  return {
    fetch,
    query,
    data: query.data?.transactionalDb.allPersonAccesses.nodes,
    loading: query.loading,
  }
}

export default useResidentCurrentAccesses
