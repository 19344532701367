import {useFormik} from 'formik'
import {useCallback, useEffect} from 'react'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import usePropertyDevices from '../../components/PropertyDevices/usePropertyDevices'
import {TDeviceCheckboxItem} from '../../components/PropertyDevices/PropertyDevices'
import {TDeviceItem} from './InstallerAccessPoints/InstallerAccessPoints'
import {DeviceClassTypes} from '../../data/graphql/queries/common/types'
import {
  getDeviceItemCheckboxId,
  isCommonAreaBuilding,
} from '../../functions/devices.function'
import {getInventoryId} from '../../functions/lock.functions'
import useVendorAssignments from '../../hooks/useVendorAssignments'
import useUnavailableDevices from '../../hooks/data/useUnavailableDevices'
import {isObjectEmpty} from '../../functions'
import useInstallerContext from './InstallerContext/useIntallerContext'

export type TInstallationTask = {
  deviceInventoryId: string
}

export type TCencelInstallationTask = {
  deviceWorkAssignmentId: string
}

type TUnitId = string
type TBuildingId = string
type TPropertyId = string

export type TDevices = TDeviceItem[]

type TFields = {
  propertyDevices: TDeviceCheckboxItem[]

  devices: CheckboxItem[]
  accessPoints: Record<
    TPropertyId,
    {
      data?: TDevices
      buildings: Record<
        TBuildingId,
        {
          units: Record<TUnitId, TDevices | null>
          buildingName: string
          data?: TDevices
        }
      >
    }
  >
}

type TSubmitDataResponse = {
  propertyId: string
  devicesToInstall: TInstallationTask[]
  devicesToUnassign: TCencelInstallationTask[]
}

const useDevicesAccessPoints = (personId: number | undefined) => {
  const assignments = useVendorAssignments(personId)
  const {replacements} = useInstallerContext()
  const unavailableDevices = useUnavailableDevices(personId)
  const {unitReplacementDevices, unitDevicesMap} = usePropertyDevices()

  const createCheckboxItem = useCallback(
    ({
      location,
      device,
    }: Omit<TDeviceItem, 'id' | 'name' | 'disabled'>): TDeviceCheckboxItem => {
      const deviceDescription = {
        [DeviceClassTypes.LOCK]: 'Lock',
        [DeviceClassTypes.THERMOSTAT]: 'Thermostat',
      }

      return {
        disabled: false,
        device,
        location,
        id: getDeviceItemCheckboxId(device.classTypeId, location.typeId),
        name: location.description + ' ' + deviceDescription[device.classTypeId],
      }
    },
    [],
  )

  const getDevicesCheckboxItems = useCallback(
    (devices: Omit<TDeviceItem, 'id' | 'name' | 'disabled'>[]) => {
      const options: Record<string, TDeviceCheckboxItem> = {}

      devices.forEach(deviceItem => {
        if (!deviceItem.device || !deviceItem.location) {
          return
        }

        const {device, location} = deviceItem
        const key = getDeviceItemCheckboxId(device.classTypeId, location.typeId)

        if (!options[key]) {
          options[key] = createCheckboxItem(deviceItem)
        }
      })

      return Object.values(options)
    },
    [createCheckboxItem],
  )

  const getUnitDevicesByUnitId = useCallback(
    (unitId: string) => {
      if (!unitDevicesMap[unitId]) {
        return []
      }

      const structuredDevices = getDevicesCheckboxItems(unitDevicesMap[unitId])

      return structuredDevices
    },
    [unitDevicesMap, getDevicesCheckboxItems],
  )

  const devicesLocationMapByUnitId = useCallback(
    (unitId: string) => {
      const floorPlanUnitDevices = getUnitDevicesByUnitId(unitId)

      return floorPlanUnitDevices.reduce<Record<string, TDeviceItem>>(
        (result, device) => ({
          ...result,
          [`${device.device.classTypeId}:${device.location.typeId}`]: device,
        }),
        {},
      )
    },
    [getUnitDevicesByUnitId],
  )

  const getDeviceClassTypeKey = (device?: Pick<TDeviceItem, 'device' | 'location'>) => {
    return `${device?.device.classTypeId}:${device?.location.code}`
  }

  const {values, errors, touched, setFieldValue, handleChange, handleBlur, ...form} =
    useFormik<TFields>({
      initialValues: {
        propertyDevices: [],
        devices: [],
        accessPoints: {},
      },
      onSubmit: values => {
        let commonAreaPropertyId = ''
        const devicesToUnassign: TCencelInstallationTask[] = []
        const devicesToInstall: TInstallationTask[] = []

        Object.keys(values.accessPoints).forEach(propertyId => {
          const property = values.accessPoints[propertyId]
          const propertyDevices = property.data

          Object.keys(property.buildings).forEach(buildingId => {
            const building = property.buildings[buildingId]
            const buildingDevices = building?.data

            if (isCommonAreaBuilding(building)) {
              commonAreaPropertyId = propertyId
            }

            Object.keys(building?.units || {}).forEach(unitId => {
              const unitDevices = building.units[unitId]

              const devicesLocationMap = devicesLocationMapByUnitId(unitId)
              const unitFlattenDevices =
                unitDevices ?? buildingDevices ?? propertyDevices ?? []

              const devices = unitFlattenDevices.reduce<TDeviceItem[]>(
                (result, deviceItem) => {
                  const unitActualDevice =
                    devicesLocationMap[
                      `${deviceItem.device.classTypeId}:${deviceItem.location.typeId}`
                    ]

                  if (unitActualDevice) {
                    result.push({
                      ...deviceItem,
                      device: {...deviceItem.device, ...unitActualDevice.device},
                    })
                  }

                  return result
                },
                [],
              )

              devices.forEach(({checked, device, location}) => {
                const classTypeId = getDeviceClassTypeKey({device, location})
                const deviceInventoryId = getInventoryId(
                  unitId,
                  device.typeId,
                  location.typeId,
                )

                const isAlreadyAssigned =
                  !!assignments.assignmentsByInventoryId[deviceInventoryId]

                if (unavailableDevices.devicesByInventoryId[deviceInventoryId]) {
                  return
                }

                if (isAlreadyAssigned) {
                  return
                }

                if (checked) {
                  if (
                    replacements.includes(deviceInventoryId) &&
                    unitReplacementDevices[unitId][classTypeId]
                  ) {
                    const replacementDevice = unitReplacementDevices[unitId][classTypeId]

                    const inventoryId = getInventoryId(
                      unitId,
                      replacementDevice.device.typeId,
                      replacementDevice.location.typeId,
                    )

                    devicesToInstall.push({
                      deviceInventoryId: inventoryId,
                    })

                    return
                  }

                  devicesToInstall.push({
                    deviceInventoryId,
                  })
                }
              })
            })
          })
        })

        Object.keys(assignments.accessPointsAssignments).forEach(propertyId => {
          const property = assignments.accessPointsAssignments[propertyId]
          const propertyDevices = property?.data
          if (property) {
            Object.keys(property.buildings).forEach(buildingId => {
              const building = property.buildings[buildingId]
              const buildingDevices = building?.data
              if (building) {
                Object.keys(building?.units || {}).forEach(unitId => {
                  if (building.units) {
                    const unitDevices = building.units[unitId]
                    const devicesLocationMap = devicesLocationMapByUnitId(unitId)
                    const cPropertyDevices = values.accessPoints[propertyId]?.data
                    const cBuildingDevices =
                      values.accessPoints[propertyId]?.buildings[buildingId]?.data
                    const cUnitDevices =
                      values.accessPoints[propertyId]?.buildings[buildingId]?.units[
                        unitId
                      ]

                    const initalDevices =
                      unitDevices ?? buildingDevices ?? propertyDevices ?? []

                    const cUnitFlattenDevices =
                      cUnitDevices ?? cBuildingDevices ?? cPropertyDevices ?? []

                    const currentDevices = cUnitFlattenDevices.reduce<TDeviceItem[]>(
                      (result, deviceItem) => {
                        const unitActualDevice =
                          devicesLocationMap[
                            `${deviceItem.device.classTypeId}:${deviceItem.location.typeId}`
                          ]

                        if (unitActualDevice) {
                          result.push({
                            ...deviceItem,
                            device: {...deviceItem.device, ...unitActualDevice.device},
                          })
                        }

                        return result
                      },
                      [],
                    )

                    initalDevices.forEach(({checked, device, location, disabled, id}) => {
                      const deviceInventoryId = getInventoryId(
                        unitId,
                        device.typeId,
                        location.typeId,
                      )

                      const deviceWorkAssignment =
                        assignments.assignmentsByInventoryId[deviceInventoryId]

                      if (unavailableDevices.devicesByInventoryId[deviceInventoryId]) {
                        return
                      }

                      if (!deviceWorkAssignment) {
                        return
                      }

                      const isCurrentlyChecked = !!currentDevices.find(d => d.id === id)
                        ?.checked

                      if (checked && !isCurrentlyChecked) {
                        devicesToUnassign.push({
                          deviceWorkAssignmentId: deviceWorkAssignment.id,
                        })
                      }
                    })
                  }
                })
              }
            })
          }
        })

        return Promise.resolve({
          propertyId: commonAreaPropertyId,
          devicesToInstall,
          devicesToUnassign,
        }) as Promise<TSubmitDataResponse>
      },
    })

  useEffect(() => {
    const structuredDevices = getDevicesCheckboxItems(
      Object.values(unitDevicesMap).reduce(
        (result, devices) => [...result, ...devices],
        [],
      ),
    )
    setFieldValue('propertyDevices', structuredDevices)
  }, [unitDevicesMap, getDevicesCheckboxItems, setFieldValue])

  useEffect(() => {
    if (assignments.loading || !isObjectEmpty(values.accessPoints)) {
      return
    }

    setFieldValue('accessPoints', structuredClone(assignments.accessPointsAssignments))
  }, [assignments.accessPointsAssignments])

  const submitForm = () => {
    return form.submitForm() as Promise<TSubmitDataResponse>
  }

  return {
    values,
    errors,
    touched,
    handleBlur,
    submitForm,
    handleChange,
    setFieldValue,
    getUnitDevicesByUnitId,
  }
}

export default useDevicesAccessPoints
