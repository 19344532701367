import './TimePicker.style.scss'

import InputText from '../InputText/InputText'
import React, {FC} from 'react'
import InputLabel from '../InputLabel'

export type TDayTime = 'am' | 'pm'

export type TTimePickerValue = {
  hours: number
  minutes: number
  dayTime: TDayTime
}

type TTimePickerProps = {
  disabled?: boolean
  value: TTimePickerValue
  label?: string
  className?: string
  onChange: (value: TTimePickerValue) => void
}

const TimePicker: FC<TTimePickerProps> = ({
  disabled,
  value,
  label,
  className,
  onChange,
}) => {
  const onChangeHours = (fieldValue: string | number) => {
    const hours = +fieldValue

    if (isNaN(hours)) {
      return
    }

    const maxHours = value.dayTime === 'am' ? 11 : 12

    onChange({
      ...value,
      hours: hours > maxHours ? maxHours : hours,
    })
  }

  const onChangeMinutes = (fieldValue: string | number) => {
    const minutes = +fieldValue

    if (isNaN(minutes)) {
      return
    }

    onChange({
      ...value,
      minutes: minutes > 59 ? 59 : minutes,
    })
  }

  const onPressAm = () => {
    if (value.dayTime !== 'am') {
      onChange({
        ...value,
        dayTime: 'am',
      })
    }
  }

  const onPressPm = () => {
    if (value.dayTime !== 'pm') {
      onChange({
        ...value,
        dayTime: 'pm',
      })
    }
  }

  const formatTime = (value: number, unit: 'min' | 'hrs') => {
    const maxValue = unit === 'hrs' ? 12 : 59

    if (value > maxValue) {
      return maxValue.toString()
    } else if (value < 10) {
      return '0' + value
    }

    return value.toString()
  }

  return (
    <div className={`time-picker ${className || ''}`}>
      <InputLabel srOnlyLabel={!label}>{label}</InputLabel>

      <div className='time-row'>
        <div className='input-wrapper'>
          <InputText
            disabled={disabled}
            srOnlyLabel
            type={'text'}
            value={formatTime(value.hours, 'hrs')}
            placeholder={'00'}
            inputTestId={'hours'}
            onValueChange={onChangeHours}
          />
        </div>
        <span className={'subtitle time-delimiter'}>:</span>
        <div className='input-wrapper'>
          <InputText
            disabled={disabled}
            srOnlyLabel
            type={'text'}
            value={formatTime(value.minutes, 'min')}
            placeholder={'00'}
            inputTestId={'minutes'}
            onValueChange={onChangeMinutes}
          />
        </div>

        <div className={'daytime-group'}>
          <div
            className={`option ${value.dayTime === 'am' ? 'active' : ''}`}
            data-testid={'amOption'}
            onClick={onPressAm}
          >
            <span className='label'>AM</span>
          </div>
          <div
            className={`option ${value.dayTime === 'pm' ? 'active' : ''}`}
            data-testid={'pmOption'}
            onClick={onPressPm}
          >
            <span className='label'>PM</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(TimePicker)
