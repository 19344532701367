import React, {useState} from 'react'
import './InputText.style.scss'
import InputWrapper from '../InputWrapper'
import {randomString} from '../../functions'
import Icon from '../Icon'
import {IconOptions} from '../Icon/Icons.d'

export interface InputTextProps {
  id?: string
  type?:
    | 'text'
    | 'search'
    | 'email'
    | 'password'
    | 'number'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'url'
    | 'tel'
  label?: string
  srOnlyLabel?: boolean
  moreInfo?: string
  footnote?: string
  theme?: 'default' | 'gray' | 'default-inverted' | 'white'
  required?: boolean
  invalid?: boolean
  invalidMessage?: string
  prefixIcon?: IconOptions
  suffixIcon?: IconOptions
  placeholder?: string
  autocomplete?: boolean
  readonly?: boolean
  disabled?: boolean
  maxLength?: number
  value: number | string
  onValueChange?: (value: InputTextProps['value']) => void
  hanlePressSuffixIcon?(): void
  size?: 'md' | 'xs' | 'lg'
  min?: string
  max?: string
  name?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  inputTestId?: string
}

const defaultProps: InputTextProps = {
  inputTestId: '',
  size: 'md',
  id: randomString(),
  type: 'text',
  invalidMessage: 'This is required',
  value: '',
  onValueChange: v => v,
}

const InputText: React.FC<InputTextProps> = (props: InputTextProps) => {
  props = {...defaultProps, ...props}
  const [showPassword, setShowPassword] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    props.onChange?.(e)
    props.onValueChange?.(value)
  }

  function handlePressToggleShowPassword() {
    setShowPassword(prev => !prev)
  }

  const type =
    props.type === 'password' ? (showPassword ? 'text' : 'password') : props.type
  const icon = (
    props.suffixIcon === 'eye' ? (showPassword ? 'eye-off' : 'eye') : props.suffixIcon
  ) as IconOptions

  return (
    <InputWrapper
      id={props.id}
      label={props.label}
      srOnlyLabel={props.srOnlyLabel}
      required={props.required}
      moreInfo={props.moreInfo}
      footnote={props.footnote}
      invalid={props.invalid}
      invalidMessage={props.invalidMessage}
    >
      <div className={`input-text theme-${props.theme}`}>
        <input
          data-testid={props.inputTestId}
          type={type}
          id={props.id}
          disabled={props.disabled}
          className={`${props.invalid ? 'is-invalid ' : ''}${
            props.prefixIcon ? 'prefix ' : ''
          }${props.suffixIcon ? 'suffix ' : ''}input-size-${props.size}
          `}
          placeholder={props.placeholder}
          readOnly={props.readonly}
          autoComplete={props.autocomplete ? 'on' : 'off'}
          value={props.value}
          min={props.min}
          max={props.max}
          name={props.name}
          onChange={handleChange}
          onBlur={props.onBlur}
        />

        {props.prefixIcon && (
          <div className={'input-prefix-icon unselectable'}>
            <Icon icon={props.prefixIcon} />
          </div>
        )}

        {props.suffixIcon && (
          <div
            className={'input-suffix-icon'}
            onClick={
              props.type === 'password'
                ? handlePressToggleShowPassword
                : props.hanlePressSuffixIcon
            }
          >
            <Icon size='sm' icon={icon} />
          </div>
        )}
      </div>
    </InputWrapper>
  )
}

export default InputText
