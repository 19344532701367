import {useState, useEffect, useCallback, useMemo} from 'react'
import {QueryOptions} from '../models'
import {useSearchParams} from 'react-router-dom'

export const defaultQueryOptions: Required<QueryOptions<any>> = {
  limit: 10,
  page: 1,
  orderBy: [],
  searchTerm: '',
  filters: {},
  preserveParams: [],
}

export interface QueryOptionsHook<T extends {[key: string]: any}> {
  queryOptions: Required<QueryOptions<T>>
  debouncedSearchTerm: string
  setQueryOptions: (
    value:
      | Required<QueryOptions<T>>
      | ((options: Required<QueryOptions<T>>) => Required<QueryOptions<T>>),
  ) => void
  upsertQueryOptions: (
    value:
      | Partial<QueryOptions<T>>
      | ((options: Required<QueryOptions<T>>) => QueryOptions<T>),
  ) => void
  onChangeNumberOfItems: (count: string) => void
}

export type SortOptionsItem = {value: string; label: string; sortKey?: string}

export const useQueryOptions = <T extends {[key: string]: any}>(
  initialOptions: QueryOptions<T> = defaultQueryOptions,
  writeToUrl = true,
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const optionsFromParams = getOptionsFromParams(searchParams)

  const [options, setOptions] = useState<Required<QueryOptions<T>>>(
    mergeOptions(defaultQueryOptions, initialOptions, optionsFromParams),
  )

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(
    initialOptions.searchTerm as string,
  )

  useEffect(() => {
    const isFilterEmpty = !Object.values(optionsFromParams?.filters || {}).some(v => !!v)
    const copyObject = {...optionsFromParams}
    delete copyObject['filters']

    if (!Object.values(copyObject).length && isFilterEmpty) {
      const mergedOptions = mergeOptions(defaultQueryOptions, initialOptions)

      if (writeToUrl) {
        setSearchParams(getParamsFromOptions(mergedOptions), {replace: true})
      } else {
        setOptions(mergedOptions)
      }
    }
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(options.searchTerm as string)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [options.searchTerm])

  useEffect(() => {
    if (writeToUrl) {
      const optionsFromParams = getOptionsFromParams(searchParams)
      setOptions(options => {
        return mergeOptions(options, optionsFromParams)
      })
    }
  }, [searchParams, writeToUrl])

  function getOptionsFromParams(params: URLSearchParams): Partial<QueryOptions<T>> {
    const searchEntries = params.entries()

    const filters = {}
    const urlOptions = {}

    for (let entry = searchEntries.next(); !entry.done; entry = searchEntries.next()) {
      const [key, value] = entry.value
      const decodedValue = decodeURI(value)
      if (Object.hasOwn(defaultQueryOptions, key)) {
        if (key === 'limit' || key === 'page') {
          urlOptions[key] = +decodedValue
        } else if (key === 'orderBy') {
          urlOptions[key] = decodedValue ? decodedValue.split(',') : []
        } else {
          urlOptions[key] = decodedValue
        }
      } else {
        if (decodedValue) {
          filters[key] = JSON.parse(decodedValue)
        } else {
          filters[key] = ''
        }
      }
    }

    return {
      ...urlOptions,
      filters,
    } as Partial<QueryOptions<T>>
  }

  const getParamsFromOptions = (options: Required<QueryOptions<T>>) => {
    const {filters, ...optionsCopy} = {...options}
    const currentParamsEntries = Array.from(searchParams.entries())

    const preservedParams = currentParamsEntries.reduce((result, current) => {
      if (options.preserveParams?.includes(current[0])) {
        result[current[0]] = current[1]
      }

      return result
    }, {})

    const filterOptions = Object.keys(filters).reduce((result, key: string) => {
      if (filters[key] !== undefined) {
        result[key] = encodeOption(filters[key])
      }

      return result
    }, {})

    const paginationOptions = Object.keys(optionsCopy).reduce((result, key: string) => {
      if (key !== 'preserveParams') {
        result[key] = optionsCopy[key]?.toString() || ''
      }

      return result
    }, {})

    return {
      ...filterOptions,
      ...paginationOptions,
      ...preservedParams,
    }
  }

  const encodeOption = (value: any) => {
    const isEmpty = (Array.isArray(value) && !value.length) || !value

    if (!isEmpty) {
      return JSON.stringify(value)
    }

    return ''
  }

  function mergeOptions(...options: QueryOptions<T>[]) {
    return options.reduce<Required<QueryOptions<T>>>((result, current) => {
      return {
        ...result,
        ...current,
        filters: {
          ...result.filters,
          ...current.filters,
        },
      }
    }, defaultQueryOptions)
  }

  const setQueryOptions = useCallback(
    (
      value:
        | Required<QueryOptions<T>>
        | ((options: Required<QueryOptions<T>>) => Required<QueryOptions<T>>),
    ): void => {
      const newOptions = typeof value === 'function' ? value(options) : value
      if (writeToUrl) {
        setSearchParams(getParamsFromOptions(newOptions))
      } else {
        setOptions(newOptions)
      }
    },
    [options, writeToUrl],
  )

  const upsertQueryOptions = useCallback(
    (
      value:
        | Partial<QueryOptions<T>>
        | ((options: Required<QueryOptions<T>>) => QueryOptions<T>),
    ): void => {
      const newOptions = typeof value === 'function' ? value(options) : value
      const mergedOptions = mergeOptions(options, newOptions)

      if (writeToUrl) {
        setSearchParams(getParamsFromOptions(mergedOptions))
      } else {
        setOptions(mergedOptions)
      }
    },
    [options, writeToUrl],
  )

  const onChangeNumberOfItems = useCallback(
    (count: string) => {
      upsertQueryOptions({limit: +count})
    },
    [upsertQueryOptions],
  )

  const memo = useMemo(
    () => ({
      queryOptions: options,
      debouncedSearchTerm,
      setQueryOptions,
      upsertQueryOptions,
      onChangeNumberOfItems,
    }),
    [
      options,
      debouncedSearchTerm,
      setQueryOptions,
      upsertQueryOptions,
      onChangeNumberOfItems,
    ],
  )

  return memo
}
